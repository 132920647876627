<template>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <form role="form" name="locationForm" ng-init="initLocationLookup()">
                <div class="card">
                    <div class="card-header">
                        <div class="title">Order Status Tracking</div>
                    </div>
                    <div class="card-body">
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Managed Status</label>
                            <div>
                                <div class="d-flex status-container">
                                <input class="large-checkbox"
                                                     type="checkbox"
                                                    v-model="data.model.tracking.preparing">
                                                    <span class="status-label">Preparing</span>
                                    <field-error :errors="data.errors" field="preparing"></field-error>
                                </div>
                                <div class="d-flex status-container">
                                     <input class="large-checkbox"
                                         type="checkbox"
                                        v-model="data.model.tracking.ready">
                                        <span class="status-label">Ready</span>
                                    <field-error :errors="data.errors" field="ready"></field-error>
                                </div>
                                <div class="d-flex status-container">
                                     <input
                                        class="large-checkbox" type="checkbox"
                                        v-model="data.model.tracking.delivering">
                                        <span class="status-label">Delivering</span>
                                    <field-error :errors="data.errors" field="delivering"></field-error>
                                </div>
                                <div class="d-flex status-container">
                                     <input
                                        class="large-checkbox" type="checkbox"
                                        v-model="data.model.tracking.delivered">
                                        <span class="status-label">Delivered</span>
                                    <field-error :errors="data.errors" field="delivered"></field-error>
                                </div>
                                <div class="d-flex status-container">
                                     <input
                                        class="large-checkbox" type="checkbox"
                                        v-model="data.model.tracking.tender">
                                        <span class="status-label">Tendering</span>
                                    <field-error :errors="data.errors" field="tender"></field-error>
                                </div>
                            </div>
                            <field-error :errors="data.errors" field="orderDaysMaintain"></field-error>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row justify-content-center">
            <div class="col-12">
                <form role="form" name="locationForm" ng-init="initLocationLookup()">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Order History</div>

                        </div>
                        <div class="card-body">
                            <div class="form-group col-12">
                                <label for="exampleInputEmail1">Business Day Rollover
                                    Time</label>
                                <div class="d-flex align-items-center">
                                    <b-form-select v-model="data.model.businessDayRolloverHours" :options="hoursOptions" class="col-sm-2" value-field="value" text-field="label"></b-form-select>
                                    <div> : </div>
                                    <b-form-select v-model="data.model.businessDayRolloverMins" :options="minsOptions" class="col-sm-2" value-field="value" text-field="label"></b-form-select>

                                </div>
                                <field-error :errors="data.errors" field="businessDayRolloverHours"></field-error>
                                <field-error :errors="data.errors" field="businessDayRolloverMins"></field-error>
                            </div>
                            <div class="form-group col-12">
                                <label for="exampleInputEmail1">Days of History to Maintain on Devices</label>
                                <div>
                                    <b-form-select v-model="data.model.orderDaysMaintain" :options="daysOptions" class="col-sm-2" value-field="value" text-field="label"></b-form-select>
                                </div>
                                <field-error :errors="data.errors" field="orderDaysMaintain"></field-error>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
</div>
</template>

<script>


import FieldError from '@/components/FieldError';

function createMinsOptions() {
    var minsOptions = [];
	for (var i=0;i<60;i++) {
		minsOptions.push({
			value : i,
			label : i<10?'0'+i:''+i
		});
	}
	return minsOptions;
}

function createHoursOptions() {
    var hoursOptions = [];
    for (var i=0;i<24;i++) {
        hoursOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return hoursOptions;
}

function createDaysOptions() {
    var daysOptions = [];
    for (var i=1;i<60;i++) {
        daysOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return daysOptions;
}

function createPrepTimeOptions() {
    var prepTimeOptions = [];
	var values = [7,10,15,20,25,30,45,60,75];
	values.forEach(function(i) {
		prepTimeOptions.push({
			value : i,
			label : (i<10?'0'+i:''+i)+' mins'
		});
	});
	return  prepTimeOptions;
}

export default {
  name: 'LocationPosConfig',
  components: {
    FieldError
  },
  props : ['data'],
  data : function() {
    var d =  {
         prepTimeOptions : createPrepTimeOptions(),
         daysOptions : createDaysOptions(),
         minsOptions : createMinsOptions(),
         hoursOptions : createHoursOptions()
         };

    return d;
  },

  methods : {

    }
};
</script>

<style scoped>
.large-checkbox {
    width: 25px;
    height: 25px;
}

.status-container {
    margin-bottom: 5px;
}

.status-label {
    margin-left: 25px;
}
</style>