<template>
    <div class="container-fluid">
        <div class="row">
            <div v-for="(report,index) in reports" v-bind:key="index" :class="report.cssClass">
                <dashboard-report :report="report"></dashboard-report>
            </div>
            <div v-if="reports.length==0" class="col-12">
                <p>No dashboard widgets configured. Please add some widgets.</p>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardReport from '../charts/DashboardReport'
import {RestHelper} from "@/utils/RestHelper";

function createReportViewModel(reportConfigs, reportConfig, reports) {
        var queryData=reportConfig.queryData;
        var displayConfig=reportConfig.displayConfig;
        var reportData = null;
        var displayData = {
                config : displayConfig,
                menuSections : [
                    {
                        header : 'Select Chart Width',
                        items:[{
                            name : 'Full Width',
                            select : function() {
                                displayData.config.width=12;
                                refreshDisplayConfig();
                            }
                        },{
                            name : '3/4 Width',
                            select : function() {
                                displayData.config.width=9;
                                refreshDisplayConfig();
                            }
                        },{
                            name : '1/2 Width',
                            select : function() {
                                displayData.config.width=6;
                                refreshDisplayConfig();
                            }
                        },{
                            name : '1/4 Width',
                            select : function() {
                                displayData.config.width=3;
                                refreshDisplayConfig();
                            }
                        }]
                    },
                    {
                    items:[{
                        name : 'Move Up',
                        select : function() {
                            var index = reportConfigs.indexOf(reportConfig);
                            if (index>=1) {
                                var toInsert1=index-1;
                                reportConfigs.splice(index, 1);
                                reportConfigs.splice(toInsert1, 0, reportConfig);
                            }
                            index = reports.indexOf(reportViewModel);
                            if (index>=1) {
                                var toInsert2=index-1;
                                reports.splice(index, 1);
                                reports.splice(toInsert2, 0, reportViewModel);
                            }
                        }
                    },{
                        name : 'Move Down',
                        select : function() {
                            var index = reportConfigs.indexOf(reportConfig);
                            if (index<reportConfigs.length-1) {
                                var toInsert1=index+1;
                                reportConfigs.splice(index, 1);
                                reportConfigs.splice(toInsert1, 0, reportConfig);


                            }
                            index = reports.indexOf(reportViewModel);
                            if (index<reports.length-1) {
                                var toInsert2=index+1;
                                reports.splice(index, 1);
                                reports.splice(toInsert2, 0, reportViewModel);


                            }
                        }
                    },{
                        name : 'Remove From Dashboard',
                        select : function() {
                            var index = reportConfigs.indexOf(reportConfig);
                            if (index>=0) {
                                reportConfigs.splice(index, 1);
                            }
                            index = reports.indexOf(reportViewModel);
                            if (index>=0) {
                                reports.splice(index, 1);
                            }
                        }
                    }]
                }]
        };




        function refreshDisplayConfig() {
            var cssClass='';
            if (displayData.config.width==null) {
                cssClass='col-12 col-md-12';
            } else {
                if (displayData.config.width>=4) {
                    cssClass='col-12 col-md-'+displayData.config.width;
                } else {
                    cssClass='col-6 col-md-'+displayData.config.width;
                }
            }
            reportViewModel.cssClass=cssClass;
        }


        var reportViewModel = {
            getReportData:function() {
                var promise = new Promise(function(resolve, reject) {


                if (reportData!=null) {
                    resolve(reportData);
                } else {
                    RestHelper.post('/wb/api/admin/reports/execute', queryData).then(function(response) {
                        if (response.data.success) {
                            reportData=response.data.result;
                            resolve(reportData);
                        } else {
                            reject();
                        }
                    });
                }
                });
                return promise;
            },
            cssClass : null,
            reportType : reportConfig.reportType,
            refresh:function() {
                reportData = null;
                return this.getReportData();
            },
            getDisplayData:function() {
                var promise = new Promise(function(resolve) {
                    resolve(displayData);
                });
                return promise;
            }
        };
        refreshDisplayConfig();
        return reportViewModel;
    }

function loadReports(reportConfigs, reports) {
        reportConfigs.forEach(function(config) {
            reports.push(createReportViewModel(reportConfigs, config, reports));
        });
    }

export default {
  name: 'DashboardReports',
  components: {
    DashboardReport
  },
  props : ['reportList'],
  data : function() {
    var d = {
         reports : []
     };
     return d;
  },
  methods : {
    reloadReports : function() {
        var that=this;
        this.reports=[];
        if (this.reportList!=null) {
            loadReports(this.reportList, that.reports);
        }
      }
    },
    created : function() {
        this.reloadReports();
    }
};
</script>

<style scoped>

</style>