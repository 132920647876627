<template>
    <div class="card report-result-card">
      <div class="card-header d-flex p-0 ui-sortable-handle" style="cursor: move;">

        <h3 class="card-title flex-grow-1  p-3" v-if="reportData.config">
          {{reportData.config.name}}
        </h3>

        <div class="card-tools">
          <b-dropdown ref="dropdown" size="lg" class="dropdown-btn" variant="link" dropleft toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <fa class="fa" icon="bars"></fa>
              </template>
              <b-dropdown-form>
                <div v-for="(section, index) in menuSections" v-bind:key="index">
                  <div class="dropdown-divider" v-if="index>0"></div>
                  <h6 class="dropdown-header" v-if="section.header">{{section.header}}</h6>
                  <a href="#" class="dropdown-item" v-for="(item, index2) in section.items" v-bind:key="index2" @click.stop.prevent="select(item)">{{item.name}}</a>
                </div>
              </b-dropdown-form>
            </b-dropdown>
            
            <button type="button" class="btn btn-tool" data-widget="collapse">
                <i class="fa fa-minus"></i>
            </button>
        </div>
            
      </div><!-- /.card-header -->
      <div class="card-body p-0">
            <report-table :report-config="reportData" :dataset="dataset" v-if="chartType=='table'"/>
            <bar-chart :report-config="reportData" :dataset="dataset" :chart-config="chartConfig" v-if="chartType=='bar'"/>
            <line-chart :report-config="reportData" :dataset="dataset" :chart-config="chartConfig" v-if="chartType=='line'"/>
            <pie-chart :report-config="reportData" :dataset="dataset" :chart-config="chartConfig" v-if="chartType=='pie'"/>
        </div>
    </div>
</template>

<script>

function createMenuOptions(data) {
        data.menuSections=[];
        if (data.chartTypes.length>1) {
            data.menuSections.push(createChartMenuSection(data));
        }
        data.displayData.menuSections.forEach(function(menuSection) {
            data.menuSections.push(menuSection);
        });
    }

    function createChartMenuSection(data) {
        var menuSection={
            header : 'Select Chart Type',
            items : [

            ]
        };
        data.chartTypes.forEach(function(type) {
            menuSection.items.push({
                name : type,
                select : function() {
                    data.setChartType(type);
                }
            })
        });
        return menuSection;
    }

function processReportData(data, reportData) {
    data.empty=reportData.results[0].data.length===0;
    data.reportData=reportData;
    data.dataset=data.reportData.results[0];  	
    data.chartTypeMap={};
    if (data.reportData.config.charts!=null) {
        data.reportData.config.charts.forEach(function(chart) {
            chart.types.forEach(function(type) {
                data.chartTypes.push(type);
                data.chartTypeMap[type]=chart;
            });
        });
    }
    data.chartTypes.push('table');
    if (data.displayData.config.chartType==null) {
        data.displayData.config.chartType=data.chartTypes[0];
    }
    data.setChartType(data.displayData.config.chartType);
    createMenuOptions(data);
}

function processDisplayData(data, displayData) {
    data.displayData=displayData;
    if (displayData.config==null) {
         data.config={};
     }
     if (displayData.menuSections==null) {
         data.menuSections=[];
     }
}
import ReportTable from './ReportTable';
import BarChart from './BarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';

export default {
  name: 'Chart',
  props : ['report'],
  components: {
    ReportTable, BarChart, LineChart, PieChart
  },
   data : function() {
      return {
          menuSections : [],
          config : {},
          chartType : null,
          chartTypes : [],
          reportData : {
            config : {
            }
          }
      };
    },
    watch : {
        model : function() {
            this.loadData();
        }

    },
  methods : {
      select : function(item) {
          item.select();
          this.$refs.dropdown.hide(true);
      },
      setChartType : function(type) {
            this.displayData.config.chartType=type;
            this.chartType=type;
            this.chartConfig=this.chartTypeMap[this.chartType];
            //this.refresh();
        },
      loadData : function() {
          var that=this;
          this.report.getDisplayData().then(function(displayData) {
              processDisplayData(that, displayData);
          }, function(err) {
              alert(err);
          });
          this.report.getReportData().then(function(reportData) {
              processReportData(that, reportData);
          }, function(err) {
                       alert(err);
                   });
  }
    },
    created : function() {
      this.loadData();
    }
  };
</script>

<style scoped>
    .report-result-card .chart-container {
        width: 100%;
    }

    .report-result-card .dropdown-btn .fa {
            color : black;
        }
</style>

<style>
    .report-result-card form {
        padding: 0px;
    }


</style>