<template>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{param.name}}</label>
      <div class="col-sm-9">
        <wysiwyg v-model="param.value" placeholder="Content"></wysiwyg>
      </div>
    </div>
</template>

<script>

import Wysiwyg from '@/components/Wysiwyg'

export default {
  name: 'HtmlParam',
  components: {
    Wysiwyg
  },
  props : ['param'],
  data : function() {
    var d = {};
    return d;
  },
  methods : {
    }
};
</script>

<style scoped>
</style>