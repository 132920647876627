import {CategoryViewModelFactory} from '@/services/menu/CategoryViewModelFactory'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'

var MenuViewModelFactory = {

    create : function(menu, workAreaModifier) {
        var menuModifier = {
            applyCategory : function(cvm) {
                if (cvm.pinned) {
                    MenuViewModel.pinnedCategory=cvm;
                    MenuViewModel.model.pinnedCategory=cvm.model.id;
                } else {
                    var index=null;
                    for (var i=0;i<MenuViewModel.model.categories.length;i++) {
                        if (MenuViewModel.model.categories[i].id==cvm.model.id) {
                            index=i;
                            break;
                        }
                    }
                    if (index==null) {
                        MenuViewModel.model.categories.push({
                            id : cvm.model.id,
                            index : cvm.model.index
                        });
                    }
                    index=null;
                    for (i=0;i<MenuViewModel.categories.length;i++) {
                        if (MenuViewModel.categories[i].model.id==cvm.model.id) {
                            index=i;
                            break;
                        }
                    }
                    if (index==null) {
                        MenuViewModel.categories.push(cvm);
                    }
                }
                originalStr=JSON.stringify(MenuViewModel.model);
            },
            deleteCategory : function(cvm) {
                if (cvm.pinned) {
                    MenuViewModel.pinnedCategory=null;
                    MenuViewModel.model.pinnedCategory=null;
                } else {
                    var index = MenuViewModel.model.categories.indexOf(cvm.model);
                    if (index>=0) {
                        MenuViewModel.model.categories.splice(index, 1);
                    }
                    index = MenuViewModel.categories.indexOf(cvm);
                    if (index>=0) {
                        MenuViewModel.categories.splice(index, 1);
                    }
                }
            }
        };



        var MenuViewModel = {
            createCategory : function() {
                var categoryViewModel = new CategoryViewModelFactory.create(null, MenuViewModel, menuModifier, workAreaModifier, false);
                return categoryViewModel;
            },
            createPinnedCategory : function() {
                var categoryViewModel = new CategoryViewModelFactory.create(null, MenuViewModel, menuModifier, workAreaModifier, true);
                return categoryViewModel;
            },
            reset : reset
        };

        if (menu!=null) {
            var pinnedCategoryId=null;
            var refs=[];
            var index=0;
            if (menu.pinnedCategory!=null) {
                var cvmPinned = CategoryViewModelFactory.create(menu.pinnedCategory, MenuViewModel, menuModifier, workAreaModifier, true);
                workAreaModifier.applyCategory(cvmPinned);
                pinnedCategoryId=menu.pinnedCategory.id;
            }
            menu.categories.forEach(function(category) {
                refs.push({
                    id : category.id,
                    index : index
                });
                index++;
                var cvm = new CategoryViewModelFactory.create(category, MenuViewModel, menuModifier, workAreaModifier, false);
                workAreaModifier.applyCategory(cvm);
            });
            menu.categories=refs;
            menu.pinnedCategory=pinnedCategoryId;

        } else {
            menu={
                    id : null,
                    name : null,
                    categories : [],
                    pinnedCategory : null
            };
        }
        var originalStr=null;

        MenuViewModel.isDirty=function() {
            var o=originalStr;
            var n=JSON.stringify(MenuViewModel.model);
            var dirty = (o!==n);
            return dirty;
        };

        function reset() {
                    originalStr=JSON.stringify(menu)
                    MenuViewModel.model=JSON.parse(originalStr)
                    if (MenuViewModel.model.pinnedCategory!=null) {
                        MenuViewModel.pinnedCategory=workAreaModifier.getCategoryById(MenuViewModel.model.pinnedCategory);
                    } else {
                        MenuViewModel.pinnedCategory=null;
                    }
                    MenuViewModel.categories=[];
                    MenuViewModel.model.categories.forEach(function(category) {
                        var cvm = workAreaModifier.getCategoryById(category.id);
                        MenuViewModel.categories.push(cvm);
                    });

                }

        MenuViewModel.reorderCategories=function(fromIndex, toIndex) {
            if (fromIndex!=toIndex) {
                    var fromItem=MenuViewModel.categories[fromIndex];
                    MenuViewModel.categories.splice(fromIndex, 1);
                    MenuViewModel.categories.splice(toIndex, 0, fromItem);

                    var fromModel=MenuViewModel.model.categories[fromIndex];
                    MenuViewModel.model.categories.splice(fromIndex, 1);
                    MenuViewModel.model.categories.splice(toIndex, 0, fromModel);

                    var index=0;
                    MenuViewModel.model.categories.forEach(function(c) {
                        c.index=index;
                        index++;
                    });
            }


        };

        function handleSaveResult(resolve,reject, result) {
            if (result.data.success) {
                menu=result.data.result;
                reset();
                workAreaModifier.applyMenu(MenuViewModel);
                resolve({
                    success : true
                });
            } else {
                MenuViewModel.errors.setServerErrors(result.data.errors);
                resolve({
                    success : false
                });
            }
        }

        MenuViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
            RestHelper.put('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/menu', MenuViewModel.model).then(function(result) {
                handleSaveResult(resolve,reject, result);
            }, PromiseUtils.errorHandler(reject));
            });
            return promise;
        };

        reset();

        MenuViewModel=reactive(MenuViewModel);

        return MenuViewModel;
    }

};

export {MenuViewModelFactory as MenuViewModelFactory};