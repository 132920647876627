import { reactive } from '@vue/composition-api'
import {ExtraViewModelFactory} from './ExtraViewModelFactory'
import {GroupingViewModelFactory} from './GroupingViewModelFactory'

var ExtrasParentViewModelFactory = {

    create : function(parentViewModel, workAreaModifier) {
        var parent=parentViewModel.model;

        /*


        		var parentIndex=0;

        		this.resetParent=function(parentP) {
        			parent=parentP;
        			reset();
        			addLocalExtras();
        			addParentExtras();
        		};

        */

        var localExtraViewModels = [];
        var changeListeners = [];
        var parentNode = null;
        var changeListener = null;

        function setParentNode(parentNodeP) {
            removeParentExtras();
            if (parentNodeP==null) {
                parentNode=null;
                if (changeListener!=null) {
                    changeListener();
                    changeListener=null;
                }
            } else {
                parentNode = parentNodeP.extrasParent;
                addParentExtras();
                changeListener = parentNode.addChangeListener(function() {
                    resetParentExtras();
                });
                notifyChangeListeners();
            }

        }

        function addChangeListener(listener) {
            changeListeners.push(listener);
            return (function() {
                var index = changeListeners.indexOf(listener);
                if (index>=0) {
                    changeListeners.splice(index, 1);
                }
            });
        }

        function notifyChangeListeners() {
            changeListeners.forEach(function(listener) {
                listener.call(this);
            });
        }

        function resetParentExtras() {
            removeParentExtras();
            addParentExtras();
            notifyChangeListeners();
        }

        function removeParentExtras() {
            var removes=[];
            for (var i=0;i<ExtrasParentViewModel.extras.length;i++) {
                var index = localExtraViewModels.indexOf(ExtrasParentViewModel.extras[i]);
                if (index==-1) {
                    removes.push(i);
                }
            }
            for (var a=removes.length-1;a>=0;a--) {
                ExtrasParentViewModel.extras.splice(removes[a], 1);
            }
        }

        function addParentExtras() {
            if (parentNode==null || parent.dontInheritParentExtras) {
                return;
            }
            var startIndex=0;
            for (startIndex=0;startIndex<ExtrasParentViewModel.extras.length;startIndex++) {
                if (!ExtrasParentViewModel.extras[startIndex].model.beforeParentExtras) {
                    break;
                }
            }

            parentNode.extras.forEach(function(extra) {
                ExtrasParentViewModel.extras.splice(startIndex, 0, extra);
                startIndex++;
            });
        }

        function addLocalExtras() {
            if (parent.extras!=null) {
                var extras = parent.extras;
                parent.extras=[];
                //var preIndex=0;
                extras.forEach(function(extra) {
                    doAddProductExtra(extra);
                });
            } else {
                parent.extras=[];
            }
        }

        function doAddProductExtra(extra) {
             parent.extras.push(extra);

                var productExtraViewModel = createProductExtraViewModel(extra);
            if (!productExtraViewModel.model.beforeParentExtras) {
                ExtrasParentViewModel.extras.push(productExtraViewModel);
            } else {
                var i=0;
                for (i=0;i<ExtrasParentViewModel.extras.length;i++) {
                    if (localExtraViewModels.indexOf(ExtrasParentViewModel.extras[i])==-1) {
                        break;
                    }
                }
                ExtrasParentViewModel.extras.splice(i,0,productExtraViewModel);
            }
            localExtraViewModels.push(productExtraViewModel);
            notifyChangeListeners();
        }

        function createProductExtraViewModel(productExtra) {
            return ExtraViewModelFactory.create(productExtra, parent, ExtrasParentViewModel.extras, localExtraViewModels, workAreaModifier);
        }

        var ExtrasParentViewModel = {
            setParentNode : setParentNode,
            addChangeListener : addChangeListener,
            resetParentExtras : resetParentExtras,
            underlying : parentViewModel,
            id : parent.id,
            extras : [],
            get dontInheritParentExtras() {
                return parent.dontInheritParentExtras;
            },
            set dontInheritParentExtras(value) {
                parent.dontInheritParentExtras=value;
                removeParentExtras();
                addParentExtras();
            },
            canEditExtra : function(extra) {
                var index = localExtraViewModels.indexOf(extra);
                return index>=0;
            },
            addExtra : function(extra) {
                doAddProductExtra({
                  "groupingId": extra.model.id,
                  "index": 0,
                  "required": false,
                  "beforeParentExtras": false,
                  "menuDisplayFormat": "groupNameSinglePrice"
                });
            },
            addProductExtra : function(productExtra) {
                doAddProductExtra(productExtra);
            },
            newModifier : function() {
                return GroupingViewModelFactory.create(null, workAreaModifier, ExtrasParentViewModel);
            }
        };

        addLocalExtras();
        addParentExtras();



        ExtrasParentViewModel.reorderModifiers=function(fromIndex, toIndex) {
              if (fromIndex!=toIndex) {
                      var fromItem=ExtrasParentViewModel.extras[fromIndex];
                      var shift=0;
                      var start=fromIndex;
                      var item;
                      if (toIndex<fromIndex) {
                        start--;
                        while (start>=toIndex) {
                            item=ExtrasParentViewModel.extras[start];
                            if (localExtraViewModels.indexOf(item)>=0) {
                                shift--;
                            }
                            start--;
                        }
                      } else {
                        start++;
                        while (start<=toIndex) {
                            item=ExtrasParentViewModel.extras[start];
                            if (localExtraViewModels.indexOf(item)>=0) {
                                shift++;
                            }
                            start++;
                        }
                      }

                      ExtrasParentViewModel.extras.splice(fromIndex, 1);
                      ExtrasParentViewModel.extras.splice(toIndex, 0, fromItem);

                      var localIndex=localExtraViewModels.indexOf(fromItem);
                      var toLocalIndex=localIndex+shift;
                      if (toLocalIndex<0) {
                        toLocalIndex=0
                      } else if (toLocalIndex>=localExtraViewModels.length) {
                        toLocalIndex=localExtraViewModels.length-1;
                      }
                      localExtraViewModels.splice(localIndex,1);
                      parent.extras.splice(localIndex,1);
                      localExtraViewModels.splice(toLocalIndex,0,fromItem);
                      parent.extras.splice(toLocalIndex,0,fromItem.model);

                    if (localExtraViewModels.length!=ExtrasParentViewModel.extras.length) {
                        var overallIndexValue=ExtrasParentViewModel.extras.indexOf(fromItem);
                        var localIndexValue=localExtraViewModels.indexOf(fromItem);
                        fromItem.model.beforeParentExtras=(overallIndexValue==localIndexValue);
                    }


                      var index=0;
                      localExtraViewModels.forEach(function(p) {
                          p.model.index=index;
                          index++;
                      });
              }
          };

        ExtrasParentViewModel.canReorderModifiers=function(fromIndex, toIndex) {
            if (toIndex==0 || toIndex==ExtrasParentViewModel.extras.length-1) {
                return true;
            }
            if (fromIndex<toIndex) {
                if (ExtrasParentViewModel.canEditExtra(ExtrasParentViewModel.extras[toIndex])) {
                    return true;
                }
                if (toIndex<ExtrasParentViewModel.extras.length-1) {
                    if (ExtrasParentViewModel.canEditExtra(ExtrasParentViewModel.extras[toIndex+1])) {
                        return true;
                    }
                }
                return false;
            } else {
                var prev=ExtrasParentViewModel.extras[toIndex];
                var next=ExtrasParentViewModel.extras[toIndex-1];
                if (!ExtrasParentViewModel.canEditExtra(prev) && !ExtrasParentViewModel.canEditExtra(next)) {
                    return false;
                } else {
                    return true;
                }
            }
       };

        ExtrasParentViewModel=reactive(ExtrasParentViewModel);

        return ExtrasParentViewModel;
    }



};

export {ExtrasParentViewModelFactory as ExtrasParentViewModelFactory};