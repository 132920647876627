<template>
    <div class="table-container">
        <b-table :fields="fields" :items="dataset.data" empty-text="No data" responsive bordered striped show-empty/>
    </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'ReportTable',
  props : ['reportConfig', 'dataset'],
  components: {


  },
   data : function() {
        var fields=[];
        var that=this;
        var index=0;
        this.reportConfig.config.tableColumns.forEach(function(col) {
            fields.push({ key: ''+index, label : col.name, sortable : true, formatter : function(value) {return that.format(value, col.type);}});
            index++;
        });
      return {
        fields : fields
      };
    },
    methods : {
            format : function(value, type) {
                switch (type) {
                case 'date':
                    return moment(new Date(value)).format('YYYY-MM-DD');
                case 'money':
                    return this.$filters.toCurrency(value);
                default:
                    return value;
                }
            }
    },
    created : function() {

    }
  };
</script>

<style scoped>

</style>