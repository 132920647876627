<template>
    <form role="form" name="clubConfigForm">
        <div v-if="!data.initialising">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Loyalty Program Details</div>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Name</label> <input type="text"
                                    class="form-control" id="name" placeholder="Enter name"
                                    v-model="data.model.name"
                                        >
                                <field-error :errors="errors" field="name"></field-error>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Slogan</label> <input type="text"
                                    class="form-control" id="name" placeholder="Enter slogan"
                                    v-model="data.model.slogan"
                                        >
                                <field-error :errors="errors" field="slogan"></field-error>
                            </div>
                            <div class="form-group col-12">
                                <label for="exampleInputEmail1">Logo</label>
                                <div>
                                    <image-preview width="175" :src="data.model.logoUrl" :edit="true"/>
                                    <div class="options">
                                        <button class="btn" @click.stop.prevent="editImage()">EDIT</button>
                                        <button class="btn" @click.stop.prevent="deleteImage()">DEL</button>
                                    </div>
                                    <field-error :errors="errors" field="logoUrl"></field-error>
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Enabled</label>
                                <b-form-checkbox switch size="lg" v-model="data.model.enabled"></b-form-checkbox>
                                <field-error :errors="errors" field="enabled"></field-error>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Reward Setup</div>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Point Allocation</label>
                                <select class="form-control" v-model="selectedAllocation">
                                    <option v-for="(option,index) in allocationOptions" :key="index" v-bind:value="option">
                                        {{ option.label }}
                                      </option>
                                </select>
                                <field-error :errors="errors" field="pointsPerSpend"></field-error>
                                <field-error :errors="errors" field="spendTrigger"></field-error>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Points Per Reward</label>
                                <input type="number" class="form-control" id="data.model.tier.pointsPerReward"
                                    placeholder="Enter points per reward"
                                        v-model="tier.pointsPerReward"
                                        >
                                <field-error :errors="errors" field="pointsPerReward"></field-error>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Reward Amount</label> <input money
                                    type="text" class="form-control" id="tier.rewardAmount"
                                    placeholder="Enter reward amount"
                                    v-model="tier.rewardAmount"
                                        >
                                <field-error :errors="errors" field="pointsPerReward"></field-error>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="exampleInputEmail1">Days for Expiry</label> <input
                                    type="number" class="form-control" id="data.model.tier.rewardExpiryDays"
                                    placeholder="Expiry days"
                                    v-model="tier.rewardExpiryDays"
                                        >
                                <field-error :errors="errors" field="rewardExpiryDays"></field-error>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <media-library-modal v-if="showMediaLibrary" :callback="mediaSelected" @hidden="showMediaLibrary=false"/>
    </form>
</template>

<script>
import {PageStack} from '@/services/PageStackService'
import {ErrorsService} from '@/services/ErrorsService'
import {EditManager} from "@/utils/EditManager";
import FieldError from '@/components/FieldError'
import MediaLibraryModal from '@/views/media/MediaLibraryModal'

export default {
  name: 'Club',
  components: {
    FieldError, MediaLibraryModal
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/club/clubconfig').saveEndpoint('/wb/api/admin/club/clubconfig').saveAlert('Saved', 'Changes to the club have been changed.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Loyalty Program',
         buttons : [data.createResetButton(),data.createSaveButton()],
         showMediaLibrary : false,
         data : data,
         tier : {
         },
         errors : ErrorsService.create(),
         _selectedAllocation : null,
         allocationOptions : [],
         selectedAllocation : null
     });
     
     d.allocationOptions.push({
         label : '100 Points For Every 1 Pound Spent',
         spendTrigger : 0.01,
         pointsPerSpend : 1
     });
     d.allocationOptions.push({
         label : '10 Points For Every 1 Pound Spent',
         spendTrigger : 0.10,
         pointsPerSpend : 1
     });
     d.allocationOptions.push({
         label : '1 Point For Every 1 Pound Spent',
         spendTrigger : 1.00,
         pointsPerSpend : 1
     });
         
     return d;
  },
  watch : {
    'selectedAllocation' : function(value) {
        if (value==null) {
            this.tier.spendTrigger=null;
            this.tier.pointsPerSpend=null;
         } else {
            this.tier.spendTrigger=value.spendTrigger;
            this.tier.pointsPerSpend=value.pointsPerSpend;
        }
    },
    'data.model' : function(value) {
        if (value.tiers!=null&&value.tiers.length>0) {
            this.tier=value.tiers[0];
        } else {
            value.tiers=[];
            value.tiers.push({
                spendTrigger : null,
                pointsPerSpend : null,
                pointsPerReward : null,
                rewardAmount : null,
                rewardExpiryDays : null
            });
            this.tier=value.tiers[0];
        }
        for (var i=0;i<this.allocationOptions.length;i++) {
            if (this.tier.spendTrigger==this.allocationOptions[i].spendTrigger && this.tier.pointsPerSpend==this.allocationOptions[i].pointsPerSpend) {
                this.selectedAllocation=this.allocationOptions[i];
                break;
            }
        }
    }
  },
  methods : {
        deleteImage : function() {
            this.data.model.logoUrl=null;
        },
         editImage : function() {
             this.showMediaLibrary=true;
         },
           mediaSelected : function(url) {
              this.data.model.logoUrl=url;
              this.showMediaLibrary=false;
          }
    },
    created : function() {
        this.data.load();
    }
};
</script>

<style scoped>

</style>