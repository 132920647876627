<template>
<div><div  v-if="!initialising">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Details</div>
                <div class="card-body">
                    <form role="form" name="categoryForm" novalidate>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label> <input type="text"
                                name="name" class="form-control" id="name"
                                placeholder="Enter name" v-model="data.model.name"
                                >
                            <field-error :errors="data.errors" field="name"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Description</label>
                            <textarea class="form-control" rows="3"
                                placeholder="Enter description"
                                v-model="data.model.description"></textarea>
                            <field-error :errors="data.errors" field="description"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Display on Menu</label>
                            <b-form-select v-model="data.model.menuDisplayFormat" :options="menuDisplayFormatOptions" value-field="value" text-field="label"></b-form-select>
                            <field-error :errors="data.errors"
                                field="menuDisplayFormat"></field-error>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>

    <variation-items v-if="data.created" :parent="data"/>


    <modifiers v-if="data.created" :parent="data.extrasParent"/>

    <product-image v-if="data.created" :parent="data.images"/>

</div></div>
</template>

<script>


import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError'
import {LabelService} from '@/services/LabelService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {EditManager} from '@/services/menu/EditManager'
import {WorkAreaService} from '@/services/menu/WorkAreaService'
import Modifiers from './Modifiers'
import VariationItems from './VariationItems'
import ProductImage from './ProductImage'

export default {
  name: 'Variation',
  components: {
    FieldError, Modifiers, VariationItems, ProductImage
  },
  props : ['variation'],
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Variation: ',
         buttons : [],
         initialising : false,
         menuDisplayFormatOptions : LabelService.menuDisplayFormatOptions,
         data : null
         });

     return d;
  },
  methods : {
        selectCategoryEntry : function(categoryProduct) {
            if (categoryProduct.isSingleProduct) {
                this.$router.push({ name: 'menuworkarea_product', params: {id : categoryProduct.selectedProduct.model.id, product: categoryProduct.selectedProduct }})
                }
        },
        initialiseEditManager : function() {
            this.pageHandler=EditManager.builder(this).viewModel(this.data).label('Variation').initialise();
        }
    },
    beforeRouteLeave : function(to, from, next) {
            this.pageHandler.beforeRouteLeave(to, from, next);
        },
    created : function() {
        var that=this;
        that.initialising=true;
        if (this.$route.params.id==null) {
            if (this.$route.params.categoryId!=null) {
                WorkAreaService.load().then(function(WorkArea) {
                    that.data=WorkArea.getCategory(that.$route.params.categoryId).newVariation();
                     that.initialiseEditManager();
                     that.initialising=false;
                 }, PromiseUtils.viewErrorHandler('Error loading item'));
            }
        } else {
            if (this.variation==null) {
                WorkAreaService.load().then(function(WorkArea) {
                    that.data=WorkArea.getVariation(that.$route.params.id);
                     that.initialiseEditManager();
                     that.initialising=false;
                 }, PromiseUtils.viewErrorHandler('Error loading variation'));
            } else {
                this.data=this.variation;
                that.initialiseEditManager();
                that.initialising=false;
            }
        }
    }
};
</script>

<style scoped>

</style>