import {reactive, watch,toRef} from '@vue/composition-api'

var VueUtils = {
    reactive : function(obj) {
        return reactive(obj);
    },
    watchProperty : function(parent, propertyName, callback) {
       var watchable = toRef(parent, propertyName);
       return watch(watchable, callback);
    },
    watchObject : function(object, callback) {
       return watch(object, callback);
    },
    bindProperties(sourceObject, sourceProperty, targetObject, targetProperty) {
        return this.watchProperty(sourceObject, sourceProperty, function(value) {
            targetObject[targetProperty]=value;
        });
    }
}

export {VueUtils as VueUtils};