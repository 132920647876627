<template>
    <form role="form" name="paymentForm" novalidate>
        <div class="container-fluid mb-3" >
            <div class="row">
                <div class="col-sm-12">
                    <ul class="list-group">
                        <li class="list-group-item actionable">
                            <div>
                                <span class="header">Website is: <span
                                    v-if="enabled">Enabled</span><span
                                    v-if="!enabled">Not Enabled</span></span>
                                <p v-if="!enabled">No website has been setup</p>
                                <p v-if="enabled">A website has been setup.</p>
                            </div>
                            <div>
                                <b-form-checkbox switch size="lg" v-model="enabled"></b-form-checkbox>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid" v-if="data.model.enabled">
                <div class="row">
                    <div class="col-12">
                        <ul class="list-group">
                            <li class="list-group-item actionable">
                                <div>
                                    <span class="header" v-if="data.model.published">Website is accessible via </span>
                                    <span class="header" v-if="!data.model.published">Website will be accessible via </span>
                                    <div class="my-2">
                                        <span v-if="data.model.published"><a :href="data.model.url" target="_blank">{{data.model.url}}</a></span>
                                        <span v-if="!data.model.published">{{data.model.url}}</span>
                                    </div>
                                    <p v-if="!data.model.published" class="mt-2"><i>Note: This website is currently not published.  Please configure and publish below.</i></p>
                                </div>

                                <div v-if="data.model.published">
                                    <button class="btn btn-primary" @click.prevent.stop="launch()">Launch</button>
                                </div>
                            </li>
                            </ul>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <ul class="list-group">
                            <li class="list-group-item clickable" @click="launchWebsiteConfig()"><span
                                class="header">Edit Website</span>
                                <p>Configure your website.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
    </form>
</template>

<script>
import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {AlertService} from '@/services/AlertService'

export default {
  name: 'UberEats',
  components: {

  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/website/setupstatus').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Website',
         buttons : [],
         data : data,
         enabled : false
         });

     return d;
  },
  watch : {
    enabled : function() {
        if (this.enabled!=this.data.model.enabled) {
            var that=this;
            RestHelper.put('/wb/api/admin/website/enablement', {enabled:this.enabled}).then(function(response) {
                that.data.load();
                if (response.data.success) {
                    AlertService.success('Website enablement updated');
                } else {
                    AlertService.danger('Error updating website enablement');
                }
            },PromiseUtils.viewErrorHandler('Error updating website enablement', function() {
                 that.data.load();
             }));
        }
    }
  },
  methods : {
            loadData : function() {
                var that=this;
                this.data.load().then(function() {
                    that.enabled=that.data.model.enabled;
                });
            },
             launch : function() {
                 window.open(this.data.model.url, '_blank');
             },
             launchWebsiteConfig : function() {
                this.$router.push('/website/config');
             }
        },
        created : function() {
            this.loadData();
        }
};
</script>

<style scoped>

</style>