import { reactive } from '@vue/composition-api'

var _stack = reactive({
  pages : []
});

function createOpViewModel($route, data) {
    var vm = {
        op : null,
        isEdit : false,
        isView : false,
        isCreate : false,
        refreshOp : function() {
            switch (this.op) {
                case 'view':
                    this.setView();
                    break;
                case 'edit':
                    this.setEdit();
                    break;
                case 'create':
                    this.setCreate();
                    break;
            }
        },
        setEdit : function() {
            this.op='edit';
            this.editButton.hide=true;
            this.saveButton.hide=false;
            this.header=this.getEditHeader();
            this.isEdit=true;
            this.isView=false;
            this.isCreate=false;
            if (this.onEdit!=null) {
                this.onEdit();
            }
            if (this.onOpChanged!=null) {
                this.onOpChanged();
            }
        },
        setView : function() {
           this.op='view';
           this.header=this.getViewHeader();
           this.editButton.hide=false;
           this.saveButton.hide=true;
           this.isEdit=false;
           this.isView=true;
           this.isCreate=false;
           if (this.onView!=null) {
               this.onView();
           }
           if (this.onOpChanged!=null) {
               this.onOpChanged();
           }
        },
        setCreate : function() {
           this.op='create';
           this.editButton.hide=true;
           this.saveButton.hide=false;
           this.header=this.getCreateHeader();
           this.isEdit=false;
           this.isView=false;
           this.isCreate=true;
           if (this.onCreate!=null) {
              this.onCreate();
          }
          if (this.onOpChanged!=null) {
              this.onOpChanged();
          }
        }
    };
    vm=Object.assign(vm, data);
    if (vm.editButton==null) {
        vm.editButton={
            label : 'Edit',
            icon : 'file',
            hide : true,
            onClick : function() {
                vm.setEdit();
            },
        };
        vm.buttons.push(vm.editButton);
    }
    if (vm.saveButton==null) {
            vm.saveButton={
                label : 'Save',
                icon : 'file',
                hide : true,
                onClick : function() {
                    vm.onSave();
                },
            };
            vm.buttons.push(vm.saveButton);
        }
    if (vm.header==null) {
        vm.header='';
    }
    return vm;
}

function doAddPage($route, data, op) {
    var vm = {
        $route : $route,
        header : '',
        depth : _stack.pages.length
    };
    if (data && data!=null) {
        vm=Object.assign(vm, data)
    }
    if (vm.buttons==null) {
        vm.buttons=[];
    }
    if (op) {
        vm=createOpViewModel($route, vm);
    }
    if (_stack.pages.length>0 && _stack.pages[_stack.pages.length-1].onStacked) {
        _stack.pages[_stack.pages.length-1].onStacked();
    }
    _stack.pages.push(vm);
    return vm;
}

var PageStack = {
    getPages : function() {
        return _stack.pages;
    },
    setPage : function($route, data) {
        _stack.pages.splice(0,_stack.pages.length)
        return this.addPage($route, data);
    },
    addOpPage : function($route, data) {
        return doAddPage($route, data, true);
    },
    addPage : function($route, data) {
            return doAddPage($route, data, false);
        },
    popPage : function(page, $router) {
        var index = _stack.pages.indexOf(page);
        var item = null;
        while (_stack.pages.length>index) {
            item=_stack.pages.pop();
        }
        if (item.onBackTo) {
            item.onBackTo();
        } else {
            $router.push(item.$route.path);
        }
    },
    chopPage : function($router) {
        var item = null;
        if (_stack.pages.length>0) {
            _stack.pages.pop();
        }
        if (_stack.pages.length>0) {
            item=_stack.pages[_stack.pages.length-1];
        }
        if (item!=null) {
            $router.push(item.$route.path);
        } else {
            $router.go(-1);
        }
    }
};

export {PageStack as PageStack};