<template>
    <div id="library-container">
    <div class="container-fluid" v-if="!initialising">
        <div class="results image-results row">
            <div v-for="(result, index) in results" :key="index" class="result col-lg-2 col-md-3 col-sm-4 col-6" @click="callback(result.url)">
                <div class="result-contents" :style="getStyles(result)">

                </div>

            </div>
            <div v-if="results.length==0">
                No images in media library
            </div>
        </div>
    </div>
    </div>
</template>

<script>

import {PromiseUtils} from '@/utils/PromiseUtils'
import {RestHelper} from '@/utils/RestHelper'

export default {
  name: 'MediaLibrary',
  components: {

  },
  props : ['callback'],
  data : function() {
    var d = {
        results : [],
        initialising : false
         };

     return d;
  },
  methods : {
    getStyles : function (image) {
        return {
            'background-image':'url('+image.url+')'
            };
    },
    loadData : function() {
        var that=this;
        that.initialising=true;
        RestHelper.get('/wb/api/admin/media/library/images').then(function(result) {
                that.results=result.data;
                that.initialising=false;
            }, PromiseUtils.viewErrorHandler('Error loading images'));
    }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>
.image-results .result .result-contents {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 5px solid white;
}

.image-results .result .result-contents:hover {
    cursor: pointer;
    border: 5px solid black;
}

#library-container .results {
	height: calc(90vh - 170px);
	overflow-y: auto;
	flex-grow: 1;
}

</style>