<template>
  <b-modal id="select-node-modal" modal-class="select-node-modal" scrollable centered ok-title="Submit" no-close-on-backdrop :title="title" @hide="handleHide()" @ok="handleOk()">
       <div class="container-fluid">
        <div class="row">
            <div class="col-12 form-group">
                <input type="text" class="form-control" v-model="filter" placeholder="Search" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 node-selector-section">
                <table class="table table-bordered extras-table">
                    <thead>
                        <tr>
                            <th width="75px" class="col-handle">&nbsp;</th>
                            <th class="col-name">Name</th>
                            <th class="col-name" v-if="multiType">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(node,index) in filteredItems" :key="index">
                            <td><label> <input type="checkbox" v-model="node.selected"/>
                            </label></td>
                            <td class="select">{{node.name}}</td>
                            <td class="select" v-if="multiType">{{node.type}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </b-modal>
</template>

<script>

import {WorkAreaService} from '@/services/menu/WorkAreaService'
import {PromiseUtils} from '@/utils/PromiseUtils'

function processTypes(WorkArea, selected, types) {
    var items=[];
    if (types.constructor === Array) {
        types.forEach(function(t) {
            processType(WorkArea, items, selected, t);
        });
    } else {
        processType(WorkArea, items, selected, types);
    }
    return items;
}

function processType(WorkArea, items, selected, type) {
    if (type=='Modifier') {
        create(items, selected, WorkArea.modifiers, type);
    } else if (type=='Item') {
        create(items, selected, WorkArea.products, type);
    } else if (type=='Variation') {
         create(items, selected, WorkArea.variations, type);
     }
}

function create(items, selected, result, type) {

    result.forEach(function(item) {
        var _selected=false;
        items.push({
            model : item,
            type : type,
            name : item.model.name,
            get selected() {
                return _selected;
            },
            set selected(value) {
                _selected=value;
                var index = selected.indexOf(item);
                if (value) {
                    if (index==-1) {
                        selected.push(item);
                    }
                } else {
                    if (index>=0) {
                        selected.splice(index, 1);
                    }
                }
            }
        });
    });
}

var component = {
  name: "SelectNodeModal",
  props: ['callback', 'types', 'title'],
  components : {},
  data : function() {
      var d = {
        initialising : false,
        items : [],
        selected : [],
        filter : null
      };
     return d;
    },
    computed : {
        filteredItems : function() {
            if (this.filter==null || this.filter=='') {
                return this.items;
            } else {
                var v=this.filter.trim();
                if (v=='') {
                    return this.items;
                } else {
                    var lowerCase=this.filter.toLowerCase();
                    return this.items.filter(item => {
                         return item.name.toLowerCase().indexOf(lowerCase) > -1
                      });
                }
            }
        }
    },
   methods : {
      handleHide : function() {
          this.$emit('hidden');
      },
      handleOk : function() {
        this.callback(this.selected);
      }
    },
    mounted : function() {
        this.$bvModal.show('select-node-modal');
      },
      created : function() {
        var that=this;
        this.multiType=this.types.constructor === Array && this.types.length>1;
        that.initialising=true;
        WorkAreaService.load().then(function(WorkArea) {
            that.items=processTypes(WorkArea, that.selected, that.types);
         }, PromiseUtils.viewErrorHandler('Error loading data'));
      }
};
export default component;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.select-node-modal .modal-dialog {
    max-width: 1000px !important;
}


.select-node-modal .modal-content {
     max-width: 1000px !important;
     width: 90vw !important;
     height: 90vh !important;
 }


</style>