<template>
    <div>
        <div v-if="!initialising && !data.enabled">
            Neither pickups or deliveries enabled
        </div>
        <div v-if="!initialising && data.enabled">
            <service-status single-row="true"/>
            <banner-msg/>

                <ul class="list-group w-100">
                    <li class="list-group-item actionable" @click="launchTestMode()">
                        <div>
                            <span class="header">Test Order</span>
                            <p>Test your menu configuration via the online ordering test mode.</p>
                        </div>
                        <button class="btn btn-primary" @click="launchTestMode()">Create Test Order</button>
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import {PageStack} from '@/services/PageStackService'
import ServiceStatus from './ServiceStatus'
import BannerMsg from './BannerMsg'
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";

export default {
  name: 'OnlineOrderingControlPanel',
  components: {
        ServiceStatus, BannerMsg
  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(that.$route, {
         header : 'Online Ordering',
         buttons : [],
         data : null,
         initialising : true
     });
     return d;
  },
  methods : {
        gotoPickup : function() {
            this.$router.push('/onlineordering/pickup/'+this.data.brandConfig.id);
        },
        gotoDelivery : function() {
            this.$router.push('/onlineordering/delivery/'+this.data.brandConfig.id);
        },
        gotoCardPayments : function() {
            this.$router.push('/onlineordering/cardpayments/'+this.data.brandConfig.id);
        },
        loadData : function() {
            var url = '/wb/api/admin/location/primary/olostatus';
            var that=this;
            that.initialising=true;
            RestHelper.get(url).then(function(response) {
                that.initialising=false;
                that.data=response.data
            },PromiseUtils.viewErrorHandler('Error loading online ordering enablement', function() {
                 that.initialising=false;
             }));
        },
        launchTestMode : function() {
            var url = process.env.APP_URL+'/admin3/testorder2';
            window.open(url, 'preview');
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>