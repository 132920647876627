import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {CategoryProductViewModelFactory} from './CategoryProductViewModelFactory'
import {ExtrasParentViewModelFactory} from './ExtrasParentViewModelFactory'
import {ProductViewModelFactory} from './ProductViewModelFactory'
import {VariationViewModelFactory} from './VariationViewModelFactory'
import {ConfiguredItemViewModelFactory} from './ConfiguredItemViewModelFactory'

var CategoryViewModelFactory = {

    create : function(category, menu, menuModifier, workAreaModifier, pinned) {
        if (category==null) {
            category={
                    name : null,
                    displayName : null,
                    products : [],
                    disabled : false,
                    onlineDisabled : false,
                    menuDisplayFormat : 'multiLine'
            };
        }

        if (pinned && category.name==null) {
            category.name='Pinned Category';
        }

        var parentNode=null;
        var parentDeleteListener=null;

        function setParentNode (parentNodeP) {
            if (parentNode==parentNodeP) {
                return;
            }
            if (parentDeleteListener!=null) {
                parentDeleteListener();
                parentDeleteListener=null;
            }
            if (parentNodeP!=null) {
                parentDeleteListener = workAreaModifier.addDeletionCallback(parentNodeP.id, function() {
                    parentDeleteListener=null;
                    parentNode=null;
                });
            }
            parentNode=parentNodeP;
        }

        var CategoryViewModel = {
            type : 'category',
            isCategory : true,
            errors : ErrorsService.create(),
            categoryProducts : [],
            extrasParent : null,
            setParentNode : setParentNode
        };

        function apply(categoryProduct) {
            category.products.push(categoryProduct);
            CategoryViewModel.model.products.push(categoryProduct);
            CategoryViewModel.categoryProducts.push(CategoryProductViewModelFactory.create(CategoryViewModel, categoryProduct, workAreaModifier));
            originalStr=JSON.stringify(CategoryViewModel.model);
        }

        var categoryModifier = {
            viewModel : CategoryViewModel,
            applyProduct : apply,
            applyVariation : apply,

            removeProduct : function(productViewModel, type) {
                var productId=productViewModel.model.id;
                var index=null;
                for (var i=0;i<category.products.length;i++) {
                    var categoryProduct=category.products[i];
                    if (categoryProduct.refId==productId && categoryProduct.type==type) {
                        index=i;
                        break;
                    }
                }
                if (index!=null) {
                    category.products.splice(index, 1);
                }
                index==null;
                for (i=0;i<CategoryViewModel.categoryProducts.length;i++) {
                    var categoryProductVM=CategoryViewModel.categoryProducts[i];
                    if (categoryProductVM.model.refId==productId && categoryProductVM.model.type==type) {
                        index=i;
                        break;
                    }
                }
                if (index!=null) {
                    CategoryViewModel.categoryProducts.splice(index, 1);
                }
            }
        };

        function resetCategoryProducts() {
            CategoryViewModel.categoryProducts = [];
            if (CategoryViewModel.model==null) {
                return;
            }
            CategoryViewModel.model.products.forEach(function(product) {
                CategoryViewModel.categoryProducts.push(CategoryProductViewModelFactory.create(CategoryViewModel, product, workAreaModifier));
            });
        }



        var originalStr=null;

        function reset() {
            originalStr=JSON.stringify(category);
            CategoryViewModel.errors = ErrorsService.create();
            CategoryViewModel.model=reactive(JSON.parse(JSON.stringify(category)));
            CategoryViewModel.created=CategoryViewModel.model.id!=null;
            CategoryViewModel.extrasParent=ExtrasParentViewModelFactory.create(CategoryViewModel, workAreaModifier);
            categoryModifier.model=CategoryViewModel.model;
            resetCategoryProducts();
        }

        reset();

        CategoryViewModel.isDirty=function() {
            return (originalStr!==JSON.stringify(CategoryViewModel.model));
        };

        CategoryViewModel.reset=reset;
        CategoryViewModel.reorderProducts=function(fromIndex, toIndex) {
            if (fromIndex!=toIndex) {
                    var fromItem=CategoryViewModel.categoryProducts[fromIndex];
                    var fromModel=CategoryViewModel.model.products[fromIndex];
                    CategoryViewModel.categoryProducts.splice(fromIndex, 1);
                    CategoryViewModel.model.products.splice(fromIndex, 1);
                    CategoryViewModel.categoryProducts.splice(toIndex, 0, fromItem);
                    CategoryViewModel.model.products.splice(toIndex, 0, fromModel);

                    var index=0;
                    CategoryViewModel.model.products.forEach(function(p) {
                        p.index=index;
                        index++;
                    });
            }
        };

        CategoryViewModel.delete=function() {
            var promise = new Promise(function(resolve, reject) {
                if (CategoryViewModel.pinned) {
                    RestHelper.delete('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/menu/'+menu.model.id+'/pinnedcategory').then(function(result) {
                        handleDeleted(resolve, result);
                    }, PromiseUtils.errorHandler(reject));
                } else {
                    RestHelper.delete('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/menu/'+menu.model.id+'/category/'+CategoryViewModel.model.id).then(function(result) {
                        handleDeleted(resolve, result);
                    }, PromiseUtils.errorHandler(reject));
                }

                function handleDeleted(resolve, result) {
                    if (result.data.success) {
                        workAreaModifier.deleteCategory(CategoryViewModel);
                        menuModifier.deleteCategory(CategoryViewModel);
                        category=null;
                        CategoryViewModel.created=false;
                        resolve({
                            success : true
                        });
                    } else {
                        resolve({
                            success : false,
                            errors : result.data.errors
                        });
                    }
                }

            });
            return promise;
        };

        CategoryViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
                CategoryViewModel.errors.clearServerErrors();
                //extrasParent.setExtrasOnModel(copy);
                //resetProductsOnModel();

                if (pinned) {
                    delete CategoryViewModel.model.index;
                    delete CategoryViewModel.model.id;
                    RestHelper.put('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/menu/'+menu.model.id+'/pinnedcategory', CategoryViewModel.model).then(function(result) {
                        handleSaveResult(resolve,reject, result);
                    }, PromiseUtils.errorHandler(reject));
                } else {
                    //CategoryViewModel.index=menu.getIndex(this);
                    RestHelper.put('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/menu/'+menu.model.id+'/category', CategoryViewModel.model).then(function(result) {
                        handleSaveResult(resolve,reject, result);
                    }, PromiseUtils.errorHandler(reject));
                }

                function handleSaveResult(resolve,reject, result) {
                    if (result.data.success) {
                        category=result.data.result;
                        reset();
                        workAreaModifier.applyCategory(CategoryViewModel);
                        menuModifier.applyCategory(CategoryViewModel);
                        resolve({
                            success : true
                        });
                    } else {
                        CategoryViewModel.errors.setServerErrors(result.data.errors);
                        resolve({
                            success : false
                        });
                    }
                }
            });
            return promise;
        };

        CategoryViewModel.newProduct=function() {
            return ProductViewModelFactory.create(null, workAreaModifier, categoryModifier);
        };

        CategoryViewModel.newVariation=function() {
            return VariationViewModelFactory.create(null, workAreaModifier, categoryModifier);
        };

        CategoryViewModel.newConfiguredItem=function() {
            return ConfiguredItemViewModelFactory.create(null, workAreaModifier, categoryModifier);
        };

        CategoryViewModel.removeProduct=function(product) {
            return categoryModifier.removeProduct(product, 'single');
        };
        CategoryViewModel.removeVariation=function(product) {
            return categoryModifier.removeProduct(product, 'variation');
        };
        CategoryViewModel.applyProduct=categoryModifier.applyProduct;
        CategoryViewModel.applyVariation=categoryModifier.applyProduct;

        CategoryViewModel.linkProduct=function(product) {
            var categoryProduct = {
                id : null,
                refId : product.model.refId,
                index : CategoryViewModel.categoryProducts.length,
                type : 'linked'
            };
            apply(categoryProduct);
            return CategoryViewModel.save();
        };
        CategoryViewModel.removeFromCategory=function(toRemove) {
            var index=null;
            for (var i=0;i<CategoryViewModel.model.products.length;i++) {
                var categoryProduct=CategoryViewModel.model.products[i];
                if (categoryProduct.id==toRemove.model.id) {
                    index=i;
                    break;
                }
            }
            if (index!=null) {
                CategoryViewModel.model.products.splice(index, 1);
            }
            index==null;
            for (i=0;i<CategoryViewModel.categoryProducts.length;i++) {
                var categoryProductVM=CategoryViewModel.categoryProducts[i];
                if (categoryProductVM.model.id==toRemove.model.id) {
                    index=i;
                    break;
                }
            }
            if (index!=null) {
                CategoryViewModel.categoryProducts.splice(index, 1);
            }
       };

        CategoryViewModel=reactive(CategoryViewModel);
        workAreaModifier.applyCategory(CategoryViewModel);
        return CategoryViewModel;
    }

};

export {CategoryViewModelFactory as CategoryViewModelFactory};