<template>
    <div class="container-fluid menueditor-container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Tax Rate
                    </div>
                    <div class="card-body">
                        <form role="form" name="taxRateForm" novalidate>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Name</label> <input type="text"
                                        name="name"
                                        class="form-control" id="name" placeholder="Enter name"
                                        v-model="data.model.name"
                                        >
                                        <field-error :errors="data.errors" field="name"></field-error>
                                </div>
                                
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Rate</label>
                                    <input money type="text"
                                        name="rate"
                                        class="form-control" id="rate" placeholder="Enter rate"
                                        v-model="data.model.rate"
                                         required>
                                        <field-error :errors="data.errors" field="rate"></field-error>
                                </div>
                                
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Default</label>
                                    <input type="checkbox"
                                        v-model="data.model.defaultRate">
                                    <field-error :errors="data.errors"
                                        field="defaultRate"></field-error>
                                </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>


import FieldError from '@/components/FieldError'
import {EditManager} from '@/services/menu/EditManager'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {PageStack} from '@/services/PageStackService'
import {WorkAreaService} from '@/services/menu/WorkAreaService'

export default {
  name: 'TaxRateEdit',
  components: {
      FieldError
  },
  props : ['taxRate'],
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Tax Rates',
         buttons : [],
         initialising : false,
         data : null
         });

     return d;
  },
  methods : {
    initialiseEditManager : function() {
            this.pageHandler=EditManager.builder(this).viewModel(this.data).header(model=>'Tax Rate: '+model.name).initialise();
        }
    },
    created : function() {
        if (this.taxRate==null) {
            var that=this;
            that.initialising=true;
            WorkAreaService.load().then(function(WorkArea) {
                if (that.$route.params.id==null) {
                    that.data=WorkArea.createTaxRate();
                } else {
                    that.data=WorkArea.getTaxRate(that.$route.params.id);
                }
                 that.initialiseEditManager();
                 that.initialising=false;
             }, PromiseUtils.viewErrorHandler('Error loading Tax Rate'));
        } else {
            this.data=this.taxRate;
            that.initialiseEditManager();
        }
    }
};
</script>

<style scoped>

</style>