<template>
    <div>
        <quill-editor
            :content="val"
            :options="editorOption"
            @change="onEditorChange($event)"
          />
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


import { quillEditor } from 'vue-quill-editor'

export default {
  name: "Wysiwyg",
  props: ['value'],
  components: {
    quillEditor
    },
    data() {
      return {
        editorOption : {
        },
        val: this.value,
      }
    },
    mounted() {

    },
    methods : {
          onEditorChange(event) {
            this.$emit('input', event.html);
            this.val=event.html;
          }
    },
    beforeDestroy() {
      this.editor.destroy()
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>