import enquire from 'enquire.js'

var isMobile = window.matchMedia("(max-width: 767px)").matches;

var ResponsiveService = {
    vm : {
        isMobile : isMobile,
        isDesktop : !isMobile
    }
};



enquire.register("screen and (max-width: 767px)", {
    match : function() {
        ResponsiveService.vm.isMobile=true;
        ResponsiveService.vm.isDesktop=false;
    }

});



enquire.register("screen and (min-width: 768px)", {
    match : function() {
        ResponsiveService.vm.isMobile=false;
        ResponsiveService.vm.isDesktop=true;
    }

});

export {ResponsiveService as ResponsiveService};