<template>
<form role="form" name="groupingForm">
    <div class="row" v-if="!initialising">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Details</div>
                <div class="card-body">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">Name</label> <input type="text"
                            class="form-control" id="name" placeholder="Enter name"
                            v-model="data.model.name"
                            >
                        <field-error :errors="data.errors"     field="name"></field-error>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">Display Name</label> <input
                            type="text" class="form-control" id="name"
                            placeholder="Enter name"
                            v-model="data.model.displayName"
                            >
                        <field-error :errors="data.errors"     field="displayName"></field-error>
                    </div>
                    <div class="form-group">

                        <label style="display: block;" for="exampleInputEmail1">Selection
                            Type</label>
                         <b-form-radio-group v-model="data.model.selectionType">
                             <b-form-radio value="single">Single Selection</b-form-radio>
                             <b-form-radio value="multiple">Multiple Selections</b-form-radio>
                             <b-form-radio value="multipleQuantities">Multiple Quantities</b-form-radio>
                           </b-form-radio-group>
                        <field-error :errors="data.errors"     field="selectionType"></field-error>
                    </div>
                    <div class="form-group" v-if="quantityEnabled">
                        <label for="exampleInputEmail1">Selectable Quantity</label>
                        <div class="extra-quantity">
                            <label>Min: </label><input type="number" class="form-control"
                                id="name" placeholder="Min"
                                v-model="data.model.minQuantity"
                                ><label>Max:
                            </label><input type="number" class="form-control" id="name"
                                placeholder="Max" v-model="data.model.maxQuantity"
                                >
                        </div>
                        <field-error :errors="data.errors"     field="minQuantity"></field-error>
                        <field-error :errors="data.errors"     field="maxQuantity"></field-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Hide Default Selections on POS</label>
                        <b-form-radio-group v-model="data.model.excludeDefaultPos">
                         <b-form-radio :value="true">Yes</b-form-radio>
                         <b-form-radio :value="false">No</b-form-radio>
                       </b-form-radio-group>
                        <field-error :errors="data.errors"     field="excludeDefaultPos"></field-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Hide Default Selections on
                            Kitchen</label>
                            <b-form-radio-group v-model="data.model.excludeDefaultKitchen">
                             <b-form-radio :value="true">Yes</b-form-radio>
                             <b-form-radio :value="false">No</b-form-radio>
                           </b-form-radio-group>
                        <field-error :errors="data.errors"     field="excludeDefaultKitchen"></field-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Hide Default Selections on
                            Customer Receipt</label>
                         <b-form-radio-group v-model="data.model.excludeDefaultCustomer">
                          <b-form-radio :value="true">Yes</b-form-radio>
                          <b-form-radio :value="false">No</b-form-radio>
                        </b-form-radio-group>
                         <field-error :errors="data.errors"     field="excludeDefaultCustomer"></field-error>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" v-if="!initialising && data.created">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Options</div>
                <div class="card-body">
                    <table class="table table-bordered">
                        <tr>
                            <th class="col-handle">&nbsp;</th>
                            <th>Item</th>
                            <th>Option Name</th>
                            <th v-if="productQuantityEnabled">Max Qty</th>
                            <th v-if="productQuantityEnabled">Default Qty</th>
                            <th v-if="!productQuantityEnabled">Default</th>
                            <th>Display on Web Menu</th>
                            <th>&nbsp;</th>
                        </tr>
                        <draggable handle=".row-handle" group="modifieroptions" tag="tbody" @end="onDrop" id="sortable-grouping-items">
                            <tr v-if="data.products.length==0">
                                <td colspan="8">No options</td>
                            </tr>
                            <tr v-for="(product) in data.products" :key="product.model.productId">
                                <td class="row-handle"><fa  icon="grip-vertical"></fa></td>
                                <td><a href="#" @click.stop.prevent="selectProduct(product)">{{product.selectedProduct.model.name}}</a></td>
                                <td class="index"><input type="text" class="form-control"
                                                placeholder="Option Name" v-model="product.model.optionName"
                                                ></td>
                                <td class="index" v-if="productQuantityEnabled"><input type="number" class="form-control"
                                                placeholder="Max Qty" v-model="product.model.maxQuantity"
                                                ></td>
                                <td class="index" v-if="productQuantityEnabled"><input type="number" class="form-control"
                                                placeholder="Default Qty" v-model="product.model.defaultQuantity"
                                                ></td>
                                <td class="index" v-if="!productQuantityEnabled"><label> <input type="checkbox"
                                                v-model="product.model.defaultOption">
                                            </label></td>
                                <td class="index" ><label> <input type="checkbox"
                                                v-model="product.model.displayOnMenu">
                                            </label></td>
                                <td class="index"><button type="button" class="btn btn-card-tool"
                                                @click="product.remove()">
                                                <fa class="fa" icon="trash"></fa>
                                            </button></td>

                            </tr>
                        </draggable>
                    </table>
                    <div class="table-btn-group btn-group float-right mt-3">
                        <button type="button" class="btn btn-outline-dark"
                            @click="addOption()">
                            <fa icon="plus"></fa> Add Option
                        </button>
                        <b-dropdown variant="outline-dark" offset="-200">
                          <b-dropdown-item-button @click="lookupOption()">Select Existing Option</b-dropdown-item-button>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <select-node-modal v-if="showSelectNodeModal" :types="selectTypes" :callback="selectCallback"  :title="selectTitle" @hidden="showSelectNodeModal=false"/>
</form>
</template>

<script>


import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError'
import {LabelService} from '@/services/LabelService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {EditManager} from '@/services/menu/EditManager'
import {WorkAreaService} from '@/services/menu/WorkAreaService'
import SelectNodeModal from './SelectNodeModal'

export default {
  name: 'ModifierGroup',
  components: {
    FieldError, SelectNodeModal
  },
  props : ['grouping'],
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Modifier: ',
         buttons : [],
         initialising : false,
         menuDisplayFormatOptions : LabelService.menuDisplayFormatOptions,
         data : null,
         showSelectNodeModal : false,
          selectTypes : null,
          selectCallback : null,
          selectTitle : null
         });

     return d;
  },
  methods : {
        selectProduct : function(product) {
            this.$router.push({ name: 'menuworkarea_product', params: {id : product.selectedProduct.model.id, product: product.selectedProduct }})
        },
        initialiseEditManager : function() {
            this.pageHandler=EditManager.builder(this).viewModel(this.data).label('Modifier Group').initialise();
        },
        addOption : function() {
          var params = {
              groupingId : this.data.model.id,
              grouping : this.data
          };
          this.$router.push({ name: 'menuworkarea_createmodifiergroupproduct', params: params});

      },
        addSelectedOption : function(selected) {
            var that=this;
            selected.forEach(function(s) {
                that.data.addProduct(s);
            });
        },
        lookupOption : function() {
            this.selectTypes=['Item'];
            this.selectCallback=this.addSelectedOption;
            this.showSelectNodeModal=true;
            this.selectTitle='Select Item as Option';
        },
        onDrop : function(evt) {
            this.data.reorderProducts(evt.oldIndex, evt.newIndex);
        }
    },
    computed : {
        quantityEnabled : function() {
            return this.data.model.selectionType=='multipleQuantities' || this.data.model.selectionType=='multiple';
        },
        productQuantityEnabled : function() {
                    return this.data.model.selectionType=='multipleQuantities';
                }
    },
    beforeRouteLeave : function(to, from, next) {
            this.pageHandler.beforeRouteLeave(to, from, next);
        },
    created : function() {
        var that=this;
        if (that.$route.params.id==null) {
            that.initialising=true;
            WorkAreaService.load().then(function(WorkArea) {
                if (that.$route.params.categoryId!=null) {
                    that.data=WorkArea.getCategory(that.$route.params.categoryId).extrasParent.newModifier();
                } else if (that.$route.params.variationId!=null) {
                    that.data=WorkArea.getVariation(that.$route.params.variationId).extrasParent.newModifier();
               } else if (that.$route.params.productId!=null) {
                    that.data=WorkArea.getProduct(that.$route.params.productId).extrasParent.newModifier();
                }
                 that.initialiseEditManager();
                 that.initialising=false;
             }, PromiseUtils.viewErrorHandler('Error loading modifier'));
        } else {
            if (this.grouping==null) {
                that.initialising=true;
                WorkAreaService.load().then(function(WorkArea) {
                    that.data=WorkArea.getGrouping(that.$route.params.id);
                     that.initialiseEditManager();
                     that.initialising=false;
                 }, PromiseUtils.viewErrorHandler('Error loading modifier'));
            } else {
                this.data=this.grouping;
                that.initialiseEditManager();
                that.initialising=false;
            }
        }

    }
};
</script>

<style scoped>

</style>