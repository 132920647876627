<template>
    <div>
        <div v-if="!initialising">
        <ul class="list-group">
            <li class="list-group-item actionable">
                <div>
                    <span class="header">Online Ordering is: <span v-if="data.enabled">On</span><span v-if="!data.enabled">Off</span></span>
                    <p v-if="!data.enabled">Online ordering is not enabled and wont be displayed on your
                        website.</p>
                    <p v-if="data.enabled">Online ordering is enabled.</p>
                </div>
                <div>
                    <b-form-checkbox switch size="lg" v-model="data.enabled"></b-form-checkbox>
                </div>
            </li>
        </ul>

        <ul class="list-group">
            <li class="list-group-item clickable" @click="gotoPickup()"><span class="header">Pickup</span>
                <p v-if="data.brandConfig.pickup.enabled">You offer pickup.</p>
                <p v-if="!data.brandConfig.pickup.enabled">You don't offer pickup.</p></li>
            <li class="list-group-item actionable" v-if="data.brandConfig.pickup.enabled">
                <div>
                    <span class="header">Pickup Paused</span>
                </div>
                <div>
                    <b-form-checkbox switch size="lg" v-model="data.pickupPaused"></b-form-checkbox>
                </div>
            </li>
            <li class="list-group-item clickable" @click="gotoDelivery()"><span class="header">Delivery</span>
                <p v-if="data.brandConfig.delivery.enabled">You offer delivery.</p>
                <p v-if="!data.brandConfig.delivery.enabled">You don't offer delivery.</p>
            </li>
            <li class="list-group-item actionable"  v-if="data.brandConfig.delivery.enabled">
                <div>
                    <span class="header">Delivery Paused</span>
                </div>
                <div>
                    <b-form-checkbox switch size="lg" v-model="data.deliveryPaused"></b-form-checkbox>
                </div>
            </li>
            <li class="list-group-item clickable" @click="gotoCardPayments()"><span class="header">Card
                    Payment</span>
                <p v-if="data.brandConfig.payment.stripeEnabled">You process card payments via Stripe.</p>
                <p v-if="!data.brandConfig.payment.stripeEnabled">You dont have any online card processing enabled.</p></li>
        </ul>

        <ul class="list-group">
                    <li class="list-group-item clickable" @click="gotoUberEats()">
                            <span class="header">Uber Eats</span>
                            <p v-if="!data.brandConfig.uberEats.activated">Please contact support to connect your POS with Uber Eats</p>
                            <p v-if="data.brandConfig.uberEats.menuSync">You are synchronizing your menu to Uber Eats</p>
                            <p v-if="data.brandConfig.uberEats.activated && !data.brandConfig.uberEats.menuSync">You are not currently synchronizing your menu to Uber Eats</p>
                            <p v-if="data.brandConfig.uberEats.orderSync">You are auto creating Uber Eats orders in your POS</p>
                            <p v-if="data.brandConfig.uberEats.activated && !data.brandConfig.uberEats.orderSync">You are not currently auto creating Uber Eats orders in your POS</p>
                    </li>
                </ul>

        <ul class="list-group">
            <li class="list-group-item actionable" @click="launchTestMode()">
                <div>
                    <span class="header">Test Order</span>
                    <p>Test your menu configuration via the online ordering test mode.</p>
                </div>
                <button class="btn btn-primary" @click="launchTestMode()">Create Test Order</button>
            </li>
        </ul>
    </div>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import {AlertService} from '@/services/AlertService'

function createConfigViewModel(config, reload) {
    return {
                brandConfig : config.brandConfig,
                get enabled() {
                    return config.brandConfig.enablement.enabled;
                },
                set enabled(enabled) {
                    if (config.brandConfig.enablement.enabled==enabled) {
                        return;
                    }
                    config.brandConfig.enablement.enabled=enabled;
                    var previous=config.brandConfig.enablement.enabled;
                    RestHelper.put('/wb/api/admin/olo/config/'+config.brandConfig.id+'/enablement', {
                        enabled : enabled
                    }).then(function(response) {
                        if (response.data.success) {
                            
                            if (enabled) {
                                AlertService.info('Online Ordering Enabled');
                            } else {
                                AlertService.info('Online Ordering Disabled');
                            }
                        } else {
                            reload();
                            var msg = '';
                            response.data.errors.forEach(function(error) {
                                msg+=error.message+'</br>';
                            });
                            AlertService.danger('Not saved', msg);
                            config.brandConfig.enablement.enabled=previous;
                        }
                    });
                },
                get pickupPaused() {
                    return config.brandConfig.pickup.paused;
                },
                set pickupPaused(paused) {
                    if (config.brandConfig.pickup.paused==paused) {
                        return;
                    }
                    var previous=config.brandConfig.pickup.paused;
                    config.brandConfig.pickup.paused=paused;
                    RestHelper.put('/wb/api/admin/olo/config/'+config.brandConfig.id+'/pickup/pause', {
                        paused : paused
                    }).then(function(response) {
                        if (response.data.success) {
                            
                            if (paused) {
                                AlertService.info('Pickup Paused');
                            } else {
                                AlertService.info('Pickup Unpaused');
                            }
                        } else {
                            reload();
                            var msg = '';
                            response.data.errors.forEach(function(error) {
                                msg+=error.message+'</br>';
                            });
                            AlertService.danger('Not saved', msg);
                            config.brandConfig.pickup.paused=previous;
                        }
                    });
                },
                get deliveryPaused() {
                    return config.brandConfig.delivery.paused;
                },
                set deliveryPaused(paused) {
                    if (config.brandConfig.delivery.paused==paused) {
                        return;
                    }
                    var previous=config.brandConfig.delivery.paused;
                    config.brandConfig.delivery.paused=paused;
                    RestHelper.put('/wb/api/admin/olo/config/'+config.brandConfig.id+'/delivery/pause', {
                        paused : paused
                    }).then(function(response) {
                        if (response.data.success) {
                            
                            if (paused) {
                                AlertService.info('Delivery Paused');
                            } else {
                                AlertService.info('Delivery Unpaused');
                            }
                        } else {
                            reload();
                            var msg = '';
                            response.data.errors.forEach(function(error) {
                                msg+=error.message+'</br>';
                            });
                            AlertService.danger('Not saved', msg);
                            config.brandConfig.delivery.paused=previous;
                        }
                    });
                }
            };
}

export default {
  name: 'OnlineOrdering',
  components: {

  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(that.$route, {
         header : 'Online Ordering',
         buttons : [],
         initialising:true,
         data : null
     });
     return d;
  },
  methods : {
        gotoPickup : function() {
            this.$router.push('/onlineordering/pickup/'+this.data.brandConfig.id);
        },
        gotoDelivery : function() {
            this.$router.push('/onlineordering/delivery/'+this.data.brandConfig.id);
        },
        gotoCardPayments : function() {
            this.$router.push('/onlineordering/cardpayments/'+this.data.brandConfig.id);
        },
        loadData : function() {
            var that=this;
            that.initialising=true;
            RestHelper.get('/wb/api/admin/olo/config').then(function(response) {
                    that.data = createConfigViewModel(response.data, that.loadData);
                    that.initialising=false;
                },PromiseUtils.viewErrorHandler('Error loading online ordering data', function() {
                     that.initialising=false;
                 }));
        },
        launchTestMode : function() {
            var url = '/admin3/testorder2';
            window.open(url, 'preview');
        },
        gotoUberEats : function() {
            this.$router.push('/onlineordering/ubereats/'+this.data.brandConfig.id);
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>