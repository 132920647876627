<template>
    <div>
        <div class="card" v-for="(section) in sections" :key="section.name">
            <div class="card-header">
                {{section.name}}
              </div>
              <div class="card-body">
                <boolean-param v-if="section.togglable" :param="section.enabled"/>
                <div v-if="section.enabled.value">
                    <div v-for="(param) in section.params" :key="param.name">
                        <div class="d-flex w-100">
                            <div class="reset"><button v-if="param.hasDefault" class="btn btn-secondary btn-sm" @click.stop.prevent="param.reset()">R</button></div>
                            <div class="flex-grow-1">
                                <textfield-param v-if="param.isTextfield" :param="param"/>
                                <textarea-param v-if="param.isTextarea" :param="param"/>
                                <image-param v-if="param.isImage" :param="param"/>
                                <html-param v-if="param.isHtml" :param="param"/>
                                <color-param v-if="param.isColor" :param="param"/>
                                <font-param v-if="param.isFont" :param="param"/>
                                <boolean-param v-if="param.isBoolean" :param="param"/>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
         </div>
    </div>
</template>

<script>

import TextfieldParam from './TextfieldParam'
import TextareaParam from './TextareaParam'
import ImageParam from './ImageParam'
import HtmlParam from './HtmlParam'
import ColorParam from './ColorParam'
import FontParam from './FontParam'
import BooleanParam from './BooleanParam'

export default {
  name: 'WebsiteConfigSections',
  components: {
    TextfieldParam, TextareaParam, ImageParam, HtmlParam, ColorParam, FontParam, BooleanParam
  },
  props : ['sections'],
  data : function() {
    var d = {};
    return d;
  },
  methods : {
    }
};
</script>

<style scoped>
.reset {
    min-width: 50px;
}
</style>