import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {ExtrasParentViewModelFactory} from './ExtrasParentViewModelFactory'
import {ProductTaxRateViewModelFactory} from './ProductTaxRateViewModelFactory'
import {ProductImageViewModelFactory} from './ProductImageViewModelFactory'

var ProductViewModelFactory = {

    create : function(product, workAreaModifier, parentModifier) {
        if (product==null) {
            product={
                    id : null,
                    name : null,
                    displayName : null,
                    kitchenName : null,
                    posName : null,
                    extras : [],
                    ingredients : [],
                    extraProduct : true,
                    mainProduct : true,
                    productPrices : null,
                    dontInheritParentExtras : false,
                    alignPos : 'newline',
                    alignKitchen : 'newline',
                    alignCustomer : 'newline',
                    disabled : false,
                    onlineDisabled : false,
                    description : null,
                    prices : [
                        {
                            price : null,
                            strategy : 'single'
                        }
                    ]
            };
        }

        var ProductViewModel = {
            errors : ErrorsService.create(),
            extrasParent : null,
            isProduct : true,
            type : 'product'
        };

        var originalStr=null;

        function reset() {
            originalStr=JSON.stringify(product);
            ProductViewModel.errors = ErrorsService.create();
            ProductViewModel.model=reactive(JSON.parse(JSON.stringify(product)));
            ProductViewModel.created=ProductViewModel.model.id!=null;
            ProductViewModel.extrasParent=ExtrasParentViewModelFactory.create(ProductViewModel, workAreaModifier);
            ProductViewModel.taxRates=ProductTaxRateViewModelFactory.create(ProductViewModel.model, workAreaModifier);
            ProductViewModel.images=ProductImageViewModelFactory.create(ProductViewModel.model, workAreaModifier);
        }

        reset();

        ProductViewModel.isDirty=function() {
            return (originalStr!==JSON.stringify(ProductViewModel.model));
        };

        ProductViewModel.reset=reset;
        ProductViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
                var url;
                if (ProductViewModel.created) {
                    url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/product';
                } else {
                    url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/'+parentModifier.viewModel.type+'/'+parentModifier.viewModel.model.id+'/product';
                }
                ProductViewModel.errors.clearServerErrors();
                //extrasParent.setExtrasOnModel(copy);
                //resetProductsOnModel();

                //ProductViewModel.index=menu.getIndex(this);
                RestHelper.put(url, ProductViewModel.model).then(function(result) {
                    handleSaveResult(resolve,reject, result);
                }, PromiseUtils.errorHandler(reject));


                function handleSaveResult(resolve,reject, result) {
                    var created=ProductViewModel.created;
                    if (result.data.success) {
                        if (created) {
                            product=result.data.result;
                        } else {
                            product=result.data.result.product;
                        }
                        reset();
                        workAreaModifier.applyProduct(ProductViewModel);
                        if (!created) {
                            if (parentModifier.viewModel.isGrouping) {
                                parentModifier.applyProduct(result.data.result.groupingProduct);
                            } else if (parentModifier.viewModel.isVariation) {
                                parentModifier.applyProduct(result.data.result.variationProduct);
                            } else if (parentModifier.viewModel.isCategory) {
                                parentModifier.applyProduct(result.data.result.categoryProduct);
                            }

                        }
                        resolve({
                            success : true
                        });
                    } else {
                        ProductViewModel.errors.setServerErrors(result.data.errors);
                        resolve({
                            success : false
                        });
                    }
                }
            });
            return promise;
        };

        var parentNode;

        var parentDeleteListener = null;

        ProductViewModel.setParentNode = function(parentNodeP) {
            if (parentNode==parentNodeP) {
                return;
            }
            if (parentDeleteListener!=null) {
                parentDeleteListener();
                parentDeleteListener=null;
            }
            if (parentNodeP!=null) {
                parentDeleteListener = workAreaModifier.addDeletionCallback(parentNodeP.id, function() {
                    parentDeleteListener=null;
                    ProductViewModel.extrasParent.setParentNode(null);
                    parentNode=null;
                });
            }
            ProductViewModel.extrasParent.setParentNode(parentNodeP);
            parentNode=parentNodeP;
        };

        ProductViewModel.moveToCategory=function(category) {
            var promise = new Promise(function(resolve, reject) {
                RestHelper.post('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/product/'+ProductViewModel.model.id+"/move", {
                    targetId : category.model.id
                }).then(function(response) {
                    if (response.data.success) {
                        if (parentNode!=null) {
                            parentNode.removeProduct(ProductViewModel);
                        }
                        category.applyProduct(response.data.result);
                        resolve({
                            success : true
                        });
                    } else {
                        resolve({
                            success : false,
                            errors : response.data.errors
                        });
                    }
                }, PromiseUtils.errorHandler(reject));


            });

            return promise;
        };

        ProductViewModel.moveToVariation=function(variation) {
            var promise = new Promise(function(resolve, reject) {
                RestHelper.post('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/product/'+ProductViewModel.model.id+"/moveToVariation", {
                    targetId : variation.model.id
                }).then(function(response) {
                    if (response.data.success) {
                        if (parentNode!=null) {
                            parentNode.removeProduct(ProductViewModel);
                        }
                        variation.applyProduct(response.data.result);
                        resolve({
                            success : true
                        });
                    } else {
                        resolve({
                            success : false,
                            errors : response.data.errors
                        });
                    }
                }, PromiseUtils.errorHandler(reject));


            });

            return promise;
        };

        ProductViewModel=reactive(ProductViewModel);
        workAreaModifier.applyProduct(ProductViewModel);
        return ProductViewModel;
    }

};

export {ProductViewModelFactory as ProductViewModelFactory};