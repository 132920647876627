<template>
    <div class="form-group row">
      <label class="col-3 col-form-label">{{param.name}}</label>
      <div class="col-9 d-flex">
      <div class="flex-grow-1" v-if="!param.hasImage">No Image Set</div>
      <image-preview width="100" v-if="param.hasImage" :src="param.value"/>
      <div class="flex-grow-1 d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-dark"
          @click="removeImage()" v-if="param.hasImage">
          <fa class="fa mr-3" icon="minus"></fa><span>Remove</span>
      </button>
      <button type="button" class="btn btn-outline-dark ml-3"
          @click="editImage()">
          <fa class="fa mr-3" icon="plus"></fa><span v-if="param.hasImage">Change Image</span><span v-if="!param.hasImage">Set Image</span>
      </button>
      </div>
      </div>
      <media-library-modal v-if="showMediaLibrary" :callback="mediaSelected" @hidden="showMediaLibrary=false"/>
    </div>
</template>

<script>
import MediaLibraryModal from '@/views/media/MediaLibraryModal'

export default {
  name: 'ImageParam',
  components: {
    MediaLibraryModal
  },
  props : ['param'],
  data : function() {
    var d = {
        showMediaLibrary : false
    };
    return d;
  },
  methods : {
    mediaSelected : function(imageUrl) {
        this.param.value=imageUrl;
        this.showMediaLibrary=false

    },
    editImage : function() {
        this.showMediaLibrary=true;
    },
    removeImage : function() {
        this.param.value=null;
    }
  }
};
</script>

<style scoped>
</style>