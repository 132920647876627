<template>
<div>
<div v-if="!data.initialising">

<location-pos-config :data="data"/>
</div>
</div>
</template>

<script>

import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'

import LocationPosConfig from './LocationPosConfig'

export default {
  name: 'Order Workflow',
  components: {
    LocationPosConfig
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/location/primary/posconfig').optional().saveEndpoint('/wb/api/admin/location/primary/posconfig').saveAlert('Saved', 'Order workflow details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Order Workflow',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data
         });

    return d;
  },
  beforeRouteLeave (to, from, next) {
     this.data.beforeRouteLeave(to, from, next);
    },
  methods : {
        loadData : function() {
            this.data.load();
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>