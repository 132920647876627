import {reactive} from '@vue/composition-api'
import {VueUtils} from "@/utils/VueUtils";
import {PromiseUtils} from "@/utils/PromiseUtils";
import {AlertService} from '@/services/AlertService';

function builder(componentData) {
    var saveButton=reactive({
        label : 'Save',
        class : 'btn-primary',
        icon : 'save',
        onClick : function() {
            save();
        },
        hidden : false,
        disabled : true
    });
    var resetButton=reactive({
        label : 'RESET',
        icon : 'undo',
        onClick : function() {
            reset();
        },
        hidden : false,
        disabled : false
        });


    var deleteButton=reactive({
         label : 'DELETE',
         class : 'btn-primary',
         icon : 'trash',
         onClick : function() {
             confirmDelete();
         },
         hidden : false,
         disabled : false
     });


    var saveAlertTitle='Saved';
    var saveAlertMessage='Changes have been saved.';
    var deleteAlertTitle='Delete';
    var deleteAlertMessage='Changes have been saved.';
    var deleteConfirmMessage='Confirm you would like to delete this data.'

    var dirty=false;
    var data = {
        saving : false,
        beforeRouteLeave : function(to, from, next) {
            if (dirty) {
            componentData.$bvModal.msgBoxConfirm('Note: Any unsaved changes will be lost. Are you sure you want to leave this page?', {
                      title: 'Confirm Navigation',
                      okTitle: 'Leave this Page',
                      cancelTitle: 'Stay on this Page',
                      footerClass: 'p-2',
                      hideHeaderClose: false,
                      centered: true
                    })
                      .then(value => {
                        if (value) {
                            componentData.data.reset();
                            next();
                        }
                      })
                      .catch(function() {
                      });

         } else {
            next();
         }

        }
    };

    function save() {
        data.saving=true;
        viewModel.save().then(function(result) {
            data.saving=false;
            if (result.success) {
                if (headerFunc!=null) {
                    componentData.header=headerFunc(viewModel.model);
                }
                AlertService.success(saveAlertTitle, saveAlertMessage);
            } else {
                AlertService.danger('Invalid data', 'Please correct data and resubmit');
            }
        },PromiseUtils.viewErrorHandler('Error saving data', function() {
            data.saving=false;
        }));
    }

    function confirmDelete() {
         componentData.$bvModal.msgBoxConfirm(deleteConfirmMessage, {
              title: 'Confirm Deletion',
              okTitle: 'Delete',
              cancelTitle: 'Cancel',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
              .then(value => {
                if (value) {
                    doDelete();
                }
              })
              .catch(function() {
              });

    }

    function doDelete() {
        data.saving=true;
        viewModel.delete().then(function(result) {
            data.saving=false;
            if (result.success) {
                AlertService.success(deleteAlertTitle, deleteAlertMessage);
                if (deletionCallback!=null) {
                    deletionCallback();
                }
            } else {
                AlertService.danger('Error deleting data', result.errors);
            }
        },PromiseUtils.viewErrorHandler('Error deleting data', function() {
            data.saving=false;
        }));
    }

    function reset() {
        viewModel.reset();
        resetButtons();
    }

    function resetButtons() {
        dirty=viewModel.isDirty();
        if (resetButton!=null) {
            resetButton.disabled=!dirty;
            saveButton.disabled=!dirty;
        } else if (saveButton!=null) {
            saveButton.disabled=!dirty;
        }
    }

    var viewModel = null;
    var deletionCallback=null;
    var label = null;
    var headerFunc=null

    function createHeaderFunc() {
        if (headerFunc==null) {
            if (label!=null) {
                headerFunc=function(model) {
                    if (model.id==null) {
                        return 'Create '+label;
                    } else {
                        return label+': '+model.name;
                    }
                }
            }
        }
    }

    return {
        initialise : function() {
            createHeaderFunc();
            componentData.buttons.push(resetButton);
            componentData.buttons.push(saveButton);
            if (deleteButton!=null) {
                componentData.buttons.push(deleteButton);
            }
            var response = reactive(data);
            if (headerFunc!=null) {
                componentData.header=headerFunc(viewModel.model);
            }
            VueUtils.watchObject(viewModel, function() {
                resetButtons();
            });
            resetButtons();
            return response;
        },
        viewModel : function(vm) {
            viewModel=vm;
            return this;
        },
        header : function(func) {
            headerFunc=func;
            return this;
        },
        label : function(lab) {
            label=lab;
            return this;
        },
        saveAlert : function(title, msg) {
            saveAlertTitle=title;
            saveAlertMessage=msg;
            return this;
        },
        noDelete : function() {
            deleteButton=null;
            return this;
        },
        deletionCallback : function(callback) {
            deletionCallback=callback;
            return this;
        }
    };
}


var EditManager = {
    builder : builder

}

export {EditManager as EditManager};