<template>
    <div>
        <dashboard-reports :report-list="data.model.reports" v-if="data.model.reports">
        </dashboard-reports>
    </div>
</template>

<script>
import {PageStack} from '@/services/PageStackService'
import DashboardReports from '../charts/DashboardReports'
import {EditManager} from "@/utils/EditManager";

export default {
  name: 'Dashboard',
  components: {
    DashboardReports
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/dashboard').saveEndpoint('/wb/api/admin/dashboard').saveAlert('Saved', 'Changes to the dashboard have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Dashboard',
         buttons : [{
            label : 'Add Card',
            icon : 'plus',
            onClick : this.addCard
        }, data.createSaveButton()],
         data : data
     });
     return d;
  },
  methods : {
    addCard : function() {
        this.$router.push('/dashboard/'+this.data.model.dashboard.id+'/addreport');
    },

    loadData : function() {
        this.data.load();
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>