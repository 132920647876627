<template>
    <div id="details" class="card">
        <div class="card-header">Details
        </div>
        <div class="card-body">
            <form role="form" name="productForm" novalidate>
                <div class="form-group col-md-6">
                    <label for="exampleInputEmail1">Name</label> <input type="text"
                        name="name" class="form-control" id="name"
                        placeholder="Enter name" v-model="data.model.name"
                        >
                    <field-error :errors="data.errors" field="name"></field-error>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleInputEmail1">Display Name</label> <input
                        type="text" class="form-control" id="name"
                        placeholder="Enter name" v-model="data.model.displayName"
                        >
                    <field-error :errors="data.errors" field="displayName"></field-error>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleInputEmail1">POS Name</label> <input
                        type="text" class="form-control" id="name"
                        placeholder="Enter name" v-model="data.model.posName"
                        >
                    <field-error :errors="data.errors" field="posName"></field-error>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleInputEmail1">Kitchen Name</label> <input
                        type="text" class="form-control" id="name"
                        placeholder="Enter name" v-model="data.model.kitchenName"
                        >
                    <field-error :errors="data.errors" field="kitchenName"></field-error>
                </div>
                <div class="form-group  col-xs-12">
                    <label for="exampleInputEmail1">Description</label>
                    <textarea class="form-control" rows="3" placeholder="Enter description" v-model="data.model.description"
                        ></textarea>
                    <field-error :errors="data.errors" field="description"></field-error>
                </div>
                <div class="form-group  col-xs-12">
                    <label for="exampleInputEmail1">Price</label>

                    <money-input
                    type="text" class="form-control" id="name"
                        placeholder="Enter price" v-model="data.model.prices[0].price"
                        />
                    <field-error :errors="data.errors" field="prices[0].price"></field-error>
                </div>
                <div class="clearfix"></div>
                <div class="form-group col-md-4">
                    <label for="exampleInputEmail1">POS Alignment</label>
                    <b-form-select v-model="data.model.alignPos" :options="alignOptions" value-field="value" text-field="label"></b-form-select>
                    <field-error :errors="data.errors" field="alignPos"></field-error>
                </div>
                <div class="form-group col-md-4">
                    <label for="exampleInputEmail1">Kitchen Alignment</label>
                    <b-form-select v-model="data.model.alignKitchen" :options="alignOptions" value-field="value" text-field="label"></b-form-select>
                    <field-error :errors="data.errors" field="alignKitchen"></field-error>
                </div>
                <div class="form-group col-md-4">
                    <label for="exampleInputEmail1">Customer Receipt Alignment</label>
                    <b-form-select v-model="data.model.alignCustomer" :options="alignOptions" value-field="value" text-field="label"></b-form-select>
                    <field-error :errors="data.errors" field="alignCustomer"></field-error>
                </div>
            </form>
        </div>
        <!-- /.card-footer-->
    </div>
</template>

<script>


import FieldError from '@/components/FieldError'
import {LabelService} from '@/services/LabelService'

export default {
  name: 'ProductDetails',
  components: {
    FieldError
  },
  props : ['data'],
  data : function() {
    var d = {
        alignOptions : LabelService.alignOptions
         };

     return d;
  },
  methods : {
    },
    created : function() {
    }
};
</script>

<style scoped>

</style>