<template>
    <form name="socialForm">
        <ul class="list-group" v-if="!initialising">
            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Facebook</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.facebookEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.facebookEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="Facebook URL" v-model="model.facebookUrl"
                            >
                        <field-error :errors="errors" field="facebookUrl"></field-error>
                    </div>
                    <!--
                    <div class="form-group">
                        <label class="checkbox-inline"> <input type="checkbox"
                            id="inlineCheckbox1" v-model="model.facebookLoginEnabled">
                            Facebook Login Enabled
                        </label>
                        <field-error :errors="errors" field="facebookLoginEnabled"></field-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">App Id</label> <input
                            type="text" class="form-control" id="appId"
                            placeholder="Facebook App ID" v-model="model.facebookAppId"
                            >
                        <field-error :errors="errors" field="facebookAppId""></field-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Secret</label> <input
                            type="text" class="form-control" id="secret"
                            placeholder="Facebook Secret" v-model="model.facebookSecret"
                            >
                        <field-error :errors="errors" field="facebookSecret"></field-error>
                    </div>
                     -->
                </div>
            </li>

            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Twitter</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.twitterEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.twitterEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="Twitter URL" v-model="model.twitterUrl"
                            >
                        <field-error :errors="errors" field="twitterUrl"></field-error>
                    </div>
                </div>
            </li>

            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Instagram</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.instagramEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.instagramEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="Instagram URL" v-model="model.instagramUrl"
                            >
                        <field-error :errors="errors" field="instagramUrl"></field-error>
                    </div>
                    <!--
                    <div class="form-group">
                        <label for="exampleInputEmail1">Access Token</label> <input
                            type="text" class="form-control" id="name"
                            placeholder="Instagram URL"
                            v-model="model.instagramAccessToken"
                            >
                        <field-error :errors="errors" field="instagramAccessToken"></field-error>
                    </div>
                     -->
                </div>
            </li>

            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">YouTube</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.youTubeEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.youTubeEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="YouTube URL" v-model="model.youTubeUrl"
                            >
                        <field-error :errors="errors" field="youTubeUrl"></field-error>
                    </div>
                </div>
            </li>

            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Snapchat</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.snapchatEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.snapchatEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="Snapchat URL" v-model="model.snapchatUrl"
                            >
                        <field-error :errors="errors" field="snapchatUrl"></field-error>
                    </div>
                </div>
            </li>

            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Pinterest</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.pinterestEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.pinterestEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="Pinterest URL" v-model="model.pinterestUrl"
                            >
                        <field-error :errors="errors" field="pinterestUrl"></field-error>
                    </div>
                </div>
            </li>

            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Trip Advisor</span>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="model.tripAdvisorEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="model.tripAdvisorEnabled">
                    <div class="form-group col-xs-12">
                        <input type="text" class="form-control"
                            placeholder="Trip Advisor URL" v-model="model.tripAdvisorUrl"
                            >
                        <field-error :errors="errors" field="tripAdvisorUrl"></field-error>
                    </div>
                </div>
            </li>
        </ul>
    </form>
</template>

<script>
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import {ErrorsService} from '@/services/ErrorsService'
import {AlertService} from '@/services/AlertService'
import FieldError from '@/components/FieldError'

export default {
  name: 'Social',
  components: {
    FieldError
  },
  data : function() {
    var that=this;
    
    var d = PageStack.setPage(that.$route, {
         header : 'Social Media',
         initialising : true,
         model : null,
         errors : ErrorsService.create(),
         buttons : [{
            label : 'Save',
            onClick : function() {
                that.save();
            }
         }]
          
     });

    return d;
  },
  computed : {

  },
  methods : {
    save : function() {
        var that=this;
        that.errors.clearServerErrors();
        RestHelper.put('/wb/api/admin/social', that.model).then(function(response) {
            if (response.data.success) {
                //originalStr=JSON.stringify(response.data.result);
                AlertService.success('Saved', 'Changes have been saved.');
            } else {
                that.errors.setServerErrors(response.data.errors);
            }
        }, function() {
        });
    },
    loadData : function() {
        var that=this;
        that.initialising=true;
        RestHelper.get('/wb/api/admin/social').then(function(result) {
                var originalStr=JSON.stringify(result.data);
                that.model=JSON.parse(originalStr);
                that.initialising=false;
            }, function() {
                that.initialising=false;
            });
    }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>