<template>
  <b-modal id="select-discount-type-modal" size="xl" scrollable modal-class="select-discount-type-modal" centered hide-footer no-close-on-backdrop title="Select Discount Type" @hide="handleHide()">
        <div class="py-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12 p-3">
                    <div class="discount-card">
                        <div class="title">Monetary Discount</div>
                        <p>Encourage customers to spend money with monetary discounts that are applied automatically to orders.</p>
                        <button class="btn btn-primary align-self-end" @click.stop.prevent="select('discount')">Select</button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-122 p-3">
                    <div class="discount-card">
                        <div class="title">Promo Code</div>
                        <p>Bring in new customers by advertising discounts that can be applied via promo codes.</p>
                        <button class="btn btn-primary align-self-end" @click.stop.prevent="select('promoCode')">Select</button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-122 p-3">
                    <div class="discount-card">
                        <div class="title">Voucher Incentive</div>
                        <p>Encourage repeat orders by offering discounts on future purchases.</p>
                        <button class="btn btn-primary align-self-end" @click.stop.prevent="select('reward')">Select</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </b-modal>
</template>

<script>

import {RestHelper} from "@/utils/RestHelper";

var component = {
  name: "SelectDiscountTypeModal",
  props: ['callback'],
  components : {},
  data : function() {
      var d = {
        reports : []
      };
     return d;
    },
   methods : {
      select : function(type) {
        this.callback(type);
      },
      handleHide : function() {
          this.$emit('hidden');
      }
    },
    mounted : function() {
        this.$bvModal.show('select-discount-type-modal');
      },
      created : function() {
        var that=this;
        that.initialising=true;
        RestHelper.get('/wb/api/admin/reports').then(function(response) {
                that.reports=response.data;
                that.initialising=false;
            });
      }
};
export default component;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.discount-card {
    border: 1px solid lightgray;
    padding: 15px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
        flex-grow: 1;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
    }
}

.select-discount-type-modal .modal-content {
     height: 90vh !important;
 }

</style>