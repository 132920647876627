import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'

var TaxRateViewModelFactory = {

    create : function(taxRate, workAreaModifier) {

        if (taxRate==null) {
            taxRate={
                    id : null,
                    name : null,
                    type : 'percentage',
                    rate : null,
                    enabled : true
            };
        }

        //var original=taxRate;
        var copy=null;
        var originalStr=null;

        function reset() {
            originalStr=JSON.stringify(taxRate);
            copy=JSON.parse(originalStr);
            TaxRateViewModel.model=copy;
        }

        var TaxRateViewModel = {
            reset : reset,
            errors : ErrorsService.create(),
            get name() {
                return this.model.name
            }
        };

        TaxRateViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
                TaxRateViewModel.errors.clearServerErrors();

                RestHelper.put('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/taxrate', TaxRateViewModel.model).then(function(result) {
                    if (result.data.success) {
                        taxRate=result.data.result;
                        reset();
                        workAreaModifier.applyTaxRate(TaxRateViewModel);
                        resolve({
                            success : true
                        });
                    } else {
                        TaxRateViewModel.errors.setServerErrors(result.data.errors);
                        resolve({
                            success : false
                        });
                    }
                }, PromiseUtils.errorHandler(reject));
            });
            return promise;
        };

        TaxRateViewModel.isDirty=function() {
            return (originalStr!==JSON.stringify(TaxRateViewModel.model));
        };

        reset();

        TaxRateViewModel=reactive(TaxRateViewModel);

        return TaxRateViewModel;
    }

};

export {TaxRateViewModelFactory as TaxRateViewModelFactory};