<template>
    <div class="page-container col-lg-10 mx-auto p-3 py-md-5">
    <h1>Edit Configuration</h1>
    <div v-if="!initialising">
    <form v-if="vm.draft.hasDraft">
        <div class="row">
            <div class="col-12">
                <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1" v-if="config.commonSections.hasParams">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="light">Common Sections</b-button>
                      </b-card-header>
                      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <website-config-sections :sections="config.commonSections.sections"/>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1" v-for="(page) in config.pages" :key="page.name">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="light">{{page.name}}</b-button>
                      </b-card-header>
                      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <website-config-sections :sections="page.sections"/>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
            </div>
        </div>
    </form>
    </div>
    </div>
</template>

<script>
//import {PromiseUtils} from "@/utils/PromiseUtils";
import {PageStack} from '@/services/PageStackService'
import {AlertService} from '@/services/AlertService'
import WebsiteConfigSections from './WebsiteConfigSections'

export default {
  name: 'WebsiteConfig',
  components: {
    WebsiteConfigSections
  },
  props : ['vm'],
  data : function() {
    var that=this;
    
    var d = PageStack.addPage(that.$route, {
        saveButton : {
            label : 'Save',
            onClick : function() {
                that.save();
            },
                               hidden : false
         },
      publishButton : {
               label : 'Publish',
               onClick : function() {
                   that.publish();
               },
                                  hidden : false
            },
       previewButton : {
                label : 'Preview',
                onClick : function() {
                    that.preview();
                },
                                   hidden : false
             },
       deleteButton : {
                  label : 'Delete Draft',
                  onClick : function() {
                      that.deleteDraft();
                  },
                  hidden : false
               },
      editButton : {
             label : 'Edit',
             onClick : function() {
                 that.edit();
             },
                                hidden : false
          },
         header : 'Website Configuration',
         steps : [],
         initialising : false,
         showMediaLibrary : false,
         buttons : []
          
     });

     d.buttons.push(d.saveButton,d.publishButton,d.previewButton,d.deleteButton,d.editButton);

    return d;
  },
  computed : {
    'hasBannerImage' : function() {
        return this.vm.draft.model.banner.imageUrl!=null;
    },
    'config' : function() {
        return this.vm.draft.config;
    }
  },
  methods : {
    resetButtons : function() {
        this.saveButton.hidden=!this.vm.draft.hasDraft;
        this.publishButton.hidden=!this.vm.draft.hasDraft;
        this.deleteButton.hidden=!this.vm.draft.hasDraft;
        this.editButton.hidden=this.vm.draft.hasDraft;
    },
    mediaSelected : function(imageUrl) {
        this.vm.draft.model.banner.imageUrl=imageUrl;
        this.showMediaLibrary=false

    },
    editImage : function() {
        this.showMediaLibrary=true;
    },
    removeImage : function() {
        this.vm.draft.model.bannerImageUrl=null;
    },
     deleteDraft : function() {
        var that=this;
         this.vm.draft.deleteDraft().then(function(success) {
             if (success) {
                 AlertService.success('Website draft delete');
             } else {
                 AlertService.success('Delete failed');
             }
             that.resetButtons();
         });
     },
    edit : function() {
        var that=this;
        that.initialising=true;
        this.vm.edit().then(function() {
            that.initialising=false;
            that.resetButtons();
        }, function() {
            that.initialising=false;
        });
    }
    },
    created : function() {
        this.resetButtons();
    }
};
</script>

<style scoped>
</style>