<template>
     <iframe :src="vm.draft.previewUrl" class="flex-grow-1 h-100"></iframe>
</template>

<script>


export default {
  name: 'WebsiteDesktopPreview',
  components: {

  },
  props : ['vm'],
  data : function() {
    var d = {
        url : null
    };

     return d;
  },
  methods : {

        }
};
</script>

<style scoped>

</style>