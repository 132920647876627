import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {GroupingProductViewModelFactory} from './GroupingProductViewModelFactory'
import {ProductViewModelFactory} from './ProductViewModelFactory'

var GroupingViewModelFactory = {

    create : function(grouping, workAreaModifier, parent) {
        if (grouping==null) {
            grouping={
                    id : null,
                    name : null,
                    type : null,
                    products : [],
                    menuDisplayFormat : 'multiLine',
                    excludeDefaultPos : false,
                    excludeDefaultKitchen : false,
                    excludeDefaultCustomer : false,
                    selectionType : 'single'
            };
        }

        var GroupingViewModel = {
            isGrouping : true,
            type : 'grouping',
            errors : ErrorsService.create(),
        };

        function apply(groupingProduct) {
            grouping.products.push(groupingProduct);
            reset();
        }

        var groupingModifier = {
            viewModel : GroupingViewModel,
            applyProduct : apply
        };

        var originalStr=null;

        function resetProducts() {
            GroupingViewModel.model.products.forEach(function(product) {
                GroupingViewModel.products.push(GroupingProductViewModelFactory.create(product, workAreaModifier, GroupingViewModel));
            });
        }

        function reset() {
            originalStr=JSON.stringify(grouping);
            GroupingViewModel.errors = ErrorsService.create();
            GroupingViewModel.model=reactive(JSON.parse(JSON.stringify(grouping)));
            GroupingViewModel.created=GroupingViewModel.model.id!=null;
            GroupingViewModel.products=[];
            resetProducts();
        }

        reset();

        GroupingViewModel.isDirty=function() {
            return (originalStr!==JSON.stringify(GroupingViewModel.model));
        };

        GroupingViewModel.addProduct = function(productViewModel) {
            var product = {
                optionName : null,
                nameOverride : null,
                priceOverride : null,
                productId : productViewModel.model.id,
                displayOnMenu : true
            };
            GroupingViewModel.model.products.push(product);
            GroupingViewModel.products.push(GroupingProductViewModelFactory.create(product, workAreaModifier, GroupingViewModel));
        };

        GroupingViewModel.reset=reset;
        GroupingViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
                GroupingViewModel.errors.clearServerErrors();
                var url;
                if (GroupingViewModel.created) {
                    url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/grouping';
                } else {
                    url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/node/'+parent.underlying.model.id+'/grouping';
                }
                RestHelper.put(url, GroupingViewModel.model).then(function(result) {
                    handleSaveResult(resolve,reject, result);
                }, PromiseUtils.errorHandler(reject));


                function handleSaveResult(resolve,reject, result) {
                    if (result.data.success) {
                        var created=GroupingViewModel.created;
                        if (created) {
                            grouping=result.data.result;
                        } else {
                            grouping=result.data.result.grouping;
                        }
                        reset();
                        workAreaModifier.applyGrouping(GroupingViewModel);
                        if (!created) {
                            parent.addProductExtra(result.data.result.productExtra);
                        }
                        resolve({
                            success : true
                        });
                    } else {
                        GroupingViewModel.errors.setServerErrors(result.data.errors);
                        resolve({
                            success : false
                        });
                    }
                }
            });
            return promise;
        };

        GroupingViewModel.newProduct=function() {
            return ProductViewModelFactory.create(null, workAreaModifier, groupingModifier);
        };

        GroupingViewModel.reorderProducts=function(fromIndex, toIndex) {
            if (fromIndex!=toIndex) {
                    var fromItem=GroupingViewModel.products[fromIndex];
                    var fromModel=GroupingViewModel.model.products[fromIndex];
                    GroupingViewModel.products.splice(fromIndex, 1);
                    GroupingViewModel.model.products.splice(fromIndex, 1);
                    GroupingViewModel.products.splice(toIndex, 0, fromItem);
                    GroupingViewModel.model.products.splice(toIndex, 0, fromModel);

                    var index=0;
                    GroupingViewModel.model.products.forEach(function(p) {
                        p.index=index;
                        index++;
                    });
            }
        };

        var parentNode;

        var parentDeleteListener = null;




        GroupingViewModel.setParentNode = function(parentNodeP) {
            if (parentNode==parentNodeP) {
                return;
            }
            if (parentDeleteListener!=null) {
                parentDeleteListener();
                parentDeleteListener=null;
            }
            if (parentNodeP!=null) {
                parentDeleteListener = workAreaModifier.addDeletionCallback(parentNodeP.id, function() {
                    parentDeleteListener=null;
                    //extrasParent.setParentNode(null);
                    parentNode=null;
                });
            }
            parentNode=parentNodeP;
        };

        GroupingViewModel=reactive(GroupingViewModel);
        workAreaModifier.applyGrouping(GroupingViewModel);
        return GroupingViewModel;
    }

};

export {GroupingViewModelFactory as GroupingViewModelFactory};