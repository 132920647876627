<template>
    <form>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label>Date Range</label>
                            <date-range-picker v-model="filter"></date-range-picker>
                        </div>
                    </div>
                    <div v-if="type=='advanced'">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label>Min Price</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter Min" v-model="filter.minPrice" >
                            </div>
                            <div class="col-sm-6">
                                <label>Max Price</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter Max" v-model="filter.maxPrice">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label>Payment Type</label>
                                <select multiple size="5" class="form-control" v-model="filter.paymentMethods">
                                    <option v-for="option in paymentOptions" :key="option.value" v-bind:value="option.value">
                                        {{ option.label }}
                                      </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label>Order Source</label>
                                <select multiple size="5" class="form-control" v-model="filter.sources">
                                    <option v-for="option in sourceOptions" :key="option.value" v-bind:value="option.value">
                                        {{ option.label }}
                                      </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label>Receipt Printed</label>
                                <div>
                                    <label class="radio-inline"> <input type="radio"
                                    name="receiptPrinted" value="Y"
                                    v-model="receiptPrinted"> Yes
                                </label> <label class="radio-inline"> <input type="radio"
                                    name="receiptPrinted" value="N"
                                    v-model="receiptPrinted"> No
                                </label> <label class="radio-inline"> <input type="radio"
                                    name="receiptPrinted" value="B"
                                    v-model="receiptPrinted"> Either
                                </label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Customer</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter customer" v-model="filter.customer">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label>Status</label>
                                <select multiple size="5" class="form-control" v-model="filter.statuses">
                                                                    <option v-for="option in statusOptions" :key="option.value" v-bind:value="option.value">
                                                                        {{ option.label }}
                                                                      </option>
                                                                </select>
                            </div>
                          </div>

                      </div>
                      <div class="form-group row">
                       <div class="col-sm-12">
                           <div class="d-flex justify-content-end"><a href="#" @click.stop.prevent="toggle()"><span v-if="type=='advanced'">simple</span><span v-if="type=='simple'">advanced</span></a></div>
                       </div>
                     </div>
                  </div>
                  <div class="card-footer">
                      <a href="#" @click.stop.prevent="reset()">reset</a>
                      <button type="submit" class="btn btn-primary float-right" :disabled="!canSubmit" @click.stop.prevent="search()">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </form>
</template>

<script>

function resetFilter() {
    return  {
       dateRange : null,
       startDate : null,
       endDate : null,
       receiptPrinted : null,
       customer : null,
       statuses : [],
       paymentMethods : [],
       sources : [],
       minPrice : null,
       maxPrice : null
   };
}

export default {
  name: 'OrderFilter',
  components: {

  },
  data : function() {
    var d = {
        filter : resetFilter(),
        type : 'simple',
        sourceOptions : [],
        paymentOptions : [],
        statusOptions : [],
        get receiptPrinted() {
            if (this.filter.receiptPrinted==null) {
                return 'B';
            } else if (this.filter.receiptPrinted==true) {
                return 'Y';
            } else {
                return 'N';
            }
        },
        set receiptPrinted(value) {
            if (value=='B') {
                this.filter.receiptPrinted=null;
            } else if (value=='Y') {
                this.filter.receiptPrinted=true;
            } else {
                this.filter.receiptPrinted=false;
            }
        }
    };
    d.sourceOptions.push({label : 'Walkin', value : 'inStore'});
    d.sourceOptions.push({label : 'Phone', value : 'phone'});
    d.sourceOptions.push({label : 'Online', value : 'web'});
    d.sourceOptions.push({label : 'Uber', value : 'uber'});
    d.sourceOptions.push({label : 'Just Eat', value : 'justeat'});
    d.sourceOptions.push({label : 'Deliveroo', value : 'deliveroo'});
    
    d.paymentOptions.push({label : 'Cash', value : 'cash'});
    d.paymentOptions.push({label : 'Card', value : 'card'});
    d.paymentOptions.push({label : 'Online', value : 'onlinePayment'});
    d.paymentOptions.push({label : 'Voucher', value : 'voucher'});
    d.paymentOptions.push({label : 'Cheque', value : 'cheque'});
    d.paymentOptions.push({label : 'Multiple', value : 'multi'});
    
    d.statusOptions.push({label : 'Completed', value : 'complete'});
    d.statusOptions.push({label : 'Cancelled', value : 'cancelled'});
    d.statusOptions.push({label : 'Aborted', value : 'aborted'});
    d.statusOptions.push({label : 'Rejected', value : 'rejected'});
     return d;
  },
  methods : {
    toggle : function() {
        if (this.type=='advanced') {
            var startDate=this.filter.startDate;
            var endDate=this.filter.endDate;
            var dateRange=this.filter.dateRange;
            this.filter=resetFilter();
            this.filter.startDate=startDate;
            this.filter.endDate=endDate;
            this.filter.dateRange=dateRange;
            this.type='simple'
        } else {
            this.type='advanced';
        }
    },
    reset : function() {
        this.filter=resetFilter();
    },
    search : function() {
        this.$emit('search', this.filter);
    }
  },
  watch : {

  },
  computed : {
    canSubmit : function() {
            return this.filter.dateRange!=null;
        }

  },

    created : function() {

    }
};
</script>

<style scoped>

</style>