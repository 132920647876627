    function create() {
        var errors = {
        };
        var generalErrors = [];

        var listeners = [];

        var obj = {
            addListener : function(Func) {
                listeners.push(Func);
                return function() {
                    var index = listeners.indexOf(Func);
                    if (index>=0) {
                        listeners.splice(index, 1);
                    }
                };
            },
            clearServerErrors : function() {
                for (var key in errors) {
                    var list = errors[key];
                    if (!list) {
                        continue;
                    }
                    for (var i=list.length-1;i>=0;i--) {
                        if (!list[i].isLocal) {
                            list.splice(i, 1);
                        }
                    }

                }
                generalErrors=[];

            },
            getFieldErrors : function(field) {
                var resp = errors[field];
                if (!resp) {
                    return [];
                } else {
                    return resp;
                }
            },
            getGeneralErrors : function() {
                return generalErrors;
            },
            setServerErrors : function(serverErrors) {
                this.clearServerErrors();

                serverErrors.forEach(function(error) {
                    if (error.field==null) {
                        generalErrors.push({
                             isLocal : false,
                             type : 'server',
                             message : error.message
                         });
                    } else {
                        if (errors[error.field] === undefined) {
                            errors[error.field] = [];
                        }
                        errors[error.field].push({
                            isLocal : false,
                            type : 'server',
                            message : error.message
                        });
                    }
                });
                listeners.forEach(function(l) {
                    l();
                });

            },
            addServerError : function(field, message) {
                if (errors[field] === undefined) {
                    errors[field] = [];
                }
                errors[field].push({
                    isLocal : false,
                    type : 'server',
                    message : message
                });
            }

        };
        return obj;
    }

var ErrorsService = {
    create : function() {
        return create();
    }

};

export {ErrorsService as ErrorsService};