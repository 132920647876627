<template>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{param.name}}</label>
      <div class="col-sm-9">
        <textarea class="form-control" v-model="param.value" :placeholder="param.name"></textarea>
      </div>
    </div>
</template>

<script>

export default {
  name: 'TextareaParam',
  components: {
  },
  props : ['param'],
  data : function() {
    var d = {};
    return d;
  },
  methods : {
    }
};
</script>

<style scoped>
</style>