import { reactive } from '@vue/composition-api'
import {ErrorsService} from '@/services/ErrorsService'

var ExtraViewModelFactory = {

    create : function(productExtra, parent, productExtraViewModels, localProductExtraViewModels, workAreaModifier) {

        if (productExtra==null) {
            productExtra={
               "groupingId": null,
               "index": 0,
               "required": false,
               "beforeParentExtras": false,
               "menuDisplayFormat": "groupNameSinglePrice"
             };
        }

        var selectedGrouping = null;

        var deleteListener = null;
        console.log(deleteListener);

        function doRemove() {
            var index = productExtraViewModels.indexOf(ExtraViewModel);
            if (index>-1) {
                productExtraViewModels.splice(index, 1);
            }
            index = localProductExtraViewModels.indexOf(ExtraViewModel);
            if (index>-1) {
                localProductExtraViewModels.splice(index, 1);
            }
            index = parent.extras.indexOf(productExtra);
            if (index>-1) {
                parent.extras.splice(index, 1);
            }
        }

        /*
        var vm = {
            get beforeParentExtras() {
                return productExtra.beforeParentExtras;
            },
            get required() {
                return productExtra.required;
            },
            set required(value) {
                productExtra.required=value;
            },
            get menuDisplayFormat() {
                return productExtra.menuDisplayFormat;
            },
            set menuDisplayFormat(value) {
                productExtra.menuDisplayFormat=value;
            },
            remove : function() {
                if (!that.canEditExtra(this)) {
                    return;
                }
                doRemove(this);
                if (deleteListener!=null) {
                    deleteListener();
                    deleteListener=null;
                }
            },
            getDto : function() {
                return productExtra;
            }
        };
        */

        var ExtraViewModel = {
            model : productExtra,
            errors : ErrorsService.create(),
            viewModel : null,
            text : null,
            nodes : null,
            remove : doRemove
        };

        function reset() {
            ExtraViewModel.viewModel=selectedGrouping;
            ExtraViewModel.model=productExtra;
            ExtraViewModel.text=selectedGrouping==null?null:'Modifier Group ['+selectedGrouping.name+']';
            ExtraViewModel.nodes=selectedGrouping==null?null:selectedGrouping.nodes;
            /*
            get availableExtras() {
                            return workAreaModifier.groupingsForProducts;
                        }
                        */
        }

        workAreaModifier.addGroupingInitCallback(function() {
            if (productExtra.groupingId!=null) {
                selectedGrouping=workAreaModifier.getGroupingById(productExtra.groupingId);
                if(selectedGrouping!=null) {
                    deleteListener = workAreaModifier.addDeletionCallback(productExtra.groupingId, function() {
                        deleteListener=null;
                        doRemove(ExtraViewModel);
                    });
                }
            }
            reset();
        });


        ExtraViewModel=reactive(ExtraViewModel);

        return ExtraViewModel;
    }



};

export {ExtraViewModelFactory as ExtraViewModelFactory};