<template>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <input type="text" class="form-control" placeholder="Filter" v-model="filter" style="margin-bottom:20px;"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-end mb-2 px-4">
                    <button type="button" class="btn mr-2" :class="{'btn-primary':listFilter==null, 'btn-light':listFilter!=null}" @click="listFilter=null">All Products</button>
                    <button type="button" class="btn mr-2" :class="{'btn-primary':listFilter=='today', 'btn-light':listFilter!='today'}"  @click="listFilter='today'">Unavailable Today</button>
                    <button type="button" class="btn mr-2" :class="{'btn-primary':listFilter=='indefinite', 'btn-light':listFilter!='indefinite'}"  @click="listFilter='indefinite'">Unavailable Indefinite</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ul class="list-group ">
                        <li class="list-group-item actionable" v-for="(product, index) in filteredProducts" v-bind:key="index">
                            <div>
                                {{product.name}}
                            </div>
                            <div>
                                  <button type="button" class="btn mr-2" :disabled="product.saving" :class="{'btn-primary':product.available, 'btn-light':!product.available}" @click="product.updateProduct(null)">Available</button>
                                  <button type="button" class="btn mr-2" :disabled="product.saving" :class="{'btn-primary':product.unavailableToday, 'btn-light':!product.unavailableToday}" @click="product.updateProduct('today')">Unavailable Today</button>
                                  <button type="button" class="btn" :disabled="product.saving" :class="{'btn-primary':product.unavailableIndefinite, 'btn-light':!product.unavailableIndefinite}" @click="product.updateProduct('indefinite')">Unavailable Indefinite</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'

function setProductViewModelValues(vm, product) {
    vm.available=product.duration==null;
    vm.unavailableToday=product.duration=='today';
    vm.unavailableIndefinite=product.duration=='indefinite';
}

function createProductViewModel(product) {
    var vm = {
        name : product.name,
        saving : false,
        updateProduct : function(duration) {
            var copy = JSON.parse(JSON.stringify(product));
            copy.duration=duration;
            copy.available=copy.duration!=null;
            var old = JSON.parse(JSON.stringify(product));
            product=copy;
            setProductViewModelValues(vm, product);
            vm.saving=true;
            var url = '/wb/api/admin/menu/availability';
            RestHelper.put(url, copy).then(function(response) {
                if (!response.data.success) {
                    product=old;
                    setProductViewModelValues(vm, product);
                }
                vm.saving=false;
            }, function () {
                product=old;
                setProductViewModelValues(vm, product);
                vm.saving=false;
              });

        },
        setAvailable : function() {
        },
        setUnavailableToday : function() {
        },
        setUnavailableIndefinite : function() {
        }
    };

    

    setProductViewModelValues(vm, product);
    return vm;
}

export default {
  name: 'UnavailableItems',
  components: {

  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(that.$route, {
         header : 'Unavailable Items',
         buttons : [],
         products : [],
         filter : '',
         listFilter : null
     });
     return d;
  },
  computed : {
    filteredProducts : function() {
        var result;
        if (this.listFilter==null) {
            result=this.products;
        } else if (this.listFilter=='indefinite') {
          result=this.products.filter(item => {
              return item.unavailableIndefinite;
           });
        } else if (this.listFilter=='today') {
             result=this.products.filter(item => {
                 return item.unavailableToday;
              });
        } else {
            result=this.products;
        }
        if (this.filter!=null && this.filter.trim()!='') {
            result=result.filter(item => {
                return item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
             });
        }
        return result;
    }
  },
  methods : {
        goto : function() {

        },

        loadData : function() {
            var that=this;
            RestHelper.get('/wb/api/admin/menu/availabilities').then(function(response) {
                that.products=[];
                response.data.forEach(function(product) {
                    that.products.push(createProductViewModel(product));
                });
            }, PromiseUtils.viewErrorHandler('Error retrieving products'));
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>