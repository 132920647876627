<template>
    <div class="chart-container">
        <span v-if="empty">There is no data for this report</span>
        <canvas ref="chart">
        </canvas>
    </div>
</template>

<script>

import moment from 'moment'
import Chart from 'chart.js';

var colors = [];
	colors.push('#2687c0');
	colors.push('#36b099');
	colors.push('#b33b32');
	colors.push('#8643a3');
	colors.push('#dcaf09');
	colors.push('#dc8b09');
	colors.push('#3da96c');
	colors.push('#c55f20');

function createData(thisData) {

                        var data = {
                            datasets: [],
                            labels : []
                        };

                        var labelIndex;
                        var labelColumn;
                        for (var i=0;i<thisData.reportConfig.config.tableColumns.length;i++) {
                            var col = thisData.reportConfig.config.tableColumns[i];
                            if (thisData.chartConfig.labelColumn===col.id) {
                                labelIndex=i;
                                labelColumn=col;
                                break;
                            }
                        }

                        thisData.reportConfig.results[0].data.forEach(function(row) {
                            var label=null;
                            if (labelColumn.type==='date') {
                                label=moment(new Date(row[labelIndex])).format('ddd DD MMM YYYY');
                                data.labels.unshift();
                            } else {
                                label=row[labelIndex];
                            }
                            if (thisData.chartConfig.reverse) {
                                data.labels.unshift(label);
                            } else {
                                data.labels.push(label);
                            }
                        });


                        thisData.reportConfig.results.forEach(function(result) {
                            var dsIndex = 0;
                            thisData.chartConfig.dataseries.forEach(function(ds) {
                                var color = colors[dsIndex%colors.length];
                                var dataList=[];
                                var dataIndex;
                                for (var i=0;i<thisData.reportConfig.config.tableColumns.length;i++) {
                                    var col = thisData.reportConfig.config.tableColumns[i];
                                    if (ds.dataColumn===col.id) {
                                        dataIndex=i;
                                        break;
                                    }
                                }
                                result.data.forEach(function(row) {
                                    var item=row[dataIndex];
                                    if (thisData.chartConfig.reverse) {
                                        dataList.unshift(item);
                                    } else {
                                        dataList.push(item);
                                    }
                                });
                                data.datasets.push({
                                    label : ds.name,
                                    data : dataList,
                                    backgroundColor : color
                                });
                                dsIndex++;
                            });
                        });

                        return data;

                    }

function createLineChart(d) {
        var cvs = d.$refs.chart;
            if (cvs==null) {
                return false;
            }
        var ctx = cvs.getContext('2d');


    var data = createData(d);

        var options = {
                legend: { display: false },
                maintainAspectRatio : true,
                aspectRatio : 2,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: false,
                            maxRotation: 90,
                            minRotation: 90
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }
                    ]
                }
              };

        new Chart(ctx, {
            type: 'line',
            data: data,
            options : options
        });
        return true;

    }

export default {
  name: 'LineChart',
  props : ['reportConfig', 'dataset', 'chartConfig'],
  components: {


  },
   data : function() {
      return {
        empty : false

      };
    },
    methods : {

    },
    mounted : function() {
        createLineChart(this);
    }
  };
</script>

<style scoped>

</style>