<template>
    <div class="container-fluid">
        <div class="row justify-content-center" v-if="!data.initialising">
            <div class="col-12">
                <form name="brandForm">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Brand Info</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group col-12">
                                <label for="exampleInputEmail1">Name</label> <input type="text"
                                    class="form-control" id="name" placeholder="Enter name"
                                    v-model="data.model.name"
                                    >
                                <field-error :errors="data.errors" field="name"></field-error>
                            </div>
                            <div class="form-group col-12">
                                <label for="exampleInputEmail1">Logo</label>
                                <div class="image-container" v-if="data.model.logoUrl">
                                    <image-preview width="175" :src="data.model.logoUrl" :edit="true"/>
                                    <div class="options">
                                        <button class="btn" @click.stop.prevent="editImage()">EDIT</button>
                                        <button class="btn" @click.stop.prevent="deleteImage()">DEL</button>
                                    </div>
                                </div>
                                <div v-if="!data.model.logoUrl">
                                <button class="btn btn-outline-dark" @click.stop.prevent="editImage()">SELECT IMAGE</button>
                                </div>
                                <field-error :errors="data.errors" field="logoUrl"></field-error>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <media-library-modal v-if="showMediaLibrary" :callback="mediaSelected" @hidden="showMediaLibrary=false"/>
    </div>
</template>

<script>

import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError';
import MediaLibraryModal from '@/views/media/MediaLibraryModal'

export default {
  name: 'Brand',
  components: {
    FieldError,MediaLibraryModal
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/brand').saveEndpoint('/wb/api/admin/brand').saveAlert('Saved', 'Changes to the brand details have been changed.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Brand Details',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data,
         showMediaLibrary : false
         });

    return d;
  },
  beforeRouteLeave (to, from, next) {
     this.data.beforeRouteLeave(to, from, next);
    },
  methods : {
        loadData : function() {
            this.data.load();
        },
        deleteImage : function() {
            this.data.model.logoUrl=null;
        },
         editImage : function() {
             this.showMediaLibrary=true;
         },
         mediaSelected : function(url) {
            this.data.model.logoUrl=url;
            this.showMediaLibrary=false;
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>
    .image-container {
        display: flex;
        flex-direction: column;
        width: 175px;
        align-items: center;
    }

    .options {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
    }

</style>