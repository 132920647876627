<template>
    <div class="sidepanel">
        <div class="contact-details">
                <div class="type" v-if="model.customer.name"><fa icon="user"/></div>
                <div class="value" v-if="model.customer.name">{{model.customer.name}}</div>
                <div class="type" v-if="model.customer.email"><fa icon="envelope"/></div>
                <div class="value" v-if="model.customer.email">{{model.customer.email}}</div>
                <div class="type" v-if="model.customer.phoneNumber"><fa icon="phone"/></div>
                <div class="value" v-if="model.customer.phoneNumber">{{model.customer.phoneNumber}}</div>
        </div>
        <div class="delivery-address" v-if="model.type=='delivery'">
            <h5>Delivery Address</h5>
            <div class="d-flex">
                <div class="address">
                    <div class="value">{{model.customer.deliveryAddress}}</div>
                    <div class="value">{{model.customer.deliveryPostcode}}</div>
                </div>
                <div class="map-icon">
                    <fa icon="map-marker-alt"/>
                </div>
            </div>
        </div>
        <div class="tender-details">
            <h5>Payment</h5>
            <div class="payment-grid" v-for="(payment, index) in model.payment.paymentItems" v-bind:key="index">
                <div class="type">
                    <fa v-if="payment.method=='cash'" icon="money-bill"></fa>
                    <fa v-if="payment.method=='card'" icon="credit-card"></fa>
                    <fa v-if="payment.method=='onlinePayment'" icon="desktop"></fa>
                    <fa v-if="payment.method=='voucher'" icon="ticket-alt"></fa>
                </div>
                <div class="amount">{{$filters.toCurrency(payment.amount)}}</div>
            </div>
        </div>
        <div class="timings">
            <h5>Audit</h5>
            <div class="time-section" v-if="model.audit.placedTime">
                <div class="description">Placed</div>
                <div class="time">{{$filters.formatDate(model.audit.placedTime, 'HH:mm')}}</div>
            </div>
            <div class="time-section" v-if="model.audit.verificationTime">
                <div class="description">Verified</div>
                <div class="time">{{$filters.formatDate(model.audit.verificationTime, 'HH:mm')}}</div>
            </div>
            <div class="time-section" v-if="model.audit.readyTime">
                <div class="description">Ready</div>
                <div class="time">{{$filters.formatDate(model.audit.readyTime, 'HH:mm')}}</div>
            </div>
            <div class="time-section" v-if="model.audit.tenderTime">
                <div class="description">Tendered</div>
                <div class="time">{{$filters.formatDate(model.audit.tenderTime, 'HH:mm')}}</div>
            </div>
            <div class="time-section" v-if="model.audit.deliveringTime">
                <div class="description">Out for Delivery</div>
                <div class="time">{{$filters.formatDate(model.audit.deliveringTime, 'HH:mm')}}</div>
            </div>
            <div class="time-section" v-if="model.audit.completeTime">
                <div class="description">Completed</div>
                <div class="time">{{$filters.formatDate(model.audit.completeTime, 'HH:mm')}}</div>
            </div>
        </div>
    </div>

</template>

<script>

var component = {
  name: 'ViewOrderDetails',
  components: {

  },
  props : ['model'],
     data : function() {
       var d = {


       };
       return d;
     },
     methods : {

     },
     computed : {

     },
     created : function() {
     }
};

export default component;
</script>

<style lang="scss" scoped>

.sidepanel {
    margin: 25px 15px 10px 15px;
    display: flex;
    flex-direction: column;
}

.contact-details {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 30px auto;
    grid-row-gap: 10px;
    padding-bottom: 10px;

}

.dates-panel {
    margin: 15px 0px 15px 0px;
}

.delivery-address {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    padding: 20px;
    margin: 10px 0px 10px 0px;
}

.delivery-address .address {
    flex-grow: 1;
    align-self: flex-start;
}

.delivery-address .map-icon {
    min-width: 30px;
    padding: 15px;
}

.dates-panel {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 100px auto;
    grid-row-gap: 10px;
    padding-bottom: 10px;
}

.timings {
    border: 1px solid lightgray;
       padding: 20px;
       margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: column;
}

.timings .time-section {
    display : flex;
    align-items: center;
    flex-grow: 1;

    .description {
        flex-basis: 0;
        flex-grow: 1;
    }

    .time {
            flex-basis: 0;
        }
}

.timings .time-section .note {
    font-size: 14px;
    color: gray;
}

.timings .time {
    font-size: 25px;
}

.timings .time-section.overdue .time {
    color : var(--ion-color-danger);
}

.spacer {
    flex-grow: 1;
}

.actions {
    display: flex;
}

.actions * {
    flex: 1;
}

.tender-details {
   display: flex;
   flex-direction: column;
   border: 1px solid lightgray;
   padding: 20px;
   margin: 10px 0px 10px 0px;
}

.tender-details .pending {
    font-weight: 500;
    margin-bottom: 5px;
}

.tender-details ion-button {
    align-self: flex-end;
}

.tender-details .payment-grid {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
}

.tender-details .payment-grid .type {
    width: 30px;
}

.tender-details .payment-grid .amount {
    margin-top: -3px;
}

</style>