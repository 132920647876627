<template>
    <div class="map-container">
         <div id="map"></div>
    </div>
</template>

<script>


export default {
  name: 'DeliveryHeatmap',
  components: {

  },
  props : ['data'],
  data : function() {
    var d ={

     };
     return d;
  },
  methods : {
    intitialiseMap : function() {
        var points = [];
        this.data.dataPoints.forEach(function(point) {

            points.push(new window.google.maps.LatLng(point.latitude, point.longitude));
        });
        var center = new window.google.maps.LatLng(this.data.center.latitude, this.data.center.longitude);
        this.map = new window.google.maps.Map(document.getElementById('map'), {
                  center: center,
                  zoom: 13,
                  mapTypeId: 'satellite'
                });
                new window.google.maps.Marker({
                    position: center,
                    map : this.map
                  });

                var heatmap = new window.google.maps.visualization.HeatmapLayer({
                  data: points,
                  gradient : [
                                 "rgba(0, 255, 255, 0)",
                                 "rgba(0, 255, 255, 1)",
                                 "rgba(0, 191, 255, 1)",
                                 "rgba(0, 127, 255, 1)",
                                 "rgba(0, 63, 255, 1)",
                                 "rgba(0, 0, 255, 1)",
                                 "rgba(0, 0, 223, 1)",
                                 "rgba(0, 0, 191, 1)",
                                 "rgba(0, 0, 159, 1)",
                                 "rgba(0, 0, 127, 1)",
                                 "rgba(63, 0, 91, 1)",
                                 "rgba(127, 0, 63, 1)",
                                 "rgba(191, 0, 31, 1)",
                                 "rgba(255, 0, 0, 1)",
                               ]
                });
                heatmap.setMap(this.map);
    }
    },
    mounted : function() {
        this.intitialiseMap();
    }
};
</script>

<style lang="scss" scoped>

.map-container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;

    #map {
        flex-grow: 1;
    }
}

</style>