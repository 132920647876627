<template>
    <div class="container-fluid">
        <div class="row justify-content-center"  v-if="!data.initialising">
            <div class="col-12">
                <form name="deviceForm">
                    <div class="card">
                        <div class="card-body">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Name</label> <input type="text" class="form-control" id="name" placeholder="Enter name" v-model="data.model.name">
                                </div>
                                <div class="form-group">
                                  <label>Location</label>
                                  <v-select label="name" :options="locations" :reduce="option => option.id" v-model="data.model.locationId"></v-select>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Created Time:</label> <p>{{$filters.formatDate(data.model.createdDate, 'HH:mm')}}</p>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Updated Time:</label> <p>{{$filters.formatDate(data.model.updatedDate, 'HH:mm')}}</p>
                                </div>


                            </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import {AlertService} from '@/services/AlertService'
import {EditManager} from "@/utils/EditManager";

export default {
  name: 'DeviceEdit',
  components: {

  },
  data : function() {
    var that=this;
    var id = this.$route.params.id;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/device/'+id).saveEndpoint('/wb/api/admin/device/'+id).deleteEndpoint('/wb/api/admin/device/'+id, this.deleted).saveAlert('Saved', 'Changes to the device details have been changed.').build();
    var d = PageStack.addPage(that.$route, {
         header : 'Edit Device',
         buttons : [{
            label : 'Send Test Msg',
            icon : 'envelope',
            onClick : this.sendTestMsg,
            hidden : false,
            disabled : false
        },data.createDeleteButton(),data.createResetButton(),data.createSaveButton()],
         data : data,
         locations : []
     });
     return d;
  },
  methods : {
    deleted : function() {
        PageStack.chopPage(this.$router);
    },

    sendTestMsg : function() {
        RestHelper.post('/wb/api/admin/device/'+this.data.model.id+'/test').then(function() {
            AlertService.success('Success', 'Test message sent.');
        }, PromiseUtils.viewErrorHandler('Error sending test message'));
     }
    },
    created : function() {
        this.data.load();
        var that=this;
        RestHelper.get('/wb/api/admin/locations').then(function(result) {
                that.locations=result.data;
            }, PromiseUtils.viewErrorHandler('Error retrieving locations'));
    }
};
</script>

<style scoped>

</style>