<template>
    <form role="form" name="paymentForm" novalidate>
        <ul class="list-group">
            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Stripe Payments are: <span
                            v-if="data.model.stripeEnabled">Enabled</span><span
                            v-if="!data.model.stripeEnabled">Not Enabled</span></span>
                        <p v-if="!data.model.stripeEnabled">Customers can't pay
                            via Stripe online.</p>
                        <p v-if="data.model.stripeEnabled">Customers can pay via
                            Stripe online.</p>
                    </div>
                    <div>
                        <b-form-checkbox switch size="lg" v-model="data.model.stripeEnabled"></b-form-checkbox>
                    </div>
                </div>
                <field-error :errors="data.errors" field="stripeEnabled"></field-error>
                <div class="container-fluid" v-if="data.model.stripeEnabled">
                    <div class="form-group col-xs-12">
                        <label for="exampleInputEmail1">Client ID</label> <input
                            type="text" name="stripeClientId" class="form-control"
                            id="stripeClientId" placeholder="Enter Stripe Client ID"
                            v-model="data.model.stripeClientId"
                            v-model-options="{updateOn: 'blur'}" required>
                        <field-error :errors="data.errors"
                            field="stripeClientId"></field-error>
                    </div>
                    <div class="form-group col-xs-12">
                        <label for="exampleInputEmail1">Client Secret</label> <input
                            type="password" name="stripeClientSecret" class="form-control"
                            id="stripeClientSecret" placeholder="Enter Stripe Client Secret"
                            v-model="data.model.stripeClientSecret"
                            v-model-options="{updateOn: 'blur'}" required>
                        <field-error :errors="data.errors"
                            field="stripeClientSecret"></field-error>
                    </div>

                </div>
            </li>
        </ul>
    </form>
</template>

<script>
import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError';


export default {
  name: 'CardPayment',
  components: {
    FieldError
  },
  data : function() {
    var id=this.$route.params.id;
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/olo/config/'+id+'/payment').saveEndpoint('/wb/api/admin/olo/config/'+id+'/payment').saveAlert('Saved', 'Payment details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Payment Integration',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data
         });

     return d;
  },
  methods : {
            loadData : function() {
                this.data.load();
            }
        },
        created : function() {
            this.loadData();
        }
};
</script>

<style scoped>

</style>