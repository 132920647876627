<template>
    <div>
        <div class="extra-wrapper" v-for="(extra, index2) in extras" v-bind:key="index2">
            <div class="extra">
               <div class="details">
                    {{extra.optionName}}
               </div>
           </div>
           <item-extra v-if="extra.extras" :extras="extra.extras"/>
        </div>
    </div>
</template>

<script>

var component = {
  name: 'ItemExtra',
  components: {
  },
    props : ['extras'],
     data : function() {
       var d = {
            order : null
       };
       return d;
     },
     methods : {

     },
     created : function() {

     }
};

export default component;
</script>

<style scoped>
.extra-wrapper {
    padding: 0px 15px 0px 25px;
}

.extra {
    display: flex;
    font-size: 14x;
    font-weight: 300;
}

.extra .details {
    flex-grow: 1;
}
</style>