import { reactive } from '@vue/composition-api';

function createTextFieldParamViewModel(param, data) {
    var vm = {
        isTextfield : true,
        name : param.name,
        set value(val) {
            data[param.id]=val;
        },
        get value() {
            return data[param.id];
        }
    };
    return vm;
}

function createTextAreaParamViewModel(param, data) {
    var vm = {
        isTextarea : true,
        name : param.name,
        set value(val) {
            data[param.id]=val;
        },
        get value() {
            return data[param.id];
        }
    };
    return vm;
}

function createHtmlParamViewModel(param, data) {
    var vm = {
        isHtml : true,
        name : param.name,
        set value(val) {
            data[param.id]=val;
        },
        get value() {
            return data[param.id];
        }
    };
    return vm;
}

function createImageParamViewModel(param, data) {
    function doesHaveImage() {
        return data[param.id]!=null && data[param.id].length>0;
    }
    var vm = {
        isImage : true,
        name : param.name,
        hasImage : doesHaveImage(),
        set value(val) {
            data[param.id]=val;
            vm.hasImage = doesHaveImage();
        },
        get value() {
            return data[param.id];
        }
    };
    return vm;
}

function createColorParamViewModel(param, data) {
    function doesHaveColor() {
        return data[param.id]!=null && data[param.id].length>0;
    }
    var vm = {
        isColor : true,
        name : param.name,
        hasColor: doesHaveColor(),
        set value(val) {
            data[param.id]=val;
            vm.hasColor = doesHaveColor();
        },
        get value() {
            return data[param.id];
        }
    };
    return vm;
}

function createFontParamViewModel(param, data) {
    var vm = {
        isFont : true,
        name : param.name,
        set value(val) {
            data[param.id]=val;
        },
        get value() {
            return data[param.id];
        }
    };
    return vm;
}

function createEnabledParamViewModel(id, data) {
    var vm = {
        isBoolean : true,
        name : 'Enabled',
        set value(val) {
            data[id]=val;
        },
        get value() {
            return data[id];
        }
    };
    return vm;
}

function createParamViewModel(param, data) {
    var paramVm;
    if (param.type=='image') {
        paramVm = createImageParamViewModel(param, data);
    } else if (param.type=='textfield') {
       paramVm = createTextFieldParamViewModel(param, data);
    } else if (param.type=='textarea') {
       paramVm = createTextAreaParamViewModel(param, data);
    } else if (param.type=='html') {
        paramVm = createHtmlParamViewModel(param, data);
    } else if (param.type=='color') {
        paramVm = createColorParamViewModel(param, data);
   } else if (param.type=='font') {
           paramVm = createFontParamViewModel(param, data);
      } else {
        paramVm = {
            name : param.name
        };
    }
    paramVm.hasDefault=param.defaultValue!=null;
    paramVm.reset=function() {
        paramVm.value=param.defaultValue;
    };
    return paramVm;
}

function createSectionViewModel(section, data) {
    var vm = {
        name : section.name,
        params : [],
        togglable : false,
        enabled : {
            value : true,

        }
    };

    if (section.toggleParam!=null) {
        vm.togglable=true;
        vm.enabled=createEnabledParamViewModel(section.toggleParam, data);
    }

    section.params.forEach(function(param) {
        vm.params.push(createParamViewModel(param, data));
    });

    return vm;
}

function createSectionsViewModels(sections, data) {
    var sectionVms = [];
    sections.forEach(function(section) {
        sectionVms.push(createSectionViewModel(section, data));
    });
    return sectionVms;
}

function createCommonSectionsModel(commonSections, data) {
    var vm = {
        name : 'Common Sections',
        hasParams : commonSections!=null && commonSections.length>0,
        sections : null
    };
    if (vm.hasParams) {
        vm.sections=createSectionsViewModels(commonSections, data);
    }
    return vm;
}

function createPageModel(page, data) {
    var vm = {
        name : page.name,
        sections : null
    };
    if (page.sections!=null) {
        vm.sections=createSectionsViewModels(page.sections, data);
    }
    return vm;
}

function createPageModels(pages, data) {
    var pageVms = [];
    if (pages!=null) {
        pages.forEach(function(page) {
            pageVms.push(createPageModel(page, data));
        });
    }
    return pageVms;
}

function createViewModel(templateConfig, data) {
    var commonSection=createCommonSectionsModel(templateConfig.commonSections, data);
    var pages=createPageModels(templateConfig.pages, data);
     var vm = reactive({
           commonSections : commonSection,
           pages : pages,
        });
    return vm;
}

var WebsiteConfigService = {
    createViewModel : createViewModel

};

export {WebsiteConfigService as WebsiteConfigService};