<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">Images</div>
                    <div class="card-body">
                            <div v-if="!hasImage">No Image Set</div>
                            <image-preview v-if="hasImage" width="100" :src="parent.imageUrl"/>
                            <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-outline-dark"
                                @click="parent.removeImage()" v-if="hasImage">
                                <fa class="fa mr-3" icon="minus"></fa><span>Remove</span>
                            </button>
                            <button type="button" class="btn btn-outline-dark ml-3"
                                @click="editImage()">
                                <fa class="fa mr-3" icon="plus"></fa><span v-if="hasImage">Change Image</span><span v-if="!hasImage">Set Image</span>
                            </button>

                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <media-library-modal v-if="showMediaLibrary" :callback="mediaSelected" @hidden="showMediaLibrary=false"/>
    </div>
</template>

<script>

import MediaLibraryModal from '@/views/media/MediaLibraryModal'

export default {
  name: 'ProductImage',
  components: {
    MediaLibraryModal
  },
  props : ['parent'],
  data : function() {
    var d = {
        showMediaLibrary : false
         };

     return d;
  },
  computed : {
    'hasImage' : function() {
        return this.parent.imageUrl!=null;
    }
  },
  methods : {
    mediaSelected : function(imageUrl) {
        this.parent.imageUrl=imageUrl;
        this.showMediaLibrary=false

    },
    editImage : function() {
        this.showMediaLibrary=true;
    }
},
created : function() {


}
};
</script>

<style scoped>

</style>