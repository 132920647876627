<template>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{param.name}}</label>
      <div class="col-sm-9">
        <b-form-checkbox switch size="lg" v-model="param.value"></b-form-checkbox>
      </div>
    </div>
</template>

<script>


export default {
  name: 'BooleanParam',
  components: {

  },
  props : ['param'],
  data : function() {
    var d = {};
    return d;
  },
  methods : {
    }
};
</script>

<style scoped>
</style>