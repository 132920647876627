<template>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <form role="form">
                <div class="card">
                    <div class="card-header">
                        <div class="title">Registered Details</div>
                    </div>
                    <div class="card-body">
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Company Name</label> <input
                                type="text" class="form-control" id="companyName"
                                placeholder="Enter Company Name" v-model="data.model.companyName"
                                >
                            <field-error :errors="data.errors" field="companyName"></field-error>
                        </div>
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">VAT Number</label> <input
                                type="text" class="form-control" id="vatNumber"
                                placeholder="Enter VAT Number" v-model="data.model.vatNumber"
                                >
                            <field-error :errors="data.errors" field="vatNumber"></field-error>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>

import FieldError from '@/components/FieldError';

export default {
  name: 'LocationTaxDetails',
  components: {
    FieldError
  },
  props : ['data'],
  data : function() {
    var d =  {

         };

    return d;
  },

  methods : {

    },
    created : function() {

    }
};
</script>

<style scoped>

</style>