<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">Modifiers</div>
                        <div class="card-body">
                        <table class="table table-bordered extras-table">
                            <thead>
                                <tr>
                                    <th class="col-handle" width="50px">&nbsp;</th>
                                    <th class="col-name">Name</th>
                                    <th class="col-required" width="50px">Required</th>
                                    <th class="col-required" width="350px">Display on Menu</th>
                                    <th class="col-btns" width="50px">&nbsp;</th>
                                </tr>
                            </thead>
                            <draggable handle=".row-handle" group="modifieritems" tag="tbody" :value="[]" :move="onMove" @end="onDrop" id="sortable-modifier-items">
                                <tr v-if="parent.extras==0">
                                    <td colspan="5">No modifiers</td>
                                </tr>
                                <tr
                                    v-for="(productExtra,index) in parent.extras" :key="productExtra.model.groupingId" :index="index">
                                    <td :index="index" :class="{'row-handle' : parent.canEditExtra(productExtra)}" class="handle handle-el"><fa v-if="parent.canEditExtra(productExtra)" icon="grip-vertical"></fa></td>
                                    <td :index="index" class="col-name"><a href="#" @click.stop.prevent="selectModifier(productExtra)">{{productExtra.viewModel.model.name}}</a></td>
                                    <td :index="index" class="col-required"><label> <input type="checkbox"
                                            v-model="productExtra.model.required"
                                            :disabled="!parent.canEditExtra(productExtra)">
                                    </label></td>
                                    <td :index="index" class="col-required">
                                        <b-form-select v-model="productExtra.model.menuDisplayFormat" :options="menuDisplayFormatOptions" :disabled="!parent.canEditExtra(productExtra)" value-field="value" text-field="label"></b-form-select>
                                    </td>
                                    <td :index="index" class="col-btns">
                                        <button type="button" class="btn btn-card-tool"
                                            @click="productExtra.remove()"
                                            v-if="parent.canEditExtra(productExtra)">
                                            <fa class="fa" icon="trash"></fa>
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                        <div class="d-flex mt-3">
                            <div class="form-check flex-grow-1">
                                <label> <input type="checkbox"
                                    v-model="parent.dontInheritParentExtras">
                                    Dont Inherit Parent Extras
                                </label>
                            </div>
                            <div class="table-btn-group btn-group float-right z-index-top">
                                    <button type="button" class="btn btn-outline-dark"
                                        @click="addModifier()">
                                        <fa icon="plus"></fa> New Modifier
                                    </button>
                                    <button type="button" class="btn btn-outline-dark"
                                        @click="showSelectModifierModal=true">
                                        <fa icon="plus"></fa> Existing Modifier
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <select-node-modal v-if="showSelectModifierModal" types="Modifier" :callback="modifierAdded" title="Select Modifier" @hidden="showSelectModifierModal=false"/>
    </div>
</template>

<script>

import {LabelService} from '@/services/LabelService'
import SelectNodeModal from './SelectNodeModal'

export default {
  name: 'Modifiers',
  components: {
    SelectNodeModal
  },
  props : ['parent'],
  data : function() {
    var d = {
        menuDisplayFormatOptions : LabelService.menuDisplayFormatOptions,
        showSelectModifierModal : false
         };

     return d;
  },
  methods : {
    modifierAdded : function(selected) {
        this.showSelectModifierModal=false;
        var that=this;
        if (selected!=null && selected.length>0) {
            selected.forEach(function(modifier) {
                that.parent.addExtra(modifier);
            });
        }
    },
    selectModifier : function(modifier) {
        this.$router.push({ name: 'menuworkarea_modifiergroup', params: {id : modifier.viewModel.model.id, grouping: modifier.viewModel }})
    },
    addModifier : function() {
        var params;
                if (this.parent.underlying.isCategory) {
                    params = {
                        categoryId : this.parent.underlying.model.id,
                        category : this.parent.underlying
                    };
                    this.$router.push({ name: 'menuworkarea_createmodifiergroup_category', params: params});
                } else if (this.parent.underlying.isVariation) {
                    params = {
                        variationId : this.parent.underlying.model.id,
                        variation : this.parent.underlying
                    };
                    this.$router.push({ name: 'menuworkarea_createmodifiergroup_variation', params: params});
               } else if (this.parent.underlying.isProduct) {
                    params = {
                        productId : this.parent.underlying.model.id,
                        product : this.parent.underlying
                    };
                    this.$router.push({ name: 'menuworkarea_createmodifiergroup_product', params: params});
               }
        },
        onDrop : function(evt) {
            this.parent.reorderModifiers(evt.oldIndex, evt.newIndex);
        },
        onMove : function(evt) {
            var elem=evt.originalEvent.target;
            var child = elem.parentNode;
            var parent = child.parentNode;
            var index = Array.prototype.indexOf.call(parent.children, child);
            return this.parent.canReorderModifiers(evt.draggedContext.index-1, index);
        }
    },
    created : function() {

    }
};

</script>

<style scoped>

</style>