<template>
<div>
<div v-if="!data.initialising">

<location-contact-details :data="data"/>
<location-opening-times :data="data"/>
<location-tax-details :data="data"/>
<location-pos-config :data="data"/>
</div>
</div>
</template>

<script>

import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'

import LocationOpeningTimes from './LocationOpeningTimes';
import LocationContactDetails from './LocationContactDetails';
import LocationTaxDetails from './LocationTaxDetails';
import LocationPosConfig from './LocationPosConfig'

export default {
  name: 'Location',
  components: {
    LocationOpeningTimes,LocationContactDetails,LocationTaxDetails,LocationPosConfig
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/location/primary').optional().saveEndpoint('/wb/api/admin/location/primary').saveAlert('Saved', 'Location details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Location Details',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data
         });

    return d;
  },
  beforeRouteLeave (to, from, next) {
     this.data.beforeRouteLeave(to, from, next);
    },
  methods : {
        loadData : function() {
            this.data.load();
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>