<template>
<div><div  v-if="!initialising">
    <product-details :data="data"/>
    <modifiers v-if="data.created" :parent="data.extrasParent"/>
    <product-tax-rates v-if="data.created" :parent="data.taxRates"/>
    <product-image v-if="data.created" :parent="data.images"/>
</div></div>
</template>

<script>


import {PageStack} from '@/services/PageStackService'
//import FieldError from '@/components/FieldError'
//import {LabelService} from '@/services/LabelService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {EditManager} from '@/services/menu/EditManager'
import {WorkAreaService} from '@/services/menu/WorkAreaService'
import Modifiers from './Modifiers'
import ProductTaxRates from './ProductTaxRates'
import ProductDetails from './ProductDetails'
import ProductImage from './ProductImage'

export default {
  name: 'Product',
  components: {
    ProductDetails, Modifiers, ProductTaxRates, ProductImage
  },
  props : ['category', 'product'],
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Item: ',
         buttons : [],
         initialising : false,
         data : null
         });

     return d;
  },
  methods : {
    initialiseEditManager : function() {
        this.pageHandler=EditManager.builder(this).viewModel(this.data).label('Item').initialise();
    }
    },
    beforeRouteLeave : function(to, from, next) {
            this.pageHandler.beforeRouteLeave(to, from, next);
        },
    created : function() {
        var that=this;
        that.initialising=true;
        if (this.$route.params.id==null) {
            if (this.$route.params.categoryId!=null) {
                WorkAreaService.load().then(function(WorkArea) {
                    that.data=WorkArea.getCategory(that.$route.params.categoryId).newProduct();
                     that.initialiseEditManager();
                     that.initialising=false;
                 }, PromiseUtils.viewErrorHandler('Error loading item'));
            } else if (this.$route.params.variationId!=null) {
                  WorkAreaService.load().then(function(WorkArea) {
                      that.data=WorkArea.getVariation(that.$route.params.variationId).newProduct();
                       that.initialiseEditManager();
                       that.initialising=false;
                   }, PromiseUtils.viewErrorHandler('Error loading item'));
              } else if (this.$route.params.groupingId!=null) {
                 WorkAreaService.load().then(function(WorkArea) {
                     that.data=WorkArea.getGrouping(that.$route.params.groupingId).newProduct();
                      that.initialiseEditManager();
                      that.initialising=false;
                  }, PromiseUtils.viewErrorHandler('Error loading item'));
             }
        } else {
            if (this.product==null) {
                WorkAreaService.load().then(function(WorkArea) {
                    that.data=WorkArea.getProduct(that.$route.params.id);
                     that.initialiseEditManager();
                     that.initialising=false;
                 }, PromiseUtils.viewErrorHandler('Error loading item'));
            } else {
                this.data=this.product;
                that.initialiseEditManager();
                that.initialising=false;
            }
        }

    }
};
</script>

<style scoped>

</style>