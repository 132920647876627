<template>
    <div>
            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label>Date Range</label>
                                    <date-range-picker ref="datepicker" v-model="filter"></date-range-picker>
                                </div>
                            </div>
                          </div>
                          <div class="card-footer">
                              <button type="submit" class="btn btn-primary float-right" :disabled="!filter.dateRange" @click.stop.prevent="search()">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="card" v-if="result">
                    <b-table :fields="fields" :items="result" empty-text="No summaries" show-empty>
                        <template v-slot:cell(actions)="data">
                          <a href="#" @click.prevent.stop="view(data.item)"><fa icon="info-circle" class="fa"></fa></a>
                        </template>
                    </b-table>
                </div>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import {LabelService} from '@/services/LabelService'
import moment from 'moment';

function createViewModel(total, data, payments, sources, taxRates) {
    var vm = {
        date : null,
        totalOrders : data.totalOrders,
        totalSales : data.totalSales,
        totalTax : data.totalTax,
        totalNetSales : data.totalNetSales
    };
    if (!total) {
        vm.date=data.date;
    }
    payments.forEach(function(type) {
            var count=0;
            var sales=0.0;
            for (var i=0;i<data.paymentSummaries.length;i++) {
                if (data.paymentSummaries[i].type==type) {
                    count=data.paymentSummaries[i].orderCount;
                    sales=data.paymentSummaries[i].totalSales;
                    break;
                }
            }
            vm['payment_count_'+type]=count;
            vm['payment_sales_'+type]=sales;
    });
    sources.forEach(function(type) {
            var count=0;
            var sales=0.0;
            for (var i=0;i<data.sourceSummaries.length;i++) {
                if (data.sourceSummaries[i].type==type) {
                    count=data.sourceSummaries[i].orderCount;
                    sales=data.sourceSummaries[i].totalSales;
                    break;
                }
            }
            vm['source_count_'+type]=count;
            vm['source_sales_'+type]=sales;

    });
    var index=0;
    taxRates.forEach(function(rate) {
            var tax=0.0;
            var net=0.0;
            for (var i=0;i<data.taxSummaries.length;i++) {
                if (data.taxSummaries[i].rate==rate) {
                    tax=data.taxSummaries[i].tax;
                    net=data.taxSummaries[i].net;
                }
            }
            vm['tax_tax_'+index]=tax;
            vm['tax_net_'+index]=net;
            index++;
    });

    return vm;
}

function createSummaryTableData(fields, summary, $filters) {
    var payments = [];
    var taxRates = [];
    var sources = [];

    summary.paymentSummaries.forEach(function(summary) {
        payments.push(summary.type);
    });
    summary.taxSummaries.forEach(function(summary) {
        taxRates.push(summary.rate);
    });
    summary.sourceSummaries.forEach(function(summary) {
        sources.push(summary.type);
    });
    fields.push({ key: 'date', label : 'Date', formatter : function(value) {return value==null?'TOTAL': moment(new Date(value)).format("ddd DD MMM YYYY");}, sortable:true});
    fields.push({ key: 'totalOrders', label : 'Total Orders', sortable:true});
    payments.forEach(function(payment) {
            fields.push({ key: 'payment_count_'+payment, label : LabelService.getPaymentMethod(payment)+' Total Orders', sortable:true});
    });
    sources.forEach(function(source) {
            fields.push({ key: 'source_count_'+source, label : LabelService.getSource(source)+' Total Orders', sortable:true});
    });
    fields.push({ key: 'totalSales', label : 'Total Sales', formatter : function(value) {return $filters.toCurrency(value);}, sortable:true});
    fields.push({ key: 'totalTax', label : 'Total Tax', formatter : function(value) {return $filters.toCurrency(value);}, sortable:true});
    fields.push({ key: 'totalNetSales', label : 'Total Net', formatter : function(value) {return $filters.toCurrency(value);}, sortable:true});
    var count=0;
    taxRates.forEach(function(rate) {
            fields.push({ key: 'tax_tax_'+count, label : 'Tax @'+rate, formatter : function(value) {return $filters.toCurrency(value);}, sortable:true});
            fields.push({ key: 'tax_net_'+count, label : 'Net Sales @'+rate, formatter : function(value) {return $filters.toCurrency(value);}, sortable:true});
            count++;
    });

    var result=[];
    summary.dailySummaries.forEach(function(data) {
        result.push(createViewModel(false, data, payments, sources, taxRates));
    });
    if (summary.dailySummaries.length>1) {
        result.push(createViewModel(true, summary, payments, sources, taxRates));
    }
    return result;
}

export default {
  name: 'DailySummary',
  components: {

  },
  data : function() {
    var that=this;
    var fields = [

            { key: 'actions', label : '', thStyle:{width:'50px'}}
          ];
    var d = PageStack.setPage(that.$route, {
         header : 'Daily Summary',
         buttons : [],
         filter : {
            dateRange : null,
            startDate : null,
            endDate : null
         },
         fields : fields,
         result : null,
         searching : false
     });
     return d;
  },
  methods : {
    search : function() {
        var that=this;
        that.searching=true;
        var url = '/wb/api/admin/orders/summaries?dateRange='+this.filter.dateRange;
        if (this.filter.startDate!=null) {
            url+='&from='+moment(this.filter.startDate).format('YYYYMMDD');
        }
        if (this.filter.endDate!=null) {
            url+='&to='+moment(this.filter.endDate).format('YYYYMMDD');
        }
        RestHelper.get(url).then(function(response) {
            that.fields=[];
            that.result=createSummaryTableData(that.fields, response.data, that.$filters);
            that.searching=false;
        },PromiseUtils.viewErrorHandler('Error searching orders', function() {
           that.searching=false;
       }));
    }
    },
    mounted : function() {
        this.$refs.datepicker.setRange('Today');
        var that=this;
        setTimeout(function() {
            that.search();
        },1);

    }
};
</script>

<style scoped>

</style>