<template>
  <b-modal id="view-order-modal" modal-class="view-order-modal" centered hide-footer no-close-on-backdrop title="Order Details" @hide="handleHide()">
        <div class="d-flex justify-content-end mb-1">
            <button class="btn btn-danger" @click.stop.prevent="deleteOrder">DELETE</button>
        </div>
        <div class="invoice p-3 mb-3">
            <div class="row" v-if="!data.initialising">

                <div class="col-12">
                    <div class="header d-flex align-items-center">
                        <div class="d-flex-column px-4 type-container">
                            <div class="type">{{data.model.type}}</div>
                            <div class="source">{{data.model.source}}</div>
                        </div>
                        <div class="date mx-5 flex-grow-1">@ {{$filters.formatDate(data.model.audit.placedTime, 'ddd DD MMM YYYY HH:mm')}}</div>
                        <div class="status">{{data.model.orderStatus}}</div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <view-order-basket :model="data.model"/>
                </div>
                <div class="col-12 col-md-6">
                    <view-order-details :model="data.model"/>
                </div>
           </div>
        </div>
    </b-modal>
</template>

<script>

import {EditManager} from "@/utils/EditManager";
import ViewOrderBasket from './ViewOrderBasket';
import ViewOrderDetails from './ViewOrderDetails';

import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";

function doDelete(that, id) {
    RestHelper.delete('/wb/api/admin/order/'+id).then(function(response) {
                            var result = response.data;
                            if (result.success) {
                                alert('deleted');
                                that.handleHide();
                            } else {
                                alert('unsuccessful');
                            }
                        },PromiseUtils.viewErrorHandler('Error', function() {
                            alert('error');
                        }));

}

var component = {
  name: "ViewOrderModal",
  props: ['callback', 'id'],
  components : {
  ViewOrderBasket,
      ViewOrderDetails
      },
  data : function() {
      var id = this.id;
      var d = {
        data : EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/order/'+id).deleteEndpoint('/wb/api/admin/order/'+id, this.deleted).build()
        };
       return d;
    },
   methods : {
   deleteOrder : function() {
    var that=this;
    this.$bvModal.msgBoxConfirm('Are you sure you want to delete this order?  Deleting will remove all trace of this order from the system', {
                          title: 'Delete Confirmation',
                          okTitle: 'Delete',
                          cancelTitle: 'Dont delete',
                          footerClass: 'p-2',
                          hideHeaderClose: false,
                          centered: true
                        })
                          .then(value => {
                            if (value) {
                               doDelete(that, that.id);
                            }
                          })
                          .catch(function() {
                          });
   },
      handleHide : function() {
          this.$emit('hidden');
      }
    },
    computed : {
        typeIcon : function() {
                    if (this.data.model.type=='delivery') {
                        return 'car';
                    } else {
                        return null;
                    }
                }
    },
    mounted : function() {
        this.$bvModal.show('view-order-modal');
      },
      created : function() {
        this.data.load();
      }
};
export default component;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.view-order-modal {
    height: 100%;
}

.view-order-modal .modal-dialog {
    max-width: 1000px !important;
}


.view-order-modal .modal-content {
     max-width: 1000px !important;
     width: 90vw !important;
     height: 90vh !important;
 }

 .view-order-modal .modal-body {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
 }

 .invoice {
    overflow-y: auto;
 }


</style>