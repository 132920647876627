<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">Tax Rate</div>
                        <div class="card-body">
                        <select :disabled="!parent.overrideTaxRates" v-model="parent.selectedTaxRate" class="form-control">
                          <option v-for="option in selectableTaxRates" v-bind:value="option" :key="option.model.id">
                            {{ option.model.name }}
                          </option>
                        </select>
                        <div class="form-check mt-3">
                            <label> <input type="checkbox"
                                v-model="parent.overrideTaxRates">
                                Override Tax Rates
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>


export default {
  name: 'ProductTaxRates',
  components: {
    //FieldError
  },
  props : ['parent'],
  data : function() {
    var d = {
        selectableTaxRates : this.parent.getSelectableTaxRates()
         };

     return d;
  },
  methods : {
    },
    created : function() {

    }
};
</script>

<style scoped>

</style>