<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12">
                <form role="form" name="offerForm">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">{{name}} Details</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group" v-if="model.type=='promoCode'">
                                <label for="exampleInputEmail1">Promo Code</label> <input type="text"
                                    class="form-control" id="title" placeholder="Enter code"
                                    v-model="model.promoCode"
                                    >
                                <field-error :errors="errors" :field="getField('promoCode')"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Title</label> <input type="text"
                                    class="form-control" id="title" placeholder="Enter title"
                                    v-model="model.title"
                                    >
                                <field-error :errors="errors" ::field="getField('title')"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Description</label>
                                <textarea class="form-control" rows="3"
                                    placeholder="Enter description"
                                    v-model="model.description"
                                    ></textarea>
                                <field-error :errors="errors" :field="getField('description')"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Terms and Conditions</label>
                                <textarea class="form-control" rows="3"
                                    placeholder="Enter Terms and Conditions"
                                    v-model="model.termsAndConditions"
                                    ></textarea>
                                <field-error :errors="errors" :field="getField('termsAndConditions')"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Start Date</label>
                                <date-picker id="startDate" v-model="model.startDate" placeholder="Select Start Date" class="mb-2"/>
                                <field-error :errors="errors" :field="getField('startDate')"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">End Date</label>
                                <date-picker id="startDate" v-model="model.endDate" placeholder="Select End Date" class="mb-2"/>
                                <field-error :errors="errors" :field="getField('endDate')"></field-error>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <div class="title">{{name}} Conditions</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Dates and Times Available</label>
                                <b-form-group>
                                      <b-form-radio-group
                                        id="timeRestricted"
                                        v-model="model.timeRestricted"
                                        name="timeRestricted"
                                      >
                                        <b-form-radio :value="false">All Times</b-form-radio>
                                        <b-form-radio :value="true">Limited Times</b-form-radio>
                                      </b-form-radio-group>
                                    </b-form-group>
                                    <field-error :errors="errors" :field="getField('timeRestricted')"></field-error>
                                <div v-if="model.timeRestricted && model.times">
                                    <div v-for="(time, index) in model.times" v-bind:key="index">
                                        <div class="d-flex align-items-center my-2">
                                            <span class="mr-2">Days: </span>
                                            <day-picker :model="time"/>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <span class="mr-2">Time: </span>
                                            <b-form-select class="col-2 col-md-1" v-model="time.fromHour" :options="hoursOptions" value-:field="getField('value" text-:field="getField('label"></b-form-select>
                                            <span class="px-1"> : </span>
                                            <b-form-select class="col-2 col-md-1" v-model="time.fromMin" :options="minsOptions" value-:field="getField('value" text-:field="getField('label"></b-form-select>
                                            <span class="px-1"> to </span>
                                            <b-form-select class="col-2 col-md-1" v-model="time.toHour" :options="hoursOptions" value-:field="getField('value" text-:field="getField('label"></b-form-select>
                                            <span> : </span>
                                            <b-form-select class="col-2 col-md-1" v-model="time.toMin" :options="minsOptions" value-:field="getField('value" text-:field="getField('label"></b-form-select>
                                            <div class="ml-3"><a href="#" @click.stop.prevent="deleteTime(time)"><i class="fa fa-minus"></i></a></div>
                                        </div>
                                    </div>
                                    <div class="mt-3"><a href="#" @click.stop.prevent="addTime()"><fa class="fa mr-2" icon="plus"></fa>add time</a></div>
                                </div>
                                <field-error :errors="errors" :field="getField('times')"></field-error>
                            </div>


                            <div class="form-group">
                                <label for="exampleInputEmail1">Order Source</label>
                                <b-form-radio-group
                                    id="sourceRestricted"
                                    v-model="model.sourceRestricted"
                                    name="sourceRestricted"
                                  >
                                    <b-form-radio :value="false">All Placed Orders</b-form-radio>
                                    <b-form-radio :value="true">Orders From Specific Sources</b-form-radio>
                                  </b-form-radio-group>
                                  <field-error :errors="errors" :field="getField('sourceRestricted')"></field-error>
                            </div>

                            <div class="form-group" v-if="model.sourceRestricted">
                                <v-select placeholder="Select Sources" multiple label="label" :options="sourceOptions" :reduce="option => option.value" v-model="model.sources"></v-select>
                                <field-error :errors="errors" :field="getField('sources')"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Order Values</label>
                                <b-form-radio-group
                                    id="valueRestricted"
                                    v-model="model.valueRestricted"
                                    name="valueRestricted"
                                  >
                                    <b-form-radio :value="false">All Values</b-form-radio>
                                    <b-form-radio :value="true">Value Range</b-form-radio>
                                  </b-form-radio-group>
                                  <field-error :errors="errors" :field="getField('valueRestricted')"></field-error>
                            </div>
                            <div class="form-group" v-if="model.valueRestricted">
                                <div class="d-flex">
                                    <money-input class="form-control col-4 col-md-2" placeholder="Min Value" v-model="model.minValue"/>
                                    <span class="m-2"> to </span>
                                    <money-input class="form-control col-4 col-md-2" placeholder="Max Value" v-model="model.maxValue"/>
                                </div>
                                <field-error :errors="errors" :field="getField('minValue')"></field-error>
                                <field-error :errors="errors" :field="getField('maxValue')"></field-error>
                            </div>
                        </div>
                    </div>

                    <div class="card" v-if="model.type!='reward'">
                        <div class="card-header">
                            <div class="title">Discount</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <div>
                                    <label>Discount Type</label>
                                    <b-form-select v-model="model.discountType" :options="discountTypeOptions" value-field="value" text-field="label"></b-form-select>
                                </div>
                                <field-error :errors="errors" :field="getField('discountType')"></field-error>
                            </div>
                            <div class="form-group" v-if="model.discountType=='fixed'">
                                <div>
                                    <label>Discount Fixed Value</label>
                                    <money-input class="form-control" placeholder="Enter Fixed Value" v-model="model.fixedValue"/>
                                </div>
                                <field-error :errors="errors" :field="getField('fixedValue')"></field-error>
                            </div>
                            <div class="form-group" v-if="model.discountType=='percentage'">
                                <div>
                                    <label>Discount Percentage</label>
                                    <money-input class="form-control" placeholder="Enter Percentage Value" v-model="model.percentageValue"/>
                                </div>
                                <field-error :errors="errors" :field="getField('percentageValue')"></field-error>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
//import {PromiseUtils} from "@/utils/PromiseUtils";
import FieldError from '@/components/FieldError';
import DayPicker from '@/components/DayPicker';

//import moment from 'moment';
function createSourceOptions() {
    var sourceOptions = [
        {value : "web",label : "Online"},
        {value : "inStore",label : "Walk-In"},
        {value : "phone",label : "Phone"},
        {value : "justeat",label : "Just Eat"},
        {value : "uber",label : "uber"},
        {value : "deliveroo",label : "deliveroo"}
    ];
    return sourceOptions;
}

function createDiscountOptions() {
    var discountOptions = [
        {value : "percentage",label : "Percentage"},
        {value : "fixed",label : "Fixed Value"}
    ];
    return discountOptions;
}

function createMinsOptions() {
    var minsOptions = [];
    for (var i=0;i<60;i++) {
        minsOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return minsOptions;
}

function createHoursOptions() {
    var hoursOptions = [];
    for (var i=0;i<24;i++) {
        hoursOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return hoursOptions;
}


export default {
  name: 'DiscountEdit',
  components: {
    FieldError,DayPicker
  },
  props : ['model', 'errors', 'name', 'fieldPrefix'],
  data : function() {
    var d = {
         discountTypeOptions : createDiscountOptions(),
         sourceOptions : createSourceOptions(),
         hoursOptions : createHoursOptions(),
         minsOptions : createMinsOptions(),
         vouchers : [],
         generatesVoucher : false,
         startDate : null,
         endDate : null
     };
     return d;
  },
  watch : {
    'model.timeRestricted' : function(val) {
        if (val) {
            if (this.model.times==null) {
                this.model.times=[];
            }
            if (this.model.times.length==0) {
                this.addTime();
            }
        } else {
            this.model.times=[];
        }
    }
  },
  methods : {
    getField : function(field) {
        return this.fieldPrefix+'.'+field;
    },
    deleteTime : function(time) {
        var index = this.model.times.indexOf(time);
        if (index>=0) {
            this.model.times.splice(index, 1);
        }
    },
    addTime : function() {
        if (this.model.times==null) {
            this.model.times=[];
        }
        this.model.times.push({
            monday : true,
            tuesday : true,
            wednesday : true,
            thursday : true,
            friday : true,
            saturday : true,
            sunday : true,
            fromHour : 12,
            fromMin : 0,
            toHour : 22,
            toMin : 0
        });
    }

  }
};
</script>

<style scoped>

</style>