import {reactive} from '@vue/composition-api'
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {VueUtils} from "@/utils/VueUtils";
import {ErrorsService} from '@/services/ErrorsService';
import {AlertService} from '@/services/AlertService';

function builder($bvModal) {
    var saveAlertTitle='Saved';
    var saveAlertMessage='Changes have been saved.';
    var deleteAlertTitle='Delete';
    var deleteAlertMessage='Changes have been saved.';
    var errorAlertTitle='Error';
    var errorAlertMessage='Unable to save. Please fix errors and resubmit.';
    var originalStr=null;
    var saveButton=null;
    var defaultValue=null;
    var resetButton=null;
    var deleteButton=null;
    var dirty=false;
    var optional=false;
    var data = {
        initialising : false,
        saving : false,
        model : {

        },
        defaultModel : function(model) {
            originalStr=JSON.stringify(model);
            data.model=JSON.parse(originalStr);
            return this;
        },
        errors : ErrorsService.create(),
        reset : function() {
            if (originalStr!=null) {
                this.model=JSON.parse(originalStr);
            }
        },
        createSaveButton : function() {
            saveButton=reactive({
                label : 'Save',
                class : 'btn-primary',
                icon : 'save',
                onClick : function() {
                    data.save();
                },
                hidden : false,
                disabled : true
            });
            return saveButton;
        },
        createResetButton : function() {
            resetButton=reactive({
                label : 'RESET',
                icon : 'undo',
                onClick : function() {
                    data.reset();
                },
                hidden : false,
                disabled : false
            });
            return resetButton;
        },
         createDeleteButton : function() {
             deleteButton=reactive({
                 label : 'DELETE',
                 class : 'btn-primary',
                 icon : 'trash',
                 onClick : function() {
                     data.delete();
                 },
                 hidden : false,
                 disabled : false
             });
             return deleteButton;
         },
        beforeRouteLeave : function(to, from, next) {
            if (dirty) {
            $bvModal.msgBoxConfirm('Note: Any unsaved changes will be lost. Are you sure you want to leave this page?', {
                      title: 'Confirm Navigation',
                      okTitle: 'Leave this Page',
                      cancelTitle: 'Stay on this Page',
                      footerClass: 'p-2',
                      hideHeaderClose: false,
                      centered: true
                    })
                      .then(value => {
                        if (value) {
                            next();
                        }
                      })
                      .catch(function() {
                      });

         } else {
            next();
         }

        }
    };

    function resetButtons() {
        var newStr=JSON.stringify(data.model);
        dirty=newStr!=originalStr;
        if (resetButton!=null) {
            resetButton.disabled=!dirty;
            saveButton.disabled=!dirty;
        } else if (saveButton!=null) {
            saveButton.disabled=!dirty;
        }
    }



    return {
        build : function() {
            var response = reactive(data);
            VueUtils.watchObject(data, function() {
                resetButtons();
            });
            return response;
        },
        optional : function(pDefaultValue) {
            optional=true;
            defaultValue=pDefaultValue;
            if (defaultValue==null) {
                defaultValue={};
            }
            return this;
        },
        loadEndpoint : function(url, errorMsg) {
            if (errorMsg==null) {
                errorMsg='Unable to load data';
            }
            data.load=function() {
                data.initialising=true;
                var promise = new Promise(function(resolve, reject) {
                    RestHelper.get(url).then(function(response) {
                        var result = response.data;
                        if (optional) {
                            if(result.present) {
                                result=result.value;
                            } else {
                                result=defaultValue;
                            }
                        }
                        originalStr=JSON.stringify(result);
                        data.model=JSON.parse(originalStr);
                        data.initialising=false;
                        resolve();
                    },PromiseUtils.viewErrorHandler(errorMsg, function() {
                        data.initialising=false;
                        reject();
                    }));
                });
                return promise;
            }
            return this;
        },
        saveAlert : function(title, msg) {
            saveAlertTitle=title;
            saveAlertMessage=msg;
            return this;
        },
         saveEndpoint : function(url, errorMsg) {
            if (errorMsg==null) {
                errorMsg='Unable to save data';
            }
            data.save=function() {
             var promise = new Promise(function(resolve, reject) {
                 data.errors.clearServerErrors();
                 data.saving=true;
                 RestHelper.put(url, data.model).then(function(response) {
                         if (response.data.success) {
                             originalStr=JSON.stringify(response.data.result);
                             data.model=JSON.parse(originalStr);
                             AlertService.success(saveAlertTitle, saveAlertMessage);
                         } else {
                             data.errors.setServerErrors(response.data.errors);
                             AlertService.danger(errorAlertTitle, errorAlertMessage);
                         }
                         data.saving=false;
                         resolve();
                     }, PromiseUtils.viewErrorHandler(errorMsg, function() {
                      data.saving=false;
                      reject();
                  }));
              });
              return promise;
         };
         return this;
         },
           deleteEndpoint : function(url, callback, errorMsg) {
              if (errorMsg==null) {
                  errorMsg='Unable to delete data';
              }
              data.delete=function() {
               var promise = new Promise(function(resolve, reject) {
                   data.errors.clearServerErrors();
                   data.saving=true;
                   RestHelper.delete(url).then(function(response) {
                           if (response.data.success) {
                               originalStr=JSON.stringify(response.data.result);
                               data.model=JSON.parse(originalStr);
                               AlertService.success(deleteAlertTitle, deleteAlertMessage);
                           } else {
                               data.errors.setServerErrors(response.data.errors);
                           }
                           data.saving=false;
                           resolve();
                           if (callback!=null) {
                            callback();
                           }
                       }, PromiseUtils.viewErrorHandler(errorMsg, function() {
                        data.saving=false;
                        reject();
                    }));
                });
                return promise;
           };
           return this;
           }
    };
}


var EditManager = {
    builder : builder

}

export {EditManager as EditManager};