<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-12">
                <dashboard-reports :report-list="reports" v-if="reports"/>
            </div>
            <div class="col-md-4 col-12">
                <setup/>
                <service-status/>
             </div>
        </div>
    </div>
</template>

<script>
import {PageStack} from '@/services/PageStackService'
import Setup from './Setup'
import ServiceStatus from '@/views/onlineordering/ServiceStatus'
import DashboardReports from '../charts/DashboardReports'
import {RestHelper} from "@/utils/RestHelper";


export default {
  name: 'Home',
  components: {
    Setup, ServiceStatus, DashboardReports
  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(that.$route, {
         header : 'Home',
         reports : null
     });
     return d;
  },
  methods : {
     loadData : function() {
        var that=this;
        RestHelper.get('/wb/api/admin/home/reports').then(function(response) {
            that.reports=response.data;
        });
     }
  },

    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>