<template>
    <div class="container-fluid">

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Select Report:</label>

                                <div class="input-group">
                                    <div class="input-group-prepend" @click="selectReport()">
                                        <span class="input-group-text"><fa
                                            class="fa" icon="ellipsis-h"></fa></span>
                                    </div>
                                    <input type="text" class="form-control" readonly="true"
                                        placeholder="Select Report" @click="selectReport()"
                                        v-model="reportSpecName">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid" v-if="reportSpec">
            <div
                class="row">
                <div class="col-12">
                    <div class="card card-default">
                        <div class="card-header">
                            <h3 class="card-title">Report Filter</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-widget="collapse">
                                    <fa class="fa" icon="minus"></fa>
                                </button>
                            </div>
                        </div>
                        <div class="card-body" style="display: block;">
                            <div class="container-fluid">
                                <form>

                                    <div class="form-group row" v-if="hasFilter('dateRange')">
                                        <label class="col-md-2 col-12 col-form-label">Date Range</label>
                                        <div class="col-md-10 col-12">
                                          <date-range-picker v-model="model.dateFilter"></date-range-picker>
                                        </div>
                                    </div>
                                    <div class="form-group row" v-if="hasFilter('days')">
                                        <label class="col-md-2 col-12 col-form-label">Days</label>
                                        <div class="col-md-10 col-12">
                                          <day-picker :model="model.dayFilter" format="short"/>
                                        </div>
                                    </div>
                                    <div class="form-group row" v-if="hasFilter('hours')">
                                        <label class="col-md-2 col-12 col-form-label">Hours</label>
                                        <div class="col-md-10 col-12">
                                          <hour-range-picker :model="model.timeFilter"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-md-2 col-12">
                                            <button class="generate btn btn-primary" @click="generate()">Generate</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row justify-content-center" v-if="report">
                <div class="col-12">
                    <dashboard-report :report="report"></dashboard-report>
                </div>
            </div>
        </div>
        <select-report-modal v-if="showSelectReport" :callback="reportSelected" @hidden="showSelectReport=false"/>
    </div>

</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import {AlertService} from '@/services/AlertService'
//import moment from 'moment';
import SelectReportModal from './SelectReportModal'
//import DayPicker from '@/components/DayPicker';
import { reactive } from '@vue/composition-api'
import DashboardReport from '../charts/DashboardReport'

function createReportViewModel(result, reportSpec) {
        var chartType = reportSpec.type;
        if (chartType=='chart') {
            chartType=result.config.charts[0].types[0];
        }
        var displayConfig={
            width : 12,
            chartType : chartType
        };
        var cssClass='col-12';
        var displayData = {
                config : displayConfig,
                menuSections : []
        };


        var reportViewModel = {
            getReportData:function() {
                var promise = new Promise(function(resolve) {
                    resolve(result);
                });
                return promise;
            },
            cssClass : cssClass,
            reportType : reportSpec.type,
            refresh:function() {
                return this.getReportData();
            },
            getDisplayData:function() {
                var promise = new Promise(function(resolve) {
                    resolve(displayData);
                });
                return promise;
            }
        };
        return reportViewModel;
    }

function createMinsOptions() {
    var minsOptions = [];
    for (var i=0;i<60;i++) {
        minsOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return minsOptions;
}

function createHoursOptions() {
    var hoursOptions = [];
    for (var i=0;i<24;i++) {
        hoursOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return hoursOptions;
}

export default {
  name: 'AddReport',
  components: {
    SelectReportModal,
    DashboardReport
  },
  data : function() {
    var isInsights = this.$route.path.indexOf('/reports')>=0;
    var header;
    var method;
    if (isInsights) {
        header='Insights';
        method='setPage';
    } else {
        header='Add Report';
        method='addPage';
    }
    var d = PageStack[method](this.$route, {
         header : header,
         buttons : [{
                                label : 'Save',
                                icon : 'save',
                                onClick : this.save,
                                hidden : isInsights
                            }],
         showSelectReport : false,
         reportSpec : null,
         minsOptions : createMinsOptions(),
         hoursOptions : createHoursOptions(),
         report : null,
         dashboardId : this.$route.params.id,
         model : {
            timeFilter : null,
            dateFilter : null,
            dayFilter : null
         }
     });
     return d;
  },
  computed : {
    'reportSpecName' : function() {
        if (this.reportSpec==null) {
            return '';
        } else {
            return this.reportSpec.name;
        }
    }
  },
  methods : {
    selectReport : function() {
        this.showSelectReport=true;
    },
    initialiseModel : function() {
        if (this.hasFilter('hours')) {
            this.model.timeFilter=reactive({
                allHours : true,
                fromHour : null,
                fromMin : null,
                toHour : null,
                toMin : null
            });
        } else {
            this.model.timeFilter=null;
        }
        if (this.hasFilter('dateRange')) {
            this.model.dateFilter=reactive({
                dateRange : null,
                startDate : null,
                endDate : null
            });
        } else {
            this.model.dateFilter=null;
        }

        if (this.hasFilter('days')) {
            this.model.dayFilter=reactive({
                allDays : true,
                mon : true,
                tue : true,
                wed : true,
                thu : true,
                fri : true,
                sat : true,
                sun : true
                });
        } else {
            this.model.dayFilter=null;
        }
    },
    reportSelected : function(report) {
        if (report!=null) {
            this.reportSpec=report;
            this.initialiseModel();
        }
        this.showSelectReport=false;
    },
    hasFilter : function(type) {
        return this.reportSpec!=null && this.reportSpec.filters.indexOf(type)>=0;
    },
    save : function() {
                var that=this;
                var report = {
                        reportId : this.reportSpec.id,
                        name : this.reportSpec.name,
                        queryData : {
                            reportId : this.reportSpec.id,
                            datasetFilters : [
                                this.model
                            ]
                        },
                        displayConfig : {

                        }
                    };
                    
                RestHelper.put('/wb/api/admin/dashboard/'+this.dashboardId+'/report', report).then(function(response) {
                    if (response.data.success) {
                        AlertService.success('Report added to dashboard');
                        PageStack.chopPage(that.$router);
                    }
                });

    },
    generate : function() {
        /*
        $scope.displayConfig={
                width : 12
        };
        */
        var reqModel = JSON.parse(JSON.stringify(this.model));
        if (reqModel.dateFilter!=null && (reqModel.dateFilter.dateRange==null && reqModel.dateFilter.startDate==null && reqModel.dateFilter.endDate==null)) {
                reqModel.dateFilter=null;
        }

        var req = {
                reportId : this.reportSpec.id,
                reportType : this.reportSpec.type,
                datasetFilters : [
                    reqModel
                ]
        };


        this.reportResult=null;
        var that=this;
        that.processing=true;
        that.report=null;
        RestHelper.post('/wb/api/admin/reports/execute', req).then(function(response) {
            if (response.data.success) {
                that.report=createReportViewModel(response.data.result, that.reportSpec);
            }
        },PromiseUtils.viewErrorHandler('Error executing report', function() {
                      that.processing=false;
                  }));
    }
    },
    created : function() {

    }
};
</script>

<style scoped>

</style>