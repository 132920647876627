<template>
    <div class="container-fluid menueditor-container">
        <div class="row" v-if="!initialising">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header with-border">
                        Tax Rates
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <tr>
                                <th>Name</th>
                                <th>Rate</th>
                                <th width="100px">Default</th>
                            </tr>
                            <tr v-for="(taxRate,index) in taxRates" :key="index">
                                <td><a href="#" @click.prevent.stop="selectTaxRate(taxRate)">{{taxRate.model.name}}</a></td>
                                <td>{{taxRate.model.rate}}%</td>
                                <td><span v-if="taxRate.model.defaultRate">Y</span></td>
                            </tr>
                            <tr v-if="taxRates.length==0">
                                <td colspan="3">No tax rates configured</td>
                            </tr>
                        </table>
                        <div class="table-btn-group btn-group float-right mt-3">
                            <button type="button" class="btn btn-outline-dark"
                                @click="addTaxRate()">
                                <fa class="fa" icon="plus"></fa> Add Tax Rate
                            </button>
                        </div>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>


/*
import FieldError from '@/components/FieldError'
import {LabelService} from '@/services/LabelService'

import {WorkAreaService} from '@/services/menu/WorkAreaService'
*/
//import {LabelService} from '@/services/LabelService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {PageStack} from '@/services/PageStackService'
import {WorkAreaService} from '@/services/menu/WorkAreaService'

export default {
  name: 'TaxRates',
  components: {

  },
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Tax Rates',
         buttons : [],
         initialising : false,
         taxRates : []
         });

     return d;
  },
  methods : {
        selectTaxRate : function(taxRate) {
            this.$router.push({ name: 'menuworkarea_taxrate', params: {id : taxRate.model.id, taxRate: taxRate }})
        },
        addTaxRate : function() {
            this.$router.push({ name: 'add_menuworkarea_taxrate', params: {}})
        }
    },
    created : function() {
        var that=this;
        that.initialising=true;
        WorkAreaService.load().then(function(WorkArea) {
            that.initialising=false;
            that.taxRates=WorkArea.taxRates;
        }, PromiseUtils.viewErrorHandler('Error loading menu'));
    }
};
</script>

<style scoped>

</style>