<template>
        <div>
            <div  :class="{ 'col-sm-6': singleRow }" class="col-12" v-if="data.pickupEnabled">
                <div class="info-box flex-column">
                    <div class="d-flex w-100">
                  <span class="info-box-icon bg-success" :class="{ 'bg-success': !data.pickupPaused, 'bg-danger': data.pickupPaused }"><fa icon="shopping-basket"></fa></span>

                  <div class="info-box-content flex-grow-1">
                        <span class="info-box-number">Pickup</span>
                        <span class="info-box-text">{{pickupStatus}}</span>

                  </div>
                  <button type="button" class="btn btn-outline-secondary btn-lg px-4" @click="togglePickupPause()"><fa :icon="pickupIcon"></fa></button>
                  </div>
                  <div class="w-100 mt-3 mb-1" style="background:lightgrey;height:1px;"/>
                    <div class="d-flex align-items-center">
                      <div class="text-nowrap mr-3">Default Pickup Time</div>
                      <b-form-select @change="updatePickupPrepTime()" v-model="data.pickupPrepTime" :options="timeOptions" class=" mt-2" value-field="value" text-field="label"></b-form-select>
                     </div>
                </div>
                <!-- /.info-box -->
              </div>
              <div :class="{ 'col-sm-6': singleRow }" class="col-12" v-if="data.deliveryEnabled">
                  <div class="info-box flex-column">
                      <div class="d-flex w-100">
                    <span class="info-box-icon bg-success" :class="{ 'bg-success': !data.deliveryPaused, 'bg-danger': data.deliveryPaused }"><fa icon="car"></fa></span>

                    <div class="info-box-content flex-grow-1">
                          <span class="info-box-number">Delivery</span>
                          <span class="info-box-text">{{deliveryStatus}}</span>

                    </div>
                    <button type="button" class="btn btn-outline-secondary btn-lg px-4" @click="toggleDeliveryPause()"><fa :icon="deliveryIcon"></fa></button>
                    </div>
                    <div class="w-100 mt-3 mb-1" style="background:lightgrey;height:1px;"/>
                      <div class="d-flex align-items-center">
                        <div class="text-nowrap mr-3">Default Delivery Time</div>
                        <b-form-select @change="updateDeliveryPrepTime()" v-model="data.deliveryPrepTime" :options="timeOptions" class=" mt-2" value-field="value" text-field="label"></b-form-select>
                       </div>
                  </div>
                  <!-- /.info-box -->
                </div>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {AlertService} from '@/services/AlertService'

function createTimeOptions() {
    var values = [7, 10, 15, 20, 25, 30, 45, 60, 75];
    var options = [];
	values.forEach(function(value) {
        options.push({
            value : value,
            label : value+' Mins'
        });
	});
	return options;
}

export default {
  name: 'ServiceStatus',
  components: {

  },
  props : ['singleRow'],
  data : function() {
    var d = {
        initialising : true,
        data : {
            pickupEnabled : false
        },
        timeOptions : createTimeOptions()

    };
     return d;
  },
  computed : {
    pickupIcon : function() {
        if (this.data.pickupPaused) {
            return 'play';
        } else {
            return 'pause';
        }
    },
    deliveryIcon : function() {
        if (this.data.deliveryPaused) {
            return 'play';
        } else {
            return 'pause';
        }
    },
    pickupStatus : function() {
        if (!this.data.pickupEnabled) {
            return 'Disabled';
        } else {
            if (this.data.pickupPaused) {
                return 'Paused';
            } else {
                return 'Active';
            }
        }
     },
      deliveryStatus : function() {
          if (!this.data.deliveryEnabled) {
              return 'Disabled';
          } else {
              if (this.data.deliveryPaused) {
                  return 'Paused';
              } else {
                  return 'Active';
              }
          }
       }
  },
  methods : {
        updateDeliveryPrepTime : function() {
            var that=this;
            that.initialising=true;
            RestHelper.post('/wb/api/admin/location/primary/delivery/preptime', {
                mins : that.data.deliveryPrepTime
            }).then(function(response) {
                that.initialising=false;
                that.loadData();
                if (!response.data.success) {
                    AlertService.danger('Error updating delivery prep time');
                }
            },PromiseUtils.viewErrorHandler('Error updating delivery prep time', function() {
                 that.initialising=false;
                 that.loadData();
             }));
        },
        updatePickupPrepTime : function() {
            var that=this;
            that.initialising=true;
            RestHelper.post('/wb/api/admin/location/primary/pickup/preptime', {
                mins : that.data.pickupPrepTime
            }).then(function(response) {
                that.initialising=false;
                that.loadData();
                if (!response.data.success) {
                    AlertService.danger('Error updating pickup prep time');
                }
            },PromiseUtils.viewErrorHandler('Error updating pickup prep time', function() {
                 that.initialising=false;
                 that.loadData();
             }));
        },
        togglePickupPause : function() {
            var paused = !this.data.pickupPaused;
                        var url = '/wb/api/admin/location/primary/pickup/';
                        if (paused) {
                            url=url+'pause';
                        } else {
                            url=url+'unpause';
                        }
                        var that=this;
                        that.initialising=true;
                        RestHelper.post(url).then(function(response) {
                            that.initialising=false;
                            that.loadData();
                            if (!response.data.success) {
                                AlertService.danger('Error updating pickup status');
                            }
                        },PromiseUtils.viewErrorHandler('Error updating pickup status', function() {
                             that.initialising=false;
                         }));
        },
        toggleDeliveryPause : function() {
            var paused = !this.data.deliveryPaused;
            var url = '/wb/api/admin/location/primary/delivery/';
            if (paused) {
                url=url+'pause';
            } else {
                url=url+'unpause';
            }
            var that=this;
            that.initialising=true;
            RestHelper.post(url).then(function(response) {
                that.initialising=false;
                that.loadData();
                if (!response.data.success) {
                    AlertService.danger('Error updating delivery status');
                }
            },PromiseUtils.viewErrorHandler('Error updating delivery status', function() {
                 that.initialising=false;
             }));
        },
        loadData : function() {
            var that=this;
            that.initialising=true;
            RestHelper.get('/wb/api/admin/location/primary/servicestatus').then(function(response) {
                    that.data = response.data;
                    that.initialising=false;
                },PromiseUtils.viewErrorHandler('Error loading online ordering data', function() {
                     that.initialising=false;
                 }));
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>