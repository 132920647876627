<template>
    <div class="container-fluid">
    <div class="row" v-if="!initialising">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">Pinned Category</div>
                        <div class="card-body">

                                <div class="d-flex justify-content-end">
                                <div class="flex-grow-1" v-if="!data.pinnedCategory">Edit Pinned Category</div>
                                                                <div class="flex-grow-1" v-if="data.pinnedCategory"><a href="#" @click.stop.prevent="selectCategory(data.pinnedCategory)">Pinned Category</a></div>
                                <button @click="addPinnedCategory()" v-if="!data.pinnedCategory" type="button" class="btn btn-outline-dark ml-3">
                                    <fa class="fa mr-3" icon="plus"></fa><span>Create Pinned Category</span>
                                </button>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
    <div class="row" v-if="!initialising">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header with-border">
                    Categories
                </div>
                <div class="card-body">
                    <table class="table table-bordered bag-categories">
                        <thead>
                            <tr>
                                <th class="col-handle">&nbsp;</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <draggable handle=".row-handle" group="categories" tag="tbody" @end="onDrop"  id="sortablebody">
                            <tr v-if="data.categories.length==0">
                                <td colspan="2">No Categories</td>
                            </tr>
                            <tr v-for="(category) in data.categories" :key="category.model.id">
                                <td width="50px" class="row-handle"><fa icon="grip-vertical"></fa></td>
                                <td><a href="#" @click.stop.prevent="selectCategory(category)">{{category.model.name}}</a></td>
                            </tr>
                        </draggable>
                    </table>
                    <div class="table-btn-group btn-group float-right z-index-top mt-3">
                        <button type="button" class="btn btn-outline-dark"
                            @click="addCategory()">
                            <fa icon="plus"></fa> Add Category
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>

import {PageStack} from '@/services/PageStackService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {WorkAreaService} from '@/services/menu/WorkAreaService'
import {EditManager} from '@/services/menu/EditManager'

export default {
  name: 'Menu',
  components: {

  },
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Menu',
         buttons : [],
         initialising : false,
         menu : null
         });

     return d;
  },
  methods : {
    selectCategory : function(category) {
        this.$router.push({ name: 'menuworkarea_category', params: {id : category.model.id, category: category }})
    },
    addCategory : function() {
        this.$router.push({ name: 'menuworkarea_category', params: {id : "0", category: this.data.createCategory() }})
    },
    addPinnedCategory : function() {
        this.$router.push({ name: 'menuworkarea_category', params: {id : "0", category: this.data.createPinnedCategory() }})
    },
    setMenu : function(WorkArea) {
        this.data=WorkArea.menu;
        this.pageHandler=EditManager.builder(this).viewModel(this.data).header(model=>'Menu: '+model.name).noDelete().initialise();
    },
    onDrop : function(evt) {
        this.data.reorderCategories(evt.oldIndex, evt.newIndex);
    }
    },
    beforeRouteLeave : function(to, from, next) {
        this.pageHandler.beforeRouteLeave(to, from, next);
    },
    created : function() {
        var that=this;
        that.initialising=true;
        WorkAreaService.load().then(function(WorkArea) {
            that.initialising=false;
            that.setMenu(WorkArea);
        }, PromiseUtils.viewErrorHandler('Error loading menu'));
    }
};
</script>

<style scoped>

</style>