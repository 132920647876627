<template>
    <div>
        <order-filter @search="search"/>
        <order-list v-if="filter" :orders="orders"/>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import OrderFilter from './OrderFilter'
import OrderList from './OrderList'

export default {
  name: 'OrderSearch',
  components: {
    OrderFilter, OrderList
  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(that.$route, {
         header : 'Order Search',
         buttons : [],
         orders : [],
         searching : false,
         filter : null
     });
     return d;
  },
  methods : {
    search : function(filter) {
        this.filter=filter;
        var that=this;
        that.searching=true;
        RestHelper.post('/wb/api/admin/orders/search', filter).then(function(response) {
            that.orders=response.data.data;
            that.searching=false;
        },PromiseUtils.viewErrorHandler('Error searching orders', function() {
              that.searching=false;
          }));
    }
},
created : function() {

}
};
</script>

<style scoped>

</style>