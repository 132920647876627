<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
          <a class="brand-link">
            <span class="brand-text font-weight-light" >Netmealz</span>
            <fa icon="arrow-circle-left" @click.stop.prevent="sidemenu.open=false"/>
          </a>
      <div class="sidebar">

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <div v-for="(item,index) in tree" v-bind:key="index">
                <li :class="{'nav-header' : item.children.length>0, 'nav-item' : item.children.length==0}">
                    <span v-if="item.children.length>0">{{item.label}}</span>
                    <a v-if="item.children.length==0" href="#" class="nav-link" :class="{'active':isActive(item)}" @click.stop.prevent="clicked(item)">
                     <fa :icon="item.icon" :class="item.iconClass" class="nav-icon"></fa>
                     <p>{{item.label}}</p>
                   </a>
                </li>
                <li class="nav-item" v-for="(item2,index2) in item.children" v-bind:key="index2">
                   <a href="#" class="nav-link" :class="{'active':isActive(item2)}" @click.stop.prevent="clicked(item2)">
                     <fa :icon="item2.icon" :class="item2.iconClass" class="nav-icon"></fa>
                     <p>{{item2.label}}</p>
                   </a>
                 </li>
            </div>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
      </aside>
</template>

<script>

function add(list, type, label, icon, iconClass, link, activeLink) {
    var item = {
       type : type,
       label : label,
       icon : icon,
       iconClass : iconClass,
       link : link,
       activeLink : activeLink,
       children : [],
       open : false
       };
    list.push(item);
    return item;
}

export default {
  name: "Sidebar",
  props : ['sidemenu'],
  data : function() {
    var d= {
        tree : []
    };
    add(d.tree, 'leaf','Home','tachometer-alt','fa','/home','/home');
    add(d.tree, 'leaf','Dashboard','tachometer-alt','fa','/dashboard','/dashboard');
    var restaurant=add(d.tree, 'parent','Restaurant Details','store-alt','fa',null,null);
    add(restaurant.children, 'leaf','Brand','copyright','fa','/brand','/brand');
    add(restaurant.children, 'leaf','Address and Contact','map-marker-alt','fa','/locationcontactdetails','/locationcontactdetails');
    add(restaurant.children, 'leaf','Opening Times','clock','fa','/locationopeningtimes','/locationopeningtimes');
    add(restaurant.children, 'leaf','Registered Company','building','fa','/companydetails','/companydetails');
    var orderManagement=add(d.tree, 'parent','Order Management','store-alt','fa',null,null);
    add(orderManagement.children, 'leaf','Devices','tablet-alt','fa','/devices','/devices');
    add(orderManagement.children, 'leaf','Order Workflow','recycle','fa','/orderworkflow','/orderworkflow');
    add(orderManagement.children, 'leaf','Users','user','fa','/stafflist','/stafflist');
    var services=add(d.tree, 'parent','Services','store-alt','fa',null,null);
    add(services.children, 'leaf','Service Status','tachometer-alt','fa','/olo','/olo');
    //add(services.children, 'leaf','Online Ordering','tachometer-alt','fa','/onlineordering','/onlineordering');
    add(services.children, 'leaf','Pickup','shopping-bag','fa','/onlineordering/pickup/0','/onlineordering/pickup/0');
    add(services.children, 'leaf','Delivery','car','fa','/onlineordering/delivery/0','/onlineordering/delivery/0');
    add(services.children, 'leaf','Uber Eats','car','fa','/onlineordering/ubereats/0','/onlineordering/ubereats/0');
    add(services.children, 'leaf','Online Payments','credit-card','fa','/onlineordering/cardpayments/0','/onlineordering/cardpayments/0');
    var business=add(d.tree, 'parent','Media','store-alt','fa',null,null);
    add(business.children, 'leaf','Website','desktop','fa','/website','/website');
    add(business.children, 'leaf','Social Media','desktop','fa','/social','/social');
    var menu=add(d.tree, 'parent','Menu','utensils','fa',null,null);
    add(menu.children, 'leaf','Menu Management','bread-slice','fa','/menuworkarea','/menuworkarea');
    add(menu.children, 'leaf','Unavailable Items','ban','fa','/unavailable','/unavailable');
    var promotions=add(d.tree, 'parent','Promotions','utensils','fa',null,null);
    add(promotions.children, 'leaf','Discounts','tags','fa','/discounts','/discounts');
    add(promotions.children, 'leaf','Loyalty Program','desktop','fa','/club','/club');

    var reports=add(d.tree, 'parent','Reporting','chart-bar','fa',null,null);
    add(reports.children, 'leaf','Order Search','receipt','fa','/orders','/orders');
    add(reports.children, 'leaf','Daily Summary','calendar-alt','fa','/dailysummary','/dailysummary');
    add(reports.children, 'leaf','Insights','calendar-alt','fa','/reports/runreport','/reports/runreport');
    add(reports.children, 'leaf','Delivery Report','calendar-alt','fa','/reports/deliveries','/reports/deliveries');
    return d;
  },
  methods : {
    isActive(item) {
        var link = item.link;
        if (link==null) {
            link=item.activeLink;
        }
        if (link==null) {
            return false;
        }
        return this.$route.path==link;
      },
      clicked : function(item) {
        if (item.type=='leaf') {
            if(this.$route.path!=item.link) {
                this.$router.push(item.link);
            }
        } else {
            item.open=!item.open;
        }
      }
  },
  mounted : function() {

  }
};
</script>
<style lang="scss" scoped>
.brand-link {
    display: flex;
    align-items: center;

    span {
        flex-grow: 1;
    }
}

.nav-item p {
    padding-left: 10px;
}
</style>