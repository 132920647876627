<template>
    <div>
            <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">Items</div>
                            <div class="card-body">
                                <div class="form-group">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th class="col-handle">&nbsp;</th>
                                            <th>Item</th>
                                            <th>Option Name</th>
                                            <th>Default</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <draggable handle=".row-handle" group="variationitems" tag="tbody" @end="onDrop" id="sortable-variation-items">
                                            <tr v-if="parent.products.length==0">
                                                <td colspan="5">No products</td>
                                            </tr>
                                            <tr v-for="(product) in parent.products" :key="product.model.productId">
                                                <td class="handle handle-el row-handle"><fa icon="grip-vertical"></fa></td>
                                                <td><a href="#" @click="selectProduct(product)">{{product.selectedProduct.model.name}}</a></td>
                                                <td class="index"><input type="text" class="form-control"
                                                    placeholder="Option Name" v-model="product.model.optionName"
                                                    ></td>
                                                <td class="index"><label> <input type="checkbox"
                                                        v-model="product.model.defaultOption">
                                                </label></td>
                                                <td class="index"><button type="button"
                                                        class="btn btn-card-tool" @click="product.remove()">
                                                        <fa class="fa" icon="trash"></fa>
                                                    </button></td>
                                            </tr>
                                        </draggable>
                                    </table>
                                    <div class="table-btn-group btn-group float-right">
                                        <button type="button" class="btn btn-outline-dark"
                                            @click="addNewProduct()">
                                            <fa icon="plus"></fa> Add Item
                                        </button>
                                        <b-dropdown variant="outline-dark" offset="-200">
                                          <b-dropdown-item-button @click="moveExisting()">Move Existing Item</b-dropdown-item-button>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <select-node-modal v-if="showSelectModifierModal" :types="selectTypes" :callback="selectCallback"  :title="selectTitle" @hidden="showSelectModifierModal=false"/>
        </div>
</template>

<script>

import {LabelService} from '@/services/LabelService'
import {AlertService} from '@/services/AlertService'
import SelectNodeModal from './SelectNodeModal'

function moveItems(items, variation) {
    items.forEach(function(item) {
        item.moveToVariation(variation).then(function(result) {
            if (result.success) {
                AlertService.success('Product moved');
            }
        });

    });
}

export default {
  name: 'VariationItems',
  components: {
    SelectNodeModal
  },
  props : ['parent'],
  data : function() {
    var d = {
        menuDisplayFormatOptions : LabelService.menuDisplayFormatOptions,
        showSelectModifierModal : false,
                 selectTypes : null,
                 selectCallback : null,
                 selectTitle : null
         };

     return d;
  },
  methods : {
    selectProduct : function(variationProduct) {
        this.$router.push({ name: 'menuworkarea_product', params: {id : variationProduct.selectedProduct.model.id, product: variationProduct.selectedProduct }})
    },
    onDrop : function(evt) {
        this.parent.reorderProducts(evt.oldIndex, evt.newIndex);
    },
      addNewProduct : function() {
          var params = {
              variationId : this.parent.model.id,
              variation : this.parent.model
          };
          this.$router.push({ name: 'menuworkarea_createvariationproduct', params: params});

      },moveExisting : function() {
           this.selectTypes=['Item'];
           this.selectCallback=this.moveExistingSelected;
           this.showSelectModifierModal=true;
           this.selectTitle='Select Item to Move';
       },
       moveExistingSelected : function(selected) {
           moveItems(selected, this.parent);
       }
    },
    created : function() {

    }
};
</script>

<style scoped>

</style>