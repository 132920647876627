
import { reactive } from '@vue/composition-api'

var VariationProductViewModelFactory = {

    create : function(product, variationViewModel, workAreaModifier) {

        var VariationProductViewModel = {
            model : product,
            remove : function() {
                var index = variationViewModel.products.indexOf(VariationProductViewModel);
                if (index>-1) {
                    variationViewModel.products.splice(index, 1);
                }
                for (var i=0;i<variationViewModel.model.products.length;i++) {
                    if (variationViewModel.model.products[i].productId==product.productId) {
                        variationViewModel.model.products.splice(i, 1);
                        break;
                    }
                }
                if (VariationProductViewModel.selectedProduct!=null) {
                    VariationProductViewModel.selectedProduct.setParentNode(null);
                }
                VariationProductViewModel.selectedProduct=null;
                if (deleteListener!=null) {
                    deleteListener();
                }
            }
        };

        var deleteListener = null;


        if (product.productId!=null) {
            VariationProductViewModel.selectedProduct=workAreaModifier.getProductById(product.productId);
            VariationProductViewModel.selectedProduct.setParentNode(variationViewModel);

            deleteListener = workAreaModifier.addDeletionCallback(product.productId, function() {
                deleteListener=null;
                VariationProductViewModel.remove();
            });
        }


        VariationProductViewModel=reactive(VariationProductViewModel);
        return VariationProductViewModel;
    }

};

export {VariationProductViewModelFactory as VariationProductViewModelFactory};