<template>
    <div class="container-fluid">
        <general-errors  :errors="data.errors"/>
        <div class="row justify-content-center" v-if="!data.initialising">
            <div class="col-12">
                <form role="form" name="offerForm">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Staff Details</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Name</label> <input type="text"
                                    class="form-control" id="title" placeholder="Enter name"
                                    v-model="data.model.name"
                                    >
                                <field-error :errors="data.errors" field="name"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label> <input type="text"
                                    class="form-control" id="title" placeholder="Enter email"
                                    v-model="data.model.email"
                                    >
                                <field-error :errors="data.errors" field="email"></field-error>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">PIN</label> <input type="password"
                                    class="form-control" id="title" placeholder="Enter PIN"
                                    v-model="data.model.pin"
                                    >
                                <field-error :errors="data.errors" field="pin"></field-error>
                            </div>
                            <div class="form-group">
                                    <label for="exampleInputEmail1">Roles</label>
                                    <v-select placeholder="Select roles" multiple label="label" :options="roleOptions" :reduce="option => option.value" v-model="data.model.roles"></v-select>
                                                            <field-error :errors="data.errors" field="roles"></field-error>
                             </div>
                             <div class="form-group">
                                 <label for="exampleInputEmail1">Active</label>
                                 <b-form-checkbox switch size="lg" v-model="data.model.active"></b-form-checkbox>
                                <field-error :errors="data.errors" field="active"></field-error>
                                                             </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
//import {PromiseUtils} from "@/utils/PromiseUtils";
import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError';
import GeneralErrors from '@/components/GeneralErrors';

function createRoleOptions() {
    var roleOptions = [
        {value : "OWNER",label : "Owner"},
        {value : "DELIVERY",label : "Delivery Driver"},
        {value : "FRONT",label : "Front of House"},
        {value : "SHIFT_MANAGER",label : "Shift Manager"}
    ];
    return roleOptions;
}

export default {
  name: 'DiscountEdit',
  components: {
    FieldError,GeneralErrors
  },
  data : function() {
    var that=this;
    var id = this.$route.params.id;
    var isCreate = id==null;
    var header;
    if (isCreate) {
        header='Add Staff Member';
    } else {
        header='Edit Staff Member';
    }

    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/staff/'+id).saveEndpoint('/wb/api/admin/staff').saveAlert('Saved', 'Changes to the the staff member have been changed.').build();
    var buttons=[];
    buttons.push(data.createResetButton());
    buttons.push(data.createSaveButton());
    var d = PageStack.addPage(that.$route, {
         header : header,
         isCreate : isCreate,
         buttons : buttons,
         data : data,
         roleOptions : createRoleOptions()
     });
     return d;
  },
  watch : {
  },
  beforeRouteLeave (to, from, next) {
       this.data.beforeRouteLeave(to, from, next);
      },
  methods : {


  },
    created : function() {
        if (this.isCreate) {
            var model = {
                id : null,
                name : null,
                email : null,
                active : true,
                roles : []
            };
            this.data.defaultModel(model);
        } else {
            this.data.load();
        }

    }
};
</script>

<style scoped>

</style>