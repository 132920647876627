<template>
  <b-modal id="delivery-zone-modal" modal-class="delivery-zone-modal" centered hide-footer no-close-on-backdrop :title="title" @hide="handleHide()">
        <div class="delivery-postcodes-body d-flex flex-column h-100">
            <form class="form-inline mb-2">
                <div class="delivery-zone-form d-flex w-100">
                    <label class="label-1 mr-2" for="exampleInputEmail1">Name</label>
                    <input type="text" class="form-control flex-grow-1 mr-2" id="name" placeholder="Enter name" v-model="name">
                    <label class="mr-2" v-if="include" for="exampleInputEmail1">Delivery Charge</label>
                    <input type="text" v-if="include" class="form-control flex-grow-1 mr-2" id="name" placeholder="No Delivery Charge"  v-model="deliveryCharge">
                    <button type="button" class="btn btn-primary" @click="submit()" :disabled="!valid">Submit</button>
                </div>
            </form>
            <form class="form-inline mb-2">
                <div class="delivery-zone-form d-flex">
                    <label class="label-1 mr-2" for="exampleInputEmail1">Postcode</label>
                    <input type="text" class="form-control flex-grow-1 mr-2" id="name" placeholder="Enter name" v-model="postcode">
                    <button type="button" class="btn btn-primary" @click="addPostcode()" :disabled="!postcode">Add</button>
                </div>
            </form>
            <hr>
            <div class="postcodes">
                <div class="postcode" v-for="(postcode, index) in postcodes" :key="index">
                    <div class="label">{{postcode}}</div>
                    <div class="close" @click="removePostcode(postcode)"><fa icon="times">{{postcode}}</fa></div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

var component = {
  name: "DeliveryPostcodeModal",
  props: ['type','include', 'model'],
  components : {},
  data : function() {
      var d = {
           title : '',
           name : null,
           deliveryCharge : null,
           postcodes : null,
           postcode : null
      };
      if (this.model!=null && this.model.postcodes!=null) {
        d.postcodes=this.model.postcodes.slice();
      } else {
        d.postcodes=[];
      }
      if (this.include) {
        d.title='Include Postcodes';
      } else {
        d.title='Exclude Postcodes';
      }
      if (this.model!=null) {
        d.name=this.model.name;
        if (this.include) {
            d.deliveryCharge=this.model.deliveryCharge;
        }
      }
     return d;
    },
    computed : {
        'valid' : function() {
            if (this.name==null || this.name.trim()=='') {
                return false;
            }
            if (this.include) {
                if (this.deliveryCharge==null || this.deliveryCharge=='') {
                    return false;
                }
            }
            return true;
        }
    },
   methods : {
      addPostcode : function() {
        var v=this.postcode.trim();
        if (v.length==0) {
            return;
        }
        var index=this.postcodes.indexOf(v);
        if (index==-1) {
            this.postcodes.push(v);
        }
        this.postcode=null;
      },
      handleHide : function() {
          this.$emit('hidden');
      },
      submit : function() {
        var model=this.model;
        if (model==null) {
            model={
                enabled : true,
                paused : false
            };
        }
        model.name=this.name;
        model.type='postcode';
        model.include=this.include;
        model.postcodes=this.postcodes;
        if (this.include) {
            model.deliveryCharge=this.deliveryCharge;
        }
        this.$emit('submitted', model);
      },
      removePostcode : function(postcode) {
        var index = this.postcodes.indexOf(postcode);
        if (index>=0) {
            this.postcodes.splice(index, 1);
        }
      }
    },
    mounted : function() {
        this.$bvModal.show('delivery-zone-modal');
      },
      created : function() {

      }
};
export default component;
</script>


<style lang="scss" scoped>
.label-1  {
    width: 100px;
    text-align: right;
}

.delivery-zone-modal .modal-dialog {
    max-width: 1000px !important;
}

.delivery-zone-modal .modal-content {
     height: 90vh !important;
 }

 .postcodes {

    display: flex;
    margin: 15px;

    .postcode {
        display: flex;
        align-items: center;
        margin: 5px;
        border: 1px solid black;

        .label {
            padding: 7px;
        }

        .close {
            padding: 7px;
        }

        .close:hover {
            cursor: pointer;
        }
    }
 }

</style>