<template>
    <div class="card">
        <b-table :fields="fields" :items="discounts" empty-text="No discounts" show-empty>
            <template v-slot:cell(actions)="data">
              <a href="#" @click.prevent.stop="view(data.item)">View</a>
              <a href="#" v-if="data.item.editable" @click.prevent.stop="edit(data.item)"> | Edit</a>
              <a href="#" v-if="data.item.editable" @click.prevent.stop="doDelete(data.item)"> | Delete</a>
              <a href="#" v-if="data.item.cancellable" @click.prevent.stop="doCancel(data.item)"> | Cancel</a>
            </template>
        </b-table>
        <select-discount-type-modal v-if="showTypeSelectModal" :callback="callback" @hidden="showTypeSelectModal=false"/>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import SelectDiscountTypeModal from './SelectDiscountTypeModal';
import moment from 'moment';

function convertType(value) {
    if (value==null) {
        return null;
    }
    switch (value) {
        case 'promoCode':
            return 'Promo Code';
        case 'reward':
            return 'Voucher Incentive';
        case 'discount':
            return 'Monetary Discount';
        default:
            return value.substring(0,1).toUpperCase()+value.substring(1);
    }
}



export default {
  name: 'DiscountList',
  components: {
    SelectDiscountTypeModal
  },
  data : function() {
    var that=this;
    var fields = [
      { key: 'title', label : 'Title', sortable : true},
      { key: 'type', label : 'Type', sortable : true, formatter : function(value) {return convertType(value);}},
      { key: 'startDate', label : 'Start Date', sortable : true, formatter : function(value) {return value==null ? '' : moment(new Date(value)).format("ddd DD MMM YYYY");}},
      { key: 'endDate', label : 'End Date', sortable : true, formatter : function(value) {return value==null ? '' : moment(new Date(value)).format("ddd DD MMM YYYY");}},
      { key: 'status', label : 'Status', sortable : true},
      { key: 'actions', label : '', thStyle:{ 'min-width':'180px'}}
    ];

    var d = PageStack.setPage(that.$route, {
         fields : fields,
         header : 'Discount List',
         buttons : [{
                                    label : 'Create',
                                    class : 'btn-primary',
                                    icon : 'plus',
                                    onClick : function() {
                                        that.create();
                                    },
                                    hidden : false,
                                    disabled : false
                                }],
         discounts : [],
         initialising : false,
         showTypeSelectModal : false
     });
     return d;
  },
  methods : {
    callback : function(type) {
        this.showTypeSelectModal=false;
        this.$router.push('/discount/create/'+type);
    },
    create : function() {
        this.showTypeSelectModal=true;
    },
    edit : function(discount) {
            this.$router.push('/discount/'+discount.type+'/'+discount.id);
    },
    view : function(discount) {
            this.$router.push('/discount/'+discount.type+'/'+discount.id);
    },
    doDelete : function(discount) {
            this.deleteAfterConfirmation(discount, 'Delete Discount', "Are you sure you wish to delete this pending discount?", 'Yes, cancel', 'No');
    },
    doCancel : function(discount) {
            this.deleteAfterConfirmation(discount, 'Cancel Discount', "Are you sure you wish to cancel this active discount?", 'Yes, cancel', 'No');
    },
    loadData : function() {
        this.initialising=true;
        var that=this;
        RestHelper.get('/wb/api/admin/discounts').then(function(result) {
            that.discounts=result.data;
            that.initialising=false;
        },PromiseUtils.viewErrorHandler('Error loading devices', function() {
             that.initialising=false;
         }));
        },
     deleteAfterConfirmation : function(discount, title, msg, ok, cancel) {
        var that=this;
         this.$bvModal.msgBoxConfirm(msg, {
                   title: title,
                   okTitle: ok,
                   cancelTitle: cancel,
                   footerClass: 'p-2',
                   hideHeaderClose: false,
                   centered: true
                 })
                   .then(value => {
                     if (value) {
                        RestHelper.delete('/wb/api/admin/discount/'+discount.id).then(function() {
                            that.loadData();
                        },PromiseUtils.viewErrorHandler('Error loading devices'));
                     }
                   })
                   .catch(function() {
                   });

     }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>