import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {MenuViewModelFactory} from '@/services/menu/MenuViewModelFactory'
import {VariationViewModelFactory} from '@/services/menu/VariationViewModelFactory'
import {ProductViewModelFactory} from '@/services/menu/ProductViewModelFactory'
import {GroupingViewModelFactory} from '@/services/menu/GroupingViewModelFactory'
import {TaxRateViewModelFactory} from '@/services/menu/TaxRateViewModelFactory'
import {ConfiguredItemViewModelFactory} from '@/services/menu/ConfiguredItemViewModelFactory'

//var workAreaModel=null;

var WorkArea = null;

var categoryMap={};
var variationMap={};
var variations=[];
var products=[];
var productMap={};
var productRefMap={};
var groupings=[]
var groupingMap={};
var taxRates=[]
var taxRateMap={};
var configuredItems=[]
var configuredItemMap={};

var deletionListeners = {};

var groupingInitialised=false;
var groupingInitCallbacks=[];

function reset() {
    WorkArea = null;

    categoryMap={};
    variationMap={};
    variations=[];
    products=[];
    productMap={};
    productRefMap={};
    groupings=[]
    groupingMap={};
    taxRates=[]
    taxRateMap={};
    configuredItems=[]
    configuredItemMap={};

    deletionListeners = {};

    groupingInitialised=false;
    groupingInitCallbacks=[];
}

reset();

var workAreaModifier = {
    applyMenu : function(menu) {
        WorkArea.menu=menu;
    },
    applyCategory : function(category) {
        categoryMap[category.model.id+'']=category;
    },
    deleteCategory : function(category) {
        delete categoryMap[category.model.id+''];
    },
    applyVariation : function(variationViewModel) {
        if (!variationViewModel.created) {
            return;
        }
        var index = variations.indexOf(variationViewModel);
        if (index==-1) {
            variations.push(variationViewModel);
            variationMap[variationViewModel.model.id]=variationViewModel;
        }
    },
    applyConfiguredItem : function(configuredItemViewModel) {
        if (!configuredItemViewModel.created) {
            return;
        }
        var index = configuredItems.indexOf(configuredItemViewModel);
        if (index==-1) {
            configuredItems.push(configuredItemViewModel);
            configuredItemMap[configuredItemViewModel.model.id]=configuredItemViewModel;
        }
    },
    applyProduct : function(productViewModel) {
        if (!productViewModel.created) {
            return;
        }
        var index = products.indexOf(productViewModel);
        if (index==-1) {
            products.push(productViewModel);
        }
        productMap[productViewModel.model.id]=productViewModel;
        productRefMap[productViewModel.model.refId]=productViewModel;
    },
    applyGrouping : function(groupingViewModel) {
        if (!groupingViewModel.created) {
            return;
        }
        var index = groupings.indexOf(groupingViewModel);
        if (index==-1) {
            groupings.push(groupingViewModel);
            groupingMap[groupingViewModel.model.id]=groupingViewModel;
        }
    },
    applyTaxRate : function(taxRateViewModel) {
        if (!taxRateViewModel.created) {
            return;
        }
        var index = taxRates.indexOf(taxRateViewModel);
        if (index==-1) {
            taxRates.push(taxRateViewModel);
            taxRateMap[taxRateViewModel.model.id]=taxRateViewModel;
        }
        resetDefaultTaxRate();
    },
    getProductById : function(id) {
        return productMap[id];
    },
    getCategoryById : function(id) {
        return categoryMap[id];
    },
    getProductByRefId : function(id) {
        return productRefMap[id];
    },
    getVariationById : function(id) {
        return variationMap[id];
    },
    getGroupingById : function(id) {
        return groupingMap[id];
    },
    getTaxRateById : function(id) {
        return taxRateMap[id];
    },
     getConfiguredItemById : function(id) {
        return configuredItemMap[id];
    },
    addDeletionCallback : function(id, listerFunc) {
        var arr = deletionListeners[id];
        if (arr==null) {
            arr=[];
            deletionListeners[id]=arr;
        }
        arr.push(listerFunc);
        return (function() {
            var arr2 = deletionListeners[id];
            if (arr2!=null) {
                var index = arr2.indexOf(listerFunc);
                if (index>=0) {
                    arr2.splice(index, 1);
                }
            }
        });
    },
    addGroupingInitCallback : function(callback) {
        if (groupingInitialised) {
            callback();
        } else {
            groupingInitCallbacks.push(callback);
        }
    },
    defaultTaxRate : null,
    taxRates : taxRates
};

function resetDefaultTaxRate() {
    taxRates.forEach(function(taxRate) {
        if (taxRate.model.defaultRate) {
            workAreaModifier.defaultTaxRate=taxRate;
        }
    })
}



/*
function notifyDeletion(id) {
		var arr = deletionListeners[id];
		if (arr!=null) {
			arr.forEach(function(func) {
				func.call(this);
			});
			deletionListeners[id]=[];
			delete deletionListeners[id];
		}
	}
*/

function loadWorkArea(model) {
    workAreaModifier.id=model.id;
    WorkArea={
        id : model.id,
        menus : [],
        modifiers : groupings,
        products : products,
        variations : variations
        };
    //workAreaModel=model;

    model.taxRates.forEach(function (taxRate) {
        var taxRateViewModel=new TaxRateViewModelFactory.create(taxRate, workAreaModifier);
        taxRates.push(taxRateViewModel);
        taxRateMap[taxRateViewModel.model.id]=taxRateViewModel;
    });
    WorkArea.taxRates=taxRates;
    resetDefaultTaxRate();
    model.products.forEach(function (product) {
        var productViewModel = ProductViewModelFactory.create(product, workAreaModifier);
        workAreaModifier.applyProduct(productViewModel);
    });
    model.variations.forEach(function (variation) {
        var variationViewModel=VariationViewModelFactory.create(variation, workAreaModifier);
        workAreaModifier.applyVariation(variationViewModel);
    });
    model.groupings.forEach(function (grouping) {
        var groupingViewModel=GroupingViewModelFactory.create(grouping, workAreaModifier);
        workAreaModifier.applyGrouping(groupingViewModel);
    });
    model.items.forEach(function (item) {
        var configuredItemViewModel=ConfiguredItemViewModelFactory.create(item, workAreaModifier);
        workAreaModifier.applyConfiguredItem(configuredItemViewModel);
    });
    groupingInitialised=true;
    model.menus.forEach(function (menu) {
        var menuViewModel=MenuViewModelFactory.create(menu, workAreaModifier);
        WorkArea.menus.push(menuViewModel);
    });
    groupingInitCallbacks.forEach(function(callback) {
        try {
            callback();
        } catch (err) {
            //
        }
    });
    groupingInitCallbacks=[];

    WorkArea.menu=null;
    if (WorkArea.menus.length>0) {
        WorkArea.menu=WorkArea.menus[0];
    }
    WorkArea.getCategory=function(id) {
        return categoryMap[id];
    };
    WorkArea.getProduct=function(id) {
        return productMap[id];
    };
    WorkArea.getVariation=function(id) {
        return variationMap[id];
    };
    WorkArea.getTaxRate=function(id) {
        return taxRateMap[id];
    };
    WorkArea.getGrouping=function(id) {
        return groupingMap[id];
    };
    WorkArea.getConfiguredItem=function(id) {
        return configuredItemMap[id];
    };
    WorkArea.createTaxRate=function() {
        return new TaxRateViewModelFactory.create(null, workAreaModifier);
    };
    WorkArea.publish = publish;
    WorkArea.deleteAll = deleteAll;
    return WorkArea;
}

function publish() {
    var promise = new Promise(function(resolve, reject) {
        RestHelper.post('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/promote').then(function(result) {
            if (result.data.success) {
                reset();
                resolve(result.data);
            } else {
                resolve(result.data);
            }


        }, PromiseUtils.errorHandler(reject));
    });
    return promise;
}

function deleteAll() {
    var promise = new Promise(function(resolve, reject) {
        RestHelper.delete('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id).then(function() {
            reset();
            resolve();
        }, PromiseUtils.errorHandler(reject));
    });
    return promise;
}


var WorkAreaService = {
    reset : function() {
        WorkArea=null;
    },
    load : function() {
        var promise = new Promise(function(resolve, reject) {
            if (WorkArea!=null) {
                resolve(WorkArea);
            } else {
                RestHelper.get('/wb/api/admin/menubuilder/workarea/latest?create=true').then(function(result) {
                    resolve(loadWorkArea(result.data));
                }, PromiseUtils.errorHandler(reject));
            }
        });
        return promise
    }
};

export {WorkAreaService as WorkAreaService};