import { reactive } from '@vue/composition-api';
import { ErrorsService} from '@/services/ErrorsService';
import { RestHelper} from '@/utils/RestHelper';
import { PromiseUtils} from '@/utils/PromiseUtils';
import {VueUtils} from '@/utils/VueUtils';
import {WebsiteConfigService} from './WebsiteConfigService';

function addDraft(vm, force) {
    function createTemplateViewModels(data) {
        var templates = [];
        data.templates.forEach(function(template) {
            var vm = {
                id : template.id,
                name : template.name,
                selected : template.id==data.activeConfig.templateId,
                select : function() {
                    templates.forEach(function(temp) {
                        temp.selected=false;
                    });
                    vm.selected=true;
                    return switchTemplate(vm.id);
                }
            };
            templates.push(vm);
        });
        return templates;
    }

    function processDraft(vm, data) {
        var originalStr=null;

        function runSave(endpoint) {
            var that=draft;
            var promise = new Promise(function(resolve, reject) {
                that.errors.clearServerErrors();
                var submit=data.activeConfig;
                submit.values=draft.model;
                RestHelper.put(endpoint, submit).then(function(response) {
                    if (response.data.success) {
                        resolve(true);
                    } else {
                        that.errors.setServerErrors(response.data.errors);
                        resolve(false);
                    }

                }, function(err) {
                    reject(err);
                });
            });
            return promise;
        }



        var draft = {
            config : null,
            hasDraft : data.activeConfig!=null,
            isDirty : false,
            errors : ErrorsService.create(),
            model : null,
            previewUrl : data.previewUrl,
            save : function() {
                return runSave('/wb/api/admin/website/save');
            },
            publish : function() {
                var promise = new Promise(function(resolve, reject) {
                    runSave('/wb/api/admin/website/publish').then(function(success) {
                        if (success) {
                            var p1 = addDraft(vm, true);
                            var p2 = addLive(vm);
                            Promise.all([p1, p2]).then(function() {
                                resolve(success);
                            }, function(err) {
                                reject(err);
                            });
                        } else {
                            resolve(success);
                        }
                    }, function(err) {
                         reject(err);
                     });
                });
                return promise;

            },
            deleteDraft : function() {
               var promise = new Promise(function(resolve, reject) {
                   var submit=data.activeConfig;
                   submit.values=draft.model;
                   RestHelper.delete('/wb/api/admin/website/'+data.activeConfig.id).then(function() {
                      draft.hasDraft=false;
                      draft.model=null;
                      resolve(true);
                   }, function(err) {
                       reject(err);
                   });
               });
               return promise;
           }
        };
        if (data.activeConfig!=null) {
            originalStr=JSON.stringify(data.activeConfig.values);
            draft.model=data.activeConfig.values;

        }
        draft.config=WebsiteConfigService.createViewModel(data.templateConfig, draft.model)
        vm.draft=reactive(draft);
        vm.templates=reactive(createTemplateViewModels(data));
        VueUtils.watchObject(vm.draft, function() {
            var newStr=JSON.stringify(vm.draft.model);
            vm.draft.isDirty=originalStr!=newStr;
        });

    }

    function switchTemplate(templateId) {
            if (force==null) {
                force=false;
            }
            var promise = new Promise(function(resolve, reject) {
                RestHelper.get('/wb/api/admin/website/template/'+templateId+'/draft?force='+force).then(function(response) {
                    processDraft(vm, response.data);
                    resolve();
                }, PromiseUtils.errorHandler(reject));
            });
            return promise;
        }

    function loadDefaultDraft() {
        if (force==null) {
            force=false;
        }
        var promise = new Promise(function(resolve, reject) {
            RestHelper.get('/wb/api/admin/website/draft?force='+force).then(function(response) {
                processDraft(vm, response.data);
                resolve();
            }, PromiseUtils.errorHandler(reject));
        });
        return promise;
    }

    return loadDefaultDraft();

}

function addLive() {
    var promise = new Promise(function(resolve) {
        resolve();
    });
    return promise;
}

function createViewModel() {
    var promise = new Promise(function(resolve, reject) {
         var vm = reactive({
            live : null,
            draft : null,
            templates : [],
            edit : function() {
                return addDraft(vm, true);
            }
            });
        var p1 = addDraft(vm, true);
        var p2 = addLive(vm);
        Promise.all([p1, p2]).then(function() {
            resolve(vm);
        }, function(err) {
            reject(err);
        });
    });
    return promise;
}

var WebsiteService = {
    createViewModel : createViewModel

};

export {WebsiteService as WebsiteService};