<template>
    <div class="container-fluid chart-directive">
        <div class="row">
            <div class="col-12">
                <metric :report="report" v-if="report.reportType=='metric'"/>
                <chart :report="report" v-if="report.reportType=='chart'"/>
            </div>
        </div>
    </div>
</template>

<script>

import Metric from './Metric';
import Chart from './Chart';

export default {
  name: 'DashboardReport',
  props : ['report'],
  components: {
    Metric, Chart
  }
};
</script>

<style scoped>

</style>