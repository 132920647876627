<template>
  <b-modal id="media-library-modal" modal-class="media-library-modal" centered hide-footer no-close-on-backdrop title="Media Library" @hide="handleHide()">
       <b-tabs content-class="mt-3" fill>
           <b-tab title="Library" active><media-library :callback="callback"/></b-tab>
           <b-tab title="Upload File"><media-upload :callback="callback"/></b-tab>
         </b-tabs>
    </b-modal>
</template>

<script>

import MediaLibrary from './MediaLibrary'
import MediaUpload from './MediaUpload'

var component = {
  name: "MediaLibraryModal",
  props: ['callback'],
  components : {MediaLibrary,MediaUpload},
  data : function() {
      var d = {
        reports : []
      };
     return d;
    },
   methods : {
      handleHide : function() {
          this.$emit('hidden');
      }
    },
    mounted : function() {
        this.$bvModal.show('media-library-modal');
      },
      created : function() {

      }
};
export default component;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.media-library-modal .modal-dialog {
    max-width: 1000px !important;
}


.media-library-modal .modal-content {
     max-width: 1000px !important;
     width: 90vw !important;
     height: 90vh !important;
 }


</style>