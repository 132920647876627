<template>
<section class="row no-gutters p-0 bg-light min-vh-100">
      <div class="col-12">
        <section class="p-0 bg-light ">
          <div class="container min-vh-lg-100 d-flex flex-column justify-content-center text-center py-4 py-md-5">
            <a href="index.html" class="fade-page">
              <h1>Netmealz</h1>
            </a>
            <div class="row justify-content-center my-5">
              <div class="col-xl-7 col-lg-8">
                <h1 class="h2 text-center mb-lg-5">Create Account</h1>
                <div class="card card-body shadow text-left text-dark">
                    <div class="form-group">
                      <input type="text" v-model="model.name" class="form-control" name="sign-up-name" placeholder="Restaurant Name">
                      <field-error :errors="errors" field="name"></field-error>
                    </div>
                    <div class="form-group">
                      <input type="email" v-model="model.email" class="form-control" name="sign-up-email" placeholder="Email Address">
                      <field-error :errors="errors" field="email"></field-error>
                    </div>
                    <div class="form-group">
                      <input type="password" v-model="model.password" class="form-control" name="sign-up-password" placeholder="Password">
                      <field-error :errors="errors" field="password"></field-error>
                      <!--<small>Password must be at least 8 characters</small>-->
                    </div>
                    <!--
                    <div class="form-group">
                      <input type="password" class="form-control" name="sign-up-password-confirm" placeholder="Confirm Password">
                    </div>
                    -->
                    <div class="text-right text-small mt-0 text-danger">
                      <span v-if="msg" class="error">{{msg}}</span>
                      </div>
                    <div class="form-group">
                      <div class="custom-control custom-checkbox text-small">
                        <input type="checkbox" v-model="agreedToTerms" class="custom-control-input" id="sign-up-agree">
                        <label class="custom-control-label" for="sign-up-agree">I agree to the <a target="_blank" href="utility-legal-terms.html">Terms &amp; Conditions</a>
                        </label>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-block" type="submit" @click.stop.prevent="register()">Create Account</button>

                </div>
                <div class="text-center text-small mt-3">
                  Already have an account? <router-link v-bind:to="'login'">Sign in here</router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <field-error :errors="errors" field="name"></field-error>
    </section>
            <!--
    <div class="container">
        <div class="row">
            <div class="offset-3 col-6">
            <div class="form-container">
      <h1>Netmealz</h1>
      <h1 class="h3 mb-3 font-weight-normal">Register</h1>
      <label for="inputEmail" class="sr-only">Restaurant Name</label>
      <input id="inputEmail" v-model="model.name" type="email" class="form-control" required autofocus placeholder="Restaurant Name">
      <field-error :errors="errors" field="name"></field-error>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input id="inputEmail" v-model="model.email" type="email" class="form-control mt-2" required placeholder="Email">
      <field-error :errors="errors" field="email"></field-error>
      <label for="inputPassword" class="sr-only">Password</label>
      <input v-model="model.password" type="password" class="form-control mt-2" placeholder="Password">
      <field-error :errors="errors" field="password"></field-error>
    </div>
      <div class="msg-container">
              <span v-if="msg" class="error">{{msg}}</span>
              </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit" v-on:click="register()">Create Account</button>

      </div>
      </div>
    </div>
    -->
</template>

<script>

import {AuthenticationService} from "@/services/AuthenticationService";
import FieldError from '@/components/FieldError';
import {ErrorsService} from '@/services/ErrorsService';

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default {
  components: {
      FieldError
    },
  data : function() {
    return {
        model : {
            email : null,
            password : null,
            name : null
        },
        agreedToTerms : false,
        msg : null,
        errors : ErrorsService.create()
    };
  },
  methods: {
    register() {
        if (!this.agreedToTerms) {
            alert('You must agree to the terms and conditions before you can create an account');
            return;
        }
      var that=this;
      that.msg = null;
      AuthenticationService.model.signup(this.model).then(function(model) {
         if (model.success) {
            that.$router.push('/')
         } else {
            that.errors.setServerErrors(model.errors);
         }
     });
    }
  },
  created : function() {
    this.model.name=getParameterByName('restaurantName');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @charset "UTF-8";
    /*!
     * Bootstrap v4.3.1 (https://getbootstrap.com/)
     * Copyright 2011-2019 The Bootstrap Authors
     * Copyright 2011-2019 Twitter, Inc.
     * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
     */
    :root {
      --blue: #007bff;
      --indigo: #6610f2;
      --purple: #6f42c1;
      --pink: #e83e8c;
      --red: #dc3545;
      --orange: #fd7e14;
      --yellow: #ffc107;
      --green: #28a745;
      --teal: #20c997;
      --cyan: #17a2b8;
      --white: #fff;
      --gray: #6c757d;
      --gray-dark: #343a40;
      --primary: #009b72;
      --secondary: #EAEDF2;
      --success: #009b72;
      --info: #17a2b8;
      --warning: #ffc107;
      --danger: #dc3545;
      --light: #F7F9FC;
      --dark: #2C3038;
      --primary-2: #046865;
      --primary-3: #192d35;
      --breakpoint-xs: 0;
      --breakpoint-sm: 576px;
      --breakpoint-md: 768px;
      --breakpoint-lg: 992px;
      --breakpoint-xl: 1200px;
      --font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

    *,
    *::before,
    *::after {
      box-sizing: border-box; }

    html {
      font-family: sans-serif;
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

    article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
      display: block; }

    body {
      margin: 0;
      font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      color: #555A64;
      text-align: left;
      background-color: #fff; }

    [tabindex="-1"]:focus {
      outline: 0 !important; }

    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible; }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      margin-bottom: 1rem; }

    p {
      margin-top: 0;
      margin-bottom: 1rem; }

    abbr[title],
    abbr[data-original-title] {
      text-decoration: underline;
      -webkit-text-decoration: underline dotted;
              text-decoration: underline dotted;
      cursor: help;
      border-bottom: 0;
      -webkit-text-decoration-skip-ink: none;
              text-decoration-skip-ink: none; }

    address {
      margin-bottom: 1rem;
      font-style: normal;
      line-height: inherit; }

    ol,
    ul,
    dl {
      margin-top: 0;
      margin-bottom: 1rem; }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0; }

    dt {
      font-weight: 700; }

    dd {
      margin-bottom: .5rem;
      margin-left: 0; }

    blockquote {
      margin: 0 0 1rem; }

    b,
    strong {
      font-weight: bolder; }

    small {
      font-size: 80%; }

    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline; }

    sub {
      bottom: -.25em; }

    sup {
      top: -.5em; }

    a {
      color: #009b72;
      text-decoration: none;
      background-color: transparent; }
      a:hover {
        color: #004f3a;
        text-decoration: none; }

    a:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none; }
      a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
        color: inherit;
        text-decoration: none; }
      a:not([href]):not([tabindex]):focus {
        outline: 0; }

    pre,
    code,
    kbd,
    samp {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      font-size: 1em; }

    pre {
      margin-top: 0;
      margin-bottom: 1rem;
      overflow: auto; }

    figure {
      margin: 0 0 1rem; }

    img {
      vertical-align: middle;
      border-style: none; }

    svg {
      overflow: hidden;
      vertical-align: middle; }

    table {
      border-collapse: collapse; }

    caption {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: rgba(85, 90, 100, 0.65);
      text-align: left;
      caption-side: bottom; }

    th {
      text-align: inherit; }

    label {
      display: inline-block;
      margin-bottom: 0.5rem; }

    button {
      border-radius: 0; }

    button:focus {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color; }

    input,
    button,
    select,
    optgroup,
    textarea {
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit; }

    button,
    input {
      overflow: visible; }

    button,
    select {
      text-transform: none; }

    select {
      word-wrap: normal; }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button; }

    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
      cursor: pointer; }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      padding: 0;
      border-style: none; }

    input[type="radio"],
    input[type="checkbox"] {
      box-sizing: border-box;
      padding: 0; }

    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
      -webkit-appearance: listbox; }

    textarea {
      overflow: auto;
      resize: vertical; }

    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0; }

    legend {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: .5rem;
      font-size: 1.5rem;
      line-height: inherit;
      color: inherit;
      white-space: normal; }
      @media (max-width: 1200px) {
        legend {
          font-size: calc(1.275rem + 0.3vw) ; } }

    progress {
      vertical-align: baseline; }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto; }

    [type="search"] {
      outline-offset: -2px;
      -webkit-appearance: none; }

    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

    ::-webkit-file-upload-button {
      font: inherit;
      -webkit-appearance: button; }

    output {
      display: inline-block; }

    summary {
      display: list-item;
      cursor: pointer; }

    template {
      display: none; }

    [hidden] {
      display: none !important; }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      margin-bottom: 1rem;
      font-weight: 700;
      line-height: 1.2;
      color: #2C3038; }

    h1, .h1 {
      font-size: 2.5rem; }
      @media (max-width: 1200px) {
        h1, .h1 {
          font-size: calc(1.375rem + 1.5vw) ; } }

    h2, .h2 {
      font-size: 2rem; }
      @media (max-width: 1200px) {
        h2, .h2 {
          font-size: calc(1.325rem + 0.9vw) ; } }

    h3, .h3 {
      font-size: 1.75rem; }
      @media (max-width: 1200px) {
        h3, .h3 {
          font-size: calc(1.3rem + 0.6vw) ; } }

    h4, .h4 {
      font-size: 1.5rem; }
      @media (max-width: 1200px) {
        h4, .h4 {
          font-size: calc(1.275rem + 0.3vw) ; } }

    h5, .h5 {
      font-size: 1.25rem; }

    h6, .h6 {
      font-size: 1rem; }

    .lead {
      font-size: 1.25rem;
      font-weight: 400; }

    .display-1 {
      font-size: 6rem;
      font-weight: 700;
      line-height: 1; }
      @media (max-width: 1200px) {
        .display-1 {
          font-size: calc(1.725rem + 5.7vw) ; } }

    .display-2 {
      font-size: 5.5rem;
      font-weight: 700;
      line-height: 1; }
      @media (max-width: 1200px) {
        .display-2 {
          font-size: calc(1.675rem + 5.1vw) ; } }

    .display-3 {
      font-size: 4.5rem;
      font-weight: 700;
      line-height: 1; }
      @media (max-width: 1200px) {
        .display-3 {
          font-size: calc(1.575rem + 3.9vw) ; } }

    .display-4 {
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 1; }
      @media (max-width: 1200px) {
        .display-4 {
          font-size: calc(1.475rem + 2.7vw) ; } }

    hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      border: 0;
      border-top: 1px solid #EAEDF2; }

    small,
    .small {
      font-size: 0.75rem;
      font-weight: 600; }

    mark,
    .mark {
      padding: 0.2em;
      background-color: #FFECBF; }

    .list-unstyled {
      padding-left: 0;
      list-style: none; }

    .list-inline {
      padding-left: 0;
      list-style: none; }

    .list-inline-item {
      display: inline-block; }
      .list-inline-item:not(:last-child) {
        margin-right: 0.5rem; }

    .initialism {
      font-size: 90%;
      text-transform: uppercase; }

    .blockquote {
      margin-bottom: 1rem;
      font-size: 1.25rem; }

    .blockquote-footer {
      display: block;
      font-size: 0.75rem;
      color: #6c757d; }
      .blockquote-footer::before {
        content: "\2014\00A0"; }

    .img-fluid {
      max-width: 100%;
      height: auto; }

    .img-thumbnail {
      padding: 0.25rem;
      background-color: #fff;
      border: 1px solid #EAEDF2;
      border-radius: 0.3125rem;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
      max-width: 100%;
      height: auto; }

    .figure {
      display: inline-block; }

    .figure-img {
      margin-bottom: 0.5rem;
      line-height: 1; }

    .figure-caption {
      font-size: 90%;
      color: #6c757d; }

    code {
      font-size: 87.5%;
      color: #e83e8c;
      word-break: break-word; }
      a > code {
        color: inherit; }

    kbd {
      padding: 0.2rem 0.4rem;
      font-size: 87.5%;
      color: #fff;
      background-color: #2C3038;
      border-radius: 0.3125rem;
      box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
      kbd kbd {
        padding: 0;
        font-size: 100%;
        font-weight: 700;
        box-shadow: none; }

    pre {
      display: block;
      font-size: 87.5%;
      color: #2C3038; }
      pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal; }

    .pre-scrollable {
      max-height: 340px;
      overflow-y: scroll; }

    .container {
      width: 100%;
      padding-right: 12px;
      padding-left: 12px;
      margin-right: auto;
      margin-left: auto; }
      @media (min-width: 576px) {
        .container {
          max-width: 540px; } }
      @media (min-width: 768px) {
        .container {
          max-width: 720px; } }
      @media (min-width: 992px) {
        .container {
          max-width: 960px; } }
      @media (min-width: 1200px) {
        .container {
          max-width: 1140px; } }

    .container-fluid {
      width: 100%;
      padding-right: 12px;
      padding-left: 12px;
      margin-right: auto;
      margin-left: auto; }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -12px;
      margin-left: -12px; }

    .no-gutters {
      margin-right: 0;
      margin-left: 0; }
      .no-gutters > .col,
      .no-gutters > [class*="col-"] {
        padding-right: 0;
        padding-left: 0; }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      position: relative;
      width: 100%;
      padding-right: 12px;
      padding-left: 12px; }

    .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }

    .col-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }

    .col-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }

    .col-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }

    .col-3 {
      flex: 0 0 25%;
      max-width: 25%; }

    .col-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }

    .col-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }

    .col-6 {
      flex: 0 0 50%;
      max-width: 50%; }

    .col-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }

    .col-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }

    .col-9 {
      flex: 0 0 75%;
      max-width: 75%; }

    .col-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }

    .col-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }

    .col-12 {
      flex: 0 0 100%;
      max-width: 100%; }

    .order-first {
      order: -1; }

    .order-last {
      order: 13; }

    .order-0 {
      order: 0; }

    .order-1 {
      order: 1; }

    .order-2 {
      order: 2; }

    .order-3 {
      order: 3; }

    .order-4 {
      order: 4; }

    .order-5 {
      order: 5; }

    .order-6 {
      order: 6; }

    .order-7 {
      order: 7; }

    .order-8 {
      order: 8; }

    .order-9 {
      order: 9; }

    .order-10 {
      order: 10; }

    .order-11 {
      order: 11; }

    .order-12 {
      order: 12; }

    .offset-1 {
      margin-left: 8.33333%; }

    .offset-2 {
      margin-left: 16.66667%; }

    .offset-3 {
      margin-left: 25%; }

    .offset-4 {
      margin-left: 33.33333%; }

    .offset-5 {
      margin-left: 41.66667%; }

    .offset-6 {
      margin-left: 50%; }

    .offset-7 {
      margin-left: 58.33333%; }

    .offset-8 {
      margin-left: 66.66667%; }

    .offset-9 {
      margin-left: 75%; }

    .offset-10 {
      margin-left: 83.33333%; }

    .offset-11 {
      margin-left: 91.66667%; }

    @media (min-width: 576px) {
      .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
      .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%; }
      .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%; }
      .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%; }
      .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%; }
      .order-sm-first {
        order: -1; }
      .order-sm-last {
        order: 13; }
      .order-sm-0 {
        order: 0; }
      .order-sm-1 {
        order: 1; }
      .order-sm-2 {
        order: 2; }
      .order-sm-3 {
        order: 3; }
      .order-sm-4 {
        order: 4; }
      .order-sm-5 {
        order: 5; }
      .order-sm-6 {
        order: 6; }
      .order-sm-7 {
        order: 7; }
      .order-sm-8 {
        order: 8; }
      .order-sm-9 {
        order: 9; }
      .order-sm-10 {
        order: 10; }
      .order-sm-11 {
        order: 11; }
      .order-sm-12 {
        order: 12; }
      .offset-sm-0 {
        margin-left: 0; }
      .offset-sm-1 {
        margin-left: 8.33333%; }
      .offset-sm-2 {
        margin-left: 16.66667%; }
      .offset-sm-3 {
        margin-left: 25%; }
      .offset-sm-4 {
        margin-left: 33.33333%; }
      .offset-sm-5 {
        margin-left: 41.66667%; }
      .offset-sm-6 {
        margin-left: 50%; }
      .offset-sm-7 {
        margin-left: 58.33333%; }
      .offset-sm-8 {
        margin-left: 66.66667%; }
      .offset-sm-9 {
        margin-left: 75%; }
      .offset-sm-10 {
        margin-left: 83.33333%; }
      .offset-sm-11 {
        margin-left: 91.66667%; } }

    @media (min-width: 768px) {
      .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
      .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%; }
      .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%; }
      .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%; }
      .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%; }
      .order-md-first {
        order: -1; }
      .order-md-last {
        order: 13; }
      .order-md-0 {
        order: 0; }
      .order-md-1 {
        order: 1; }
      .order-md-2 {
        order: 2; }
      .order-md-3 {
        order: 3; }
      .order-md-4 {
        order: 4; }
      .order-md-5 {
        order: 5; }
      .order-md-6 {
        order: 6; }
      .order-md-7 {
        order: 7; }
      .order-md-8 {
        order: 8; }
      .order-md-9 {
        order: 9; }
      .order-md-10 {
        order: 10; }
      .order-md-11 {
        order: 11; }
      .order-md-12 {
        order: 12; }
      .offset-md-0 {
        margin-left: 0; }
      .offset-md-1 {
        margin-left: 8.33333%; }
      .offset-md-2 {
        margin-left: 16.66667%; }
      .offset-md-3 {
        margin-left: 25%; }
      .offset-md-4 {
        margin-left: 33.33333%; }
      .offset-md-5 {
        margin-left: 41.66667%; }
      .offset-md-6 {
        margin-left: 50%; }
      .offset-md-7 {
        margin-left: 58.33333%; }
      .offset-md-8 {
        margin-left: 66.66667%; }
      .offset-md-9 {
        margin-left: 75%; }
      .offset-md-10 {
        margin-left: 83.33333%; }
      .offset-md-11 {
        margin-left: 91.66667%; } }

    @media (min-width: 992px) {
      .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
      .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%; }
      .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%; }
      .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%; }
      .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%; }
      .order-lg-first {
        order: -1; }
      .order-lg-last {
        order: 13; }
      .order-lg-0 {
        order: 0; }
      .order-lg-1 {
        order: 1; }
      .order-lg-2 {
        order: 2; }
      .order-lg-3 {
        order: 3; }
      .order-lg-4 {
        order: 4; }
      .order-lg-5 {
        order: 5; }
      .order-lg-6 {
        order: 6; }
      .order-lg-7 {
        order: 7; }
      .order-lg-8 {
        order: 8; }
      .order-lg-9 {
        order: 9; }
      .order-lg-10 {
        order: 10; }
      .order-lg-11 {
        order: 11; }
      .order-lg-12 {
        order: 12; }
      .offset-lg-0 {
        margin-left: 0; }
      .offset-lg-1 {
        margin-left: 8.33333%; }
      .offset-lg-2 {
        margin-left: 16.66667%; }
      .offset-lg-3 {
        margin-left: 25%; }
      .offset-lg-4 {
        margin-left: 33.33333%; }
      .offset-lg-5 {
        margin-left: 41.66667%; }
      .offset-lg-6 {
        margin-left: 50%; }
      .offset-lg-7 {
        margin-left: 58.33333%; }
      .offset-lg-8 {
        margin-left: 66.66667%; }
      .offset-lg-9 {
        margin-left: 75%; }
      .offset-lg-10 {
        margin-left: 83.33333%; }
      .offset-lg-11 {
        margin-left: 91.66667%; } }

    @media (min-width: 1200px) {
      .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
      .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
      .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
      .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%; }
      .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
      .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%; }
      .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
      .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%; }
      .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
      .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
      .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%; }
      .order-xl-first {
        order: -1; }
      .order-xl-last {
        order: 13; }
      .order-xl-0 {
        order: 0; }
      .order-xl-1 {
        order: 1; }
      .order-xl-2 {
        order: 2; }
      .order-xl-3 {
        order: 3; }
      .order-xl-4 {
        order: 4; }
      .order-xl-5 {
        order: 5; }
      .order-xl-6 {
        order: 6; }
      .order-xl-7 {
        order: 7; }
      .order-xl-8 {
        order: 8; }
      .order-xl-9 {
        order: 9; }
      .order-xl-10 {
        order: 10; }
      .order-xl-11 {
        order: 11; }
      .order-xl-12 {
        order: 12; }
      .offset-xl-0 {
        margin-left: 0; }
      .offset-xl-1 {
        margin-left: 8.33333%; }
      .offset-xl-2 {
        margin-left: 16.66667%; }
      .offset-xl-3 {
        margin-left: 25%; }
      .offset-xl-4 {
        margin-left: 33.33333%; }
      .offset-xl-5 {
        margin-left: 41.66667%; }
      .offset-xl-6 {
        margin-left: 50%; }
      .offset-xl-7 {
        margin-left: 58.33333%; }
      .offset-xl-8 {
        margin-left: 66.66667%; }
      .offset-xl-9 {
        margin-left: 75%; }
      .offset-xl-10 {
        margin-left: 83.33333%; }
      .offset-xl-11 {
        margin-left: 91.66667%; } }

    .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #555A64; }
      .table th,
      .table td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #EAEDF2; }
      .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #EAEDF2; }
      .table tbody + tbody {
        border-top: 2px solid #EAEDF2; }

    .table-sm th,
    .table-sm td {
      padding: 0.3rem; }

    .table-bordered {
      border: 1px solid #EAEDF2; }
      .table-bordered th,
      .table-bordered td {
        border: 1px solid #EAEDF2; }
      .table-bordered thead th,
      .table-bordered thead td {
        border-bottom-width: 2px; }

    .table-borderless th,
    .table-borderless td,
    .table-borderless thead th,
    .table-borderless tbody + tbody {
      border: 0; }

    .table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05); }

    .table-hover tbody tr:hover {
      color: #555A64;
      background-color: rgba(0, 0, 0, 0.075); }

    .table-primary,
    .table-primary > th,
    .table-primary > td {
      background-color: #b8e3d8; }

    .table-primary th,
    .table-primary td,
    .table-primary thead th,
    .table-primary tbody + tbody {
      border-color: #7acbb6; }

    .table-hover .table-primary:hover {
      background-color: #a6dcce; }
      .table-hover .table-primary:hover > td,
      .table-hover .table-primary:hover > th {
        background-color: #a6dcce; }

    .table-secondary,
    .table-secondary > th,
    .table-secondary > td {
      background-color: #f9fafb; }

    .table-secondary th,
    .table-secondary td,
    .table-secondary thead th,
    .table-secondary tbody + tbody {
      border-color: #f4f6f8; }

    .table-hover .table-secondary:hover {
      background-color: #eaedf1; }
      .table-hover .table-secondary:hover > td,
      .table-hover .table-secondary:hover > th {
        background-color: #eaedf1; }

    .table-success,
    .table-success > th,
    .table-success > td {
      background-color: #b8e3d8; }

    .table-success th,
    .table-success td,
    .table-success thead th,
    .table-success tbody + tbody {
      border-color: #7acbb6; }

    .table-hover .table-success:hover {
      background-color: #a6dcce; }
      .table-hover .table-success:hover > td,
      .table-hover .table-success:hover > th {
        background-color: #a6dcce; }

    .table-info,
    .table-info > th,
    .table-info > td {
      background-color: #bee5eb; }

    .table-info th,
    .table-info td,
    .table-info thead th,
    .table-info tbody + tbody {
      border-color: #86cfda; }

    .table-hover .table-info:hover {
      background-color: #abdde5; }
      .table-hover .table-info:hover > td,
      .table-hover .table-info:hover > th {
        background-color: #abdde5; }

    .table-warning,
    .table-warning > th,
    .table-warning > td {
      background-color: #ffeeba; }

    .table-warning th,
    .table-warning td,
    .table-warning thead th,
    .table-warning tbody + tbody {
      border-color: #ffdf7e; }

    .table-hover .table-warning:hover {
      background-color: #ffe8a1; }
      .table-hover .table-warning:hover > td,
      .table-hover .table-warning:hover > th {
        background-color: #ffe8a1; }

    .table-danger,
    .table-danger > th,
    .table-danger > td {
      background-color: #f5c6cb; }

    .table-danger th,
    .table-danger td,
    .table-danger thead th,
    .table-danger tbody + tbody {
      border-color: #ed969e; }

    .table-hover .table-danger:hover {
      background-color: #f1b0b7; }
      .table-hover .table-danger:hover > td,
      .table-hover .table-danger:hover > th {
        background-color: #f1b0b7; }

    .table-light,
    .table-light > th,
    .table-light > td {
      background-color: #fdfdfe; }

    .table-light th,
    .table-light td,
    .table-light thead th,
    .table-light tbody + tbody {
      border-color: #fbfcfd; }

    .table-hover .table-light:hover {
      background-color: #ececf6; }
      .table-hover .table-light:hover > td,
      .table-hover .table-light:hover > th {
        background-color: #ececf6; }

    .table-dark,
    .table-dark > th,
    .table-dark > td {
      background-color: #c4c5c7; }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #919398; }

    .table-hover .table-dark:hover {
      background-color: #b7b8bb; }
      .table-hover .table-dark:hover > td,
      .table-hover .table-dark:hover > th {
        background-color: #b7b8bb; }

    .table-primary-2,
    .table-primary-2 > th,
    .table-primary-2 > td {
      background-color: #b9d5d4; }

    .table-primary-2 th,
    .table-primary-2 td,
    .table-primary-2 thead th,
    .table-primary-2 tbody + tbody {
      border-color: #7cb0af; }

    .table-hover .table-primary-2:hover {
      background-color: #a9cbca; }
      .table-hover .table-primary-2:hover > td,
      .table-hover .table-primary-2:hover > th {
        background-color: #a9cbca; }

    .table-primary-3,
    .table-primary-3 > th,
    .table-primary-3 > td {
      background-color: #bfc4c6; }

    .table-primary-3 th,
    .table-primary-3 td,
    .table-primary-3 thead th,
    .table-primary-3 tbody + tbody {
      border-color: #879296; }

    .table-hover .table-primary-3:hover {
      background-color: #b2b8ba; }
      .table-hover .table-primary-3:hover > td,
      .table-hover .table-primary-3:hover > th {
        background-color: #b2b8ba; }

    .table-active,
    .table-active > th,
    .table-active > td {
      background-color: rgba(0, 0, 0, 0.075); }

    .table-hover .table-active:hover {
      background-color: rgba(0, 0, 0, 0.075); }
      .table-hover .table-active:hover > td,
      .table-hover .table-active:hover > th {
        background-color: rgba(0, 0, 0, 0.075); }

    .table .thead-dark th {
      color: #fff;
      background-color: #343a40;
      border-color: #454d55; }

    .table .thead-light th {
      color: #555A64;
      background-color: #F7F9FC;
      border-color: #EAEDF2; }

    .table-dark {
      color: #fff;
      background-color: #343a40; }
      .table-dark th,
      .table-dark td,
      .table-dark thead th {
        border-color: #454d55; }
      .table-dark.table-bordered {
        border: 0; }
      .table-dark.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05); }
      .table-dark.table-hover tbody tr:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.075); }

    @media (max-width: 575.98px) {
      .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-sm > .table-bordered {
          border: 0; } }

    @media (max-width: 767.98px) {
      .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-md > .table-bordered {
          border: 0; } }

    @media (max-width: 991.98px) {
      .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-lg > .table-bordered {
          border: 0; } }

    @media (max-width: 1199.98px) {
      .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
        .table-responsive-xl > .table-bordered {
          border: 0; } }

    .table-responsive {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive > .table-bordered {
        border: 0; }

    .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 1.375rem + 2px);
      padding: 0.6875rem 0.75rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      color: #555A64;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #EAEDF2;
      border-radius: 0.1875rem;
      box-shadow: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .form-control {
          transition: none; } }
      .form-control::-ms-expand {
        background-color: transparent;
        border: 0; }
      .form-control:focus {
        color: #555A64;
        background-color: #fff;
        border-color: #009b72;
        outline: 0;
        box-shadow: none, 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .form-control::-webkit-input-placeholder {
        color: rgba(85, 90, 100, 0.5);
        opacity: 1; }
      .form-control::-moz-placeholder {
        color: rgba(85, 90, 100, 0.5);
        opacity: 1; }
      .form-control:-ms-input-placeholder {
        color: rgba(85, 90, 100, 0.5);
        opacity: 1; }
      .form-control::placeholder {
        color: rgba(85, 90, 100, 0.5);
        opacity: 1; }
      .form-control:disabled, .form-control[readonly] {
        background-color: #EAEDF2;
        opacity: 1; }

    select.form-control:focus::-ms-value {
      color: #555A64;
      background-color: #fff; }

    .form-control-file,
    .form-control-range {
      display: block;
      width: 100%; }

    .col-form-label {
      padding-top: calc(0.6875rem + 1px);
      padding-bottom: calc(0.6875rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.5; }

    .col-form-label-lg {
      padding-top: calc(1.25rem + 1px);
      padding-bottom: calc(1.25rem + 1px);
      font-size: 1.25rem;
      line-height: 1.5; }

    .col-form-label-sm {
      padding-top: calc(0.40625rem + 1px);
      padding-bottom: calc(0.40625rem + 1px);
      font-size: 0.875rem;
      line-height: 1.5; }

    .form-control-plaintext {
      display: block;
      width: 100%;
      padding-top: 0.6875rem;
      padding-bottom: 0.6875rem;
      margin-bottom: 0;
      line-height: 1.5;
      color: #555A64;
      background-color: transparent;
      border: solid transparent;
      border-width: 1px 0; }
      .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
        padding-right: 0;
        padding-left: 0; }

    .form-control-sm {
      height: calc(1.5em + 0.6875rem + 2px);
      padding: 0.40625rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.3125rem; }

    .form-control-lg {
      height: calc(1.5em + 2.375rem + 2px);
      padding: 1.25rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.1875rem; }

    select.form-control[size], select.form-control[multiple] {
      height: auto; }

    textarea.form-control {
      height: auto; }

    .form-group {
      margin-bottom: 1rem; }

    .form-text {
      display: block;
      margin-top: 0.25rem; }

    .form-row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -8px;
      margin-left: -8px; }
      .form-row > .col,
      .form-row > [class*="col-"] {
        padding-right: 8px;
        padding-left: 8px; }

    .form-check {
      position: relative;
      display: block;
      padding-left: 1.25rem; }

    .form-check-input {
      position: absolute;
      margin-top: 0.3rem;
      margin-left: -1.25rem; }
      .form-check-input:disabled ~ .form-check-label {
        color: rgba(85, 90, 100, 0.65); }

    .form-check-label {
      margin-bottom: 0; }

    .form-check-inline {
      display: inline-flex;
      align-items: center;
      padding-left: 0;
      margin-right: 0.75rem; }
      .form-check-inline .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0; }

    .valid-feedback {
      display: none;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 0.75rem;
      color: #009b72; }

    .valid-tooltip {
      position: absolute;
      top: 100%;
      z-index: 5;
      display: none;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      margin-top: .1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #fff;
      background-color: rgba(0, 155, 114, 0.9);
      border-radius: 0.3125rem; }

    .was-validated .form-control:valid, .form-control.is-valid {
      border-color: #009b72;
      padding-right: calc(1.5em + 1.375rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23009b72' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.34375rem);
      background-size: calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem); }
      .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
        border-color: #009b72;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .was-validated .form-control:valid ~ .valid-feedback,
      .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
      .form-control.is-valid ~ .valid-tooltip {
        display: block; }

    .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
      padding-right: calc(1.5em + 1.375rem);
      background-position: top calc(0.375em + 0.34375rem) right calc(0.375em + 0.34375rem); }

    .was-validated .custom-select:valid, .custom-select.is-valid {
      border-color: #009b72;
      padding-right: calc((1em + 1.375rem) * 3 / 4 + 1.75rem);
      background: none no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23009b72' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem); }
      .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
        border-color: #009b72;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .was-validated .custom-select:valid ~ .valid-feedback,
      .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
      .custom-select.is-valid ~ .valid-tooltip {
        display: block; }

    .was-validated .form-control-file:valid ~ .valid-feedback,
    .was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
    .form-control-file.is-valid ~ .valid-tooltip {
      display: block; }

    .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
      color: #009b72; }

    .was-validated .form-check-input:valid ~ .valid-feedback,
    .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
    .form-check-input.is-valid ~ .valid-tooltip {
      display: block; }

    .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
      color: #009b72; }
      .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: #009b72; }

    .was-validated .custom-control-input:valid ~ .valid-feedback,
    .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
    .custom-control-input.is-valid ~ .valid-tooltip {
      display: block; }

    .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
      border-color: #00ce98;
      background-color: #00ce98; }

    .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }

    .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #009b72; }

    .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
      border-color: #009b72; }

    .was-validated .custom-file-input:valid ~ .valid-feedback,
    .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
    .custom-file-input.is-valid ~ .valid-tooltip {
      display: block; }

    .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
      border-color: #009b72;
      box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }

    .invalid-feedback {
      display: none;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 0.75rem;
      color: #dc3545; }

    .invalid-tooltip {
      position: absolute;
      top: 100%;
      z-index: 5;
      display: none;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      margin-top: .1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #fff;
      background-color: rgba(220, 53, 69, 0.9);
      border-radius: 0.3125rem; }

    .was-validated .form-control:invalid, .form-control.is-invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + 1.375rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.34375rem);
      background-size: calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem); }
      .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
      .was-validated .form-control:invalid ~ .invalid-feedback,
      .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
      .form-control.is-invalid ~ .invalid-tooltip {
        display: block; }

    .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
      padding-right: calc(1.5em + 1.375rem);
      background-position: top calc(0.375em + 0.34375rem) right calc(0.375em + 0.34375rem); }

    .was-validated .custom-select:invalid, .custom-select.is-invalid {
      border-color: #dc3545;
      padding-right: calc((1em + 1.375rem) * 3 / 4 + 1.75rem);
      background: none no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem); }
      .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
      .was-validated .custom-select:invalid ~ .invalid-feedback,
      .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
      .custom-select.is-invalid ~ .invalid-tooltip {
        display: block; }

    .was-validated .form-control-file:invalid ~ .invalid-feedback,
    .was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
    .form-control-file.is-invalid ~ .invalid-tooltip {
      display: block; }

    .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
      color: #dc3545; }

    .was-validated .form-check-input:invalid ~ .invalid-feedback,
    .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
    .form-check-input.is-invalid ~ .invalid-tooltip {
      display: block; }

    .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
      color: #dc3545; }
      .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
        border-color: #dc3545; }

    .was-validated .custom-control-input:invalid ~ .invalid-feedback,
    .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
    .custom-control-input.is-invalid ~ .invalid-tooltip {
      display: block; }

    .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
      border-color: #e4606d;
      background-color: #e4606d; }

    .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

    .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #dc3545; }

    .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
      border-color: #dc3545; }

    .was-validated .custom-file-input:invalid ~ .invalid-feedback,
    .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
    .custom-file-input.is-invalid ~ .invalid-tooltip {
      display: block; }

    .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

    .form-inline {
      display: flex;
      flex-flow: row wrap;
      align-items: center; }
      .form-inline .form-check {
        width: 100%; }
      @media (min-width: 576px) {
        .form-inline label {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0; }
        .form-inline .form-group {
          display: flex;
          flex: 0 0 auto;
          flex-flow: row wrap;
          align-items: center;
          margin-bottom: 0; }
        .form-inline .form-control {
          display: inline-block;
          width: auto;
          vertical-align: middle; }
        .form-inline .form-control-plaintext {
          display: inline-block; }
        .form-inline .input-group,
        .form-inline .custom-select {
          width: auto; }
        .form-inline .form-check {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          padding-left: 0; }
        .form-inline .form-check-input {
          position: relative;
          flex-shrink: 0;
          margin-top: 0;
          margin-right: 0.25rem;
          margin-left: 0; }
        .form-inline .custom-control {
          align-items: center;
          justify-content: center; }
        .form-inline .custom-control-label {
          margin-bottom: 0; } }

    .btn {
      display: inline-block;
      font-weight: 700;
      color: #555A64;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      background-color: transparent;
      border: 2px solid transparent;
      padding: 0.625rem 1.5rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 100px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .btn {
          transition: none; } }
      .btn:hover {
        color: #555A64;
        text-decoration: none; }
      .btn:focus, .btn.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .btn.disabled, .btn:disabled {
        opacity: 0.3;
        box-shadow: none; }
      .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
        box-shadow: none; }
        .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }

    a.btn.disabled,
    fieldset:disabled a.btn {
      pointer-events: none; }

    .btn-primary {
      color: #fff;
      background-color: #009b72;
      border-color: #009b72;
      box-shadow: none; }
      .btn-primary:hover {
        color: #fff;
        background-color: #007556;
        border-color: #00684c; }
      .btn-primary:focus, .btn-primary.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(38, 170, 135, 0.5); }
      .btn-primary.disabled, .btn-primary:disabled {
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }
      .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #00684c;
        border-color: #005b43; }
        .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(38, 170, 135, 0.5); }

    .btn-secondary {
      color: #2C3038;
      background-color: #EAEDF2;
      border-color: #EAEDF2;
      box-shadow: none; }
      .btn-secondary:hover {
        color: #2C3038;
        background-color: #d2d9e3;
        border-color: #cbd2df; }
      .btn-secondary:focus, .btn-secondary.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(206, 209, 214, 0.5); }
      .btn-secondary.disabled, .btn-secondary:disabled {
        color: #2C3038;
        background-color: #EAEDF2;
        border-color: #EAEDF2; }
      .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
      .show > .btn-secondary.dropdown-toggle {
        color: #2C3038;
        background-color: #cbd2df;
        border-color: #c3cbda; }
        .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-secondary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(206, 209, 214, 0.5); }

    .btn-success {
      color: #fff;
      background-color: #009b72;
      border-color: #009b72;
      box-shadow: none; }
      .btn-success:hover {
        color: #fff;
        background-color: #007556;
        border-color: #00684c; }
      .btn-success:focus, .btn-success.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(38, 170, 135, 0.5); }
      .btn-success.disabled, .btn-success:disabled {
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }
      .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
      .show > .btn-success.dropdown-toggle {
        color: #fff;
        background-color: #00684c;
        border-color: #005b43; }
        .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
        .show > .btn-success.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(38, 170, 135, 0.5); }

    .btn-info {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
      box-shadow: none; }
      .btn-info:hover {
        color: #fff;
        background-color: #138496;
        border-color: #117a8b; }
      .btn-info:focus, .btn-info.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
      .btn-info.disabled, .btn-info:disabled {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8; }
      .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
      .show > .btn-info.dropdown-toggle {
        color: #fff;
        background-color: #117a8b;
        border-color: #10707f; }
        .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
        .show > .btn-info.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

    .btn-warning {
      color: #2C3038;
      background-color: #ffc107;
      border-color: #ffc107;
      box-shadow: none; }
      .btn-warning:hover {
        color: #2C3038;
        background-color: #e0a800;
        border-color: #d39e00; }
      .btn-warning:focus, .btn-warning.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(223, 171, 14, 0.5); }
      .btn-warning.disabled, .btn-warning:disabled {
        color: #2C3038;
        background-color: #ffc107;
        border-color: #ffc107; }
      .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
      .show > .btn-warning.dropdown-toggle {
        color: #2C3038;
        background-color: #d39e00;
        border-color: #c69500; }
        .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
        .show > .btn-warning.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(223, 171, 14, 0.5); }

    .btn-danger {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
      box-shadow: none; }
      .btn-danger:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130; }
      .btn-danger:focus, .btn-danger.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
      .btn-danger.disabled, .btn-danger:disabled {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545; }
      .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
      .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d; }
        .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
        .show > .btn-danger.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

    .btn-light {
      color: #2C3038;
      background-color: #F7F9FC;
      border-color: #F7F9FC;
      box-shadow: none; }
      .btn-light:hover {
        color: #2C3038;
        background-color: #dbe4f2;
        border-color: #d2ddee; }
      .btn-light:focus, .btn-light.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(217, 219, 223, 0.5); }
      .btn-light.disabled, .btn-light:disabled {
        color: #2C3038;
        background-color: #F7F9FC;
        border-color: #F7F9FC; }
      .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
      .show > .btn-light.dropdown-toggle {
        color: #2C3038;
        background-color: #d2ddee;
        border-color: #c9d6eb; }
        .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
        .show > .btn-light.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(217, 219, 223, 0.5); }

    .btn-dark {
      color: #fff;
      background-color: #2C3038;
      border-color: #2C3038;
      box-shadow: none; }
      .btn-dark:hover {
        color: #fff;
        background-color: #1b1e23;
        border-color: #16181b; }
      .btn-dark:focus, .btn-dark.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(76, 79, 86, 0.5); }
      .btn-dark.disabled, .btn-dark:disabled {
        color: #fff;
        background-color: #2C3038;
        border-color: #2C3038; }
      .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
      .show > .btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #16181b;
        border-color: #101114; }
        .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
        .show > .btn-dark.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(76, 79, 86, 0.5); }

    .btn-primary-2 {
      color: #fff;
      background-color: #046865;
      border-color: #046865;
      box-shadow: none; }
      .btn-primary-2:hover {
        color: #fff;
        background-color: #034341;
        border-color: #023735; }
      .btn-primary-2:focus, .btn-primary-2.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(42, 127, 124, 0.5); }
      .btn-primary-2.disabled, .btn-primary-2:disabled {
        color: #fff;
        background-color: #046865;
        border-color: #046865; }
      .btn-primary-2:not(:disabled):not(.disabled):active, .btn-primary-2:not(:disabled):not(.disabled).active,
      .show > .btn-primary-2.dropdown-toggle {
        color: #fff;
        background-color: #023735;
        border-color: #022b29; }
        .btn-primary-2:not(:disabled):not(.disabled):active:focus, .btn-primary-2:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary-2.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(42, 127, 124, 0.5); }

    .btn-primary-3 {
      color: #fff;
      background-color: #192d35;
      border-color: #192d35;
      box-shadow: none; }
      .btn-primary-3:hover {
        color: #fff;
        background-color: #0d171b;
        border-color: #091012; }
      .btn-primary-3:focus, .btn-primary-3.focus {
        box-shadow: none, 0 0 0 0.2rem rgba(60, 77, 83, 0.5); }
      .btn-primary-3.disabled, .btn-primary-3:disabled {
        color: #fff;
        background-color: #192d35;
        border-color: #192d35; }
      .btn-primary-3:not(:disabled):not(.disabled):active, .btn-primary-3:not(:disabled):not(.disabled).active,
      .show > .btn-primary-3.dropdown-toggle {
        color: #fff;
        background-color: #091012;
        border-color: #05080a; }
        .btn-primary-3:not(:disabled):not(.disabled):active:focus, .btn-primary-3:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary-3.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(60, 77, 83, 0.5); }

    .btn-outline-primary {
      color: #009b72;
      border-color: #009b72; }
      .btn-outline-primary:hover {
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }
      .btn-outline-primary:focus, .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.5); }
      .btn-outline-primary.disabled, .btn-outline-primary:disabled {
        color: #009b72;
        background-color: transparent; }
      .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
      .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }
        .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-primary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.5); }

    .btn-outline-secondary {
      color: #EAEDF2;
      border-color: #EAEDF2; }
      .btn-outline-secondary:hover {
        color: #2C3038;
        background-color: #EAEDF2;
        border-color: #EAEDF2; }
      .btn-outline-secondary:focus, .btn-outline-secondary.focus {
        box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5); }
      .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
        color: #EAEDF2;
        background-color: transparent; }
      .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
      .show > .btn-outline-secondary.dropdown-toggle {
        color: #2C3038;
        background-color: #EAEDF2;
        border-color: #EAEDF2; }
        .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-secondary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5); }

    .btn-outline-success {
      color: #009b72;
      border-color: #009b72; }
      .btn-outline-success:hover {
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }
      .btn-outline-success:focus, .btn-outline-success.focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.5); }
      .btn-outline-success.disabled, .btn-outline-success:disabled {
        color: #009b72;
        background-color: transparent; }
      .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
      .show > .btn-outline-success.dropdown-toggle {
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }
        .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-success.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.5); }

    .btn-outline-info {
      color: #17a2b8;
      border-color: #17a2b8; }
      .btn-outline-info:hover {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8; }
      .btn-outline-info:focus, .btn-outline-info.focus {
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
      .btn-outline-info.disabled, .btn-outline-info:disabled {
        color: #17a2b8;
        background-color: transparent; }
      .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
      .show > .btn-outline-info.dropdown-toggle {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8; }
        .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-info.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

    .btn-outline-warning {
      color: #ffc107;
      border-color: #ffc107; }
      .btn-outline-warning:hover {
        color: #2C3038;
        background-color: #ffc107;
        border-color: #ffc107; }
      .btn-outline-warning:focus, .btn-outline-warning.focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
      .btn-outline-warning.disabled, .btn-outline-warning:disabled {
        color: #ffc107;
        background-color: transparent; }
      .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
      .show > .btn-outline-warning.dropdown-toggle {
        color: #2C3038;
        background-color: #ffc107;
        border-color: #ffc107; }
        .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-warning.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

    .btn-outline-danger {
      color: #dc3545;
      border-color: #dc3545; }
      .btn-outline-danger:hover {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545; }
      .btn-outline-danger:focus, .btn-outline-danger.focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
      .btn-outline-danger.disabled, .btn-outline-danger:disabled {
        color: #dc3545;
        background-color: transparent; }
      .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
      .show > .btn-outline-danger.dropdown-toggle {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545; }
        .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-danger.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

    .btn-outline-light {
      color: #F7F9FC;
      border-color: #F7F9FC; }
      .btn-outline-light:hover {
        color: #2C3038;
        background-color: #F7F9FC;
        border-color: #F7F9FC; }
      .btn-outline-light:focus, .btn-outline-light.focus {
        box-shadow: 0 0 0 0.2rem rgba(247, 249, 252, 0.5); }
      .btn-outline-light.disabled, .btn-outline-light:disabled {
        color: #F7F9FC;
        background-color: transparent; }
      .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
      .show > .btn-outline-light.dropdown-toggle {
        color: #2C3038;
        background-color: #F7F9FC;
        border-color: #F7F9FC; }
        .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-light.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(247, 249, 252, 0.5); }

    .btn-outline-dark {
      color: #2C3038;
      border-color: #2C3038; }
      .btn-outline-dark:hover {
        color: #fff;
        background-color: #2C3038;
        border-color: #2C3038; }
      .btn-outline-dark:focus, .btn-outline-dark.focus {
        box-shadow: 0 0 0 0.2rem rgba(44, 48, 56, 0.5); }
      .btn-outline-dark.disabled, .btn-outline-dark:disabled {
        color: #2C3038;
        background-color: transparent; }
      .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
      .show > .btn-outline-dark.dropdown-toggle {
        color: #fff;
        background-color: #2C3038;
        border-color: #2C3038; }
        .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-dark.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(44, 48, 56, 0.5); }

    .btn-outline-primary-2 {
      color: #046865;
      border-color: #046865; }
      .btn-outline-primary-2:hover {
        color: #fff;
        background-color: #046865;
        border-color: #046865; }
      .btn-outline-primary-2:focus, .btn-outline-primary-2.focus {
        box-shadow: 0 0 0 0.2rem rgba(4, 104, 101, 0.5); }
      .btn-outline-primary-2.disabled, .btn-outline-primary-2:disabled {
        color: #046865;
        background-color: transparent; }
      .btn-outline-primary-2:not(:disabled):not(.disabled):active, .btn-outline-primary-2:not(:disabled):not(.disabled).active,
      .show > .btn-outline-primary-2.dropdown-toggle {
        color: #fff;
        background-color: #046865;
        border-color: #046865; }
        .btn-outline-primary-2:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-2:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-primary-2.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(4, 104, 101, 0.5); }

    .btn-outline-primary-3 {
      color: #192d35;
      border-color: #192d35; }
      .btn-outline-primary-3:hover {
        color: #fff;
        background-color: #192d35;
        border-color: #192d35; }
      .btn-outline-primary-3:focus, .btn-outline-primary-3.focus {
        box-shadow: 0 0 0 0.2rem rgba(25, 45, 53, 0.5); }
      .btn-outline-primary-3.disabled, .btn-outline-primary-3:disabled {
        color: #192d35;
        background-color: transparent; }
      .btn-outline-primary-3:not(:disabled):not(.disabled):active, .btn-outline-primary-3:not(:disabled):not(.disabled).active,
      .show > .btn-outline-primary-3.dropdown-toggle {
        color: #fff;
        background-color: #192d35;
        border-color: #192d35; }
        .btn-outline-primary-3:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-3:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-primary-3.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(25, 45, 53, 0.5); }

    .btn-link {
      font-weight: 600;
      color: #009b72;
      text-decoration: none; }
      .btn-link:hover {
        color: #004f3a;
        text-decoration: none; }
      .btn-link:focus, .btn-link.focus {
        text-decoration: none;
        box-shadow: none; }
      .btn-link:disabled, .btn-link.disabled {
        color: #6c757d;
        pointer-events: none; }

    .btn-lg, .btn-group-lg > .btn {
      padding: 1.1875rem 2.25rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 100px; }

    .btn-sm, .btn-group-sm > .btn {
      padding: 0.34375rem 1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 100px; }

    .btn-block {
      display: block;
      width: 100%; }
      .btn-block + .btn-block {
        margin-top: 0.5rem; }

    input[type="submit"].btn-block,
    input[type="reset"].btn-block,
    input[type="button"].btn-block {
      width: 100%; }

    .fade {
      transition: opacity 0.15s linear; }
      @media (prefers-reduced-motion: reduce) {
        .fade {
          transition: none; } }
      .fade:not(.show) {
        opacity: 0; }

    .collapse:not(.show) {
      display: none; }

    .collapsing {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: height 0.35s ease; }
      @media (prefers-reduced-motion: reduce) {
        .collapsing {
          transition: none; } }

    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
      position: relative; }

    .dropdown-toggle {
      white-space: nowrap; }
      .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent; }
      .dropdown-toggle:empty::after {
        margin-left: 0; }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: 1rem 0;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #555A64;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #EAEDF2;
      border-radius: 0.3125rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09); }

    .dropdown-menu-left {
      right: auto;
      left: 0; }

    .dropdown-menu-right {
      right: 0;
      left: auto; }

    @media (min-width: 576px) {
      .dropdown-menu-sm-left {
        right: auto;
        left: 0; }
      .dropdown-menu-sm-right {
        right: 0;
        left: auto; } }

    @media (min-width: 768px) {
      .dropdown-menu-md-left {
        right: auto;
        left: 0; }
      .dropdown-menu-md-right {
        right: 0;
        left: auto; } }

    @media (min-width: 992px) {
      .dropdown-menu-lg-left {
        right: auto;
        left: 0; }
      .dropdown-menu-lg-right {
        right: 0;
        left: auto; } }

    @media (min-width: 1200px) {
      .dropdown-menu-xl-left {
        right: auto;
        left: 0; }
      .dropdown-menu-xl-right {
        right: 0;
        left: auto; } }

    .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 0.125rem; }

    .dropup .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent; }

    .dropup .dropdown-toggle:empty::after {
      margin-left: 0; }

    .dropright .dropdown-menu {
      top: 0;
      right: auto;
      left: 100%;
      margin-top: 0;
      margin-left: 0.125rem; }

    .dropright .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid; }

    .dropright .dropdown-toggle:empty::after {
      margin-left: 0; }

    .dropright .dropdown-toggle::after {
      vertical-align: 0; }

    .dropleft .dropdown-menu {
      top: 0;
      right: 100%;
      left: auto;
      margin-top: 0;
      margin-right: 0.125rem; }

    .dropleft .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: ""; }

    .dropleft .dropdown-toggle::after {
      display: none; }

    .dropleft .dropdown-toggle::before {
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent; }

    .dropleft .dropdown-toggle:empty::after {
      margin-left: 0; }

    .dropleft .dropdown-toggle::before {
      vertical-align: 0; }

    .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
      right: auto;
      bottom: auto; }

    .dropdown-divider {
      height: 0;
      margin: 0.5rem 0;
      overflow: hidden;
      border-top: 1px solid #EAEDF2; }

    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1.5rem;
      clear: both;
      font-weight: 600;
      color: rgba(85, 90, 100, 0.6);
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0; }
      .dropdown-item:hover, .dropdown-item:focus {
        color: #009b72;
        text-decoration: none;
        background-color: none; }
      .dropdown-item.active, .dropdown-item:active {
        color: #009b72;
        text-decoration: none;
        background-color: rgba(0, 155, 114, 0.1); }
      .dropdown-item.disabled, .dropdown-item:disabled {
        color: rgba(85, 90, 100, 0.25);
        pointer-events: none;
        background-color: transparent; }

    .dropdown-menu.show {
      display: block; }

    .dropdown-header {
      display: block;
      padding: 1rem 1.5rem;
      margin-bottom: 0;
      font-size: 0.875rem;
      color: #2C3038;
      white-space: nowrap; }

    .dropdown-item-text {
      display: block;
      padding: 0.25rem 1.5rem;
      color: rgba(85, 90, 100, 0.6); }

    .btn-group,
    .btn-group-vertical {
      position: relative;
      display: inline-flex;
      vertical-align: middle; }
      .btn-group > .btn,
      .btn-group-vertical > .btn {
        position: relative;
        flex: 1 1 auto; }
        .btn-group > .btn:hover,
        .btn-group-vertical > .btn:hover {
          z-index: 1; }
        .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
        .btn-group-vertical > .btn:focus,
        .btn-group-vertical > .btn:active,
        .btn-group-vertical > .btn.active {
          z-index: 1; }

    .btn-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; }
      .btn-toolbar .input-group {
        width: auto; }

    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) {
      margin-left: -2px; }

    .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

    .dropdown-toggle-split {
      padding-right: 1.125rem;
      padding-left: 1.125rem; }
      .dropdown-toggle-split::after,
      .dropup .dropdown-toggle-split::after,
      .dropright .dropdown-toggle-split::after {
        margin-left: 0; }
      .dropleft .dropdown-toggle-split::before {
        margin-right: 0; }

    .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }

    .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
      padding-right: 1.6875rem;
      padding-left: 1.6875rem; }

    .btn-group.show .dropdown-toggle {
      box-shadow: none; }
      .btn-group.show .dropdown-toggle.btn-link {
        box-shadow: none; }

    .btn-group-vertical {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; }
      .btn-group-vertical > .btn,
      .btn-group-vertical > .btn-group {
        width: 100%; }
      .btn-group-vertical > .btn:not(:first-child),
      .btn-group-vertical > .btn-group:not(:first-child) {
        margin-top: -2px; }
      .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
      .btn-group-vertical > .btn-group:not(:last-child) > .btn {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .btn-group-vertical > .btn:not(:first-child),
      .btn-group-vertical > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }

    .btn-group-toggle > .btn,
    .btn-group-toggle > .btn-group > .btn {
      margin-bottom: 0; }
      .btn-group-toggle > .btn input[type="radio"],
      .btn-group-toggle > .btn input[type="checkbox"],
      .btn-group-toggle > .btn-group > .btn input[type="radio"],
      .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none; }

    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%; }
      .input-group > .form-control,
      .input-group > .form-control-plaintext,
      .input-group > .custom-select,
      .input-group > .custom-file {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0; }
        .input-group > .form-control + .form-control,
        .input-group > .form-control + .custom-select,
        .input-group > .form-control + .custom-file,
        .input-group > .form-control-plaintext + .form-control,
        .input-group > .form-control-plaintext + .custom-select,
        .input-group > .form-control-plaintext + .custom-file,
        .input-group > .custom-select + .form-control,
        .input-group > .custom-select + .custom-select,
        .input-group > .custom-select + .custom-file,
        .input-group > .custom-file + .form-control,
        .input-group > .custom-file + .custom-select,
        .input-group > .custom-file + .custom-file {
          margin-left: -1px; }
      .input-group > .form-control:focus,
      .input-group > .custom-select:focus,
      .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
        z-index: 3; }
      .input-group > .custom-file .custom-file-input:focus {
        z-index: 4; }
      .input-group > .form-control:not(:last-child),
      .input-group > .custom-select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .input-group > .form-control:not(:first-child),
      .input-group > .custom-select:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .input-group > .custom-file {
        display: flex;
        align-items: center; }
        .input-group > .custom-file:not(:last-child) .custom-file-label,
        .input-group > .custom-file:not(:last-child) .custom-file-label::after {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
        .input-group > .custom-file:not(:first-child) .custom-file-label {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

    .input-group-prepend,
    .input-group-append {
      display: flex; }
      .input-group-prepend .btn,
      .input-group-append .btn {
        position: relative;
        z-index: 2; }
        .input-group-prepend .btn:focus,
        .input-group-append .btn:focus {
          z-index: 3; }
      .input-group-prepend .btn + .btn,
      .input-group-prepend .btn + .input-group-text,
      .input-group-prepend .input-group-text + .input-group-text,
      .input-group-prepend .input-group-text + .btn,
      .input-group-append .btn + .btn,
      .input-group-append .btn + .input-group-text,
      .input-group-append .input-group-text + .input-group-text,
      .input-group-append .input-group-text + .btn {
        margin-left: -1px; }

    .input-group-prepend {
      margin-right: -1px; }

    .input-group-append {
      margin-left: -1px; }

    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.6875rem 0.75rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      color: #555A64;
      text-align: center;
      white-space: nowrap;
      background-color: #EAEDF2;
      border: 1px solid #EAEDF2;
      border-radius: 0.1875rem; }
      .input-group-text input[type="radio"],
      .input-group-text input[type="checkbox"] {
        margin-top: 0; }

    .input-group-lg > .form-control:not(textarea),
    .input-group-lg > .custom-select {
      height: calc(1.5em + 2.375rem + 2px); }

    .input-group-lg > .form-control,
    .input-group-lg > .custom-select,
    .input-group-lg > .input-group-prepend > .input-group-text,
    .input-group-lg > .input-group-append > .input-group-text,
    .input-group-lg > .input-group-prepend > .btn,
    .input-group-lg > .input-group-append > .btn {
      padding: 1.25rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.1875rem; }

    .input-group-sm > .form-control:not(textarea),
    .input-group-sm > .custom-select {
      height: calc(1.5em + 0.6875rem + 2px); }

    .input-group-sm > .form-control,
    .input-group-sm > .custom-select,
    .input-group-sm > .input-group-prepend > .input-group-text,
    .input-group-sm > .input-group-append > .input-group-text,
    .input-group-sm > .input-group-prepend > .btn,
    .input-group-sm > .input-group-append > .btn {
      padding: 0.40625rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.3125rem; }

    .input-group-lg > .custom-select,
    .input-group-sm > .custom-select {
      padding-right: 1.75rem; }

    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

    .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

    .custom-control {
      position: relative;
      display: block;
      min-height: 1.5rem;
      padding-left: 1.75rem; }

    .custom-control-inline {
      display: inline-flex;
      margin-right: 1rem; }

    .custom-control-input {
      position: absolute;
      z-index: -1;
      opacity: 0; }
      .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #009b72;
        background-color: #009b72;
        box-shadow: none; }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none, 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #009b72; }
      .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        color: #fff;
        background-color: #4fffd0;
        border-color: #4fffd0;
        box-shadow: none; }
      .custom-control-input:disabled ~ .custom-control-label {
        color: #6c757d; }
        .custom-control-input:disabled ~ .custom-control-label::before {
          background-color: #EAEDF2; }

    .custom-control-label {
      position: relative;
      margin-bottom: 0;
      vertical-align: top; }
      .custom-control-label::before {
        position: absolute;
        top: 0.125rem;
        left: -1.75rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #cbd2df solid 1px;
        box-shadow: none; }
      .custom-control-label::after {
        position: absolute;
        top: 0.125rem;
        left: -1.75rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        background: no-repeat 50% / 50% 50%; }

    .custom-checkbox .custom-control-label::before {
      border-radius: 0.1875rem; }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
      border-color: #009b72;
      background-color: #009b72;
      box-shadow: none; }

    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

    .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(0, 155, 114, 0.5); }

    .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
      background-color: rgba(0, 155, 114, 0.5); }

    .custom-radio .custom-control-label::before {
      border-radius: 50%; }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

    .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(0, 155, 114, 0.5); }

    .custom-switch {
      padding-left: 3.5rem; }
      .custom-switch .custom-control-label::before {
        left: -3.5rem;
        width: 3rem;
        pointer-events: all;
        border-radius: 0.625rem; }
      .custom-switch .custom-control-label::after {
        top: calc(0.125rem + 2px);
        left: calc(-3.5rem + 2px);
        width: calc(1.25rem - 4px);
        height: calc(1.25rem - 4px);
        background-color: #cbd2df;
        border-radius: 0.625rem;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        @media (prefers-reduced-motion: reduce) {
          .custom-switch .custom-control-label::after {
            transition: none; } }
      .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        transform: translateX(1.75rem); }
      .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(0, 155, 114, 0.5); }

    .custom-select {
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 1.375rem + 2px);
      padding: 0.6875rem 1.75rem 0.6875rem 0.75rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      color: #555A64;
      vertical-align: middle;
      background: none no-repeat right 0.75rem center/8px 10px;
      background-color: #fff;
      border: 1px solid #EAEDF2;
      border-radius: 0.3125rem;
      box-shadow: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none; }
      .custom-select:focus {
        border-color: #009b72;
        outline: 0;
        box-shadow: none, 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
        .custom-select:focus::-ms-value {
          color: #555A64;
          background-color: #fff; }
      .custom-select[multiple], .custom-select[size]:not([size="1"]) {
        height: auto;
        padding-right: 0.75rem;
        background-image: none; }
      .custom-select:disabled {
        color: #6c757d;
        background-color: #F7F9FC; }
      .custom-select::-ms-expand {
        display: none; }

    .custom-select-sm {
      height: calc(1.5em + 0.6875rem + 2px);
      padding-top: 0.40625rem;
      padding-bottom: 0.40625rem;
      padding-left: 0.5rem;
      font-size: 0.875rem; }

    .custom-select-lg {
      height: calc(1.5em + 2.375rem + 2px);
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      padding-left: 1rem;
      font-size: 1.25rem; }

    .custom-file {
      position: relative;
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 1.375rem + 2px);
      margin-bottom: 0; }

    .custom-file-input {
      position: relative;
      z-index: 2;
      width: 100%;
      height: calc(1.5em + 1.375rem + 2px);
      margin: 0;
      opacity: 0; }
      .custom-file-input:focus ~ .custom-file-label {
        border-color: #009b72;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .custom-file-input:disabled ~ .custom-file-label {
        background-color: #EAEDF2; }
      .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: "Browse"; }
      .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: attr(data-browse); }

    .custom-file-label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      height: calc(1.5em + 1.375rem + 2px);
      padding: 0.6875rem 0.75rem;
      font-weight: 600;
      line-height: 1.5;
      color: #555A64;
      background-color: #fff;
      border: 1px solid #EAEDF2;
      border-radius: 0.1875rem;
      box-shadow: none; }
      .custom-file-label::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.5em + 1.375rem);
        padding: 0.6875rem 0.75rem;
        line-height: 1.5;
        color: #fff;
        content: "Browse";
        background-color: #009b72;
        border-left: inherit;
        border-radius: 0 0.1875rem 0.1875rem 0; }

    .custom-range {
      width: 100%;
      height: calc(1rem + 0.4rem);
      padding: 0;
      background-color: transparent;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none; }
      .custom-range:focus {
        outline: none; }
        .custom-range:focus::-webkit-slider-thumb {
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
        .custom-range:focus::-moz-range-thumb {
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
        .custom-range:focus::-ms-thumb {
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }
      .custom-range::-moz-focus-outer {
        border: 0; }
      .custom-range::-webkit-slider-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: -0.25rem;
        background-color: #009b72;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
                appearance: none; }
        @media (prefers-reduced-motion: reduce) {
          .custom-range::-webkit-slider-thumb {
            transition: none; } }
        .custom-range::-webkit-slider-thumb:active {
          background-color: #4fffd0; }
      .custom-range::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #EAEDF2;
        border-color: transparent;
        border-radius: 1rem;
        box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
      .custom-range::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        background-color: #009b72;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -moz-appearance: none;
             appearance: none; }
        @media (prefers-reduced-motion: reduce) {
          .custom-range::-moz-range-thumb {
            transition: none; } }
        .custom-range::-moz-range-thumb:active {
          background-color: #4fffd0; }
      .custom-range::-moz-range-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #EAEDF2;
        border-color: transparent;
        border-radius: 1rem;
        box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
      .custom-range::-ms-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: 0;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        background-color: #009b72;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none; }
        @media (prefers-reduced-motion: reduce) {
          .custom-range::-ms-thumb {
            transition: none; } }
        .custom-range::-ms-thumb:active {
          background-color: #4fffd0; }
      .custom-range::-ms-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: transparent;
        border-color: transparent;
        border-width: 0.5rem;
        box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
      .custom-range::-ms-fill-lower {
        background-color: #EAEDF2;
        border-radius: 1rem; }
      .custom-range::-ms-fill-upper {
        margin-right: 15px;
        background-color: #EAEDF2;
        border-radius: 1rem; }
      .custom-range:disabled::-webkit-slider-thumb {
        background-color: #adb5bd; }
      .custom-range:disabled::-webkit-slider-runnable-track {
        cursor: default; }
      .custom-range:disabled::-moz-range-thumb {
        background-color: #adb5bd; }
      .custom-range:disabled::-moz-range-track {
        cursor: default; }
      .custom-range:disabled::-ms-thumb {
        background-color: #adb5bd; }

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .custom-control-label::before,
        .custom-file-label,
        .custom-select {
          transition: none; } }

    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }

    .nav-link {
      display: block;
      padding: 0.5rem 1rem; }
      .nav-link:hover, .nav-link:focus {
        text-decoration: none; }
      .nav-link.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default; }

    .nav-tabs {
      border-bottom: 0 solid #EAEDF2; }
      .nav-tabs .nav-item {
        margin-bottom: 0; }
      .nav-tabs .nav-link {
        border: 0 solid transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
          border-color: none; }
        .nav-tabs .nav-link.disabled {
          color: #6c757d;
          background-color: transparent;
          border-color: transparent; }
      .nav-tabs .nav-link.active,
      .nav-tabs .nav-item.show .nav-link {
        color: #009b72;
        background-color: none;
        border-color: none; }
      .nav-tabs .dropdown-menu {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }

    .nav-pills .nav-link {
      border-radius: 0.3125rem; }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff;
      background-color: #009b72; }

    .nav-fill .nav-item {
      flex: 1 1 auto;
      text-align: center; }

    .nav-justified .nav-item {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center; }

    .tab-content > .tab-pane {
      display: none; }

    .tab-content > .active {
      display: block; }

    .navbar {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem; }
      .navbar > .container,
      .navbar > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between; }

    .navbar-brand {
      display: inline-block;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-right: 1rem;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap; }
      .navbar-brand:hover, .navbar-brand:focus {
        text-decoration: none; }

    .navbar-nav {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
      .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0; }
      .navbar-nav .dropdown-menu {
        position: static;
        float: none; }

    .navbar-text {
      display: inline-block;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }

    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center; }

    .navbar-toggler {
      padding: 0 0;
      font-size: 1.25rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 100px; }
      .navbar-toggler:hover, .navbar-toggler:focus {
        text-decoration: none; }

    .navbar-toggler-icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      content: "";
      background: no-repeat center center;
      background-size: 100% 100%; }

    @media (max-width: 575.98px) {
      .navbar-expand-sm > .container,
      .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }

    @media (min-width: 576px) {
      .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start; }
        .navbar-expand-sm .navbar-nav {
          flex-direction: row; }
          .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: 0.75rem;
            padding-left: 0.75rem; }
        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
          flex-wrap: nowrap; }
        .navbar-expand-sm .navbar-collapse {
          display: flex !important;
          flex-basis: auto; }
        .navbar-expand-sm .navbar-toggler {
          display: none; } }

    @media (max-width: 767.98px) {
      .navbar-expand-md > .container,
      .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }

    @media (min-width: 768px) {
      .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start; }
        .navbar-expand-md .navbar-nav {
          flex-direction: row; }
          .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 0.75rem;
            padding-left: 0.75rem; }
        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
          flex-wrap: nowrap; }
        .navbar-expand-md .navbar-collapse {
          display: flex !important;
          flex-basis: auto; }
        .navbar-expand-md .navbar-toggler {
          display: none; } }

    @media (max-width: 991.98px) {
      .navbar-expand-lg > .container,
      .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }

    @media (min-width: 992px) {
      .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start; }
        .navbar-expand-lg .navbar-nav {
          flex-direction: row; }
          .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.75rem;
            padding-left: 0.75rem; }
        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
          flex-wrap: nowrap; }
        .navbar-expand-lg .navbar-collapse {
          display: flex !important;
          flex-basis: auto; }
        .navbar-expand-lg .navbar-toggler {
          display: none; } }

    @media (max-width: 1199.98px) {
      .navbar-expand-xl > .container,
      .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }

    @media (min-width: 1200px) {
      .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start; }
        .navbar-expand-xl .navbar-nav {
          flex-direction: row; }
          .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute; }
          .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: 0.75rem;
            padding-left: 0.75rem; }
        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
          flex-wrap: nowrap; }
        .navbar-expand-xl .navbar-collapse {
          display: flex !important;
          flex-basis: auto; }
        .navbar-expand-xl .navbar-toggler {
          display: none; } }

    .navbar-expand {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      .navbar-expand > .container,
      .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0; }
      .navbar-expand .navbar-nav {
        flex-direction: row; }
        .navbar-expand .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand .navbar-nav .nav-link {
          padding-right: 0.75rem;
          padding-left: 0.75rem; }
      .navbar-expand > .container,
      .navbar-expand > .container-fluid {
        flex-wrap: nowrap; }
      .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      .navbar-expand .navbar-toggler {
        display: none; }

    .navbar-light .navbar-brand {
      color: rgba(0, 0, 0, 0.9); }
      .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
        color: rgba(0, 0, 0, 0.9); }

    .navbar-light .navbar-nav .nav-link {
      color: rgba(0, 0, 0, 0.5); }
      .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
        color: rgba(0, 0, 0, 0.7); }
      .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, 0.3); }

    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
      color: rgba(0, 0, 0, 0.9); }

    .navbar-light .navbar-toggler {
      color: rgba(0, 0, 0, 0.5);
      border-color: rgba(0, 0, 0, 0.1); }

    .navbar-light .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

    .navbar-light .navbar-text {
      color: rgba(0, 0, 0, 0.5); }
      .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, 0.9); }
        .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
          color: rgba(0, 0, 0, 0.9); }

    .navbar-dark .navbar-brand {
      color: #fff; }
      .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
        color: #fff; }

    .navbar-dark .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.65); }
      .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: rgba(255, 255, 255, 0.85); }
      .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.25); }

    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
      color: #fff; }

    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 0.65);
      border-color: rgba(255, 255, 255, 0.1); }

    .navbar-dark .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.65)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

    .navbar-dark .navbar-text {
      color: rgba(255, 255, 255, 0.65); }
      .navbar-dark .navbar-text a {
        color: #fff; }
        .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
          color: #fff; }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid #EAEDF2;
      border-radius: 0.3125rem; }
      .card > hr {
        margin-right: 0;
        margin-left: 0; }
      .card > .list-group:first-child .list-group-item:first-child {
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem; }
      .card > .list-group:last-child .list-group-item:last-child {
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem; }

    .card-body {
      flex: 1 1 auto;
      padding: 2.25rem; }

    .card-title {
      margin-bottom: 0.75rem; }

    .card-subtitle {
      margin-top: -0.375rem;
      margin-bottom: 0; }

    .card-text:last-child {
      margin-bottom: 0; }

    .card-link:hover {
      text-decoration: none; }

    .card-link + .card-link {
      margin-left: 2.25rem; }

    .card-header {
      padding: 0.75rem 2.25rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid #EAEDF2; }
      .card-header:first-child {
        border-radius: calc(0.3125rem - 1px) calc(0.3125rem - 1px) 0 0; }
      .card-header + .list-group .list-group-item:first-child {
        border-top: 0; }

    .card-footer {
      padding: 0.75rem 2.25rem;
      background-color: rgba(0, 0, 0, 0.03);
      border-top: 1px solid #EAEDF2; }
      .card-footer:last-child {
        border-radius: 0 0 calc(0.3125rem - 1px) calc(0.3125rem - 1px); }

    .card-header-tabs {
      margin-right: -1.125rem;
      margin-bottom: -0.75rem;
      margin-left: -1.125rem;
      border-bottom: 0; }

    .card-header-pills {
      margin-right: -1.125rem;
      margin-left: -1.125rem; }

    .card-img-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1.25rem; }

    .card-img {
      width: 100%;
      border-radius: calc(0.3125rem - 1px); }

    .card-img-top {
      width: 100%;
      border-top-left-radius: calc(0.3125rem - 1px);
      border-top-right-radius: calc(0.3125rem - 1px); }

    .card-img-bottom {
      width: 100%;
      border-bottom-right-radius: calc(0.3125rem - 1px);
      border-bottom-left-radius: calc(0.3125rem - 1px); }

    .card-deck {
      display: flex;
      flex-direction: column; }
      .card-deck .card {
        margin-bottom: 12px; }
      @media (min-width: 576px) {
        .card-deck {
          flex-flow: row wrap;
          margin-right: -12px;
          margin-left: -12px; }
          .card-deck .card {
            display: flex;
            flex: 1 0 0%;
            flex-direction: column;
            margin-right: 12px;
            margin-bottom: 0;
            margin-left: 12px; } }

    .card-group {
      display: flex;
      flex-direction: column; }
      .card-group > .card {
        margin-bottom: 12px; }
      @media (min-width: 576px) {
        .card-group {
          flex-flow: row wrap; }
          .card-group > .card {
            flex: 1 0 0%;
            margin-bottom: 0; }
            .card-group > .card + .card {
              margin-left: 0;
              border-left: 0; }
            .card-group > .card:not(:last-child) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0; }
              .card-group > .card:not(:last-child) .card-img-top,
              .card-group > .card:not(:last-child) .card-header {
                border-top-right-radius: 0; }
              .card-group > .card:not(:last-child) .card-img-bottom,
              .card-group > .card:not(:last-child) .card-footer {
                border-bottom-right-radius: 0; }
            .card-group > .card:not(:first-child) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0; }
              .card-group > .card:not(:first-child) .card-img-top,
              .card-group > .card:not(:first-child) .card-header {
                border-top-left-radius: 0; }
              .card-group > .card:not(:first-child) .card-img-bottom,
              .card-group > .card:not(:first-child) .card-footer {
                border-bottom-left-radius: 0; } }

    .card-columns .card {
      margin-bottom: 0.75rem; }

    @media (min-width: 576px) {
      .card-columns {
        -webkit-column-count: 3;
           -moz-column-count: 3;
                column-count: 3;
        -webkit-column-gap: 1.25rem;
           -moz-column-gap: 1.25rem;
                column-gap: 1.25rem;
        orphans: 1;
        widows: 1; }
        .card-columns .card {
          display: inline-block;
          width: 100%; } }

    .accordion > .card {
      overflow: hidden; }
      .accordion > .card:not(:first-of-type) .card-header:first-child {
        border-radius: 0; }
      .accordion > .card:not(:first-of-type):not(:last-of-type) {
        border-bottom: 0;
        border-radius: 0; }
      .accordion > .card:first-of-type {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .accordion > .card:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
      .accordion > .card .card-header {
        margin-bottom: -1px; }

    .breadcrumb {
      display: flex;
      flex-wrap: wrap;
      padding: 0.75rem 1.5rem;
      margin-bottom: 1rem;
      list-style: none;
      background-color: #EAEDF2;
      border-radius: 0.3125rem; }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0; }
      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0;
        color: #6c757d;
        content: "/"; }

    .breadcrumb-item + .breadcrumb-item:hover::before {
      text-decoration: underline; }

    .breadcrumb-item + .breadcrumb-item:hover::before {
      text-decoration: none; }

    .breadcrumb-item.active {
      color: #6c757d; }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.3125rem; }

    .page-link {
      position: relative;
      display: block;
      padding: 0.75rem 1rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #009b72;
      background-color: #fff;
      border: 1px solid #EAEDF2; }
      .page-link:hover {
        z-index: 2;
        color: #004f3a;
        text-decoration: none;
        background-color: #EAEDF2;
        border-color: #EAEDF2; }
      .page-link:focus {
        z-index: 2;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.25); }

    .page-item:first-child .page-link {
      margin-left: 0;
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem; }

    .page-item:last-child .page-link {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem; }

    .page-item.active .page-link {
      z-index: 1;
      color: #fff;
      background-color: #009b72;
      border-color: #009b72; }

    .page-item.disabled .page-link {
      color: rgba(85, 90, 100, 0.65);
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #EAEDF2; }

    .pagination-lg .page-link {
      padding: 0.75rem 1.5rem;
      font-size: 1.25rem;
      line-height: 1.5; }

    .pagination-lg .page-item:first-child .page-link {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem; }

    .pagination-lg .page-item:last-child .page-link {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem; }

    .pagination-sm .page-link {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5; }

    .pagination-sm .page-item:first-child .page-link {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem; }

    .pagination-sm .page-item:last-child .page-link {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem; }

    .badge {
      display: inline-block;
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.3125rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .badge {
          transition: none; } }
      a.badge:hover, a.badge:focus {
        text-decoration: none; }
      .badge:empty {
        display: none; }

    .btn .badge {
      position: relative;
      top: -1px; }

    .badge-pill {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      border-radius: 10rem; }

    .badge-primary {
      color: #fff;
      background-color: #009b72; }
      a.badge-primary:hover, a.badge-primary:focus {
        color: #fff;
        background-color: #00684c; }
      a.badge-primary:focus, a.badge-primary.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.5); }

    .badge-secondary {
      color: #2C3038;
      background-color: #EAEDF2; }
      a.badge-secondary:hover, a.badge-secondary:focus {
        color: #2C3038;
        background-color: #cbd2df; }
      a.badge-secondary:focus, a.badge-secondary.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5); }

    .badge-success {
      color: #fff;
      background-color: #009b72; }
      a.badge-success:hover, a.badge-success:focus {
        color: #fff;
        background-color: #00684c; }
      a.badge-success:focus, a.badge-success.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 155, 114, 0.5); }

    .badge-info {
      color: #fff;
      background-color: #17a2b8; }
      a.badge-info:hover, a.badge-info:focus {
        color: #fff;
        background-color: #117a8b; }
      a.badge-info:focus, a.badge-info.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

    .badge-warning {
      color: #2C3038;
      background-color: #ffc107; }
      a.badge-warning:hover, a.badge-warning:focus {
        color: #2C3038;
        background-color: #d39e00; }
      a.badge-warning:focus, a.badge-warning.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

    .badge-danger {
      color: #fff;
      background-color: #dc3545; }
      a.badge-danger:hover, a.badge-danger:focus {
        color: #fff;
        background-color: #bd2130; }
      a.badge-danger:focus, a.badge-danger.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

    .badge-light {
      color: #2C3038;
      background-color: #F7F9FC; }
      a.badge-light:hover, a.badge-light:focus {
        color: #2C3038;
        background-color: #d2ddee; }
      a.badge-light:focus, a.badge-light.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(247, 249, 252, 0.5); }

    .badge-dark {
      color: #fff;
      background-color: #2C3038; }
      a.badge-dark:hover, a.badge-dark:focus {
        color: #fff;
        background-color: #16181b; }
      a.badge-dark:focus, a.badge-dark.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(44, 48, 56, 0.5); }

    .badge-primary-2 {
      color: #fff;
      background-color: #046865; }
      a.badge-primary-2:hover, a.badge-primary-2:focus {
        color: #fff;
        background-color: #023735; }
      a.badge-primary-2:focus, a.badge-primary-2.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(4, 104, 101, 0.5); }

    .badge-primary-3 {
      color: #fff;
      background-color: #192d35; }
      a.badge-primary-3:hover, a.badge-primary-3:focus {
        color: #fff;
        background-color: #091012; }
      a.badge-primary-3:focus, a.badge-primary-3.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(25, 45, 53, 0.5); }

    .jumbotron {
      padding: 2rem 1rem;
      margin-bottom: 2rem;
      background-color: #F7F9FC;
      border-radius: 0.3125rem; }
      @media (min-width: 576px) {
        .jumbotron {
          padding: 4rem 2rem; } }

    .jumbotron-fluid {
      padding-right: 0;
      padding-left: 0;
      border-radius: 0; }

    .alert {
      position: relative;
      padding: 0.75rem 1.5rem;
      margin-bottom: 1rem;
      border: 0 solid transparent;
      border-radius: 0.3125rem; }

    .alert-heading {
      color: inherit; }

    .alert-link {
      font-weight: 700; }

    .alert-dismissible {
      padding-right: 4.5rem; }
      .alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem 1.5rem;
        color: inherit; }

    .alert-primary {
      color: #003e2e;
      background-color: #ccebe3;
      border-color: #b8e3d8; }
      .alert-primary hr {
        border-top-color: #a6dcce; }
      .alert-primary .alert-link {
        color: #000b08; }

    .alert-secondary {
      color: #5e5f61;
      background-color: #fbfbfc;
      border-color: #f9fafb; }
      .alert-secondary hr {
        border-top-color: #eaedf1; }
      .alert-secondary .alert-link {
        color: #454647; }

    .alert-success {
      color: #003e2e;
      background-color: #ccebe3;
      border-color: #b8e3d8; }
      .alert-success hr {
        border-top-color: #a6dcce; }
      .alert-success .alert-link {
        color: #000b08; }

    .alert-info {
      color: #09414a;
      background-color: #d1ecf1;
      border-color: #bee5eb; }
      .alert-info hr {
        border-top-color: #abdde5; }
      .alert-info .alert-link {
        color: #03191d; }

    .alert-warning {
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffeeba; }
      .alert-warning hr {
        border-top-color: #ffe8a1; }
      .alert-warning .alert-link {
        color: #342802; }

    .alert-danger {
      color: #58151c;
      background-color: #f8d7da;
      border-color: #f5c6cb; }
      .alert-danger hr {
        border-top-color: #f1b0b7; }
      .alert-danger .alert-link {
        color: #2f0b0f; }

    .alert-light {
      color: #636465;
      background-color: #fdfefe;
      border-color: #fdfdfe; }
      .alert-light hr {
        border-top-color: #ececf6; }
      .alert-light .alert-link {
        color: #4a4b4b; }

    .alert-dark {
      color: #121316;
      background-color: #d5d6d7;
      border-color: #c4c5c7; }
      .alert-dark hr {
        border-top-color: #b7b8bb; }
      .alert-dark .alert-link {
        color: black; }

    .alert-primary-2 {
      color: #022a28;
      background-color: #cde1e0;
      border-color: #b9d5d4; }
      .alert-primary-2 hr {
        border-top-color: #a9cbca; }
      .alert-primary-2 .alert-link {
        color: black; }

    .alert-primary-3 {
      color: #0a1215;
      background-color: #d1d5d7;
      border-color: #bfc4c6; }
      .alert-primary-3 hr {
        border-top-color: #b2b8ba; }
      .alert-primary-3 .alert-link {
        color: black; }

    @-webkit-keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0; }
      to {
        background-position: 0 0; } }

    @keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0; }
      to {
        background-position: 0 0; } }

    .progress {
      display: flex;
      height: 1rem;
      overflow: hidden;
      font-size: 0.75rem;
      background-color: #F7F9FC;
      border-radius: 0.3125rem;
      box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

    .progress-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #009b72;
      transition: width 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        .progress-bar {
          transition: none; } }

    .progress-bar-striped {
      background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
      background-size: 1rem 1rem; }

    .progress-bar-animated {
      -webkit-animation: progress-bar-stripes 1s linear infinite;
              animation: progress-bar-stripes 1s linear infinite; }
      @media (prefers-reduced-motion: reduce) {
        .progress-bar-animated {
          -webkit-animation: none;
                  animation: none; } }

    .media {
      display: flex;
      align-items: flex-start; }

    .media-body {
      flex: 1; }

    .list-group {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0; }

    .list-group-item-action {
      width: 100%;
      color: #555A64;
      text-align: inherit; }
      .list-group-item-action:hover, .list-group-item-action:focus {
        z-index: 1;
        color: #555A64;
        text-decoration: none;
        background-color: #f8f9fa; }
      .list-group-item-action:active {
        color: #555A64;
        background-color: #F7F9FC; }

    .list-group-item {
      position: relative;
      display: block;
      padding: 1.5rem 2.25rem;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid #EAEDF2; }
      .list-group-item:first-child {
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem; }
      .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem; }
      .list-group-item.disabled, .list-group-item:disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: #fff; }
      .list-group-item.active {
        z-index: 2;
        color: #fff;
        background-color: #009b72;
        border-color: #009b72; }

    .list-group-horizontal {
      flex-direction: row; }
      .list-group-horizontal .list-group-item {
        margin-right: -1px;
        margin-bottom: 0; }
        .list-group-horizontal .list-group-item:first-child {
          border-top-left-radius: 0.3125rem;
          border-bottom-left-radius: 0.3125rem;
          border-top-right-radius: 0; }
        .list-group-horizontal .list-group-item:last-child {
          margin-right: 0;
          border-top-right-radius: 0.3125rem;
          border-bottom-right-radius: 0.3125rem;
          border-bottom-left-radius: 0; }

    @media (min-width: 576px) {
      .list-group-horizontal-sm {
        flex-direction: row; }
        .list-group-horizontal-sm .list-group-item {
          margin-right: -1px;
          margin-bottom: 0; }
          .list-group-horizontal-sm .list-group-item:first-child {
            border-top-left-radius: 0.3125rem;
            border-bottom-left-radius: 0.3125rem;
            border-top-right-radius: 0; }
          .list-group-horizontal-sm .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            border-bottom-left-radius: 0; } }

    @media (min-width: 768px) {
      .list-group-horizontal-md {
        flex-direction: row; }
        .list-group-horizontal-md .list-group-item {
          margin-right: -1px;
          margin-bottom: 0; }
          .list-group-horizontal-md .list-group-item:first-child {
            border-top-left-radius: 0.3125rem;
            border-bottom-left-radius: 0.3125rem;
            border-top-right-radius: 0; }
          .list-group-horizontal-md .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            border-bottom-left-radius: 0; } }

    @media (min-width: 992px) {
      .list-group-horizontal-lg {
        flex-direction: row; }
        .list-group-horizontal-lg .list-group-item {
          margin-right: -1px;
          margin-bottom: 0; }
          .list-group-horizontal-lg .list-group-item:first-child {
            border-top-left-radius: 0.3125rem;
            border-bottom-left-radius: 0.3125rem;
            border-top-right-radius: 0; }
          .list-group-horizontal-lg .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            border-bottom-left-radius: 0; } }

    @media (min-width: 1200px) {
      .list-group-horizontal-xl {
        flex-direction: row; }
        .list-group-horizontal-xl .list-group-item {
          margin-right: -1px;
          margin-bottom: 0; }
          .list-group-horizontal-xl .list-group-item:first-child {
            border-top-left-radius: 0.3125rem;
            border-bottom-left-radius: 0.3125rem;
            border-top-right-radius: 0; }
          .list-group-horizontal-xl .list-group-item:last-child {
            margin-right: 0;
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            border-bottom-left-radius: 0; } }

    .list-group-flush .list-group-item {
      border-right: 0;
      border-left: 0;
      border-radius: 0; }
      .list-group-flush .list-group-item:last-child {
        margin-bottom: -1px; }

    .list-group-flush:first-child .list-group-item:first-child {
      border-top: 0; }

    .list-group-flush:last-child .list-group-item:last-child {
      margin-bottom: 0;
      border-bottom: 0; }

    .list-group-item-primary {
      color: #00513b;
      background-color: #b8e3d8; }
      .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
        color: #00513b;
        background-color: #a6dcce; }
      .list-group-item-primary.list-group-item-action.active {
        color: #fff;
        background-color: #00513b;
        border-color: #00513b; }

    .list-group-item-secondary {
      color: #7a7b7e;
      background-color: #f9fafb; }
      .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
        color: #7a7b7e;
        background-color: #eaedf1; }
      .list-group-item-secondary.list-group-item-action.active {
        color: #fff;
        background-color: #7a7b7e;
        border-color: #7a7b7e; }

    .list-group-item-success {
      color: #00513b;
      background-color: #b8e3d8; }
      .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
        color: #00513b;
        background-color: #a6dcce; }
      .list-group-item-success.list-group-item-action.active {
        color: #fff;
        background-color: #00513b;
        border-color: #00513b; }

    .list-group-item-info {
      color: #0c5460;
      background-color: #bee5eb; }
      .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
        color: #0c5460;
        background-color: #abdde5; }
      .list-group-item-info.list-group-item-action.active {
        color: #fff;
        background-color: #0c5460;
        border-color: #0c5460; }

    .list-group-item-warning {
      color: #856404;
      background-color: #ffeeba; }
      .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
        color: #856404;
        background-color: #ffe8a1; }
      .list-group-item-warning.list-group-item-action.active {
        color: #fff;
        background-color: #856404;
        border-color: #856404; }

    .list-group-item-danger {
      color: #721c24;
      background-color: #f5c6cb; }
      .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
        color: #721c24;
        background-color: #f1b0b7; }
      .list-group-item-danger.list-group-item-action.active {
        color: #fff;
        background-color: #721c24;
        border-color: #721c24; }

    .list-group-item-light {
      color: #808183;
      background-color: #fdfdfe; }
      .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
        color: #808183;
        background-color: #ececf6; }
      .list-group-item-light.list-group-item-action.active {
        color: #fff;
        background-color: #808183;
        border-color: #808183; }

    .list-group-item-dark {
      color: #17191d;
      background-color: #c4c5c7; }
      .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
        color: #17191d;
        background-color: #b7b8bb; }
      .list-group-item-dark.list-group-item-action.active {
        color: #fff;
        background-color: #17191d;
        border-color: #17191d; }

    .list-group-item-primary-2 {
      color: #023635;
      background-color: #b9d5d4; }
      .list-group-item-primary-2.list-group-item-action:hover, .list-group-item-primary-2.list-group-item-action:focus {
        color: #023635;
        background-color: #a9cbca; }
      .list-group-item-primary-2.list-group-item-action.active {
        color: #fff;
        background-color: #023635;
        border-color: #023635; }

    .list-group-item-primary-3 {
      color: #0d171c;
      background-color: #bfc4c6; }
      .list-group-item-primary-3.list-group-item-action:hover, .list-group-item-primary-3.list-group-item-action:focus {
        color: #0d171c;
        background-color: #b2b8ba; }
      .list-group-item-primary-3.list-group-item-action.active {
        color: #fff;
        background-color: #0d171c;
        border-color: #0d171c; }

    .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
      @media (max-width: 1200px) {
        .close {
          font-size: calc(1.275rem + 0.3vw) ; } }
      .close:hover {
        color: #000;
        text-decoration: none; }
      .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        opacity: .75; }

    button.close {
      padding: 0;
      background-color: transparent;
      border: 0;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none; }

    a.close.disabled {
      pointer-events: none; }

    .toast {
      max-width: 350px;
      overflow: hidden;
      font-size: 0.875rem;
      background-color: rgba(255, 255, 255, 0.85);
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
      opacity: 0;
      border-radius: 0.25rem; }
      .toast:not(:last-child) {
        margin-bottom: 0.75rem; }
      .toast.showing {
        opacity: 1; }
      .toast.show {
        display: block;
        opacity: 1; }
      .toast.hide {
        display: none; }

    .toast-header {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
      color: #6c757d;
      background-color: rgba(255, 255, 255, 0.85);
      background-clip: padding-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

    .toast-body {
      padding: 0.75rem; }

    .modal-open {
      overflow: hidden; }
      .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto; }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1050;
      display: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
      outline: 0; }

    .modal-dialog {
      position: relative;
      width: auto;
      margin: 0.5rem;
      pointer-events: none; }
      .modal.fade .modal-dialog {
        transition: transform 0.3s ease-out;
        transform: translate(0, -50px); }
        @media (prefers-reduced-motion: reduce) {
          .modal.fade .modal-dialog {
            transition: none; } }
      .modal.show .modal-dialog {
        transform: none; }

    .modal-dialog-scrollable {
      display: flex;
      max-height: calc(100% - 1rem); }
      .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 1rem);
        overflow: hidden; }
      .modal-dialog-scrollable .modal-header,
      .modal-dialog-scrollable .modal-footer {
        flex-shrink: 0; }
      .modal-dialog-scrollable .modal-body {
        overflow-y: auto; }

    .modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - 1rem); }
      .modal-dialog-centered::before {
        display: block;
        height: calc(100vh - 1rem);
        content: ""; }
      .modal-dialog-centered.modal-dialog-scrollable {
        flex-direction: column;
        justify-content: center;
        height: 100%; }
        .modal-dialog-centered.modal-dialog-scrollable .modal-content {
          max-height: none; }
        .modal-dialog-centered.modal-dialog-scrollable::before {
          content: none; }

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #EAEDF2;
      border-radius: 0.3125rem;
      box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.09);
      outline: 0; }

    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;
      width: 100vw;
      height: 100vh;
      background-color: #000; }
      .modal-backdrop.fade {
        opacity: 0; }
      .modal-backdrop.show {
        opacity: 0.5; }

    .modal-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid #EAEDF2;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem; }
      .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto; }

    .modal-title {
      margin-bottom: 0;
      line-height: 1.5; }

    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem; }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1rem;
      border-top: 1px solid #EAEDF2;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem; }
      .modal-footer > :not(:first-child) {
        margin-left: .25rem; }
      .modal-footer > :not(:last-child) {
        margin-right: .25rem; }

    .modal-scrollbar-measure {
      position: absolute;
      top: -9999px;
      width: 50px;
      height: 50px;
      overflow: scroll; }

    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto; }
      .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem); }
        .modal-dialog-scrollable .modal-content {
          max-height: calc(100vh - 3.5rem); }
      .modal-dialog-centered {
        min-height: calc(100% - 3.5rem); }
        .modal-dialog-centered::before {
          height: calc(100vh - 3.5rem); }
      .modal-content {
        box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.09); }
      .modal-sm {
        max-width: 300px; } }

    @media (min-width: 992px) {
      .modal-lg,
      .modal-xl {
        max-width: 800px; } }

    @media (min-width: 1200px) {
      .modal-xl {
        max-width: 1140px; } }

    .tooltip {
      position: absolute;
      z-index: 1070;
      display: block;
      margin: 0;
      font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      word-spacing: normal;
      white-space: normal;
      line-break: auto;
      font-size: 0.875rem;
      word-wrap: break-word;
      opacity: 0; }
      .tooltip.show {
        opacity: 0.9; }
      .tooltip .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem; }
        .tooltip .arrow::before {
          position: absolute;
          content: "";
          border-color: transparent;
          border-style: solid; }

    .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
      padding: 0.4rem 0; }
      .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
        bottom: 0; }
        .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
          top: 0;
          border-width: 0.4rem 0.4rem 0;
          border-top-color: #000; }

    .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
      padding: 0 0.4rem; }
      .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem; }
        .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
          right: 0;
          border-width: 0.4rem 0.4rem 0.4rem 0;
          border-right-color: #000; }

    .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
      padding: 0.4rem 0; }
      .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
        top: 0; }
        .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
          bottom: 0;
          border-width: 0 0.4rem 0.4rem;
          border-bottom-color: #000; }

    .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
      padding: 0 0.4rem; }
      .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem; }
        .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
          left: 0;
          border-width: 0.4rem 0 0.4rem 0.4rem;
          border-left-color: #000; }

    .tooltip-inner {
      max-width: 200px;
      padding: 0.25rem 0.5rem;
      color: #fff;
      text-align: center;
      background-color: #000;
      border-radius: 0.3125rem; }

    .popover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1060;
      display: block;
      max-width: 276px;
      font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      word-spacing: normal;
      white-space: normal;
      line-break: auto;
      font-size: 1rem;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #EAEDF2;
      border-radius: 0.3125rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09); }
      .popover .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3125rem; }
        .popover .arrow::before, .popover .arrow::after {
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid; }

    .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
      margin-bottom: 0.5rem; }
      .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
        bottom: calc((0.5rem + 1px) * -1); }
        .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
          bottom: 0;
          border-width: 0.5rem 0.5rem 0;
          border-top-color: #eaedf2; }
        .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
          bottom: 1px;
          border-width: 0.5rem 0.5rem 0;
          border-top-color: #fff; }

    .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
      margin-left: 0.5rem; }
      .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
        left: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3125rem 0; }
        .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
          left: 0;
          border-width: 0.5rem 0.5rem 0.5rem 0;
          border-right-color: #eaedf2; }
        .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
          left: 1px;
          border-width: 0.5rem 0.5rem 0.5rem 0;
          border-right-color: #fff; }

    .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
      margin-top: 0.5rem; }
      .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
        top: calc((0.5rem + 1px) * -1); }
        .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
          top: 0;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-bottom-color: #eaedf2; }
        .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
          top: 1px;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-bottom-color: #fff; }
      .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -0.5rem;
        content: "";
        border-bottom: 1px solid #f7f7f7; }

    .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
      margin-right: 0.5rem; }
      .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
        right: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3125rem 0; }
        .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
          right: 0;
          border-width: 0.5rem 0 0.5rem 0.5rem;
          border-left-color: #eaedf2; }
        .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
          right: 1px;
          border-width: 0.5rem 0 0.5rem 0.5rem;
          border-left-color: #fff; }

    .popover-header {
      padding: 1rem 1rem;
      margin-bottom: 0;
      font-size: 1rem;
      color: #2C3038;
      background-color: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      border-top-left-radius: calc(0.3125rem - 1px);
      border-top-right-radius: calc(0.3125rem - 1px); }
      .popover-header:empty {
        display: none; }

    .popover-body {
      padding: 1rem 1rem;
      color: #555A64; }

    .carousel {
      position: relative; }

    .carousel.pointer-event {
      touch-action: pan-y; }

    .carousel-inner {
      position: relative;
      width: 100%;
      overflow: hidden; }
      .carousel-inner::after {
        display: block;
        clear: both;
        content: ""; }

    .carousel-item {
      position: relative;
      display: none;
      float: left;
      width: 100%;
      margin-right: -100%;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      transition: transform 0.6s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-item {
          transition: none; } }

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      display: block; }

    .carousel-item-next:not(.carousel-item-left),
    .active.carousel-item-right {
      transform: translateX(100%); }

    .carousel-item-prev:not(.carousel-item-right),
    .active.carousel-item-left {
      transform: translateX(-100%); }

    .carousel-fade .carousel-item {
      opacity: 0;
      transition-property: opacity;
      transform: none; }

    .carousel-fade .carousel-item.active,
    .carousel-fade .carousel-item-next.carousel-item-left,
    .carousel-fade .carousel-item-prev.carousel-item-right {
      z-index: 1;
      opacity: 1; }

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      z-index: 0;
      opacity: 0;
      transition: 0s 0.6s opacity; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
          transition: none; } }

    .carousel-control-prev,
    .carousel-control-next {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15%;
      color: #fff;
      text-align: center;
      opacity: 0.5;
      transition: opacity 0.15s ease; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-control-prev,
        .carousel-control-next {
          transition: none; } }
      .carousel-control-prev:hover, .carousel-control-prev:focus,
      .carousel-control-next:hover,
      .carousel-control-next:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        opacity: 0.9; }

    .carousel-control-prev {
      left: 0; }

    .carousel-control-next {
      right: 0; }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: no-repeat 50% / 100% 100%; }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

    .carousel-indicators {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 15;
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-right: 15%;
      margin-left: 15%;
      list-style: none; }
      .carousel-indicators li {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        transition: opacity 0.6s ease; }
        @media (prefers-reduced-motion: reduce) {
          .carousel-indicators li {
            transition: none; } }
      .carousel-indicators .active {
        opacity: 1; }

    .carousel-caption {
      position: absolute;
      right: 15%;
      bottom: 20px;
      left: 15%;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center; }

    @-webkit-keyframes spinner-border {
      to {
        transform: rotate(360deg); } }

    @keyframes spinner-border {
      to {
        transform: rotate(360deg); } }

    .spinner-border {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      vertical-align: text-bottom;
      border: 0.25em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      -webkit-animation: spinner-border .75s linear infinite;
              animation: spinner-border .75s linear infinite; }

    .spinner-border-sm {
      width: 1rem;
      height: 1rem;
      border-width: 0.2em; }

    @-webkit-keyframes spinner-grow {
      0% {
        transform: scale(0); }
      50% {
        opacity: 1; } }

    @keyframes spinner-grow {
      0% {
        transform: scale(0); }
      50% {
        opacity: 1; } }

    .spinner-grow {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      vertical-align: text-bottom;
      background-color: currentColor;
      border-radius: 50%;
      opacity: 0;
      -webkit-animation: spinner-grow .75s linear infinite;
              animation: spinner-grow .75s linear infinite; }

    .spinner-grow-sm {
      width: 1rem;
      height: 1rem; }

    .align-baseline {
      vertical-align: baseline !important; }

    .align-top {
      vertical-align: top !important; }

    .align-middle {
      vertical-align: middle !important; }

    .align-bottom {
      vertical-align: bottom !important; }

    .align-text-bottom {
      vertical-align: text-bottom !important; }

    .align-text-top {
      vertical-align: text-top !important; }

    .bg-primary {
      background-color: #009b72 !important; }

    a.bg-primary:hover, a.bg-primary:focus,
    button.bg-primary:hover,
    button.bg-primary:focus {
      background-color: #00684c !important; }

    .bg-secondary {
      background-color: #EAEDF2 !important; }

    a.bg-secondary:hover, a.bg-secondary:focus,
    button.bg-secondary:hover,
    button.bg-secondary:focus {
      background-color: #cbd2df !important; }

    .bg-success {
      background-color: #009b72 !important; }

    a.bg-success:hover, a.bg-success:focus,
    button.bg-success:hover,
    button.bg-success:focus {
      background-color: #00684c !important; }

    .bg-info {
      background-color: #17a2b8 !important; }

    a.bg-info:hover, a.bg-info:focus,
    button.bg-info:hover,
    button.bg-info:focus {
      background-color: #117a8b !important; }

    .bg-warning {
      background-color: #ffc107 !important; }

    a.bg-warning:hover, a.bg-warning:focus,
    button.bg-warning:hover,
    button.bg-warning:focus {
      background-color: #d39e00 !important; }

    .bg-danger {
      background-color: #dc3545 !important; }

    a.bg-danger:hover, a.bg-danger:focus,
    button.bg-danger:hover,
    button.bg-danger:focus {
      background-color: #bd2130 !important; }

    .bg-light {
      background-color: #F7F9FC !important; }

    a.bg-light:hover, a.bg-light:focus,
    button.bg-light:hover,
    button.bg-light:focus {
      background-color: #d2ddee !important; }

    .bg-dark {
      background-color: #2C3038 !important; }

    a.bg-dark:hover, a.bg-dark:focus,
    button.bg-dark:hover,
    button.bg-dark:focus {
      background-color: #16181b !important; }

    .bg-primary-2 {
      background-color: #046865 !important; }

    a.bg-primary-2:hover, a.bg-primary-2:focus,
    button.bg-primary-2:hover,
    button.bg-primary-2:focus {
      background-color: #023735 !important; }

    .bg-primary-3 {
      background-color: #192d35 !important; }

    a.bg-primary-3:hover, a.bg-primary-3:focus,
    button.bg-primary-3:hover,
    button.bg-primary-3:focus {
      background-color: #091012 !important; }

    .bg-white {
      background-color: #fff !important; }

    .bg-transparent {
      background-color: transparent !important; }

    .border {
      border: 1px solid #EAEDF2 !important; }

    .border-top {
      border-top: 1px solid #EAEDF2 !important; }

    .border-right {
      border-right: 1px solid #EAEDF2 !important; }

    .border-bottom {
      border-bottom: 1px solid #EAEDF2 !important; }

    .border-left {
      border-left: 1px solid #EAEDF2 !important; }

    .border-0 {
      border: 0 !important; }

    .border-top-0 {
      border-top: 0 !important; }

    .border-right-0 {
      border-right: 0 !important; }

    .border-bottom-0 {
      border-bottom: 0 !important; }

    .border-left-0 {
      border-left: 0 !important; }

    .border-primary {
      border-color: #009b72 !important; }

    .border-secondary {
      border-color: #EAEDF2 !important; }

    .border-success {
      border-color: #009b72 !important; }

    .border-info {
      border-color: #17a2b8 !important; }

    .border-warning {
      border-color: #ffc107 !important; }

    .border-danger {
      border-color: #dc3545 !important; }

    .border-light {
      border-color: #F7F9FC !important; }

    .border-dark {
      border-color: #2C3038 !important; }

    .border-primary-2 {
      border-color: #046865 !important; }

    .border-primary-3 {
      border-color: #192d35 !important; }

    .border-white {
      border-color: #fff !important; }

    .rounded-sm {
      border-radius: 0.3125rem !important; }

    .rounded {
      border-radius: 0.3125rem !important; }

    .rounded-top {
      border-top-left-radius: 0.3125rem !important;
      border-top-right-radius: 0.3125rem !important; }

    .rounded-right {
      border-top-right-radius: 0.3125rem !important;
      border-bottom-right-radius: 0.3125rem !important; }

    .rounded-bottom {
      border-bottom-right-radius: 0.3125rem !important;
      border-bottom-left-radius: 0.3125rem !important; }

    .rounded-left {
      border-top-left-radius: 0.3125rem !important;
      border-bottom-left-radius: 0.3125rem !important; }

    .rounded-lg {
      border-radius: 0.3125rem !important; }

    .rounded-circle {
      border-radius: 50% !important; }

    .rounded-pill {
      border-radius: 50rem !important; }

    .rounded-0 {
      border-radius: 0 !important; }

    .clearfix::after {
      display: block;
      clear: both;
      content: ""; }

    .d-none {
      display: none !important; }

    .d-inline {
      display: inline !important; }

    .d-inline-block {
      display: inline-block !important; }

    .d-block {
      display: block !important; }

    .d-table {
      display: table !important; }

    .d-table-row {
      display: table-row !important; }

    .d-table-cell {
      display: table-cell !important; }

    .d-flex {
      display: flex !important; }

    .d-inline-flex {
      display: inline-flex !important; }

    @media (min-width: 576px) {
      .d-sm-none {
        display: none !important; }
      .d-sm-inline {
        display: inline !important; }
      .d-sm-inline-block {
        display: inline-block !important; }
      .d-sm-block {
        display: block !important; }
      .d-sm-table {
        display: table !important; }
      .d-sm-table-row {
        display: table-row !important; }
      .d-sm-table-cell {
        display: table-cell !important; }
      .d-sm-flex {
        display: flex !important; }
      .d-sm-inline-flex {
        display: inline-flex !important; } }

    @media (min-width: 768px) {
      .d-md-none {
        display: none !important; }
      .d-md-inline {
        display: inline !important; }
      .d-md-inline-block {
        display: inline-block !important; }
      .d-md-block {
        display: block !important; }
      .d-md-table {
        display: table !important; }
      .d-md-table-row {
        display: table-row !important; }
      .d-md-table-cell {
        display: table-cell !important; }
      .d-md-flex {
        display: flex !important; }
      .d-md-inline-flex {
        display: inline-flex !important; } }

    @media (min-width: 992px) {
      .d-lg-none {
        display: none !important; }
      .d-lg-inline {
        display: inline !important; }
      .d-lg-inline-block {
        display: inline-block !important; }
      .d-lg-block {
        display: block !important; }
      .d-lg-table {
        display: table !important; }
      .d-lg-table-row {
        display: table-row !important; }
      .d-lg-table-cell {
        display: table-cell !important; }
      .d-lg-flex {
        display: flex !important; }
      .d-lg-inline-flex {
        display: inline-flex !important; } }

    @media (min-width: 1200px) {
      .d-xl-none {
        display: none !important; }
      .d-xl-inline {
        display: inline !important; }
      .d-xl-inline-block {
        display: inline-block !important; }
      .d-xl-block {
        display: block !important; }
      .d-xl-table {
        display: table !important; }
      .d-xl-table-row {
        display: table-row !important; }
      .d-xl-table-cell {
        display: table-cell !important; }
      .d-xl-flex {
        display: flex !important; }
      .d-xl-inline-flex {
        display: inline-flex !important; } }

    @media print {
      .d-print-none {
        display: none !important; }
      .d-print-inline {
        display: inline !important; }
      .d-print-inline-block {
        display: inline-block !important; }
      .d-print-block {
        display: block !important; }
      .d-print-table {
        display: table !important; }
      .d-print-table-row {
        display: table-row !important; }
      .d-print-table-cell {
        display: table-cell !important; }
      .d-print-flex {
        display: flex !important; }
      .d-print-inline-flex {
        display: inline-flex !important; } }

    .embed-responsive {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden; }
      .embed-responsive::before {
        display: block;
        content: ""; }
      .embed-responsive .embed-responsive-item,
      .embed-responsive iframe,
      .embed-responsive embed,
      .embed-responsive object,
      .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0; }

    .embed-responsive-21by9::before {
      padding-top: 42.85714%; }

    .embed-responsive-16by9::before {
      padding-top: 56.25%; }

    .embed-responsive-4by3::before {
      padding-top: 75%; }

    .embed-responsive-1by1::before {
      padding-top: 100%; }

    .embed-responsive-21by9::before {
      padding-top: 42.85714%; }

    .embed-responsive-16by9::before {
      padding-top: 56.25%; }

    .embed-responsive-4by3::before {
      padding-top: 75%; }

    .embed-responsive-1by1::before {
      padding-top: 100%; }

    .flex-row {
      flex-direction: row !important; }

    .flex-column {
      flex-direction: column !important; }

    .flex-row-reverse {
      flex-direction: row-reverse !important; }

    .flex-column-reverse {
      flex-direction: column-reverse !important; }

    .flex-wrap {
      flex-wrap: wrap !important; }

    .flex-nowrap {
      flex-wrap: nowrap !important; }

    .flex-wrap-reverse {
      flex-wrap: wrap-reverse !important; }

    .flex-fill {
      flex: 1 1 auto !important; }

    .flex-grow-0 {
      flex-grow: 0 !important; }

    .flex-grow-1 {
      flex-grow: 1 !important; }

    .flex-shrink-0 {
      flex-shrink: 0 !important; }

    .flex-shrink-1 {
      flex-shrink: 1 !important; }

    .justify-content-start {
      justify-content: flex-start !important; }

    .justify-content-end {
      justify-content: flex-end !important; }

    .justify-content-center {
      justify-content: center !important; }

    .justify-content-between {
      justify-content: space-between !important; }

    .justify-content-around {
      justify-content: space-around !important; }

    .align-items-start {
      align-items: flex-start !important; }

    .align-items-end {
      align-items: flex-end !important; }

    .align-items-center {
      align-items: center !important; }

    .align-items-baseline {
      align-items: baseline !important; }

    .align-items-stretch {
      align-items: stretch !important; }

    .align-content-start {
      align-content: flex-start !important; }

    .align-content-end {
      align-content: flex-end !important; }

    .align-content-center {
      align-content: center !important; }

    .align-content-between {
      align-content: space-between !important; }

    .align-content-around {
      align-content: space-around !important; }

    .align-content-stretch {
      align-content: stretch !important; }

    .align-self-auto {
      align-self: auto !important; }

    .align-self-start {
      align-self: flex-start !important; }

    .align-self-end {
      align-self: flex-end !important; }

    .align-self-center {
      align-self: center !important; }

    .align-self-baseline {
      align-self: baseline !important; }

    .align-self-stretch {
      align-self: stretch !important; }

    @media (min-width: 576px) {
      .flex-sm-row {
        flex-direction: row !important; }
      .flex-sm-column {
        flex-direction: column !important; }
      .flex-sm-row-reverse {
        flex-direction: row-reverse !important; }
      .flex-sm-column-reverse {
        flex-direction: column-reverse !important; }
      .flex-sm-wrap {
        flex-wrap: wrap !important; }
      .flex-sm-nowrap {
        flex-wrap: nowrap !important; }
      .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important; }
      .flex-sm-fill {
        flex: 1 1 auto !important; }
      .flex-sm-grow-0 {
        flex-grow: 0 !important; }
      .flex-sm-grow-1 {
        flex-grow: 1 !important; }
      .flex-sm-shrink-0 {
        flex-shrink: 0 !important; }
      .flex-sm-shrink-1 {
        flex-shrink: 1 !important; }
      .justify-content-sm-start {
        justify-content: flex-start !important; }
      .justify-content-sm-end {
        justify-content: flex-end !important; }
      .justify-content-sm-center {
        justify-content: center !important; }
      .justify-content-sm-between {
        justify-content: space-between !important; }
      .justify-content-sm-around {
        justify-content: space-around !important; }
      .align-items-sm-start {
        align-items: flex-start !important; }
      .align-items-sm-end {
        align-items: flex-end !important; }
      .align-items-sm-center {
        align-items: center !important; }
      .align-items-sm-baseline {
        align-items: baseline !important; }
      .align-items-sm-stretch {
        align-items: stretch !important; }
      .align-content-sm-start {
        align-content: flex-start !important; }
      .align-content-sm-end {
        align-content: flex-end !important; }
      .align-content-sm-center {
        align-content: center !important; }
      .align-content-sm-between {
        align-content: space-between !important; }
      .align-content-sm-around {
        align-content: space-around !important; }
      .align-content-sm-stretch {
        align-content: stretch !important; }
      .align-self-sm-auto {
        align-self: auto !important; }
      .align-self-sm-start {
        align-self: flex-start !important; }
      .align-self-sm-end {
        align-self: flex-end !important; }
      .align-self-sm-center {
        align-self: center !important; }
      .align-self-sm-baseline {
        align-self: baseline !important; }
      .align-self-sm-stretch {
        align-self: stretch !important; } }

    @media (min-width: 768px) {
      .flex-md-row {
        flex-direction: row !important; }
      .flex-md-column {
        flex-direction: column !important; }
      .flex-md-row-reverse {
        flex-direction: row-reverse !important; }
      .flex-md-column-reverse {
        flex-direction: column-reverse !important; }
      .flex-md-wrap {
        flex-wrap: wrap !important; }
      .flex-md-nowrap {
        flex-wrap: nowrap !important; }
      .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important; }
      .flex-md-fill {
        flex: 1 1 auto !important; }
      .flex-md-grow-0 {
        flex-grow: 0 !important; }
      .flex-md-grow-1 {
        flex-grow: 1 !important; }
      .flex-md-shrink-0 {
        flex-shrink: 0 !important; }
      .flex-md-shrink-1 {
        flex-shrink: 1 !important; }
      .justify-content-md-start {
        justify-content: flex-start !important; }
      .justify-content-md-end {
        justify-content: flex-end !important; }
      .justify-content-md-center {
        justify-content: center !important; }
      .justify-content-md-between {
        justify-content: space-between !important; }
      .justify-content-md-around {
        justify-content: space-around !important; }
      .align-items-md-start {
        align-items: flex-start !important; }
      .align-items-md-end {
        align-items: flex-end !important; }
      .align-items-md-center {
        align-items: center !important; }
      .align-items-md-baseline {
        align-items: baseline !important; }
      .align-items-md-stretch {
        align-items: stretch !important; }
      .align-content-md-start {
        align-content: flex-start !important; }
      .align-content-md-end {
        align-content: flex-end !important; }
      .align-content-md-center {
        align-content: center !important; }
      .align-content-md-between {
        align-content: space-between !important; }
      .align-content-md-around {
        align-content: space-around !important; }
      .align-content-md-stretch {
        align-content: stretch !important; }
      .align-self-md-auto {
        align-self: auto !important; }
      .align-self-md-start {
        align-self: flex-start !important; }
      .align-self-md-end {
        align-self: flex-end !important; }
      .align-self-md-center {
        align-self: center !important; }
      .align-self-md-baseline {
        align-self: baseline !important; }
      .align-self-md-stretch {
        align-self: stretch !important; } }

    @media (min-width: 992px) {
      .flex-lg-row {
        flex-direction: row !important; }
      .flex-lg-column {
        flex-direction: column !important; }
      .flex-lg-row-reverse {
        flex-direction: row-reverse !important; }
      .flex-lg-column-reverse {
        flex-direction: column-reverse !important; }
      .flex-lg-wrap {
        flex-wrap: wrap !important; }
      .flex-lg-nowrap {
        flex-wrap: nowrap !important; }
      .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important; }
      .flex-lg-fill {
        flex: 1 1 auto !important; }
      .flex-lg-grow-0 {
        flex-grow: 0 !important; }
      .flex-lg-grow-1 {
        flex-grow: 1 !important; }
      .flex-lg-shrink-0 {
        flex-shrink: 0 !important; }
      .flex-lg-shrink-1 {
        flex-shrink: 1 !important; }
      .justify-content-lg-start {
        justify-content: flex-start !important; }
      .justify-content-lg-end {
        justify-content: flex-end !important; }
      .justify-content-lg-center {
        justify-content: center !important; }
      .justify-content-lg-between {
        justify-content: space-between !important; }
      .justify-content-lg-around {
        justify-content: space-around !important; }
      .align-items-lg-start {
        align-items: flex-start !important; }
      .align-items-lg-end {
        align-items: flex-end !important; }
      .align-items-lg-center {
        align-items: center !important; }
      .align-items-lg-baseline {
        align-items: baseline !important; }
      .align-items-lg-stretch {
        align-items: stretch !important; }
      .align-content-lg-start {
        align-content: flex-start !important; }
      .align-content-lg-end {
        align-content: flex-end !important; }
      .align-content-lg-center {
        align-content: center !important; }
      .align-content-lg-between {
        align-content: space-between !important; }
      .align-content-lg-around {
        align-content: space-around !important; }
      .align-content-lg-stretch {
        align-content: stretch !important; }
      .align-self-lg-auto {
        align-self: auto !important; }
      .align-self-lg-start {
        align-self: flex-start !important; }
      .align-self-lg-end {
        align-self: flex-end !important; }
      .align-self-lg-center {
        align-self: center !important; }
      .align-self-lg-baseline {
        align-self: baseline !important; }
      .align-self-lg-stretch {
        align-self: stretch !important; } }

    @media (min-width: 1200px) {
      .flex-xl-row {
        flex-direction: row !important; }
      .flex-xl-column {
        flex-direction: column !important; }
      .flex-xl-row-reverse {
        flex-direction: row-reverse !important; }
      .flex-xl-column-reverse {
        flex-direction: column-reverse !important; }
      .flex-xl-wrap {
        flex-wrap: wrap !important; }
      .flex-xl-nowrap {
        flex-wrap: nowrap !important; }
      .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important; }
      .flex-xl-fill {
        flex: 1 1 auto !important; }
      .flex-xl-grow-0 {
        flex-grow: 0 !important; }
      .flex-xl-grow-1 {
        flex-grow: 1 !important; }
      .flex-xl-shrink-0 {
        flex-shrink: 0 !important; }
      .flex-xl-shrink-1 {
        flex-shrink: 1 !important; }
      .justify-content-xl-start {
        justify-content: flex-start !important; }
      .justify-content-xl-end {
        justify-content: flex-end !important; }
      .justify-content-xl-center {
        justify-content: center !important; }
      .justify-content-xl-between {
        justify-content: space-between !important; }
      .justify-content-xl-around {
        justify-content: space-around !important; }
      .align-items-xl-start {
        align-items: flex-start !important; }
      .align-items-xl-end {
        align-items: flex-end !important; }
      .align-items-xl-center {
        align-items: center !important; }
      .align-items-xl-baseline {
        align-items: baseline !important; }
      .align-items-xl-stretch {
        align-items: stretch !important; }
      .align-content-xl-start {
        align-content: flex-start !important; }
      .align-content-xl-end {
        align-content: flex-end !important; }
      .align-content-xl-center {
        align-content: center !important; }
      .align-content-xl-between {
        align-content: space-between !important; }
      .align-content-xl-around {
        align-content: space-around !important; }
      .align-content-xl-stretch {
        align-content: stretch !important; }
      .align-self-xl-auto {
        align-self: auto !important; }
      .align-self-xl-start {
        align-self: flex-start !important; }
      .align-self-xl-end {
        align-self: flex-end !important; }
      .align-self-xl-center {
        align-self: center !important; }
      .align-self-xl-baseline {
        align-self: baseline !important; }
      .align-self-xl-stretch {
        align-self: stretch !important; } }

    .float-left {
      float: left !important; }

    .float-right {
      float: right !important; }

    .float-none {
      float: none !important; }

    @media (min-width: 576px) {
      .float-sm-left {
        float: left !important; }
      .float-sm-right {
        float: right !important; }
      .float-sm-none {
        float: none !important; } }

    @media (min-width: 768px) {
      .float-md-left {
        float: left !important; }
      .float-md-right {
        float: right !important; }
      .float-md-none {
        float: none !important; } }

    @media (min-width: 992px) {
      .float-lg-left {
        float: left !important; }
      .float-lg-right {
        float: right !important; }
      .float-lg-none {
        float: none !important; } }

    @media (min-width: 1200px) {
      .float-xl-left {
        float: left !important; }
      .float-xl-right {
        float: right !important; }
      .float-xl-none {
        float: none !important; } }

    .overflow-auto {
      overflow: auto !important; }

    .overflow-hidden {
      overflow: hidden !important; }

    .position-static {
      position: static !important; }

    .position-relative {
      position: relative !important; }

    .position-absolute {
      position: absolute !important; }

    .position-fixed {
      position: fixed !important; }

    .position-sticky {
      position: -webkit-sticky !important;
      position: sticky !important; }

    .fixed-top {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030; }

    .fixed-bottom {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1030; }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020; } }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0; }

    .sr-only-focusable:active, .sr-only-focusable:focus {
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal; }

    .shadow-sm {
      box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.09) !important; }

    .shadow {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important; }

    .shadow-lg {
      box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.09) !important; }

    .shadow-none {
      box-shadow: none !important; }

    .w-25 {
      width: 25% !important; }

    .w-50 {
      width: 50% !important; }

    .w-75 {
      width: 75% !important; }

    .w-100 {
      width: 100% !important; }

    .w-auto {
      width: auto !important; }

    .h-25 {
      height: 25% !important; }

    .h-50 {
      height: 50% !important; }

    .h-75 {
      height: 75% !important; }

    .h-100 {
      height: 100% !important; }

    .h-auto {
      height: auto !important; }

    .mw-100 {
      max-width: 100% !important; }

    .mh-100 {
      max-height: 100% !important; }

    .min-vw-100 {
      min-width: 100vw !important; }

    .min-vh-100 {
      min-height: 100vh !important; }

    .vw-100 {
      width: 100vw !important; }

    .vh-100 {
      height: 100vh !important; }

    .stretched-link::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
      background-color: rgba(0, 0, 0, 0); }

    .m-0 {
      margin: 0 !important; }

    .mt-0,
    .my-0 {
      margin-top: 0 !important; }

    .mr-0,
    .mx-0 {
      margin-right: 0 !important; }

    .mb-0,
    .my-0 {
      margin-bottom: 0 !important; }

    .ml-0,
    .mx-0 {
      margin-left: 0 !important; }

    .m-1 {
      margin: 0.25rem !important; }

    .mt-1,
    .my-1 {
      margin-top: 0.25rem !important; }

    .mr-1,
    .mx-1 {
      margin-right: 0.25rem !important; }

    .mb-1,
    .my-1 {
      margin-bottom: 0.25rem !important; }

    .ml-1,
    .mx-1 {
      margin-left: 0.25rem !important; }

    .m-2 {
      margin: 0.5rem !important; }

    .mt-2,
    .my-2 {
      margin-top: 0.5rem !important; }

    .mr-2,
    .mx-2 {
      margin-right: 0.5rem !important; }

    .mb-2,
    .my-2 {
      margin-bottom: 0.5rem !important; }

    .ml-2,
    .mx-2 {
      margin-left: 0.5rem !important; }

    .m-3 {
      margin: 1rem !important; }

    .mt-3,
    .my-3 {
      margin-top: 1rem !important; }

    .mr-3,
    .mx-3 {
      margin-right: 1rem !important; }

    .mb-3,
    .my-3 {
      margin-bottom: 1rem !important; }

    .ml-3,
    .mx-3 {
      margin-left: 1rem !important; }

    .m-4 {
      margin: 1.5rem !important; }

    .mt-4,
    .my-4 {
      margin-top: 1.5rem !important; }

    .mr-4,
    .mx-4 {
      margin-right: 1.5rem !important; }

    .mb-4,
    .my-4 {
      margin-bottom: 1.5rem !important; }

    .ml-4,
    .mx-4 {
      margin-left: 1.5rem !important; }

    .m-5 {
      margin: 3rem !important; }

    .mt-5,
    .my-5 {
      margin-top: 3rem !important; }

    .mr-5,
    .mx-5 {
      margin-right: 3rem !important; }

    .mb-5,
    .my-5 {
      margin-bottom: 3rem !important; }

    .ml-5,
    .mx-5 {
      margin-left: 3rem !important; }

    .p-0 {
      padding: 0 !important; }

    .pt-0,
    .py-0 {
      padding-top: 0 !important; }

    .pr-0,
    .px-0 {
      padding-right: 0 !important; }

    .pb-0,
    .py-0 {
      padding-bottom: 0 !important; }

    .pl-0,
    .px-0 {
      padding-left: 0 !important; }

    .p-1 {
      padding: 0.25rem !important; }

    .pt-1,
    .py-1 {
      padding-top: 0.25rem !important; }

    .pr-1,
    .px-1 {
      padding-right: 0.25rem !important; }

    .pb-1,
    .py-1 {
      padding-bottom: 0.25rem !important; }

    .pl-1,
    .px-1 {
      padding-left: 0.25rem !important; }

    .p-2 {
      padding: 0.5rem !important; }

    .pt-2,
    .py-2 {
      padding-top: 0.5rem !important; }

    .pr-2,
    .px-2 {
      padding-right: 0.5rem !important; }

    .pb-2,
    .py-2 {
      padding-bottom: 0.5rem !important; }

    .pl-2,
    .px-2 {
      padding-left: 0.5rem !important; }

    .p-3 {
      padding: 1rem !important; }

    .pt-3,
    .py-3 {
      padding-top: 1rem !important; }

    .pr-3,
    .px-3 {
      padding-right: 1rem !important; }

    .pb-3,
    .py-3 {
      padding-bottom: 1rem !important; }

    .pl-3,
    .px-3 {
      padding-left: 1rem !important; }

    .p-4 {
      padding: 1.5rem !important; }

    .pt-4,
    .py-4 {
      padding-top: 1.5rem !important; }

    .pr-4,
    .px-4 {
      padding-right: 1.5rem !important; }

    .pb-4,
    .py-4 {
      padding-bottom: 1.5rem !important; }

    .pl-4,
    .px-4 {
      padding-left: 1.5rem !important; }

    .p-5 {
      padding: 3rem !important; }

    .pt-5,
    .py-5 {
      padding-top: 3rem !important; }

    .pr-5,
    .px-5 {
      padding-right: 3rem !important; }

    .pb-5,
    .py-5 {
      padding-bottom: 3rem !important; }

    .pl-5,
    .px-5 {
      padding-left: 3rem !important; }

    .m-n1 {
      margin: -0.25rem !important; }

    .mt-n1,
    .my-n1 {
      margin-top: -0.25rem !important; }

    .mr-n1,
    .mx-n1 {
      margin-right: -0.25rem !important; }

    .mb-n1,
    .my-n1 {
      margin-bottom: -0.25rem !important; }

    .ml-n1,
    .mx-n1 {
      margin-left: -0.25rem !important; }

    .m-n2 {
      margin: -0.5rem !important; }

    .mt-n2,
    .my-n2 {
      margin-top: -0.5rem !important; }

    .mr-n2,
    .mx-n2 {
      margin-right: -0.5rem !important; }

    .mb-n2,
    .my-n2 {
      margin-bottom: -0.5rem !important; }

    .ml-n2,
    .mx-n2 {
      margin-left: -0.5rem !important; }

    .m-n3 {
      margin: -1rem !important; }

    .mt-n3,
    .my-n3 {
      margin-top: -1rem !important; }

    .mr-n3,
    .mx-n3 {
      margin-right: -1rem !important; }

    .mb-n3,
    .my-n3 {
      margin-bottom: -1rem !important; }

    .ml-n3,
    .mx-n3 {
      margin-left: -1rem !important; }

    .m-n4 {
      margin: -1.5rem !important; }

    .mt-n4,
    .my-n4 {
      margin-top: -1.5rem !important; }

    .mr-n4,
    .mx-n4 {
      margin-right: -1.5rem !important; }

    .mb-n4,
    .my-n4 {
      margin-bottom: -1.5rem !important; }

    .ml-n4,
    .mx-n4 {
      margin-left: -1.5rem !important; }

    .m-n5 {
      margin: -3rem !important; }

    .mt-n5,
    .my-n5 {
      margin-top: -3rem !important; }

    .mr-n5,
    .mx-n5 {
      margin-right: -3rem !important; }

    .mb-n5,
    .my-n5 {
      margin-bottom: -3rem !important; }

    .ml-n5,
    .mx-n5 {
      margin-left: -3rem !important; }

    .m-auto {
      margin: auto !important; }

    .mt-auto,
    .my-auto {
      margin-top: auto !important; }

    .mr-auto,
    .mx-auto {
      margin-right: auto !important; }

    .mb-auto,
    .my-auto {
      margin-bottom: auto !important; }

    .ml-auto,
    .mx-auto {
      margin-left: auto !important; }

    @media (min-width: 576px) {
      .m-sm-0 {
        margin: 0 !important; }
      .mt-sm-0,
      .my-sm-0 {
        margin-top: 0 !important; }
      .mr-sm-0,
      .mx-sm-0 {
        margin-right: 0 !important; }
      .mb-sm-0,
      .my-sm-0 {
        margin-bottom: 0 !important; }
      .ml-sm-0,
      .mx-sm-0 {
        margin-left: 0 !important; }
      .m-sm-1 {
        margin: 0.25rem !important; }
      .mt-sm-1,
      .my-sm-1 {
        margin-top: 0.25rem !important; }
      .mr-sm-1,
      .mx-sm-1 {
        margin-right: 0.25rem !important; }
      .mb-sm-1,
      .my-sm-1 {
        margin-bottom: 0.25rem !important; }
      .ml-sm-1,
      .mx-sm-1 {
        margin-left: 0.25rem !important; }
      .m-sm-2 {
        margin: 0.5rem !important; }
      .mt-sm-2,
      .my-sm-2 {
        margin-top: 0.5rem !important; }
      .mr-sm-2,
      .mx-sm-2 {
        margin-right: 0.5rem !important; }
      .mb-sm-2,
      .my-sm-2 {
        margin-bottom: 0.5rem !important; }
      .ml-sm-2,
      .mx-sm-2 {
        margin-left: 0.5rem !important; }
      .m-sm-3 {
        margin: 1rem !important; }
      .mt-sm-3,
      .my-sm-3 {
        margin-top: 1rem !important; }
      .mr-sm-3,
      .mx-sm-3 {
        margin-right: 1rem !important; }
      .mb-sm-3,
      .my-sm-3 {
        margin-bottom: 1rem !important; }
      .ml-sm-3,
      .mx-sm-3 {
        margin-left: 1rem !important; }
      .m-sm-4 {
        margin: 1.5rem !important; }
      .mt-sm-4,
      .my-sm-4 {
        margin-top: 1.5rem !important; }
      .mr-sm-4,
      .mx-sm-4 {
        margin-right: 1.5rem !important; }
      .mb-sm-4,
      .my-sm-4 {
        margin-bottom: 1.5rem !important; }
      .ml-sm-4,
      .mx-sm-4 {
        margin-left: 1.5rem !important; }
      .m-sm-5 {
        margin: 3rem !important; }
      .mt-sm-5,
      .my-sm-5 {
        margin-top: 3rem !important; }
      .mr-sm-5,
      .mx-sm-5 {
        margin-right: 3rem !important; }
      .mb-sm-5,
      .my-sm-5 {
        margin-bottom: 3rem !important; }
      .ml-sm-5,
      .mx-sm-5 {
        margin-left: 3rem !important; }
      .p-sm-0 {
        padding: 0 !important; }
      .pt-sm-0,
      .py-sm-0 {
        padding-top: 0 !important; }
      .pr-sm-0,
      .px-sm-0 {
        padding-right: 0 !important; }
      .pb-sm-0,
      .py-sm-0 {
        padding-bottom: 0 !important; }
      .pl-sm-0,
      .px-sm-0 {
        padding-left: 0 !important; }
      .p-sm-1 {
        padding: 0.25rem !important; }
      .pt-sm-1,
      .py-sm-1 {
        padding-top: 0.25rem !important; }
      .pr-sm-1,
      .px-sm-1 {
        padding-right: 0.25rem !important; }
      .pb-sm-1,
      .py-sm-1 {
        padding-bottom: 0.25rem !important; }
      .pl-sm-1,
      .px-sm-1 {
        padding-left: 0.25rem !important; }
      .p-sm-2 {
        padding: 0.5rem !important; }
      .pt-sm-2,
      .py-sm-2 {
        padding-top: 0.5rem !important; }
      .pr-sm-2,
      .px-sm-2 {
        padding-right: 0.5rem !important; }
      .pb-sm-2,
      .py-sm-2 {
        padding-bottom: 0.5rem !important; }
      .pl-sm-2,
      .px-sm-2 {
        padding-left: 0.5rem !important; }
      .p-sm-3 {
        padding: 1rem !important; }
      .pt-sm-3,
      .py-sm-3 {
        padding-top: 1rem !important; }
      .pr-sm-3,
      .px-sm-3 {
        padding-right: 1rem !important; }
      .pb-sm-3,
      .py-sm-3 {
        padding-bottom: 1rem !important; }
      .pl-sm-3,
      .px-sm-3 {
        padding-left: 1rem !important; }
      .p-sm-4 {
        padding: 1.5rem !important; }
      .pt-sm-4,
      .py-sm-4 {
        padding-top: 1.5rem !important; }
      .pr-sm-4,
      .px-sm-4 {
        padding-right: 1.5rem !important; }
      .pb-sm-4,
      .py-sm-4 {
        padding-bottom: 1.5rem !important; }
      .pl-sm-4,
      .px-sm-4 {
        padding-left: 1.5rem !important; }
      .p-sm-5 {
        padding: 3rem !important; }
      .pt-sm-5,
      .py-sm-5 {
        padding-top: 3rem !important; }
      .pr-sm-5,
      .px-sm-5 {
        padding-right: 3rem !important; }
      .pb-sm-5,
      .py-sm-5 {
        padding-bottom: 3rem !important; }
      .pl-sm-5,
      .px-sm-5 {
        padding-left: 3rem !important; }
      .m-sm-n1 {
        margin: -0.25rem !important; }
      .mt-sm-n1,
      .my-sm-n1 {
        margin-top: -0.25rem !important; }
      .mr-sm-n1,
      .mx-sm-n1 {
        margin-right: -0.25rem !important; }
      .mb-sm-n1,
      .my-sm-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-sm-n1,
      .mx-sm-n1 {
        margin-left: -0.25rem !important; }
      .m-sm-n2 {
        margin: -0.5rem !important; }
      .mt-sm-n2,
      .my-sm-n2 {
        margin-top: -0.5rem !important; }
      .mr-sm-n2,
      .mx-sm-n2 {
        margin-right: -0.5rem !important; }
      .mb-sm-n2,
      .my-sm-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-sm-n2,
      .mx-sm-n2 {
        margin-left: -0.5rem !important; }
      .m-sm-n3 {
        margin: -1rem !important; }
      .mt-sm-n3,
      .my-sm-n3 {
        margin-top: -1rem !important; }
      .mr-sm-n3,
      .mx-sm-n3 {
        margin-right: -1rem !important; }
      .mb-sm-n3,
      .my-sm-n3 {
        margin-bottom: -1rem !important; }
      .ml-sm-n3,
      .mx-sm-n3 {
        margin-left: -1rem !important; }
      .m-sm-n4 {
        margin: -1.5rem !important; }
      .mt-sm-n4,
      .my-sm-n4 {
        margin-top: -1.5rem !important; }
      .mr-sm-n4,
      .mx-sm-n4 {
        margin-right: -1.5rem !important; }
      .mb-sm-n4,
      .my-sm-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-sm-n4,
      .mx-sm-n4 {
        margin-left: -1.5rem !important; }
      .m-sm-n5 {
        margin: -3rem !important; }
      .mt-sm-n5,
      .my-sm-n5 {
        margin-top: -3rem !important; }
      .mr-sm-n5,
      .mx-sm-n5 {
        margin-right: -3rem !important; }
      .mb-sm-n5,
      .my-sm-n5 {
        margin-bottom: -3rem !important; }
      .ml-sm-n5,
      .mx-sm-n5 {
        margin-left: -3rem !important; }
      .m-sm-auto {
        margin: auto !important; }
      .mt-sm-auto,
      .my-sm-auto {
        margin-top: auto !important; }
      .mr-sm-auto,
      .mx-sm-auto {
        margin-right: auto !important; }
      .mb-sm-auto,
      .my-sm-auto {
        margin-bottom: auto !important; }
      .ml-sm-auto,
      .mx-sm-auto {
        margin-left: auto !important; } }

    @media (min-width: 768px) {
      .m-md-0 {
        margin: 0 !important; }
      .mt-md-0,
      .my-md-0 {
        margin-top: 0 !important; }
      .mr-md-0,
      .mx-md-0 {
        margin-right: 0 !important; }
      .mb-md-0,
      .my-md-0 {
        margin-bottom: 0 !important; }
      .ml-md-0,
      .mx-md-0 {
        margin-left: 0 !important; }
      .m-md-1 {
        margin: 0.25rem !important; }
      .mt-md-1,
      .my-md-1 {
        margin-top: 0.25rem !important; }
      .mr-md-1,
      .mx-md-1 {
        margin-right: 0.25rem !important; }
      .mb-md-1,
      .my-md-1 {
        margin-bottom: 0.25rem !important; }
      .ml-md-1,
      .mx-md-1 {
        margin-left: 0.25rem !important; }
      .m-md-2 {
        margin: 0.5rem !important; }
      .mt-md-2,
      .my-md-2 {
        margin-top: 0.5rem !important; }
      .mr-md-2,
      .mx-md-2 {
        margin-right: 0.5rem !important; }
      .mb-md-2,
      .my-md-2 {
        margin-bottom: 0.5rem !important; }
      .ml-md-2,
      .mx-md-2 {
        margin-left: 0.5rem !important; }
      .m-md-3 {
        margin: 1rem !important; }
      .mt-md-3,
      .my-md-3 {
        margin-top: 1rem !important; }
      .mr-md-3,
      .mx-md-3 {
        margin-right: 1rem !important; }
      .mb-md-3,
      .my-md-3 {
        margin-bottom: 1rem !important; }
      .ml-md-3,
      .mx-md-3 {
        margin-left: 1rem !important; }
      .m-md-4 {
        margin: 1.5rem !important; }
      .mt-md-4,
      .my-md-4 {
        margin-top: 1.5rem !important; }
      .mr-md-4,
      .mx-md-4 {
        margin-right: 1.5rem !important; }
      .mb-md-4,
      .my-md-4 {
        margin-bottom: 1.5rem !important; }
      .ml-md-4,
      .mx-md-4 {
        margin-left: 1.5rem !important; }
      .m-md-5 {
        margin: 3rem !important; }
      .mt-md-5,
      .my-md-5 {
        margin-top: 3rem !important; }
      .mr-md-5,
      .mx-md-5 {
        margin-right: 3rem !important; }
      .mb-md-5,
      .my-md-5 {
        margin-bottom: 3rem !important; }
      .ml-md-5,
      .mx-md-5 {
        margin-left: 3rem !important; }
      .p-md-0 {
        padding: 0 !important; }
      .pt-md-0,
      .py-md-0 {
        padding-top: 0 !important; }
      .pr-md-0,
      .px-md-0 {
        padding-right: 0 !important; }
      .pb-md-0,
      .py-md-0 {
        padding-bottom: 0 !important; }
      .pl-md-0,
      .px-md-0 {
        padding-left: 0 !important; }
      .p-md-1 {
        padding: 0.25rem !important; }
      .pt-md-1,
      .py-md-1 {
        padding-top: 0.25rem !important; }
      .pr-md-1,
      .px-md-1 {
        padding-right: 0.25rem !important; }
      .pb-md-1,
      .py-md-1 {
        padding-bottom: 0.25rem !important; }
      .pl-md-1,
      .px-md-1 {
        padding-left: 0.25rem !important; }
      .p-md-2 {
        padding: 0.5rem !important; }
      .pt-md-2,
      .py-md-2 {
        padding-top: 0.5rem !important; }
      .pr-md-2,
      .px-md-2 {
        padding-right: 0.5rem !important; }
      .pb-md-2,
      .py-md-2 {
        padding-bottom: 0.5rem !important; }
      .pl-md-2,
      .px-md-2 {
        padding-left: 0.5rem !important; }
      .p-md-3 {
        padding: 1rem !important; }
      .pt-md-3,
      .py-md-3 {
        padding-top: 1rem !important; }
      .pr-md-3,
      .px-md-3 {
        padding-right: 1rem !important; }
      .pb-md-3,
      .py-md-3 {
        padding-bottom: 1rem !important; }
      .pl-md-3,
      .px-md-3 {
        padding-left: 1rem !important; }
      .p-md-4 {
        padding: 1.5rem !important; }
      .pt-md-4,
      .py-md-4 {
        padding-top: 1.5rem !important; }
      .pr-md-4,
      .px-md-4 {
        padding-right: 1.5rem !important; }
      .pb-md-4,
      .py-md-4 {
        padding-bottom: 1.5rem !important; }
      .pl-md-4,
      .px-md-4 {
        padding-left: 1.5rem !important; }
      .p-md-5 {
        padding: 3rem !important; }
      .pt-md-5,
      .py-md-5 {
        padding-top: 3rem !important; }
      .pr-md-5,
      .px-md-5 {
        padding-right: 3rem !important; }
      .pb-md-5,
      .py-md-5 {
        padding-bottom: 3rem !important; }
      .pl-md-5,
      .px-md-5 {
        padding-left: 3rem !important; }
      .m-md-n1 {
        margin: -0.25rem !important; }
      .mt-md-n1,
      .my-md-n1 {
        margin-top: -0.25rem !important; }
      .mr-md-n1,
      .mx-md-n1 {
        margin-right: -0.25rem !important; }
      .mb-md-n1,
      .my-md-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-md-n1,
      .mx-md-n1 {
        margin-left: -0.25rem !important; }
      .m-md-n2 {
        margin: -0.5rem !important; }
      .mt-md-n2,
      .my-md-n2 {
        margin-top: -0.5rem !important; }
      .mr-md-n2,
      .mx-md-n2 {
        margin-right: -0.5rem !important; }
      .mb-md-n2,
      .my-md-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-md-n2,
      .mx-md-n2 {
        margin-left: -0.5rem !important; }
      .m-md-n3 {
        margin: -1rem !important; }
      .mt-md-n3,
      .my-md-n3 {
        margin-top: -1rem !important; }
      .mr-md-n3,
      .mx-md-n3 {
        margin-right: -1rem !important; }
      .mb-md-n3,
      .my-md-n3 {
        margin-bottom: -1rem !important; }
      .ml-md-n3,
      .mx-md-n3 {
        margin-left: -1rem !important; }
      .m-md-n4 {
        margin: -1.5rem !important; }
      .mt-md-n4,
      .my-md-n4 {
        margin-top: -1.5rem !important; }
      .mr-md-n4,
      .mx-md-n4 {
        margin-right: -1.5rem !important; }
      .mb-md-n4,
      .my-md-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-md-n4,
      .mx-md-n4 {
        margin-left: -1.5rem !important; }
      .m-md-n5 {
        margin: -3rem !important; }
      .mt-md-n5,
      .my-md-n5 {
        margin-top: -3rem !important; }
      .mr-md-n5,
      .mx-md-n5 {
        margin-right: -3rem !important; }
      .mb-md-n5,
      .my-md-n5 {
        margin-bottom: -3rem !important; }
      .ml-md-n5,
      .mx-md-n5 {
        margin-left: -3rem !important; }
      .m-md-auto {
        margin: auto !important; }
      .mt-md-auto,
      .my-md-auto {
        margin-top: auto !important; }
      .mr-md-auto,
      .mx-md-auto {
        margin-right: auto !important; }
      .mb-md-auto,
      .my-md-auto {
        margin-bottom: auto !important; }
      .ml-md-auto,
      .mx-md-auto {
        margin-left: auto !important; } }

    @media (min-width: 992px) {
      .m-lg-0 {
        margin: 0 !important; }
      .mt-lg-0,
      .my-lg-0 {
        margin-top: 0 !important; }
      .mr-lg-0,
      .mx-lg-0 {
        margin-right: 0 !important; }
      .mb-lg-0,
      .my-lg-0 {
        margin-bottom: 0 !important; }
      .ml-lg-0,
      .mx-lg-0 {
        margin-left: 0 !important; }
      .m-lg-1 {
        margin: 0.25rem !important; }
      .mt-lg-1,
      .my-lg-1 {
        margin-top: 0.25rem !important; }
      .mr-lg-1,
      .mx-lg-1 {
        margin-right: 0.25rem !important; }
      .mb-lg-1,
      .my-lg-1 {
        margin-bottom: 0.25rem !important; }
      .ml-lg-1,
      .mx-lg-1 {
        margin-left: 0.25rem !important; }
      .m-lg-2 {
        margin: 0.5rem !important; }
      .mt-lg-2,
      .my-lg-2 {
        margin-top: 0.5rem !important; }
      .mr-lg-2,
      .mx-lg-2 {
        margin-right: 0.5rem !important; }
      .mb-lg-2,
      .my-lg-2 {
        margin-bottom: 0.5rem !important; }
      .ml-lg-2,
      .mx-lg-2 {
        margin-left: 0.5rem !important; }
      .m-lg-3 {
        margin: 1rem !important; }
      .mt-lg-3,
      .my-lg-3 {
        margin-top: 1rem !important; }
      .mr-lg-3,
      .mx-lg-3 {
        margin-right: 1rem !important; }
      .mb-lg-3,
      .my-lg-3 {
        margin-bottom: 1rem !important; }
      .ml-lg-3,
      .mx-lg-3 {
        margin-left: 1rem !important; }
      .m-lg-4 {
        margin: 1.5rem !important; }
      .mt-lg-4,
      .my-lg-4 {
        margin-top: 1.5rem !important; }
      .mr-lg-4,
      .mx-lg-4 {
        margin-right: 1.5rem !important; }
      .mb-lg-4,
      .my-lg-4 {
        margin-bottom: 1.5rem !important; }
      .ml-lg-4,
      .mx-lg-4 {
        margin-left: 1.5rem !important; }
      .m-lg-5 {
        margin: 3rem !important; }
      .mt-lg-5,
      .my-lg-5 {
        margin-top: 3rem !important; }
      .mr-lg-5,
      .mx-lg-5 {
        margin-right: 3rem !important; }
      .mb-lg-5,
      .my-lg-5 {
        margin-bottom: 3rem !important; }
      .ml-lg-5,
      .mx-lg-5 {
        margin-left: 3rem !important; }
      .p-lg-0 {
        padding: 0 !important; }
      .pt-lg-0,
      .py-lg-0 {
        padding-top: 0 !important; }
      .pr-lg-0,
      .px-lg-0 {
        padding-right: 0 !important; }
      .pb-lg-0,
      .py-lg-0 {
        padding-bottom: 0 !important; }
      .pl-lg-0,
      .px-lg-0 {
        padding-left: 0 !important; }
      .p-lg-1 {
        padding: 0.25rem !important; }
      .pt-lg-1,
      .py-lg-1 {
        padding-top: 0.25rem !important; }
      .pr-lg-1,
      .px-lg-1 {
        padding-right: 0.25rem !important; }
      .pb-lg-1,
      .py-lg-1 {
        padding-bottom: 0.25rem !important; }
      .pl-lg-1,
      .px-lg-1 {
        padding-left: 0.25rem !important; }
      .p-lg-2 {
        padding: 0.5rem !important; }
      .pt-lg-2,
      .py-lg-2 {
        padding-top: 0.5rem !important; }
      .pr-lg-2,
      .px-lg-2 {
        padding-right: 0.5rem !important; }
      .pb-lg-2,
      .py-lg-2 {
        padding-bottom: 0.5rem !important; }
      .pl-lg-2,
      .px-lg-2 {
        padding-left: 0.5rem !important; }
      .p-lg-3 {
        padding: 1rem !important; }
      .pt-lg-3,
      .py-lg-3 {
        padding-top: 1rem !important; }
      .pr-lg-3,
      .px-lg-3 {
        padding-right: 1rem !important; }
      .pb-lg-3,
      .py-lg-3 {
        padding-bottom: 1rem !important; }
      .pl-lg-3,
      .px-lg-3 {
        padding-left: 1rem !important; }
      .p-lg-4 {
        padding: 1.5rem !important; }
      .pt-lg-4,
      .py-lg-4 {
        padding-top: 1.5rem !important; }
      .pr-lg-4,
      .px-lg-4 {
        padding-right: 1.5rem !important; }
      .pb-lg-4,
      .py-lg-4 {
        padding-bottom: 1.5rem !important; }
      .pl-lg-4,
      .px-lg-4 {
        padding-left: 1.5rem !important; }
      .p-lg-5 {
        padding: 3rem !important; }
      .pt-lg-5,
      .py-lg-5 {
        padding-top: 3rem !important; }
      .pr-lg-5,
      .px-lg-5 {
        padding-right: 3rem !important; }
      .pb-lg-5,
      .py-lg-5 {
        padding-bottom: 3rem !important; }
      .pl-lg-5,
      .px-lg-5 {
        padding-left: 3rem !important; }
      .m-lg-n1 {
        margin: -0.25rem !important; }
      .mt-lg-n1,
      .my-lg-n1 {
        margin-top: -0.25rem !important; }
      .mr-lg-n1,
      .mx-lg-n1 {
        margin-right: -0.25rem !important; }
      .mb-lg-n1,
      .my-lg-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-lg-n1,
      .mx-lg-n1 {
        margin-left: -0.25rem !important; }
      .m-lg-n2 {
        margin: -0.5rem !important; }
      .mt-lg-n2,
      .my-lg-n2 {
        margin-top: -0.5rem !important; }
      .mr-lg-n2,
      .mx-lg-n2 {
        margin-right: -0.5rem !important; }
      .mb-lg-n2,
      .my-lg-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-lg-n2,
      .mx-lg-n2 {
        margin-left: -0.5rem !important; }
      .m-lg-n3 {
        margin: -1rem !important; }
      .mt-lg-n3,
      .my-lg-n3 {
        margin-top: -1rem !important; }
      .mr-lg-n3,
      .mx-lg-n3 {
        margin-right: -1rem !important; }
      .mb-lg-n3,
      .my-lg-n3 {
        margin-bottom: -1rem !important; }
      .ml-lg-n3,
      .mx-lg-n3 {
        margin-left: -1rem !important; }
      .m-lg-n4 {
        margin: -1.5rem !important; }
      .mt-lg-n4,
      .my-lg-n4 {
        margin-top: -1.5rem !important; }
      .mr-lg-n4,
      .mx-lg-n4 {
        margin-right: -1.5rem !important; }
      .mb-lg-n4,
      .my-lg-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-lg-n4,
      .mx-lg-n4 {
        margin-left: -1.5rem !important; }
      .m-lg-n5 {
        margin: -3rem !important; }
      .mt-lg-n5,
      .my-lg-n5 {
        margin-top: -3rem !important; }
      .mr-lg-n5,
      .mx-lg-n5 {
        margin-right: -3rem !important; }
      .mb-lg-n5,
      .my-lg-n5 {
        margin-bottom: -3rem !important; }
      .ml-lg-n5,
      .mx-lg-n5 {
        margin-left: -3rem !important; }
      .m-lg-auto {
        margin: auto !important; }
      .mt-lg-auto,
      .my-lg-auto {
        margin-top: auto !important; }
      .mr-lg-auto,
      .mx-lg-auto {
        margin-right: auto !important; }
      .mb-lg-auto,
      .my-lg-auto {
        margin-bottom: auto !important; }
      .ml-lg-auto,
      .mx-lg-auto {
        margin-left: auto !important; } }

    @media (min-width: 1200px) {
      .m-xl-0 {
        margin: 0 !important; }
      .mt-xl-0,
      .my-xl-0 {
        margin-top: 0 !important; }
      .mr-xl-0,
      .mx-xl-0 {
        margin-right: 0 !important; }
      .mb-xl-0,
      .my-xl-0 {
        margin-bottom: 0 !important; }
      .ml-xl-0,
      .mx-xl-0 {
        margin-left: 0 !important; }
      .m-xl-1 {
        margin: 0.25rem !important; }
      .mt-xl-1,
      .my-xl-1 {
        margin-top: 0.25rem !important; }
      .mr-xl-1,
      .mx-xl-1 {
        margin-right: 0.25rem !important; }
      .mb-xl-1,
      .my-xl-1 {
        margin-bottom: 0.25rem !important; }
      .ml-xl-1,
      .mx-xl-1 {
        margin-left: 0.25rem !important; }
      .m-xl-2 {
        margin: 0.5rem !important; }
      .mt-xl-2,
      .my-xl-2 {
        margin-top: 0.5rem !important; }
      .mr-xl-2,
      .mx-xl-2 {
        margin-right: 0.5rem !important; }
      .mb-xl-2,
      .my-xl-2 {
        margin-bottom: 0.5rem !important; }
      .ml-xl-2,
      .mx-xl-2 {
        margin-left: 0.5rem !important; }
      .m-xl-3 {
        margin: 1rem !important; }
      .mt-xl-3,
      .my-xl-3 {
        margin-top: 1rem !important; }
      .mr-xl-3,
      .mx-xl-3 {
        margin-right: 1rem !important; }
      .mb-xl-3,
      .my-xl-3 {
        margin-bottom: 1rem !important; }
      .ml-xl-3,
      .mx-xl-3 {
        margin-left: 1rem !important; }
      .m-xl-4 {
        margin: 1.5rem !important; }
      .mt-xl-4,
      .my-xl-4 {
        margin-top: 1.5rem !important; }
      .mr-xl-4,
      .mx-xl-4 {
        margin-right: 1.5rem !important; }
      .mb-xl-4,
      .my-xl-4 {
        margin-bottom: 1.5rem !important; }
      .ml-xl-4,
      .mx-xl-4 {
        margin-left: 1.5rem !important; }
      .m-xl-5 {
        margin: 3rem !important; }
      .mt-xl-5,
      .my-xl-5 {
        margin-top: 3rem !important; }
      .mr-xl-5,
      .mx-xl-5 {
        margin-right: 3rem !important; }
      .mb-xl-5,
      .my-xl-5 {
        margin-bottom: 3rem !important; }
      .ml-xl-5,
      .mx-xl-5 {
        margin-left: 3rem !important; }
      .p-xl-0 {
        padding: 0 !important; }
      .pt-xl-0,
      .py-xl-0 {
        padding-top: 0 !important; }
      .pr-xl-0,
      .px-xl-0 {
        padding-right: 0 !important; }
      .pb-xl-0,
      .py-xl-0 {
        padding-bottom: 0 !important; }
      .pl-xl-0,
      .px-xl-0 {
        padding-left: 0 !important; }
      .p-xl-1 {
        padding: 0.25rem !important; }
      .pt-xl-1,
      .py-xl-1 {
        padding-top: 0.25rem !important; }
      .pr-xl-1,
      .px-xl-1 {
        padding-right: 0.25rem !important; }
      .pb-xl-1,
      .py-xl-1 {
        padding-bottom: 0.25rem !important; }
      .pl-xl-1,
      .px-xl-1 {
        padding-left: 0.25rem !important; }
      .p-xl-2 {
        padding: 0.5rem !important; }
      .pt-xl-2,
      .py-xl-2 {
        padding-top: 0.5rem !important; }
      .pr-xl-2,
      .px-xl-2 {
        padding-right: 0.5rem !important; }
      .pb-xl-2,
      .py-xl-2 {
        padding-bottom: 0.5rem !important; }
      .pl-xl-2,
      .px-xl-2 {
        padding-left: 0.5rem !important; }
      .p-xl-3 {
        padding: 1rem !important; }
      .pt-xl-3,
      .py-xl-3 {
        padding-top: 1rem !important; }
      .pr-xl-3,
      .px-xl-3 {
        padding-right: 1rem !important; }
      .pb-xl-3,
      .py-xl-3 {
        padding-bottom: 1rem !important; }
      .pl-xl-3,
      .px-xl-3 {
        padding-left: 1rem !important; }
      .p-xl-4 {
        padding: 1.5rem !important; }
      .pt-xl-4,
      .py-xl-4 {
        padding-top: 1.5rem !important; }
      .pr-xl-4,
      .px-xl-4 {
        padding-right: 1.5rem !important; }
      .pb-xl-4,
      .py-xl-4 {
        padding-bottom: 1.5rem !important; }
      .pl-xl-4,
      .px-xl-4 {
        padding-left: 1.5rem !important; }
      .p-xl-5 {
        padding: 3rem !important; }
      .pt-xl-5,
      .py-xl-5 {
        padding-top: 3rem !important; }
      .pr-xl-5,
      .px-xl-5 {
        padding-right: 3rem !important; }
      .pb-xl-5,
      .py-xl-5 {
        padding-bottom: 3rem !important; }
      .pl-xl-5,
      .px-xl-5 {
        padding-left: 3rem !important; }
      .m-xl-n1 {
        margin: -0.25rem !important; }
      .mt-xl-n1,
      .my-xl-n1 {
        margin-top: -0.25rem !important; }
      .mr-xl-n1,
      .mx-xl-n1 {
        margin-right: -0.25rem !important; }
      .mb-xl-n1,
      .my-xl-n1 {
        margin-bottom: -0.25rem !important; }
      .ml-xl-n1,
      .mx-xl-n1 {
        margin-left: -0.25rem !important; }
      .m-xl-n2 {
        margin: -0.5rem !important; }
      .mt-xl-n2,
      .my-xl-n2 {
        margin-top: -0.5rem !important; }
      .mr-xl-n2,
      .mx-xl-n2 {
        margin-right: -0.5rem !important; }
      .mb-xl-n2,
      .my-xl-n2 {
        margin-bottom: -0.5rem !important; }
      .ml-xl-n2,
      .mx-xl-n2 {
        margin-left: -0.5rem !important; }
      .m-xl-n3 {
        margin: -1rem !important; }
      .mt-xl-n3,
      .my-xl-n3 {
        margin-top: -1rem !important; }
      .mr-xl-n3,
      .mx-xl-n3 {
        margin-right: -1rem !important; }
      .mb-xl-n3,
      .my-xl-n3 {
        margin-bottom: -1rem !important; }
      .ml-xl-n3,
      .mx-xl-n3 {
        margin-left: -1rem !important; }
      .m-xl-n4 {
        margin: -1.5rem !important; }
      .mt-xl-n4,
      .my-xl-n4 {
        margin-top: -1.5rem !important; }
      .mr-xl-n4,
      .mx-xl-n4 {
        margin-right: -1.5rem !important; }
      .mb-xl-n4,
      .my-xl-n4 {
        margin-bottom: -1.5rem !important; }
      .ml-xl-n4,
      .mx-xl-n4 {
        margin-left: -1.5rem !important; }
      .m-xl-n5 {
        margin: -3rem !important; }
      .mt-xl-n5,
      .my-xl-n5 {
        margin-top: -3rem !important; }
      .mr-xl-n5,
      .mx-xl-n5 {
        margin-right: -3rem !important; }
      .mb-xl-n5,
      .my-xl-n5 {
        margin-bottom: -3rem !important; }
      .ml-xl-n5,
      .mx-xl-n5 {
        margin-left: -3rem !important; }
      .m-xl-auto {
        margin: auto !important; }
      .mt-xl-auto,
      .my-xl-auto {
        margin-top: auto !important; }
      .mr-xl-auto,
      .mx-xl-auto {
        margin-right: auto !important; }
      .mb-xl-auto,
      .my-xl-auto {
        margin-bottom: auto !important; }
      .ml-xl-auto,
      .mx-xl-auto {
        margin-left: auto !important; } }

    .text-monospace {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

    .text-justify {
      text-align: justify !important; }

    .text-wrap {
      white-space: normal !important; }

    .text-nowrap {
      white-space: nowrap !important; }

    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

    .text-left {
      text-align: left !important; }

    .text-right {
      text-align: right !important; }

    .text-center {
      text-align: center !important; }

    @media (min-width: 576px) {
      .text-sm-left {
        text-align: left !important; }
      .text-sm-right {
        text-align: right !important; }
      .text-sm-center {
        text-align: center !important; } }

    @media (min-width: 768px) {
      .text-md-left {
        text-align: left !important; }
      .text-md-right {
        text-align: right !important; }
      .text-md-center {
        text-align: center !important; } }

    @media (min-width: 992px) {
      .text-lg-left {
        text-align: left !important; }
      .text-lg-right {
        text-align: right !important; }
      .text-lg-center {
        text-align: center !important; } }

    @media (min-width: 1200px) {
      .text-xl-left {
        text-align: left !important; }
      .text-xl-right {
        text-align: right !important; }
      .text-xl-center {
        text-align: center !important; } }

    .text-lowercase {
      text-transform: lowercase !important; }

    .text-uppercase {
      text-transform: uppercase !important; }

    .text-capitalize {
      text-transform: capitalize !important; }

    .font-weight-light {
      font-weight: 400 !important; }

    .font-weight-lighter {
      font-weight: lighter !important; }

    .font-weight-normal {
      font-weight: 600 !important; }

    .font-weight-bold {
      font-weight: 700 !important; }

    .font-weight-bolder {
      font-weight: bolder !important; }

    .font-italic {
      font-style: italic !important; }

    .text-white {
      color: #fff !important; }

    .text-primary {
      color: #009b72 !important; }

    a.text-primary:hover, a.text-primary:focus {
      color: #004f3a !important; }

    .text-secondary {
      color: #EAEDF2 !important; }

    a.text-secondary:hover, a.text-secondary:focus {
      color: #bbc5d5 !important; }

    .text-success {
      color: #009b72 !important; }

    a.text-success:hover, a.text-success:focus {
      color: #004f3a !important; }

    .text-info {
      color: #17a2b8 !important; }

    a.text-info:hover, a.text-info:focus {
      color: #0f6674 !important; }

    .text-warning {
      color: #ffc107 !important; }

    a.text-warning:hover, a.text-warning:focus {
      color: #ba8b00 !important; }

    .text-danger {
      color: #dc3545 !important; }

    a.text-danger:hover, a.text-danger:focus {
      color: #a71d2a !important; }

    .text-light {
      color: #F7F9FC !important; }

    a.text-light:hover, a.text-light:focus {
      color: #bfcfe7 !important; }

    .text-dark {
      color: #2C3038 !important; }

    a.text-dark:hover, a.text-dark:focus {
      color: #0a0b0d !important; }

    .text-primary-2 {
      color: #046865 !important; }

    a.text-primary-2:hover, a.text-primary-2:focus {
      color: #011e1d !important; }

    .text-primary-3 {
      color: #192d35 !important; }

    a.text-primary-3:hover, a.text-primary-3:focus {
      color: #000101 !important; }

    .text-body {
      color: #555A64 !important; }

    .text-muted {
      color: rgba(85, 90, 100, 0.65) !important; }

    .text-black-50 {
      color: rgba(0, 0, 0, 0.5) !important; }

    .text-white-50 {
      color: rgba(255, 255, 255, 0.5) !important; }

    .text-hide {
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0; }

    .text-decoration-none {
      text-decoration: none !important; }

    .text-break {
      word-break: break-word !important;
      overflow-wrap: break-word !important; }

    .text-reset {
      color: inherit !important; }

    .visible {
      visibility: visible !important; }

    .invisible {
      visibility: hidden !important; }

    @media print {
      *,
      *::before,
      *::after {
        text-shadow: none !important;
        box-shadow: none !important; }
      a:not(.btn) {
        text-decoration: underline; }
      abbr[title]::after {
        content: " (" attr(title) ")"; }
      pre {
        white-space: pre-wrap !important; }
      pre,
      blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid; }
      thead {
        display: table-header-group; }
      tr,
      img {
        page-break-inside: avoid; }
      p,
      h2,
      h3 {
        orphans: 3;
        widows: 3; }
      h2,
      h3 {
        page-break-after: avoid; }
      @page {
        size: a3; }
      body {
        min-width: 992px !important; }
      .container {
        min-width: 992px !important; }
      .navbar {
        display: none; }
      .badge {
        border: 1px solid #000; }
      .table {
        border-collapse: collapse !important; }
        .table td,
        .table th {
          background-color: #fff !important; }
      .table-bordered th,
      .table-bordered td {
        border: 1px solid #EAEDF2 !important; }
      .table-dark {
        color: inherit; }
        .table-dark th,
        .table-dark td,
        .table-dark thead th,
        .table-dark tbody + tbody {
          border-color: #EAEDF2; }
      .table .thead-dark th {
        color: inherit;
        border-color: #EAEDF2; } }

    .badge {
      vertical-align: middle;
      letter-spacing: 0; }

    a.badge-primary {
      background-color: rgba(0, 155, 114, 0.1);
      color: #009b72; }

    a.badge-secondary {
      background-color: rgba(234, 237, 242, 0.1);
      color: #EAEDF2; }

    a.badge-success {
      background-color: rgba(0, 155, 114, 0.1);
      color: #009b72; }

    a.badge-info {
      background-color: rgba(23, 162, 184, 0.1);
      color: #17a2b8; }

    a.badge-warning {
      background-color: rgba(255, 193, 7, 0.1);
      color: #ffc107; }

    a.badge-danger {
      background-color: rgba(220, 53, 69, 0.1);
      color: #dc3545; }

    a.badge-light {
      background-color: rgba(247, 249, 252, 0.1);
      color: #F7F9FC; }

    a.badge-dark {
      background-color: rgba(44, 48, 56, 0.1);
      color: #2C3038; }

    a.badge-primary-2 {
      background-color: rgba(4, 104, 101, 0.1);
      color: #046865; }

    a.badge-primary-3 {
      background-color: rgba(25, 45, 53, 0.1);
      color: #192d35; }

    a.badge.badge-white {
      background: rgba(255, 255, 255, 0.1);
      color: #fff; }
      a.badge.badge-white:hover {
        background: rgba(255, 255, 255, 0.25); }

    .card-img-top + .badge {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem; }

    @media (max-width: 767.98px) {
      .btn-lg, .btn-group-lg > .btn {
        font-size: 1rem;
        padding: 0.625rem 1.5rem; } }

    .btn-outline-primary {
      border-color: rgba(0, 155, 114, 0.25); }
      .btn-outline-primary:hover {
        background-color: rgba(0, 155, 114, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #009b72; }

    .btn-outline-secondary {
      border-color: rgba(234, 237, 242, 0.25); }
      .btn-outline-secondary:hover {
        background-color: rgba(234, 237, 242, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #EAEDF2; }

    .btn-outline-success {
      border-color: rgba(0, 155, 114, 0.25); }
      .btn-outline-success:hover {
        background-color: rgba(0, 155, 114, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #009b72; }

    .btn-outline-info {
      border-color: rgba(23, 162, 184, 0.25); }
      .btn-outline-info:hover {
        background-color: rgba(23, 162, 184, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #17a2b8; }

    .btn-outline-warning {
      border-color: rgba(255, 193, 7, 0.25); }
      .btn-outline-warning:hover {
        background-color: rgba(255, 193, 7, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #ffc107; }

    .btn-outline-danger {
      border-color: rgba(220, 53, 69, 0.25); }
      .btn-outline-danger:hover {
        background-color: rgba(220, 53, 69, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #dc3545; }

    .btn-outline-light {
      border-color: rgba(247, 249, 252, 0.25); }
      .btn-outline-light:hover {
        background-color: rgba(247, 249, 252, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #F7F9FC; }

    .btn-outline-dark {
      border-color: rgba(44, 48, 56, 0.25); }
      .btn-outline-dark:hover {
        background-color: rgba(44, 48, 56, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #2C3038; }

    .btn-outline-primary-2 {
      border-color: rgba(4, 104, 101, 0.25); }
      .btn-outline-primary-2:hover {
        background-color: rgba(4, 104, 101, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #046865; }

    .btn-outline-primary-3 {
      border-color: rgba(25, 45, 53, 0.25); }
      .btn-outline-primary-3:hover {
        background-color: rgba(25, 45, 53, 0.2);
        border-color: rgba(0, 0, 0, 0);
        color: #192d35; }

    .btn > span {
      display: inline-block; }

    .btn .icon {
      height: 1.25rem; }
      .btn .icon:last-child:not(:only-child) {
        margin-right: -.5rem;
        margin-left: .5rem; }
      .btn .icon:first-child:not(:only-child) {
        margin-left: -.5rem;
        margin-right: .5rem; }

    .btn.rounded-circle {
      padding: 0;
      width: 3rem;
      height: 3rem;
      display: inline-flex;
      align-items: center;
      justify-content: center; }

    .btn.btn-sm.rounded-circle, .btn-group-sm > .btn.rounded-circle {
      width: 2.25rem;
      height: 2.25rem; }
      .btn.btn-sm.rounded-circle .icon, .btn-group-sm > .btn.rounded-circle .icon {
        height: 1rem; }

    .btn.btn-lg .icon, .btn-group-lg > .btn .icon {
      height: 2.25rem; }

    .btn.btn-lg.rounded-circle, .btn-group-lg > .btn.rounded-circle {
      width: 4.5rem;
      height: 4.5rem; }
      .btn.btn-lg.rounded-circle .icon, .btn-group-lg > .btn.rounded-circle .icon {
        height: 1.5rem; }

    .btn[class*='outline-']:focus .icon [stroke]:not([stroke="none"]) {
      stroke: #fff; }

    .btn[class*='outline-']:focus .icon [fill]:not([fill="none"]) {
      fill: #fff; }

    .btn-back-to-top {
      position: fixed;
      bottom: 2rem;
      right: 2rem;
      z-index: 1030; }

    .btn-loading {
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center; }
      .btn-loading:not(.btn-loading-animate) > span {
        vertical-align: baseline;
        display: block;
        line-height: 1; }
      .btn-loading .icon {
        display: none;
        margin-top: -.5em;
        margin-bottom: -.5em; }
      .btn-loading.btn-loading-animate .icon {
        display: inline-block; }
        .btn-loading.btn-loading-animate .icon path {
          -webkit-animation: spin .5s infinite linear;
                  animation: spin .5s infinite linear;
          transform-origin: 12px; }

    @-webkit-keyframes spin {
      0% {
        transform: rotateZ(0deg); }
      100% {
        transform: rotateZ(360deg); } }

    @keyframes spin {
      0% {
        transform: rotateZ(0deg); }
      100% {
        transform: rotateZ(360deg); } }

    .card.bg-white {
      border-color: rgba(0, 0, 0, 0);
      background-color: rgba(255, 255, 255, 0.05) !important; }
      .card.bg-white .card-footer {
        border-color: rgba(255, 255, 255, 0.1); }

    .card-footer {
      background-color: transparent; }

    @media (max-width: 767.98px) {
      .card-body {
        padding: 1.5rem; } }

    a.card {
      color: #555A64;
      font-weight: 600; }

    .bg-body-color {
      background: #555A64; }

    .bg-gradient {
      background: linear-gradient(to right, #009b72, #046865) !important; }

    .bg-primary-alt {
      background-color: rgba(0, 155, 114, 0.1); }

    .bg-secondary-alt {
      background-color: rgba(234, 237, 242, 0.1); }

    .bg-success-alt {
      background-color: rgba(0, 155, 114, 0.1); }

    .bg-info-alt {
      background-color: rgba(23, 162, 184, 0.1); }

    .bg-warning-alt {
      background-color: rgba(255, 193, 7, 0.1); }

    .bg-danger-alt {
      background-color: rgba(220, 53, 69, 0.1); }

    .bg-light-alt {
      background-color: rgba(247, 249, 252, 0.1); }

    .bg-dark-alt {
      background-color: rgba(44, 48, 56, 0.1); }

    .bg-primary-2-alt {
      background-color: rgba(4, 104, 101, 0.1); }

    .bg-primary-3-alt {
      background-color: rgba(25, 45, 53, 0.1); }

    .bg-white-alt {
      background-color: rgba(255, 255, 255, 0.1); }

    .container {
      position: relative;
      z-index: 1; }

    .dropdown-item {
      font-weight: 700;
      transition: all 0.2s ease-in-out; }

    .dropdown-header {
      padding-top: 0;
      padding-bottom: 0.5rem; }

    .dropdown-toggle {
      display: flex;
      align-items: center; }
      .dropdown-toggle:after {
        border: none !important;
        transition: all 0.2s ease-in-out;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0;
        transform: rotateZ(90deg);
        background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%23fff'/%3e%3c/svg%3e"); }
      .dropdown-toggle:not(:hover):after {
        opacity: .25; }

    .btn.rounded-circle.dropdown-toggle:after {
      display: none; }

    .dropup .dropdown-toggle:after {
      transform: rotateZ(-90deg);
      background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%23fff'/%3e%3c/svg%3e"); }

    .dropright .dropdown-toggle:after {
      transform: rotateZ(0deg);
      background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%23fff'/%3e%3c/svg%3e"); }

    .dropleft .dropdown-toggle:before {
      transform: rotateZ(360deg);
      background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%23fff'/%3e%3c/svg%3e"); }

    @media (max-width: 767.98px) {
      .dropdown-menu {
        padding: 0.75rem 0; }
      .dropdown-item {
        padding-left: 1rem;
        padding-right: 1rem; } }

    [data-toggle="dropdown-grid"] + .dropdown-menu {
      width: 100vw;
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none;
      border: none;
      border-radius: 0;
      background: none;
      margin-top: 0; }
      [data-toggle="dropdown-grid"] + .dropdown-menu .dropdown-menu {
        top: 0;
        margin-top: 0; }
      [data-toggle="dropdown-grid"] + .dropdown-menu .card {
        margin-bottom: 0; }
        [data-toggle="dropdown-grid"] + .dropdown-menu .card.card-sm {
          padding-top: 1rem;
          padding-bottom: 1rem; }

    .dropdown-menu.row {
      pointer-events: none;
      padding: 0; }
      .dropdown-menu.row.show {
        display: flex; }
      .dropdown-menu.row [class*='col'] {
        padding-right: 0;
        padding-left: 0; }

    [data-dropdown-content] {
      pointer-events: all; }

    [data-toggle="dropdown-grid"].dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      [data-toggle="dropdown-grid"].dropdown-item:after {
        content: '';
        transition: all 0.2s ease-in-out;
        opacity: .25;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 1.5rem;
        transform: rotateZ(0);
        background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%232C3038'/%3e%3c/svg%3e"); }
      [data-toggle="dropdown-grid"].dropdown-item:hover:after, [data-toggle="dropdown-grid"].dropdown-item:focus:after {
        transform: rotateZ(0);
        background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%23009b72'/%3e%3c/svg%3e"); }

    [data-toggle="dropdown-grid"]:hover:after, [data-toggle="dropdown-grid"]:focus:after {
      opacity: 1; }

    @media (max-width: 991.98px) {
      .navbar-expand-lg .dropdown-menu,
      .navbar-expand-lg [data-dropdown-content] {
        left: 0 !important; }
      .navbar-expand-lg [data-toggle="dropdown-grid"] + .dropdown-menu {
        width: auto;
        position: relative; }
      .navbar-expand-lg .dropdown-menu .dropdown .dropdown-toggle .icon {
        transform: rotateZ(270deg); } }

    body[data-dropdown-grid-hover="true"] [data-toggle="dropdown-grid"]:hover:before,
    body[data-dropdown-grid-hover="true"] [data-dropdown-content]:not(.container):hover:before,
    [data-toggle="dropdown-grid"][data-hover="true"]:hover:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3rem;
      top: 100%;
      left: 0; }

    body[data-dropdown-grid-hover="true"] [data-toggle="dropdown-grid"] + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before,
    body[data-dropdown-grid-hover="true"] [data-dropdown-content]:not(.container) + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before,
    [data-toggle="dropdown-grid"][data-hover="true"] + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before {
      width: 140%;
      height: 110%;
      top: 0;
      left: -20%; }

    [data-dropdown-content]:not(.container):hover:before {
      top: -1.5rem;
      left: -1.5rem;
      width: calc(100% + 3rem);
      height: calc(100% + 3rem);
      padding: 3rem; }

    @media (min-width: 992px) {
      .dropdown-grid-menu {
        background: #fff;
        border-radius: 0.3125rem;
        border: 1px solid #EAEDF2;
        padding: 1rem 0;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
        min-width: 10rem; } }

    .custom-control label, .custom-control input {
      cursor: pointer; }

    .custom-control label {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

    .custom-control.text-small label:before, .custom-control.blockquote-footer label:before, .custom-control.text-small label:after, .custom-control.blockquote-footer label:after {
      top: 0; }

    .form-group {
      position: relative; }
      .form-group .custom-select + .icon {
        position: absolute;
        transform: translateY(-50%);
        right: 0.75rem;
        top: 1.5rem;
        pointer-events: none; }
      .form-group .custom-select.custom-select-sm + .icon {
        top: 1.125rem; }
      .form-group .custom-select.custom-select-lg + .icon {
        top: 2.25rem;
        transform: translateY(-50%) scale(1.25); }

    .custom-select, .custom-file-input {
      cursor: pointer; }

    .custom-file-label:after {
      padding-left: 1.5rem;
      padding-right: 1.5rem; }

    @media (max-width: 767.98px) {
      .form-control-lg {
        font-size: 1rem;
        padding: 0.6875rem 0.75rem;
        height: calc(1.5em + 1.375rem + 2px); } }

    .input-group-text {
      transition: all 0.2s ease-in-out; }

    .input-group .form-control:last-child {
      border-left: 0; }

    .input-group:focus-within .input-group-text {
      border-color: #009b72; }

    [data-recaptcha]:not([data-size="invisible"]) {
      margin-bottom: 0.5rem; }

    .grecaptcha-badge {
      display: none !important; }

    .was-validated .form-control:invalid, .form-control.is-invalid {
      background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18 6L6 18' stroke='%23dc3545' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M6 6L18 18' stroke='%23dc3545' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e"); }

    .was-validated .form-control:valid, .form-control.is-valid {
      background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M20 6L9 17L4 12' stroke='%23009b72' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e"); }

    footer {
      padding: 3.75rem 0; }

    @media (min-width: 768px) {
      footer {
        padding: 6rem 0; } }

    @media (min-width: 992px) {
      footer {
        padding: 7.5rem 0; } }

    @media (min-width: 768px) {
      .footer-1 hr {
        margin-top: 6rem; } }

    .icon {
      flex-shrink: 0;
      width: auto;
      height: 2rem; }
      .icon.icon-xs {
        height: 1rem; }
        .icon.icon-xs path[stroke] {
          stroke-width: 3px; }
      .icon.icon-sm {
        height: 1.5rem; }
      .icon.icon-lg {
        height: 4.5rem; }
      .icon.icon-xl {
        height: 6rem; }

    @media (min-width: 768px) {
      .icon {
        height: 3rem; } }

    @media (max-width: 767.98px) {
      .media > h5 {
        font-size: 1rem; } }

    .media .icon.icon-sm {
      height: 2.25rem; }

    .navbar {
      z-index: 1020;
      width: 100%; }
      .navbar[data-overlay] {
        position: absolute; }
      .navbar[data-sticky="top"] {
        transition: all 0.2s ease-in-out;
        will-change: background-color; }
        .navbar[data-sticky="top"].scrolled {
          z-index: 1021; }

    .navbar-container {
      position: relative; }

    @media (max-width: 991.98px) {
      .navbar .dropdown-grid-menu {
        border-left: 2px solid #EAEDF2;
        padding: 0.25rem 0; }
        .navbar .dropdown-grid-menu [data-toggle="dropdown-grid"].dropdown-item {
          padding-left: 1rem; }
          .navbar .dropdown-grid-menu [data-toggle="dropdown-grid"].dropdown-item:after {
            transform: rotateZ(90deg); }
        .navbar .dropdown-grid-menu .dropdown-grid-menu {
          margin-left: 1rem; }
      .navbar .dropdown-toggle.arrow-bottom:before, .navbar .dropdown-toggle.arrow-top:before {
        display: none; } }

    @media (max-width: 767.98px) {
      .navbar {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; } }

    @media (max-width: 1199.98px) {
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }

    @media (min-width: 1200px) {
      .navbar-nav > .nav-item {
        font-size: 1.25rem; } }

    .navbar-dark .navbar-brand-dynamic-color svg [stroke]:not([stroke="none"]),
    .navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]) {
      stroke: #fff; }

    .navbar-dark .navbar-brand-dynamic-color svg [fill]:not([fill="none"]),
    .navbar-dark .navbar-toggler svg [fill]:not([fill="none"]) {
      fill: #fff; }

    .navbar-light .dropdown-toggle:after {
      transform: rotateZ(90deg);
      background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%232C3038'/%3e%3c/svg%3e"); }

    .navbar-toggler {
      border: none;
      position: relative; }
      .navbar-toggler svg {
        transition: all 0.2s ease-in-out; }
      .navbar-toggler[aria-expanded="true"] .navbar-toggler-open {
        opacity: 0;
        transform: scale(0.5) translateX(-100%); }
      .navbar-toggler[aria-expanded="false"] .navbar-toggler-close {
        opacity: 0;
        transform: scale(0.5) translateX(100%); }

    .navbar-toggler-close {
      position: absolute;
      left: 0;
      top: 0; }

    .navbar.scrolled, .navbar.navbar-toggled-show {
      background-color: #fff; }
      .navbar.scrolled.navbar-dark .navbar-nav .nav-link, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 0.5); }
        .navbar.scrolled.navbar-dark .navbar-nav .nav-link:hover, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .nav-link:hover {
          color: rgba(0, 0, 0, 0.7); }
        .navbar.scrolled.navbar-dark .navbar-nav .nav-link.dropdown-toggle:after, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .nav-link.dropdown-toggle:after {
          transform: rotateZ(90deg);
          background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%232C3038'/%3e%3c/svg%3e"); }
      .navbar.scrolled.navbar-dark .navbar-brand-dynamic-color svg [stroke]:not([stroke="none"]), .navbar.scrolled.navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]), .navbar.navbar-toggled-show.navbar-dark .navbar-brand-dynamic-color svg [stroke]:not([stroke="none"]), .navbar.navbar-toggled-show.navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]) {
        stroke: #2C3038; }
      .navbar.scrolled.navbar-dark .navbar-brand-dynamic-color svg [fill]:not([fill="none"]), .navbar.scrolled.navbar-dark .navbar-toggler svg [fill]:not([fill="none"]), .navbar.navbar-toggled-show.navbar-dark .navbar-brand-dynamic-color svg [fill]:not([fill="none"]), .navbar.navbar-toggled-show.navbar-dark .navbar-toggler svg [fill]:not([fill="none"]) {
        fill: #2C3038; }

    .nav-link {
      transition: all 0.2s ease-in-out; }

    section {
      position: relative;
      padding: 3.75rem 0; }

    @media (min-width: 768px) {
      section {
        padding: 6rem 0; } }

    @media (min-width: 992px) {
      section {
        padding: 7.5rem 0; } }

    .section-title {
      margin-bottom: 2.5rem; }

    @media (min-width: 768px) {
      .section-title {
        margin-bottom: 3.75rem; } }

    @media (min-width: 992px) {
      .section-title {
        margin-bottom: 4.6875rem; } }

    [data-overlay] {
      position: relative; }

    img[data-inject-svg] {
      opacity: 0; }

    svg[class*='bg-'] {
      background: none !important; }

    svg.bg-primary [stroke]:not([stroke="none"]) {
      stroke: #009b72; }

    svg.bg-primary [fill]:not([fill="none"]) {
      fill: #009b72; }

    svg.bg-secondary [stroke]:not([stroke="none"]) {
      stroke: #EAEDF2; }

    svg.bg-secondary [fill]:not([fill="none"]) {
      fill: #EAEDF2; }

    svg.bg-success [stroke]:not([stroke="none"]) {
      stroke: #009b72; }

    svg.bg-success [fill]:not([fill="none"]) {
      fill: #009b72; }

    svg.bg-info [stroke]:not([stroke="none"]) {
      stroke: #17a2b8; }

    svg.bg-info [fill]:not([fill="none"]) {
      fill: #17a2b8; }

    svg.bg-warning [stroke]:not([stroke="none"]) {
      stroke: #ffc107; }

    svg.bg-warning [fill]:not([fill="none"]) {
      fill: #ffc107; }

    svg.bg-danger [stroke]:not([stroke="none"]) {
      stroke: #dc3545; }

    svg.bg-danger [fill]:not([fill="none"]) {
      fill: #dc3545; }

    svg.bg-light [stroke]:not([stroke="none"]) {
      stroke: #F7F9FC; }

    svg.bg-light [fill]:not([fill="none"]) {
      fill: #F7F9FC; }

    svg.bg-dark [stroke]:not([stroke="none"]) {
      stroke: #2C3038; }

    svg.bg-dark [fill]:not([fill="none"]) {
      fill: #2C3038; }

    svg.bg-primary-2 [stroke]:not([stroke="none"]) {
      stroke: #046865; }

    svg.bg-primary-2 [fill]:not([fill="none"]) {
      fill: #046865; }

    svg.bg-primary-3 [stroke]:not([stroke="none"]) {
      stroke: #192d35; }

    svg.bg-primary-3 [fill]:not([fill="none"]) {
      fill: #192d35; }

    svg.bg-white [stroke]:not([stroke="none"]) {
      stroke: #fff; }

    svg.bg-white [fill]:not([fill="none"]) {
      fill: #fff; }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

    a {
      color: #009b72; }
      a:hover {
        color: #00684c; }
      a:focus {
        outline: none; }

    .text-small, .blockquote-footer {
      font-size: .875rem;
      line-height: 1.25rem; }

    .display-1,
    .display-2,
    .display-3,
    .display-4,
    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
      letter-spacing: -.02em; }

    @media (min-width: 768px) {
      .display-1,
      .display-2,
      .display-3,
      .display-4,
      h1,
      .h1 {
        margin-bottom: 1.5rem; }
      h1, .h1 {
        line-height: 1.125; }
      p.lead {
        margin-bottom: 1.5rem; } }

    a {
      font-weight: 700; }
      a.lead {
        font-weight: 700; }

    mark {
      color: inherit;
      padding: 0;
      background: none;
      background-image: linear-gradient(120deg, rgba(255, 193, 7, 0.4) 0%, rgba(255, 193, 7, 0.4) 100%);
      background-repeat: no-repeat;
      background-size: 0% .3em;
      background-position: 0 80%;
      position: relative;
      -webkit-animation-delay: 1s;
              animation-delay: 1s; }
      mark[data-aos="highlight-text"].aos-animate {
        background-size: 100% .3em; }
      mark:not([data-aos="highlight-text"]) {
        background-size: 100% .3em; }

    .text-primary mark {
      background-image: linear-gradient(120deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%); }

    @-webkit-keyframes animateMark {
      0% {
        background-size: 0% .3em; }
      100% {
        background-size: 100% .3em; } }

    @keyframes animateMark {
      0% {
        background-size: 0% .3em; }
      100% {
        background-size: 100% .3em; } }

    @media (max-width: 991.98px) {
      .lead {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 600; } }

    .blockquote {
      color: #009b72;
      border-left: 2px solid #EAEDF2;
      padding-left: 1rem; }

    .blockquote-footer {
      margin-top: 0.5rem;
      color: #555A64; }

    @media (max-width: 1199.98px) {
      .blockquote {
        font-size: 1.25rem !important; } }

    address p {
      margin-bottom: 0; }

    @media (min-width: 768px) {
      .blockquote {
        font-size: 1.5rem;
        padding-left: 3rem; } }

    .text-white .display-1,
    .text-white .display-2,
    .text-white .display-3,
    .text-white .display-4,
    .text-white h1,
    .text-white h2,
    .text-white h3,
    .text-white h4,
    .text-white h5,
    .text-white h6,
    .text-white .h1,
    .text-white .h2,
    .text-white .h3,
    .text-white .h4,
    .text-white .h5,
    .text-white .h6,
    .text-white .blockquote {
      color: #fff; }

    .text-white a {
      transition: all 0.2s ease-in-out; }

    .text-white .text-muted {
      color: rgba(255, 255, 255, 0.5) !important; }

    .text-white hr {
      border-color: rgba(255, 255, 255, 0.1); }

    .text-dark .display-1,
    .text-dark .display-2,
    .text-dark .display-3,
    .text-dark .display-4,
    .text-dark h1,
    .text-dark h2,
    .text-dark h3,
    .text-dark h4,
    .text-dark h5,
    .text-dark h6,
    .text-dark .h1,
    .text-dark .h2,
    .text-dark .h3,
    .text-dark .h4,
    .text-dark .h5,
    .text-dark .h6,
    .text-dark .blockquote {
      color: #2C3038; }

    .text-dark a {
      transition: all 0.2s ease-in-out; }

    .text-dark .text-muted {
      color: rgba(44, 48, 56, 0.5) !important; }

    .text-dark hr {
      border-color: rgba(44, 48, 56, 0.1); }

    figcaption {
      margin-top: 0.5rem;
      font-size: 0.875rem; }

    figure {
      text-align: center; }

    .links-white a:not(:hover) {
      color: rgba(255, 255, 255, 0.7); }

    .links-white a:hover {
      color: #fff; }

    .arrow-bottom:before,
    .arrow-top:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #fff;
      border-left: 1px #EAEDF2 solid;
      border-top: 1px #EAEDF2 solid;
      display: block;
      transform: translateX(-50%) rotateZ(225deg);
      position: absolute;
      bottom: -5px;
      left: 50%;
      z-index: 1001; }

    .arrow-top:before {
      bottom: auto;
      top: -5px;
      transform: translateX(-50%) rotateZ(45deg); }

    .dropdown-toggle.arrow-bottom:before {
      bottom: -12px;
      transform: translateX(-50%) rotateZ(45deg); }

    .dropdown-toggle.arrow-bottom + .dropdown-menu {
      margin-top: 7px; }

    .dropdown-toggle.arrow-top:before {
      bottom: auto;
      top: -12px;
      transform: translateX(-50%) rotateZ(225deg); }

    .dropdown-toggle.arrow-top + .dropdown-menu {
      margin-bottom: 7px; }

    .dropdown .dropdown-toggle[aria-expanded="false"]:before,
    .dropup .dropdown-toggle[aria-expanded="false"]:before {
      display: none; }

    .o-hidden {
      overflow: hidden; }

    .sticky-xs-top {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 0;
      z-index: 1020; }

    [class*='sticky-xs'][class*='-top'][class*='col'] {
      top: 1.5rem; }

    @media (min-width: 576px) {
      .sticky-sm-top {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 1020; }
      [class*='sticky-sm'][class*='-top'][class*='col'] {
        top: 1.5rem; } }

    @media (min-width: 768px) {
      .sticky-md-top {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 1020; }
      [class*='sticky-md'][class*='-top'][class*='col'] {
        top: 1.5rem; } }

    @media (min-width: 992px) {
      .sticky-lg-top {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 1020; }
      [class*='sticky-lg'][class*='-top'][class*='col'] {
        top: 1.5rem; } }

    @media (min-width: 1200px) {
      .sticky-xl-top {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 1020; }
      [class*='sticky-xl'][class*='-top'][class*='col'] {
        top: 1.5rem; } }

    .bg-image {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0; }
      .bg-image:not([class*='absolute']) {
        position: absolute; }
      .bg-image + .card-body {
        position: relative;
        z-index: 1; }

    img.bg-image {
      object-fit: cover; }

    .opacity-0 {
      opacity: 0; }

    .opacity-10 {
      opacity: 0.1; }

    .opacity-20 {
      opacity: 0.2; }

    .opacity-30 {
      opacity: 0.3; }

    .opacity-40 {
      opacity: 0.4; }

    .opacity-50 {
      opacity: 0.5; }

    .opacity-60 {
      opacity: 0.6; }

    .opacity-70 {
      opacity: 0.7; }

    .opacity-80 {
      opacity: 0.8; }

    .opacity-90 {
      opacity: 0.9; }

    .transform-flip-x {
      transform: scaleY(-1); }

    .transform-flip-y {
      transform: scaleX(-1); }

    .h-0 {
      height: 0; }

    .min-vh-10 {
      min-height: 10vh; }

    .min-vh-20 {
      min-height: 20vh; }

    .min-vh-30 {
      min-height: 30vh; }

    .min-vh-40 {
      min-height: 40vh; }

    .min-vh-50 {
      min-height: 50vh; }

    .min-vh-60 {
      min-height: 60vh; }

    .min-vh-70 {
      min-height: 70vh; }

    .min-vh-80 {
      min-height: 80vh; }

    .min-vh-90 {
      min-height: 90vh; }

    @media (min-width: 768px) {
      .min-vh-md-10 {
        min-height: 10vh; }
      .min-vh-md-20 {
        min-height: 20vh; }
      .min-vh-md-30 {
        min-height: 30vh; }
      .min-vh-md-40 {
        min-height: 40vh; }
      .min-vh-md-50 {
        min-height: 50vh; }
      .min-vh-md-60 {
        min-height: 60vh; }
      .min-vh-md-70 {
        min-height: 70vh; }
      .min-vh-md-80 {
        min-height: 80vh; }
      .min-vh-md-90 {
        min-height: 90vh; }
      .min-vh-md-100 {
        min-height: 100vh; } }

    @media (min-width: 992px) {
      .min-vh-lg-10 {
        min-height: 10vh; }
      .min-vh-lg-20 {
        min-height: 20vh; }
      .min-vh-lg-30 {
        min-height: 30vh; }
      .min-vh-lg-40 {
        min-height: 40vh; }
      .min-vh-lg-50 {
        min-height: 50vh; }
      .min-vh-lg-60 {
        min-height: 60vh; }
      .min-vh-lg-70 {
        min-height: 70vh; }
      .min-vh-lg-80 {
        min-height: 80vh; }
      .min-vh-lg-90 {
        min-height: 90vh; }
      .min-vh-lg-100 {
        min-height: 100vh; } }

    img.h-100 {
      object-fit: cover; }

    img.flex-fill {
      width: 100%;
      height: 100%;
      object-fit: cover; }

    .img-fluid.position-relative {
      z-index: 1; }

    @media (min-width: 992px) {
      .w-lg-100 {
        width: 100%; } }

    [class*='hover-'] {
      transition: all 0.2s ease-in-out; }

    .hover-fade-out:hover {
      opacity: .75; }

    .hover-box-shadow:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
      transform: translateY(-0.5rem); }

    .top {
      top: 0;
      bottom: auto; }

    .bottom {
      top: auto;
      bottom: 0; }

    .left {
      right: auto;
      left: 0; }

    .right {
      left: auto;
      right: 0; }

    .border-thick {
      border-width: 4px !important; }

    .rounded-lg {
      border-radius: 200rem !important; }

    @media (min-width: 992px) {
      .border-lg-right {
        border-right: 1px solid #EAEDF2; } }

    [data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
      transition-duration: 50ms; }

    [data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
      transition-delay: 50ms; }

    [data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
      transition-duration: .1s; }

    [data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
      transition-delay: .1s; }

    [data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
      transition-duration: .15s; }

    [data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
      transition-delay: .15s; }

    [data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
      transition-duration: .2s; }

    [data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
      transition-delay: .2s; }

    [data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
      transition-duration: .25s; }

    [data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
      transition-delay: .25s; }

    [data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
      transition-duration: .3s; }

    [data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
      transition-delay: .3s; }

    [data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
      transition-duration: .35s; }

    [data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
      transition-delay: .35s; }

    [data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
      transition-duration: .4s; }

    [data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
      transition-delay: .4s; }

    [data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
      transition-duration: .45s; }

    [data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
      transition-delay: .45s; }

    [data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
      transition-duration: .5s; }

    [data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
      transition-delay: .5s; }

    [data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
      transition-duration: .55s; }

    [data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
      transition-delay: .55s; }

    [data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
      transition-duration: .6s; }

    [data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
      transition-delay: .6s; }

    [data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
      transition-duration: .65s; }

    [data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
      transition-delay: .65s; }

    [data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
      transition-duration: .7s; }

    [data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
      transition-delay: .7s; }

    [data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
      transition-duration: .75s; }

    [data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
      transition-delay: .75s; }

    [data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
      transition-duration: .8s; }

    [data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
      transition-delay: .8s; }

    [data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
      transition-duration: .85s; }

    [data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
      transition-delay: .85s; }

    [data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
      transition-duration: .9s; }

    [data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
      transition-delay: .9s; }

    [data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
      transition-duration: .95s; }

    [data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
      transition-delay: .95s; }

    [data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
      transition-duration: 1s; }

    [data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
      transition-delay: 1s; }

    [data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
      transition-duration: 1.05s; }

    [data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
      transition-delay: 1.05s; }

    [data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
      transition-duration: 1.1s; }

    [data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
      transition-delay: 1.1s; }

    [data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
      transition-duration: 1.15s; }

    [data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
      transition-delay: 1.15s; }

    [data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
      transition-duration: 1.2s; }

    [data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
      transition-delay: 1.2s; }

    [data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
      transition-duration: 1.25s; }

    [data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
      transition-delay: 1.25s; }

    [data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
      transition-duration: 1.3s; }

    [data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
      transition-delay: 1.3s; }

    [data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
      transition-duration: 1.35s; }

    [data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
      transition-delay: 1.35s; }

    [data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
      transition-duration: 1.4s; }

    [data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
      transition-delay: 1.4s; }

    [data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
      transition-duration: 1.45s; }

    [data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
      transition-delay: 1.45s; }

    [data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
      transition-duration: 1.5s; }

    [data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
      transition-delay: 1.5s; }

    [data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
      transition-duration: 1.55s; }

    [data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
      transition-delay: 1.55s; }

    [data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
      transition-duration: 1.6s; }

    [data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
      transition-delay: 1.6s; }

    [data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
      transition-duration: 1.65s; }

    [data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
      transition-delay: 1.65s; }

    [data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
      transition-duration: 1.7s; }

    [data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
      transition-delay: 1.7s; }

    [data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
      transition-duration: 1.75s; }

    [data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
      transition-delay: 1.75s; }

    [data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
      transition-duration: 1.8s; }

    [data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
      transition-delay: 1.8s; }

    [data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
      transition-duration: 1.85s; }

    [data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
      transition-delay: 1.85s; }

    [data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
      transition-duration: 1.9s; }

    [data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
      transition-delay: 1.9s; }

    [data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
      transition-duration: 1.95s; }

    [data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
      transition-delay: 1.95s; }

    [data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
      transition-duration: 2s; }

    [data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
      transition-delay: 2s; }

    [data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
      transition-duration: 2.05s; }

    [data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
      transition-delay: 2.05s; }

    [data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
      transition-duration: 2.1s; }

    [data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
      transition-delay: 2.1s; }

    [data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
      transition-duration: 2.15s; }

    [data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
      transition-delay: 2.15s; }

    [data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
      transition-duration: 2.2s; }

    [data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
      transition-delay: 2.2s; }

    [data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
      transition-duration: 2.25s; }

    [data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
      transition-delay: 2.25s; }

    [data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
      transition-duration: 2.3s; }

    [data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
      transition-delay: 2.3s; }

    [data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
      transition-duration: 2.35s; }

    [data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
      transition-delay: 2.35s; }

    [data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
      transition-duration: 2.4s; }

    [data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
      transition-delay: 2.4s; }

    [data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
      transition-duration: 2.45s; }

    [data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
      transition-delay: 2.45s; }

    [data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
      transition-duration: 2.5s; }

    [data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
      transition-delay: 2.5s; }

    [data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
      transition-duration: 2.55s; }

    [data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
      transition-delay: 2.55s; }

    [data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
      transition-duration: 2.6s; }

    [data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
      transition-delay: 2.6s; }

    [data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
      transition-duration: 2.65s; }

    [data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
      transition-delay: 2.65s; }

    [data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
      transition-duration: 2.7s; }

    [data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
      transition-delay: 2.7s; }

    [data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
      transition-duration: 2.75s; }

    [data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
      transition-delay: 2.75s; }

    [data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
      transition-duration: 2.8s; }

    [data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
      transition-delay: 2.8s; }

    [data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
      transition-duration: 2.85s; }

    [data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
      transition-delay: 2.85s; }

    [data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
      transition-duration: 2.9s; }

    [data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
      transition-delay: 2.9s; }

    [data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
      transition-duration: 2.95s; }

    [data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
      transition-delay: 2.95s; }

    [data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
      transition-duration: 3s; }

    [data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
      transition-delay: 0; }

    [data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
      transition-delay: 3s; }

    [data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
      transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

    [data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
      transition-timing-function: ease; }

    [data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
      transition-timing-function: ease-in; }

    [data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
      transition-timing-function: ease-out; }

    [data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
      transition-timing-function: ease-in-out; }

    [data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
      transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

    [data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

    [data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

    [data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
      transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

    [data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
      transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

    [data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
      transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

    [data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
      transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

    [data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
      transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

    [data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
      transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

    [data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
      transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

    [data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
      transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

    [data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
      transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

    [data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
      transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

    [data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
      transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

    [data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
      transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

    [data-aos^=fade][data-aos^=fade] {
      opacity: 0;
      transition-property: opacity,transform; }

    [data-aos^=fade][data-aos^=fade].aos-animate {
      opacity: 1;
      transform: translateZ(0); }

    [data-aos=fade-up] {
      transform: translate3d(0, 100px, 0); }

    [data-aos=fade-down] {
      transform: translate3d(0, -100px, 0); }

    [data-aos=fade-right] {
      transform: translate3d(-100px, 0, 0); }

    [data-aos=fade-left] {
      transform: translate3d(100px, 0, 0); }

    [data-aos=fade-up-right] {
      transform: translate3d(-100px, 100px, 0); }

    [data-aos=fade-up-left] {
      transform: translate3d(100px, 100px, 0); }

    [data-aos=fade-down-right] {
      transform: translate3d(-100px, -100px, 0); }

    [data-aos=fade-down-left] {
      transform: translate3d(100px, -100px, 0); }

    [data-aos^=zoom][data-aos^=zoom] {
      opacity: 0;
      transition-property: opacity,transform; }

    [data-aos^=zoom][data-aos^=zoom].aos-animate {
      opacity: 1;
      transform: translateZ(0) scale(1); }

    [data-aos=zoom-in] {
      transform: scale(0.6); }

    [data-aos=zoom-in-up] {
      transform: translate3d(0, 100px, 0) scale(0.6); }

    [data-aos=zoom-in-down] {
      transform: translate3d(0, -100px, 0) scale(0.6); }

    [data-aos=zoom-in-right] {
      transform: translate3d(-100px, 0, 0) scale(0.6); }

    [data-aos=zoom-in-left] {
      transform: translate3d(100px, 0, 0) scale(0.6); }

    [data-aos=zoom-out] {
      transform: scale(1.2); }

    [data-aos=zoom-out-up] {
      transform: translate3d(0, 100px, 0) scale(1.2); }

    [data-aos=zoom-out-down] {
      transform: translate3d(0, -100px, 0) scale(1.2); }

    [data-aos=zoom-out-right] {
      transform: translate3d(-100px, 0, 0) scale(1.2); }

    [data-aos=zoom-out-left] {
      transform: translate3d(100px, 0, 0) scale(1.2); }

    [data-aos^=slide][data-aos^=slide] {
      transition-property: transform; }

    [data-aos^=slide][data-aos^=slide].aos-animate {
      transform: translateZ(0); }

    [data-aos=slide-up] {
      transform: translate3d(0, 100%, 0); }

    [data-aos=slide-down] {
      transform: translate3d(0, -100%, 0); }

    [data-aos=slide-right] {
      transform: translate3d(-100%, 0, 0); }

    [data-aos=slide-left] {
      transform: translate3d(100%, 0, 0); }

    [data-aos^=flip][data-aos^=flip] {
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      transition-property: transform; }

    [data-aos=flip-left] {
      transform: perspective(2500px) rotateY(-100deg); }

    [data-aos=flip-left].aos-animate {
      transform: perspective(2500px) rotateY(0); }

    [data-aos=flip-right] {
      transform: perspective(2500px) rotateY(100deg); }

    [data-aos=flip-right].aos-animate {
      transform: perspective(2500px) rotateY(0); }

    [data-aos=flip-up] {
      transform: perspective(2500px) rotateX(-100deg); }

    [data-aos=flip-up].aos-animate {
      transform: perspective(2500px) rotateX(0); }

    [data-aos=flip-down] {
      transform: perspective(2500px) rotateX(100deg); }

    [data-aos=flip-down].aos-animate {
      transform: perspective(2500px) rotateX(0); }

    .card > [data-toggle="collapse"] h6, .card > [data-toggle="collapse"] .icon {
      transition: all 0.2s ease-in-out; }

    .card > [data-toggle="collapse"][aria-expanded="false"]:hover h6 {
      color: #009b72; }

    .card > [data-toggle="collapse"][aria-expanded="true"] h6 {
      color: #009b72; }

    .card > [data-toggle="collapse"][aria-expanded="true"] .icon {
      transform: rotateZ(90deg); }
      .card > [data-toggle="collapse"][aria-expanded="true"] .icon [fill]:not([fill="none"]) {
        fill: #009b72; }

    .article figure, .article blockquote, .article pre {
      margin: 2rem 0; }

    .article ul, .article ol {
      padding-left: 2rem; }

    .article pre {
      padding: 1rem;
      background: #2C3038;
      color: #e83e8c;
      border-radius: 0.3125rem; }

    @media (min-width: 768px) {
      .article figure, .article blockquote, .article pre {
        margin: 4rem 0; }
      .article h5:not(:first-child) {
        margin-top: 3rem; }
      .article ul > li:not(:last-child), .article ol > li:not(:last-child) {
        margin-bottom: 0.5rem; }
      .article pre {
        padding: 2.25rem; } }

    .article-comments {
      list-style: none;
      padding-left: 0; }
      .article-comments > li:not(:last-child) {
        margin-bottom: 1rem; }
      .article-comments > li li {
        margin-top: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        border-left: 2px solid #EAEDF2; }

    @media (min-width: 768px) {
      .article-comments > li:not(:last-child) {
        margin-bottom: 1.5rem; } }

    .avatar {
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
      flex-shrink: 0; }
      .avatar.avatar-xs, .avatar.avatar-sm {
        width: 2.25rem;
        height: 2.25rem; }
      .avatar.avatar-lg {
        width: 4.5rem;
        height: 4.5rem; }
      .avatar.avatar-xl {
        width: 6rem;
        height: 6rem; }

    .avatar-group {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      padding-left: 0; }
      .avatar-group > li {
        position: relative; }
        .avatar-group > li:not(:last-child) {
          margin-right: -1rem; }
      .avatar-group .avatar {
        border: 2px solid #fff; }

    @media (min-width: 768px) {
      .avatar {
        height: 4.5rem;
        width: 4.5rem; }
        .avatar.avatar-sm {
          width: 3rem;
          height: 3rem; }
        .avatar.avatar-lg {
          width: 6rem;
          height: 6rem; } }

    @media (min-width: 992px) {
      .avatar.avatar-xl {
        width: 10.5rem;
        height: 10.5rem; }
      .avatar-group.avatar-group-lg > li:not(:last-child) {
        margin-right: -2rem; } }

    .avatar-container {
      position: relative;
      display: inline-block; }
      .avatar-container > .badge {
        position: absolute;
        border: 2px solid #fff;
        top: 0;
        right: 0; }
        .avatar-container > .badge:empty {
          border-radius: 50%;
          width: 25%;
          height: 25%;
          display: block;
          max-height: 20px;
          max-width: 20px;
          min-height: 14px;
          min-width: 14px;
          padding: 0; }


    .breadcrumb {
      align-items: center; }

    .breadcrumb-item + .breadcrumb-item {
      display: flex;
      align-items: center; }
      .breadcrumb-item + .breadcrumb-item:before {
        content: '';
        opacity: .5;
        width: 1.5rem;
        height: 1.5rem;
        background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.376 11.584C14.6728 11.7819 14.6728 12.2181 14.376 12.416L9.77735 15.4818C9.44507 15.7033 9 15.4651 9 15.0657L9 8.93426C9 8.53491 9.44507 8.29671 9.77735 8.51823L14.376 11.584Z' fill='%232C3038'/%3e%3c/svg%3e"); }

    .breadcrumb-item a {
      color: #555A64; }
      .breadcrumb-item a:hover {
        color: #009b72; }

    @media (max-width: 767.98px) {
      .breadcrumb {
        font-size: 0.875rem;
        padding: 0.5625rem 1.125rem; } }

    .divider {
      width: 100%;
      position: relative;
      height: 5vw;
      pointer-events: none;
      -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
              mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
      -webkit-mask-size: 100% 101%;
              mask-size: 100% 101%;
      background: white; }
      .divider.divider-side {
        position: absolute;
        z-index: 1;
        top: 0;
        height: 100%;
        width: 5vw;
        max-width: 72px;
        -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff' fill-rule='evenodd' transform='translate(50.000000, 50.000000) rotate(-90.000000) translate(-50.000000, -50.000000)' /%3e%3c/svg%3e");
                mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff' fill-rule='evenodd' transform='translate(50.000000, 50.000000) rotate(-90.000000) translate(-50.000000, -50.000000)' /%3e%3c/svg%3e");
        -webkit-mask-size: 100% 101%;
                mask-size: 100% 101%; }
        .divider.divider-side:first-child {
          left: -1px; }
        .divider.divider-side:last-child {
          right: -1px; }
      .divider.divider-bottom {
        top: 1px; }
      .divider.divider-top {
        bottom: 1px; }

    section > .divider-bottom {
      margin-top: calc(3.75rem - 5vw); }

    section > .divider-top {
      margin-bottom: calc(3.75rem - 5vw); }

    @media (min-width: 768px) {
      section > .divider-bottom {
        margin-top: calc(6rem - 5vw); }
      section > .divider-top {
        margin-bottom: calc(6rem - 5vw); } }

    @media (min-width: 992px) {
      section > .divider-bottom {
        margin-top: calc(7.5rem - 5vw); }
      section > .divider-top {
        margin-bottom: calc(7.5rem - 5vw); } }

    .card .divider {
      height: 1.5rem; }

    @media (min-width: 992px) {
      .docs-sidebar {
        height: calc(100vh - 80px);
        overflow-y: scroll;
        position: -webkit-sticky;
        position: sticky;
        top: 80px; }
      #docs-index {
        display: block !important; } }

    .docs-dl {
      margin-left: 0;
      margin-right: 0;
      border-top: 1px solid #EAEDF2; }
      .docs-dl p {
        margin-bottom: 0; }
      .docs-dl > dt, .docs-dl > dd {
        margin: 0;
        padding: .5rem;
        border-bottom: 1px solid #EAEDF2; }

    .alert p {
      margin-bottom: 0; }

    .code-snippet {
      position: relative; }
      .code-snippet .btn-clipboard {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        opacity: 0; }
      .code-snippet:hover .btn-clipboard {
        opacity: 1; }

    .docs-graphic .btn {
      margin: .5rem; }

    .docs-graphic:not(:hover) .btn {
      opacity: 0; }

    .lead > p:last-child {
      margin-bottom: 0; }

    .docs-sidebar .nav-link {
      color: #555A64; }
      .docs-sidebar .nav-link:not(:hover) {
        opacity: .5; }
      .docs-sidebar .nav-link:hover .icon path {
        fill: #009b72; }
      .docs-sidebar .nav-link[aria-expanded="true"] .icon {
        transform: rotateZ(90deg); }
      .docs-sidebar .nav-link.active {
        color: #2C3038;
        opacity: 1; }

    table {
      margin-bottom: 3rem; }

    thead {
      margin: 1rem 0; }

    td {
      padding: 1rem; }

    tr {
      border-bottom: 1px #EAEDF2 solid; }

    td code {
      word-break: keep-all; }

    body.compensate-for-scrollbar {
      overflow: hidden; }

    .fancybox-active {
      height: auto; }

    .fancybox-is-hidden {
      left: -9999px;
      margin: 0;
      position: absolute !important;
      top: -9999px;
      visibility: hidden; }

    .fancybox-container {
      -webkit-backface-visibility: hidden;
      height: 100%;
      left: 0;
      outline: none;
      position: fixed;
      -webkit-tap-highlight-color: transparent;
      top: 0;
      touch-action: manipulation;
      transform: translateZ(0);
      width: 100%;
      z-index: 99992; }

    .fancybox-container * {
      box-sizing: border-box; }

    .fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }

    .fancybox-outer {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; }

    .fancybox-bg {
      background: #1e1e1e;
      opacity: 0;
      transition-duration: inherit;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

    .fancybox-is-open .fancybox-bg {
      opacity: .9;
      transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

    .fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
      direction: ltr;
      opacity: 0;
      position: absolute;
      transition: opacity .25s ease,visibility 0s ease .25s;
      visibility: hidden;
      z-index: 99997; }

    .fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
      opacity: 1;
      transition: opacity .25s ease 0s,visibility 0s ease 0s;
      visibility: visible; }

    .fancybox-infobar {
      color: #ccc;
      font-size: 13px;
      -webkit-font-smoothing: subpixel-antialiased;
      height: 44px;
      left: 0;
      line-height: 44px;
      min-width: 44px;
      mix-blend-mode: difference;
      padding: 0 10px;
      pointer-events: none;
      top: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }

    .fancybox-toolbar {
      right: 0;
      top: 0; }

    .fancybox-stage {
      direction: ltr;
      overflow: visible;
      transform: translateZ(0);
      z-index: 99994; }

    .fancybox-is-open .fancybox-stage {
      overflow: hidden; }

    .fancybox-slide {
      -webkit-backface-visibility: hidden;
      display: none;
      height: 100%;
      left: 0;
      outline: none;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 44px;
      position: absolute;
      text-align: center;
      top: 0;
      transition-property: transform,opacity;
      white-space: normal;
      width: 100%;
      z-index: 99994; }

    .fancybox-slide:before {
      content: "";
      display: inline-block;
      font-size: 0;
      height: 100%;
      vertical-align: middle;
      width: 0; }

    .fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
      display: block; }

    .fancybox-slide--image {
      overflow: hidden;
      padding: 44px 0; }

    .fancybox-slide--image:before {
      display: none; }

    .fancybox-slide--html {
      padding: 6px; }

    .fancybox-content {
      background: #fff;
      display: inline-block;
      margin: 0;
      max-width: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 44px;
      position: relative;
      text-align: left;
      vertical-align: middle; }

    .fancybox-slide--image .fancybox-content {
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
      animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
      -webkit-backface-visibility: hidden;
      background: transparent;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0;
      max-width: none;
      overflow: visible;
      padding: 0;
      position: absolute;
      top: 0;
      transform-origin: top left;
      transition-property: transform,opacity;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      z-index: 99995; }

    .fancybox-can-zoomOut .fancybox-content {
      cursor: zoom-out; }

    .fancybox-can-zoomIn .fancybox-content {
      cursor: zoom-in; }

    .fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content {
      cursor: -webkit-grab;
      cursor: grab; }

    .fancybox-is-grabbing .fancybox-content {
      cursor: -webkit-grabbing;
      cursor: grabbing; }

    .fancybox-container [data-selectable=true] {
      cursor: text; }

    .fancybox-image, .fancybox-spaceball {
      background: transparent;
      border: 0;
      height: 100%;
      left: 0;
      margin: 0;
      max-height: none;
      max-width: none;
      padding: 0;
      position: absolute;
      top: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%; }

    .fancybox-spaceball {
      z-index: 1; }

    .fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--pdf .fancybox-content, .fancybox-slide--video .fancybox-content {
      height: 100%;
      overflow: visible;
      padding: 0;
      width: 100%; }

    .fancybox-slide--video .fancybox-content {
      background: #000; }

    .fancybox-slide--map .fancybox-content {
      background: #e5e3df; }

    .fancybox-slide--iframe .fancybox-content {
      background: #fff; }

    .fancybox-iframe, .fancybox-video {
      background: transparent;
      border: 0;
      display: block;
      height: 100%;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 100%; }

    .fancybox-iframe {
      left: 0;
      position: absolute;
      top: 0; }

    .fancybox-error {
      background: #fff;
      cursor: default;
      max-width: 400px;
      padding: 40px;
      width: 100%; }

    .fancybox-error p {
      color: #444;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      padding: 0; }

    .fancybox-button {
      background: rgba(30, 30, 30, 0.6);
      border: 0;
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      display: inline-block;
      height: 44px;
      margin: 0;
      padding: 10px;
      position: relative;
      transition: color .2s;
      vertical-align: top;
      visibility: inherit;
      width: 44px; }

    .fancybox-button, .fancybox-button:link, .fancybox-button:visited {
      color: #ccc; }

    .fancybox-button:hover {
      color: #fff; }

    .fancybox-button:focus {
      outline: none; }

    .fancybox-button.fancybox-focus {
      outline: 1px dotted; }

    .fancybox-button[disabled], .fancybox-button[disabled]:hover {
      color: #888;
      cursor: default;
      outline: none; }

    .fancybox-button div {
      height: 100%; }

    .fancybox-button svg {
      display: block;
      height: 100%;
      overflow: visible;
      position: relative;
      width: 100%; }

    .fancybox-button svg path {
      fill: currentColor;
      stroke-width: 0; }

    .fancybox-button--fsenter svg:nth-child(2), .fancybox-button--fsexit svg:nth-child(1), .fancybox-button--pause svg:nth-child(1), .fancybox-button--play svg:nth-child(2) {
      display: none; }

    .fancybox-progress {
      background: #ff5268;
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: scaleX(0);
      transform-origin: 0;
      transition-property: transform;
      transition-timing-function: linear;
      z-index: 99998; }

    .fancybox-close-small {
      background: transparent;
      border: 0;
      border-radius: 0;
      color: #ccc;
      cursor: pointer;
      opacity: .8;
      padding: 8px;
      position: absolute;
      right: -12px;
      top: -44px;
      z-index: 401; }

    .fancybox-close-small:hover {
      color: #fff;
      opacity: 1; }

    .fancybox-slide--html .fancybox-close-small {
      color: currentColor;
      padding: 10px;
      right: 0;
      top: 0; }

    .fancybox-slide--image.fancybox-is-scaling .fancybox-content {
      overflow: hidden; }

    .fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
      display: none; }

    .fancybox-navigation .fancybox-button {
      background-clip: content-box;
      height: 100px;
      opacity: 0;
      position: absolute;
      top: calc(50% - 50px);
      width: 70px; }

    .fancybox-navigation .fancybox-button div {
      padding: 7px; }

    .fancybox-navigation .fancybox-button--arrow_left {
      left: 0;
      padding: 31px 26px 31px 6px; }

    .fancybox-navigation .fancybox-button--arrow_right {
      padding: 31px 6px 31px 26px;
      right: 0; }

    .fancybox-caption {
      bottom: 0;
      color: #eee;
      font-size: 14px;
      font-weight: 400;
      left: 0;
      line-height: 1.5;
      padding: 25px 44px;
      right: 0;
      text-align: center;
      z-index: 99996; }

    .fancybox-caption:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=);
      background-repeat: repeat-x;
      background-size: contain;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: -44px;
      z-index: -1; }

    .fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
      color: #ccc;
      text-decoration: none; }

    .fancybox-caption a:hover {
      color: #fff;
      text-decoration: underline; }

    .fancybox-loading {
      -webkit-animation: a 1s linear infinite;
      animation: a 1s linear infinite;
      background: transparent;
      border: 4px solid #888;
      border-bottom-color: #fff;
      border-radius: 50%;
      height: 50px;
      left: 50%;
      margin: -25px 0 0 -25px;
      opacity: .7;
      padding: 0;
      position: absolute;
      top: 50%;
      width: 50px;
      z-index: 99999; }

    @-webkit-keyframes a {
      to {
        transform: rotate(1turn); } }

    @keyframes a {
      to {
        transform: rotate(1turn); } }

    .fancybox-animated {
      transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

    .fancybox-fx-slide.fancybox-slide--previous {
      opacity: 0;
      transform: translate3d(-100%, 0, 0); }

    .fancybox-fx-slide.fancybox-slide--next {
      opacity: 0;
      transform: translate3d(100%, 0, 0); }

    .fancybox-fx-slide.fancybox-slide--current {
      opacity: 1;
      transform: translateZ(0); }

    .fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
      opacity: 0;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

    .fancybox-fx-fade.fancybox-slide--current {
      opacity: 1; }

    .fancybox-fx-zoom-in-out.fancybox-slide--previous {
      opacity: 0;
      transform: scale3d(1.5, 1.5, 1.5); }

    .fancybox-fx-zoom-in-out.fancybox-slide--next {
      opacity: 0;
      transform: scale3d(0.5, 0.5, 0.5); }

    .fancybox-fx-zoom-in-out.fancybox-slide--current {
      opacity: 1;
      transform: scaleX(1); }

    .fancybox-fx-rotate.fancybox-slide--previous {
      opacity: 0;
      transform: rotate(-1turn); }

    .fancybox-fx-rotate.fancybox-slide--next {
      opacity: 0;
      transform: rotate(1turn); }

    .fancybox-fx-rotate.fancybox-slide--current {
      opacity: 1;
      transform: rotate(0deg); }

    .fancybox-fx-circular.fancybox-slide--previous {
      opacity: 0;
      transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

    .fancybox-fx-circular.fancybox-slide--next {
      opacity: 0;
      transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

    .fancybox-fx-circular.fancybox-slide--current {
      opacity: 1;
      transform: scaleX(1) translateZ(0); }

    .fancybox-fx-tube.fancybox-slide--previous {
      transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

    .fancybox-fx-tube.fancybox-slide--next {
      transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

    .fancybox-fx-tube.fancybox-slide--current {
      transform: translateZ(0) scale(1); }

    @media (max-height: 576px) {
      .fancybox-caption {
        padding: 12px; }
      .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px; }
      .fancybox-slide--image {
        padding: 6px 0; }
      .fancybox-close-small {
        right: -6px; }
      .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px; } }

    .fancybox-share {
      background: #f4f4f4;
      border-radius: 3px;
      max-width: 90%;
      padding: 30px;
      text-align: center; }

    .fancybox-share h1 {
      color: #222;
      font-size: 35px;
      font-weight: 700;
      margin: 0 0 20px; }

    .fancybox-share p {
      margin: 0;
      padding: 0; }

    .fancybox-share__button {
      border: 0;
      border-radius: 3px;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      line-height: 40px;
      margin: 0 5px 10px;
      min-width: 130px;
      padding: 0 15px;
      text-decoration: none;
      transition: all .2s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      white-space: nowrap; }

    .fancybox-share__button:link, .fancybox-share__button:visited {
      color: #fff; }

    .fancybox-share__button:hover {
      text-decoration: none; }

    .fancybox-share__button--fb {
      background: #3b5998; }

    .fancybox-share__button--fb:hover {
      background: #344e86; }

    .fancybox-share__button--pt {
      background: #bd081d; }

    .fancybox-share__button--pt:hover {
      background: #aa0719; }

    .fancybox-share__button--tw {
      background: #1da1f2; }

    .fancybox-share__button--tw:hover {
      background: #0d95e8; }

    .fancybox-share__button svg {
      height: 25px;
      margin-right: 7px;
      position: relative;
      top: -1px;
      vertical-align: middle;
      width: 25px; }

    .fancybox-share__button svg path {
      fill: #fff; }

    .fancybox-share__input {
      background: transparent;
      border: 0;
      border-bottom: 1px solid #d7d7d7;
      border-radius: 0;
      color: #5d5b5b;
      font-size: 14px;
      margin: 10px 0 0;
      outline: none;
      padding: 10px 15px;
      width: 100%; }

    .fancybox-thumbs {
      background: #ddd;
      bottom: 0;
      display: none;
      margin: 0;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      padding: 2px 2px 4px;
      position: absolute;
      right: 0;
      -webkit-tap-highlight-color: transparent;
      top: 0;
      width: 212px;
      z-index: 99995; }

    .fancybox-thumbs-x {
      overflow-x: auto;
      overflow-y: hidden; }

    .fancybox-show-thumbs .fancybox-thumbs {
      display: block; }

    .fancybox-show-thumbs .fancybox-inner {
      right: 212px; }

    .fancybox-thumbs__list {
      font-size: 0;
      height: 100%;
      list-style: none;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      position: absolute;
      position: relative;
      white-space: nowrap;
      width: 100%; }

    .fancybox-thumbs-x .fancybox-thumbs__list {
      overflow: hidden; }

    .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
      width: 7px; }

    .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

    .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
      background: #2a2a2a;
      border-radius: 10px; }

    .fancybox-thumbs__list a {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: rgba(0, 0, 0, 0.1);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      float: left;
      height: 75px;
      margin: 2px;
      max-height: calc(100% - 8px);
      max-width: calc(50% - 4px);
      outline: none;
      overflow: hidden;
      padding: 0;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      width: 100px; }

    .fancybox-thumbs__list a:before {
      border: 6px solid #ff5268;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      z-index: 99991; }

    .fancybox-thumbs__list a:focus:before {
      opacity: .5; }

    .fancybox-thumbs__list a.fancybox-thumbs-active:before {
      opacity: 1; }

    @media (max-width: 576px) {
      .fancybox-thumbs {
        width: 110px; }
      .fancybox-show-thumbs .fancybox-inner {
        right: 110px; }
      .fancybox-thumbs__list a {
        max-width: calc(100% - 10px); } }

    .flatpickr-calendar {
      background: transparent;
      opacity: 0;
      display: none;
      text-align: center;
      visibility: hidden;
      padding: 0;
      -webkit-animation: none;
      animation: none;
      direction: ltr;
      border: 0;
      font-size: 14px;
      line-height: 24px;
      border-radius: 5px;
      position: absolute;
      width: 307.875px;
      box-sizing: border-box;
      touch-action: manipulation;
      background: #fff;
      box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

    .flatpickr-calendar.open, .flatpickr-calendar.inline {
      opacity: 1;
      max-height: 640px;
      visibility: visible; }

    .flatpickr-calendar.open {
      display: inline-block;
      z-index: 99999; }

    .flatpickr-calendar.animate.open {
      -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
      animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

    .flatpickr-calendar.inline {
      display: block;
      position: relative;
      top: 2px; }

    .flatpickr-calendar.static {
      position: absolute;
      top: calc(100% + 2px); }

    .flatpickr-calendar.static.open {
      z-index: 999;
      display: block; }

    .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
      box-shadow: none !important; }

    .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
      box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

    .flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }

    .flatpickr-calendar .hasWeeks .dayContainer {
      border-left: 0; }

    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
      height: 40px;
      border-top: 1px solid #e6e6e6; }

    .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
      height: auto; }

    .flatpickr-calendar:before, .flatpickr-calendar:after {
      position: absolute;
      display: block;
      pointer-events: none;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      left: 22px; }

    .flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
      left: auto;
      right: 22px; }

    .flatpickr-calendar:before {
      border-width: 5px;
      margin: 0 -5px; }

    .flatpickr-calendar:after {
      border-width: 4px;
      margin: 0 -4px; }

    .flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
      bottom: 100%; }

    .flatpickr-calendar.arrowTop:before {
      border-bottom-color: #e6e6e6; }

    .flatpickr-calendar.arrowTop:after {
      border-bottom-color: #fff; }

    .flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
      top: 100%; }

    .flatpickr-calendar.arrowBottom:before {
      border-top-color: #e6e6e6; }

    .flatpickr-calendar.arrowBottom:after {
      border-top-color: #fff; }

    .flatpickr-calendar:focus {
      outline: 0; }

    .flatpickr-wrapper {
      position: relative;
      display: inline-block; }

    .flatpickr-months {
      display: flex; }

    .flatpickr-months .flatpickr-month {
      background: transparent;
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
      height: 34px;
      line-height: 1;
      text-align: center;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      overflow: hidden;
      flex: 1; }

    .flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
      text-decoration: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      height: 34px;
      padding: 10px;
      z-index: 3;
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9); }

    .flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
      display: none; }

    .flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
      position: relative; }

    .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
      /*
          /*rtl:begin:ignore*/
      left: 0;
      /*
          /*rtl:end:ignore*/ }

    /*
          /*rtl:begin:ignore*/
    /*
          /*rtl:end:ignore*/
    .flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
      /*
          /*rtl:begin:ignore*/
      right: 0;
      /*
          /*rtl:end:ignore*/ }

    /*
          /*rtl:begin:ignore*/
    /*
          /*rtl:end:ignore*/
    .flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
      color: #959ea9; }

    .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
      fill: #f64747; }

    .flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
      width: 14px;
      height: 14px; }

    .flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
      transition: fill .1s;
      fill: inherit; }

    .numInputWrapper {
      position: relative;
      height: auto; }

    .numInputWrapper input, .numInputWrapper span {
      display: inline-block; }

    .numInputWrapper input {
      width: 100%; }

    .numInputWrapper input::-ms-clear {
      display: none; }

    .numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none; }

    .numInputWrapper span {
      position: absolute;
      right: 0;
      width: 14px;
      padding: 0 4px 0 2px;
      height: 50%;
      line-height: 50%;
      opacity: 0;
      cursor: pointer;
      border: 1px solid rgba(57, 57, 57, 0.15);
      box-sizing: border-box; }

    .numInputWrapper span:hover {
      background: rgba(0, 0, 0, 0.1); }

    .numInputWrapper span:active {
      background: rgba(0, 0, 0, 0.2); }

    .numInputWrapper span:after {
      display: block;
      content: "";
      position: absolute; }

    .numInputWrapper span.arrowUp {
      top: 0;
      border-bottom: 0; }

    .numInputWrapper span.arrowUp:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid rgba(57, 57, 57, 0.6);
      top: 26%; }

    .numInputWrapper span.arrowDown {
      top: 50%; }

    .numInputWrapper span.arrowDown:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgba(57, 57, 57, 0.6);
      top: 40%; }

    .numInputWrapper span svg {
      width: inherit;
      height: auto; }

    .numInputWrapper span svg path {
      fill: rgba(0, 0, 0, 0.5); }

    .numInputWrapper:hover {
      background: rgba(0, 0, 0, 0.05); }

    .numInputWrapper:hover span {
      opacity: 1; }

    .flatpickr-current-month {
      font-size: 135%;
      line-height: inherit;
      font-weight: 300;
      color: inherit;
      position: absolute;
      width: 75%;
      left: 12.5%;
      padding: 7.48px 0 0 0;
      line-height: 1;
      height: 34px;
      display: inline-block;
      text-align: center;
      transform: translate3d(0, 0, 0); }

    .flatpickr-current-month span.cur-month {
      font-family: inherit;
      font-weight: 700;
      color: inherit;
      display: inline-block;
      margin-left: .5ch;
      padding: 0; }

    .flatpickr-current-month span.cur-month:hover {
      background: rgba(0, 0, 0, 0.05); }

    .flatpickr-current-month .numInputWrapper {
      width: 6ch;
      width: 7ch\0;
      display: inline-block; }

    .flatpickr-current-month .numInputWrapper span.arrowUp:after {
      border-bottom-color: rgba(0, 0, 0, 0.9); }

    .flatpickr-current-month .numInputWrapper span.arrowDown:after {
      border-top-color: rgba(0, 0, 0, 0.9); }

    .flatpickr-current-month input.cur-year {
      background: transparent;
      box-sizing: border-box;
      color: inherit;
      cursor: text;
      padding: 0 0 0 .5ch;
      margin: 0;
      display: inline-block;
      font-size: inherit;
      font-family: inherit;
      font-weight: 300;
      line-height: inherit;
      height: auto;
      border: 0;
      border-radius: 0;
      vertical-align: initial;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield; }

    .flatpickr-current-month input.cur-year:focus {
      outline: 0; }

    .flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
      font-size: 100%;
      color: rgba(0, 0, 0, 0.5);
      background: transparent;
      pointer-events: none; }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
      appearance: menulist;
      background: transparent;
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      color: inherit;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
      font-weight: 300;
      height: auto;
      line-height: inherit;
      margin: -1px 0 0 0;
      outline: none;
      padding: 0 0 0 .5ch;
      position: relative;
      vertical-align: initial;
      -webkit-box-sizing: border-box;
      -webkit-appearance: menulist;
      -moz-appearance: menulist;
      width: auto; }

    .flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
      outline: none; }

    .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
      background: rgba(0, 0, 0, 0.05); }

    .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
      background-color: transparent;
      outline: none;
      padding: 0; }

    .flatpickr-weekdays {
      background: transparent;
      text-align: center;
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      height: 28px; }

    .flatpickr-weekdays .flatpickr-weekdaycontainer {
      display: flex;
      flex: 1; }

    span.flatpickr-weekday {
      cursor: default;
      font-size: 90%;
      background: transparent;
      color: rgba(0, 0, 0, 0.54);
      line-height: 1;
      margin: 0;
      text-align: center;
      display: block;
      flex: 1;
      font-weight: bolder; }

    .dayContainer, .flatpickr-weeks {
      padding: 1px 0 0 0; }

    .flatpickr-days {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      width: 307.875px; }

    .flatpickr-days:focus {
      outline: 0; }

    .dayContainer {
      padding: 0;
      outline: 0;
      text-align: left;
      width: 307.875px;
      min-width: 307.875px;
      max-width: 307.875px;
      box-sizing: border-box;
      display: inline-block;
      display: flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      justify-content: space-around;
      transform: translate3d(0, 0, 0);
      opacity: 1; }

    .dayContainer + .dayContainer {
      box-shadow: -1px 0 0 #e6e6e6; }

    .flatpickr-day {
      background: none;
      border: 1px solid transparent;
      border-radius: 150px;
      box-sizing: border-box;
      color: #393939;
      cursor: pointer;
      font-weight: 400;
      width: 14.2857143%;
      flex-basis: 14.2857143%;
      max-width: 39px;
      height: 39px;
      line-height: 39px;
      margin: 0;
      display: inline-block;
      position: relative;
      justify-content: center;
      text-align: center; }

    .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
      cursor: pointer;
      outline: 0;
      background: #e6e6e6;
      border-color: #e6e6e6; }

    .flatpickr-day.today {
      border-color: #959ea9; }

    .flatpickr-day.today:hover, .flatpickr-day.today:focus {
      border-color: #959ea9;
      background: #959ea9;
      color: #fff; }

    .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
      background: #569ff7;
      box-shadow: none;
      color: #fff;
      border-color: #569ff7; }

    .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
      border-radius: 50px 0 0 50px; }

    .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
      border-radius: 0 50px 50px 0; }

    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
      box-shadow: -10px 0 0 #569ff7; }

    .flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
      border-radius: 50px; }

    .flatpickr-day.inRange {
      border-radius: 0;
      box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

    .flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
      color: rgba(57, 57, 57, 0.3);
      background: transparent;
      border-color: transparent;
      cursor: default; }

    .flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
      cursor: not-allowed;
      color: rgba(57, 57, 57, 0.1); }

    .flatpickr-day.week.selected {
      border-radius: 0;
      box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7; }

    .flatpickr-day.hidden {
      visibility: hidden; }

    .rangeMode .flatpickr-day {
      margin-top: 1px; }

    .flatpickr-weekwrapper {
      float: left; }

    .flatpickr-weekwrapper .flatpickr-weeks {
      padding: 0 12px;
      box-shadow: 1px 0 0 #e6e6e6; }

    .flatpickr-weekwrapper .flatpickr-weekday {
      float: none;
      width: 100%;
      line-height: 28px; }

    .flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
      display: block;
      width: 100%;
      max-width: none;
      color: rgba(57, 57, 57, 0.3);
      background: transparent;
      cursor: default;
      border: none; }

    .flatpickr-innerContainer {
      display: block;
      display: flex;
      box-sizing: border-box;
      overflow: hidden; }

    .flatpickr-rContainer {
      display: inline-block;
      padding: 0;
      box-sizing: border-box; }

    .flatpickr-time {
      text-align: center;
      outline: 0;
      display: block;
      height: 0;
      line-height: 40px;
      max-height: 40px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex; }

    .flatpickr-time:after {
      content: "";
      display: table;
      clear: both; }

    .flatpickr-time .numInputWrapper {
      flex: 1;
      width: 40%;
      height: 40px;
      float: left; }

    .flatpickr-time .numInputWrapper span.arrowUp:after {
      border-bottom-color: #393939; }

    .flatpickr-time .numInputWrapper span.arrowDown:after {
      border-top-color: #393939; }

    .flatpickr-time.hasSeconds .numInputWrapper {
      width: 26%; }

    .flatpickr-time.time24hr .numInputWrapper {
      width: 49%; }

    .flatpickr-time input {
      background: transparent;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      text-align: center;
      margin: 0;
      padding: 0;
      height: inherit;
      line-height: inherit;
      color: #393939;
      font-size: 14px;
      position: relative;
      box-sizing: border-box;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield; }

    .flatpickr-time input.flatpickr-hour {
      font-weight: bold; }

    .flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
      font-weight: 400; }

    .flatpickr-time input:focus {
      outline: 0;
      border: 0; }

    .flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
      height: inherit;
      float: left;
      line-height: inherit;
      color: #393939;
      font-weight: bold;
      width: 2%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      align-self: center; }

    .flatpickr-time .flatpickr-am-pm {
      outline: 0;
      width: 18%;
      cursor: pointer;
      text-align: center;
      font-weight: 400; }

    .flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
      background: #eee; }

    .flatpickr-input[readonly] {
      cursor: pointer; }

    @-webkit-keyframes fpFadeInDown {
      from {
        opacity: 0;
        transform: translate3d(0, -20px, 0); }
      to {
        opacity: 1;
        transform: translate3d(0, 0, 0); } }

    @keyframes fpFadeInDown {
      from {
        opacity: 0;
        transform: translate3d(0, -20px, 0); }
      to {
        opacity: 1;
        transform: translate3d(0, 0, 0); } }

    .flatpickr-months {
      margin: 0.5rem 0; }
      .flatpickr-months .flatpickr-month, .flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
        height: auto;
        position: relative; }
        .flatpickr-months .flatpickr-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
          fill: #009b72; }
      .flatpickr-months .flatpickr-month {
        color: #555A64; }

    .flatpickr-current-month select {
      -moz-appearance: none !important; }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
      font-weight: 700; }

    .flatpickr-current-month span.cur-month {
      font-weight: 700; }
      .flatpickr-current-month span.cur-month:hover {
        background: rgba(0, 155, 114, 0.15); }

    .numInputWrapper:hover {
      background: rgba(0, 155, 114, 0.15); }

    .flatpickr-day {
      border-radius: 0.3125rem;
      font-weight: 500;
      color: #555A64; }
      .flatpickr-day.today {
        border-color: #009b72; }
        .flatpickr-day.today:hover {
          background: #009b72;
          border-color: #009b72; }
      .flatpickr-day:hover {
        background: rgba(0, 155, 114, 0.1);
        border-color: rgba(0, 0, 0, 0); }

    span.flatpickr-weekday {
      color: #555A64; }

    .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
      background: #009b72;
      border-color: #009b72; }

    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
      box-shadow: -10px 0 0 #009b72; }

    .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
      border-radius: 0.3125rem 0 0 0.3125rem; }

    .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
      border-radius: 0 0.3125rem 0.3125rem 0; }

    input.flatpickr-input + input[readonly] {
      background-color: #fff;
      cursor: pointer; }

    .flatpickr-months select, .flatpickr-months input {
      -webkit-appearance: none !important; }

    /*! Flickity v2.2.1
    https://flickity.metafizzy.co
    ---------------------------------------------- */
    .flickity-enabled {
      position: relative; }

    .flickity-enabled:focus {
      outline: none; }

    .flickity-viewport {
      overflow: hidden;
      position: relative;
      height: 100%; }

    .flickity-slider {
      position: absolute;
      width: 100%;
      height: 100%; }

    /* draggable */
    .flickity-enabled.is-draggable {
      -webkit-tap-highlight-color: transparent;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }

    .flickity-enabled.is-draggable .flickity-viewport {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab; }

    .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
      cursor: -webkit-grabbing;
      cursor: grabbing; }

    /* ---- flickity-button ---- */
    .flickity-button {
      position: absolute;
      background: rgba(255, 255, 255, 0.75);
      border: none;
      color: #333; }

    .flickity-button:hover {
      background: white;
      cursor: pointer; }

    .flickity-button:focus {
      outline: none;
      box-shadow: 0 0 0 5px #19F; }

    .flickity-button:active {
      opacity: 0.6; }

    .flickity-button:disabled {
      opacity: 0.3;
      cursor: auto;
      /* prevent disabled button from capturing pointer up event. #716 */
      pointer-events: none; }

    .flickity-button-icon {
      fill: currentColor; }

    /* ---- previous/next buttons ---- */
    .flickity-prev-next-button {
      top: 50%;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      /* vertically center */
      transform: translateY(-50%); }

    .flickity-prev-next-button.previous {
      left: 10px; }

    .flickity-prev-next-button.next {
      right: 10px; }

    /* right to left */
    .flickity-rtl .flickity-prev-next-button.previous {
      left: auto;
      right: 10px; }

    .flickity-rtl .flickity-prev-next-button.next {
      right: auto;
      left: 10px; }

    .flickity-prev-next-button .flickity-button-icon {
      position: absolute;
      left: 20%;
      top: 20%;
      width: 60%;
      height: 60%; }

    /* ---- page dots ---- */
    .flickity-page-dots {
      position: absolute;
      width: 100%;
      bottom: -25px;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1; }

    .flickity-rtl .flickity-page-dots {
      direction: rtl; }

    .flickity-page-dots .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 8px;
      background: #333;
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer; }

    .flickity-page-dots .dot.is-selected {
      opacity: 1; }

    [data-flickity]:not(.flickity-enabled) {
      opacity: 0; }

    .flickity-page-dots {
      bottom: -2.125rem; }
      .flickity-page-dots .dot {
        width: 6px;
        height: 6px;
        border-radius: 10rem;
        margin: 0 5px;
        background: #555A64;
        opacity: .2;
        transition: all 0.2s ease-in-out; }
        .flickity-page-dots .dot.is-selected {
          transform: scale(1.33333); }

    .flickity-prev-next-button {
      padding: 0;
      background: #009b72;
      border: 2px solid #009b72;
      width: 3rem;
      height: 3rem;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center; }
      .flickity-prev-next-button:after {
        background-image: url("data:image/svg+xml;utf8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 5L5 12L12 19M19 12H6H19Z' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        content: '';
        width: 1.25rem;
        height: 1.25rem;
        display: block;
        background-size: cover; }
      .flickity-prev-next-button.previous {
        left: 1.5rem; }
      .flickity-prev-next-button.next {
        right: 1.5rem; }
        .flickity-prev-next-button.next:after {
          transform: rotateZ(180deg); }
      .flickity-prev-next-button:hover {
        box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.09);
        background: #00684c;
        border-color: #00684c; }

    [data-flickity].buttons-attached .flickity-prev-next-button {
      border-color: #fff; }
      [data-flickity].buttons-attached .flickity-prev-next-button.previous {
        left: -1.5rem; }
      [data-flickity].buttons-attached .flickity-prev-next-button.next {
        right: -1.5rem; }

    .flickity-button-icon {
      display: none; }

    @media (max-width: 1199.98px) {
      .flickity-prev-next-button {
        display: none; } }

    [data-flickity].controls-light .flickity-page-dots .dot {
      background: #fff; }

    .controls-inside:not(:hover) .flickity-prev-next-button {
      opacity: 0; }

    .controls-inside .flickity-page-dots {
      position: absolute;
      bottom: 1rem; }

    .controls-inside .flickity-prev-next-button.previous, .arrows-inside .flickity-prev-next-button.previous {
      left: 1rem; }

    .controls-inside .flickity-prev-next-button.next, .arrows-inside .flickity-prev-next-button.next {
      right: 1rem; }

    .controls-hover:not(:hover) .flickity-prev-next-button, .controls-hover:not(:hover) .flickity-page-dots {
      opacity: 0; }

    .carousel-cell {
      width: 100%; }

    .highlight-selected {
      position: relative;
      z-index: 1; }
      .highlight-selected .carousel-cell {
        transition: opacity .2s ease, transform .2s ease; }
        .highlight-selected .carousel-cell:not(.is-selected) {
          transform: scale(0.9);
          opacity: .25; }

    @media (min-width: 992px) {
      .flex-lg-column [data-flickity].flex-fill .flickity-viewport {
        flex: 1 1 auto;
        height: 100% !important; }
      .flex-lg-column [data-flickity].flex-fill .carousel-cell {
        height: 100%; }
      .flex-lg-column [data-flickity].flex-fill img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      body .blob {
        display: none; }
      body .row {
        flex-grow: 1; }
      body .card {
        display: block; }
      body .icon {
        width: 2rem; }
        body .icon.icon-xs {
          width: 1rem; }
        body .icon.icon-sm {
          width: 1.5rem; }
        body .icon.icon-lg {
          width: 4.5rem; }
        body .icon.icon-xl {
          width: 6rem; }
      body .flex-wrap > div {
        width: 100%; }
      body a[role="button"]:not(.dropdown-toggle):not(.dropdown-item) {
        display: block; } }

    /**
    Ion.RangeSlider, 2.3.0
    © Denis Ineshin, 2010 - 2018, IonDen.com
    Build date: 2018-12-11 23:23:51
    */
    .irs {
      position: relative;
      display: block;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 12px;
      font-family: Arial, sans-serif; }

    .irs-line {
      position: relative;
      display: block;
      overflow: hidden;
      outline: none !important; }

    .irs-bar {
      position: absolute;
      display: block;
      left: 0;
      width: 0; }

    .irs-shadow {
      position: absolute;
      display: none;
      left: 0;
      width: 0; }

    .irs-handle {
      position: absolute;
      display: block;
      box-sizing: border-box;
      cursor: default;
      z-index: 1; }

    .irs-handle.type_last {
      z-index: 2; }

    .irs-min,
    .irs-max {
      position: absolute;
      display: block;
      cursor: default; }

    .irs-min {
      left: 0; }

    .irs-max {
      right: 0; }

    .irs-from,
    .irs-to,
    .irs-single {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      cursor: default;
      white-space: nowrap; }

    .irs-grid {
      position: absolute;
      display: none;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px; }

    .irs-with-grid .irs-grid {
      display: block; }

    .irs-grid-pol {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 8px;
      background: #000; }

    .irs-grid-pol.small {
      height: 4px; }

    .irs-grid-text {
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      text-align: center;
      font-size: 9px;
      line-height: 9px;
      padding: 0 3px;
      color: #000; }

    .irs-disable-mask {
      position: absolute;
      display: block;
      top: 0;
      left: -1%;
      width: 102%;
      height: 100%;
      cursor: default;
      background: rgba(0, 0, 0, 0);
      z-index: 2; }

    .lt-ie9 .irs-disable-mask {
      background: #000;
      filter: alpha(opacity=0);
      cursor: not-allowed; }

    .irs-disabled {
      opacity: 0.4; }

    .irs-hidden-input {
      position: absolute !important;
      display: block !important;
      top: 0 !important;
      left: 0 !important;
      width: 0 !important;
      height: 0 !important;
      font-size: 0 !important;
      line-height: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden;
      outline: none !important;
      z-index: -9999 !important;
      background: none !important;
      border-style: solid !important;
      border-color: transparent !important; }

    .irs--flat {
      height: 40px; }

    .irs--flat.irs-with-grid {
      height: 60px; }

    .irs--flat .irs-line {
      top: 25px;
      height: 12px;
      background-color: #e1e4e9;
      border-radius: 4px; }

    .irs--flat .irs-bar {
      top: 25px;
      height: 12px;
      background-color: #ed5565; }

    .irs--flat .irs-bar--single {
      border-radius: 4px 0 0 4px; }

    .irs--flat .irs-shadow {
      height: 1px;
      bottom: 16px;
      background-color: #e1e4e9; }

    .irs--flat .irs-handle {
      top: 22px;
      width: 16px;
      height: 18px;
      background-color: transparent; }

    .irs--flat .irs-handle > i:first-child {
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      margin-left: -1px;
      background-color: #da4453; }

    .irs--flat .irs-handle.state_hover > i:first-child,
    .irs--flat .irs-handle:hover > i:first-child {
      background-color: #a43540; }

    .irs--flat .irs-min,
    .irs--flat .irs-max {
      top: 0;
      padding: 1px 3px;
      color: #999;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      background-color: #e1e4e9;
      border-radius: 4px; }

    .irs--flat .irs-from,
    .irs--flat .irs-to,
    .irs--flat .irs-single {
      color: white;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      background-color: #ed5565;
      border-radius: 4px; }

    .irs--flat .irs-from:before,
    .irs--flat .irs-to:before,
    .irs--flat .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-top-color: #ed5565; }

    .irs--flat .irs-grid-pol {
      background-color: #e1e4e9; }

    .irs--flat .irs-grid-text {
      color: #999; }

    .irs--big {
      height: 55px; }

    .irs--big.irs-with-grid {
      height: 70px; }

    .irs--big .irs-line {
      top: 33px;
      height: 12px;
      background-color: white;
      background: linear-gradient(to bottom, #ddd -50%, white 150%);
      border: 1px solid #ccc;
      border-radius: 12px; }

    .irs--big .irs-bar {
      top: 33px;
      height: 12px;
      background-color: #92bce0;
      border: 1px solid #428bca;
      background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
      box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5); }

    .irs--big .irs-bar--single {
      border-radius: 12px 0 0 12px; }

    .irs--big .irs-shadow {
      height: 1px;
      bottom: 16px;
      background-color: rgba(66, 139, 202, 0.5); }

    .irs--big .irs-handle {
      top: 25px;
      width: 30px;
      height: 30px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #cbcfd5;
      background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
      border-radius: 30px; }

    .irs--big .irs-handle.state_hover,
    .irs--big .irs-handle:hover {
      border-color: rgba(0, 0, 0, 0.45);
      background-color: #939ba7;
      background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%); }

    .irs--big .irs-min,
    .irs--big .irs-max {
      top: 0;
      padding: 1px 5px;
      color: white;
      text-shadow: none;
      background-color: #9f9f9f;
      border-radius: 3px; }

    .irs--big .irs-from,
    .irs--big .irs-to,
    .irs--big .irs-single {
      color: white;
      text-shadow: none;
      padding: 1px 5px;
      background-color: #428bca;
      background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
      border-radius: 3px; }

    .irs--big .irs-grid-pol {
      background-color: #428bca; }

    .irs--big .irs-grid-text {
      color: #428bca; }

    .irs--modern {
      height: 55px; }

    .irs--modern.irs-with-grid {
      height: 55px; }

    .irs--modern .irs-line {
      top: 25px;
      height: 5px;
      background-color: #d1d6e0;
      background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
      border: 1px solid #a3adc1;
      border-bottom-width: 0;
      border-radius: 5px; }

    .irs--modern .irs-bar {
      top: 25px;
      height: 5px;
      background: #20b426;
      background: linear-gradient(to bottom, #20b426 0%, #18891d 100%); }

    .irs--modern .irs-bar--single {
      border-radius: 5px 0 0 5px; }

    .irs--modern .irs-shadow {
      height: 1px;
      bottom: 21px;
      background-color: rgba(209, 214, 224, 0.5); }

    .irs--modern .irs-handle {
      top: 37px;
      width: 12px;
      height: 13px;
      border: 1px solid #a3adc1;
      border-top-width: 0;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 3px 3px; }

    .irs--modern .irs-handle > i:nth-child(1) {
      position: absolute;
      display: block;
      top: -4px;
      left: 1px;
      width: 6px;
      height: 6px;
      border: 1px solid #a3adc1;
      background: white;
      transform: rotate(45deg); }

    .irs--modern .irs-handle > i:nth-child(2) {
      position: absolute;
      display: block;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 10px;
      height: 12px;
      background: #e9e6e6;
      background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
      border-radius: 0 0 3px 3px; }

    .irs--modern .irs-handle > i:nth-child(3) {
      position: absolute;
      display: block;
      box-sizing: border-box;
      top: 3px;
      left: 3px;
      width: 4px;
      height: 5px;
      border-left: 1px solid #a3adc1;
      border-right: 1px solid #a3adc1; }

    .irs--modern .irs-handle.state_hover,
    .irs--modern .irs-handle:hover {
      border-color: #7685a2;
      background: #c3c7cd;
      background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%); }

    .irs--modern .irs-handle.state_hover > i:nth-child(1),
    .irs--modern .irs-handle:hover > i:nth-child(1) {
      border-color: #7685a2; }

    .irs--modern .irs-handle.state_hover > i:nth-child(3),
    .irs--modern .irs-handle:hover > i:nth-child(3) {
      border-color: #48536a; }

    .irs--modern .irs-min,
    .irs--modern .irs-max {
      top: 0;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      color: white;
      background-color: #d1d6e0;
      border-radius: 5px; }

    .irs--modern .irs-from,
    .irs--modern .irs-to,
    .irs--modern .irs-single {
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      background-color: #20b426;
      color: white;
      border-radius: 5px; }

    .irs--modern .irs-from:before,
    .irs--modern .irs-to:before,
    .irs--modern .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-top-color: #20b426; }

    .irs--modern .irs-grid {
      height: 25px; }

    .irs--modern .irs-grid-pol {
      background-color: #dedede; }

    .irs--modern .irs-grid-text {
      color: silver;
      font-size: 13px; }

    .irs--sharp {
      height: 50px;
      font-size: 12px;
      line-height: 1; }

    .irs--sharp.irs-with-grid {
      height: 57px; }

    .irs--sharp .irs-line {
      top: 30px;
      height: 2px;
      background-color: black;
      border-radius: 2px; }

    .irs--sharp .irs-bar {
      top: 30px;
      height: 2px;
      background-color: #ee22fa; }

    .irs--sharp .irs-bar--single {
      border-radius: 2px 0 0 2px; }

    .irs--sharp .irs-shadow {
      height: 1px;
      bottom: 21px;
      background-color: rgba(0, 0, 0, 0.5); }

    .irs--sharp .irs-handle {
      top: 25px;
      width: 10px;
      height: 10px;
      background-color: #a804b2; }

    .irs--sharp .irs-handle > i:first-child {
      position: absolute;
      display: block;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #a804b2; }

    .irs--sharp .irs-handle.state_hover,
    .irs--sharp .irs-handle:hover {
      background-color: black; }

    .irs--sharp .irs-handle.state_hover > i:first-child,
    .irs--sharp .irs-handle:hover > i:first-child {
      border-top-color: black; }

    .irs--sharp .irs-min,
    .irs--sharp .irs-max {
      color: white;
      font-size: 14px;
      line-height: 1;
      top: 0;
      padding: 3px 4px;
      opacity: 0.4;
      background-color: #a804b2;
      border-radius: 2px; }

    .irs--sharp .irs-from,
    .irs--sharp .irs-to,
    .irs--sharp .irs-single {
      font-size: 14px;
      line-height: 1;
      text-shadow: none;
      padding: 3px 4px;
      background-color: #a804b2;
      color: white;
      border-radius: 2px; }

    .irs--sharp .irs-from:before,
    .irs--sharp .irs-to:before,
    .irs--sharp .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-top-color: #a804b2; }

    .irs--sharp .irs-grid {
      height: 25px; }

    .irs--sharp .irs-grid-pol {
      background-color: #dedede; }

    .irs--sharp .irs-grid-text {
      color: silver;
      font-size: 13px; }

    .irs--round {
      height: 50px; }

    .irs--round.irs-with-grid {
      height: 65px; }

    .irs--round .irs-line {
      top: 36px;
      height: 4px;
      background-color: #dee4ec;
      border-radius: 4px; }

    .irs--round .irs-bar {
      top: 36px;
      height: 4px;
      background-color: #006cfa; }

    .irs--round .irs-bar--single {
      border-radius: 4px 0 0 4px; }

    .irs--round .irs-shadow {
      height: 4px;
      bottom: 21px;
      background-color: rgba(222, 228, 236, 0.5); }

    .irs--round .irs-handle {
      top: 26px;
      width: 24px;
      height: 24px;
      border: 4px solid #006cfa;
      background-color: white;
      border-radius: 24px;
      box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3); }

    .irs--round .irs-handle.state_hover,
    .irs--round .irs-handle:hover {
      background-color: #f0f6ff; }

    .irs--round .irs-min,
    .irs--round .irs-max {
      color: #333;
      font-size: 14px;
      line-height: 1;
      top: 0;
      padding: 3px 5px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px; }

    .irs--round .irs-from,
    .irs--round .irs-to,
    .irs--round .irs-single {
      font-size: 14px;
      line-height: 1;
      text-shadow: none;
      padding: 3px 5px;
      background-color: #006cfa;
      color: white;
      border-radius: 4px; }

    .irs--round .irs-from:before,
    .irs--round .irs-to:before,
    .irs--round .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-top-color: #006cfa; }

    .irs--round .irs-grid {
      height: 25px; }

    .irs--round .irs-grid-pol {
      background-color: #dedede; }

    .irs--round .irs-grid-text {
      color: silver;
      font-size: 13px; }

    .irs--square {
      height: 50px; }

    .irs--square.irs-with-grid {
      height: 60px; }

    .irs--square .irs-line {
      top: 31px;
      height: 4px;
      background-color: #dedede; }

    .irs--square .irs-bar {
      top: 31px;
      height: 4px;
      background-color: black; }

    .irs--square .irs-shadow {
      height: 2px;
      bottom: 21px;
      background-color: #dedede; }

    .irs--square .irs-handle {
      top: 25px;
      width: 16px;
      height: 16px;
      border: 3px solid black;
      background-color: white;
      transform: rotate(45deg); }

    .irs--square .irs-handle.state_hover,
    .irs--square .irs-handle:hover {
      background-color: #f0f6ff; }

    .irs--square .irs-min,
    .irs--square .irs-max {
      color: #333;
      font-size: 14px;
      line-height: 1;
      top: 0;
      padding: 3px 5px;
      background-color: rgba(0, 0, 0, 0.1); }

    .irs--square .irs-from,
    .irs--square .irs-to,
    .irs--square .irs-single {
      font-size: 14px;
      line-height: 1;
      text-shadow: none;
      padding: 3px 5px;
      background-color: black;
      color: white; }

    .irs--square .irs-grid {
      height: 25px; }

    .irs--square .irs-grid-pol {
      background-color: #dedede; }

    .irs--square .irs-grid-text {
      color: silver;
      font-size: 11px; }

    .irs {
      font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

    .irs--theme {
      height: 40px; }
      .irs--theme.irs-with-grid {
        height: 60px; }
      .irs--theme .irs-line {
        height: 6px;
        top: 27px;
        background: #EAEDF2;
        border-radius: 200rem; }
      .irs--theme .irs-line-left {
        height: 12px;
        background-position: 0 -30px; }
      .irs--theme .irs-line-mid {
        height: 12px;
        background-position: 0 0; }
      .irs--theme .irs-line-right {
        height: 12px;
        background-position: 100% -30px; }
      .irs--theme .irs-bar {
        height: 6px;
        top: 27px;
        background-color: #009b72;
        border-radius: 200rem; }
      .irs--theme .irs-bar-edge {
        top: 25px;
        height: 12px;
        width: 9px;
        background-position: 0 -90px; }
      .irs--theme .irs-shadow {
        height: 3px;
        top: 34px;
        background: #000;
        opacity: 0.25; }
      .irs--theme .lt-ie9 .irs-shadow {
        filter: alpha(opacity=25); }
      .irs--theme .irs-slider {
        width: 16px;
        height: 18px;
        top: 22px;
        background-position: 0 -120px; }
      .irs--theme .irs-slider.state_hover,
      .irs--theme .irs-slider:hover {
        background-position: 0 -150px; }
      .irs--theme .irs-min,
      .irs--theme .irs-max {
        opacity: 0; }
      .irs--theme .irs-from,
      .irs--theme .irs-to,
      .irs--theme .irs-single {
        color: #fff;
        font-size: 10px;
        line-height: 1.333;
        text-shadow: none;
        padding: 1px 5px;
        background: #2C3038;
        border-radius: 4px; }
      .irs--theme .irs-from:after,
      .irs--theme .irs-to:after,
      .irs--theme .irs-single:after {
        position: absolute;
        display: block;
        content: "";
        bottom: -6px;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -3px;
        overflow: hidden;
        border: 3px solid transparent;
        border-top-color: #2C3038; }
      .irs--theme .irs-grid {
        bottom: -8px; }
      .irs--theme .irs-grid-pol {
        background: rgba(44, 48, 56, 0.25); }
      .irs--theme .irs-grid-text {
        margin-top: .5rem;
        color: rgba(85, 90, 100, 0.65);
        font-size: 0.75rem;
        font-weight: 700;
        bottom: -8px; }
      .irs--theme .irs-handle {
        top: 22px;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.09);
        border: 1px solid #EAEDF2;
        background: #fff;
        cursor: pointer; }
        .irs--theme .irs-handle.state_hover {
          background-color: #009b72; }
      .irs--theme .irs-handle > i:first-child {
        display: none; }
      .irs--theme .irs-handle.state_hover > i:first-child,
      .irs--theme .irs-handle:hover > i:first-child {
        background-color: #a43540; }

    .jarallax {
      position: relative;
      z-index: 0; }

    .jarallax > .jarallax-img {
      position: absolute;
      object-fit: cover;
      /* support for plugin https://github.com/bfred-it/object-fit-images */
      font-family: 'object-fit: cover;';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1; }

    div[data-maps-api-key] div.map-marker, div[data-maps-api-key] div.map-style {
      display: none; }

    .gm-err-title, .gm-err-message {
      color: #e0e0e0;
      margin: 0; }
      .gm-err-title:after, .gm-err-message:after {
        content: 'Enter a valid Google Maps API Key';
        color: #616161;
        display: block; }

    .gm-err-message:after {
      content: 'Please consult the Google Maps API documentation on how to obtain an API key.'; }

    .modal-header {
      position: relative;
      z-index: 1; }
      .modal-header .close {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0; }

    body {
      transition: opacity .4s ease; }
      body.fade-page {
        opacity: 0; }

    .page-link {
      transition: all 0.2s ease-in-out; }
      .page-link .icon-xs {
        position: relative;
        bottom: 1px; }
      .page-link:hover {
        z-index: 0; }

    .page-item:first-child .page-link.rounded {
      border-radius: 200em 0 0 200em !important; }

    .page-item:first-child .page-link .icon {
      margin-left: 0.25rem; }

    .page-item:last-child .page-link.rounded {
      border-radius: 0 200em 200em 0 !important; }

    .page-item:last-child .page-link .icon {
      margin-right: 0.25rem; }

    .page-item.active .page-link .icon [stroke]:not([stroke="none"]) {
      stroke: #fff; }

    .page-item.active .page-link .icon [fill]:not([fill="none"]) {
      fill: #fff; }

    @-webkit-keyframes plyr-progress {
      to {
        background-position: 25px 0; } }

    @keyframes plyr-progress {
      to {
        background-position: 25px 0; } }

    @-webkit-keyframes plyr-popup {
      0% {
        opacity: .5;
        transform: translateY(10px); }
      to {
        opacity: 1;
        transform: translateY(0); } }

    @keyframes plyr-popup {
      0% {
        opacity: .5;
        transform: translateY(10px); }
      to {
        opacity: 1;
        transform: translateY(0); } }

    @-webkit-keyframes plyr-fade-in {
      from {
        opacity: 0; }
      to {
        opacity: 1; } }

    @keyframes plyr-fade-in {
      from {
        opacity: 0; }
      to {
        opacity: 1; } }

    .plyr {
      -moz-osx-font-smoothing: auto;
      -webkit-font-smoothing: subpixel-antialiased;
      direction: ltr;
      font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
      font-variant-numeric: tabular-nums;
      font-weight: 500;
      line-height: 1.7;
      max-width: 100%;
      min-width: 200px;
      position: relative;
      text-shadow: none;
      transition: box-shadow .3s ease; }

    .plyr audio, .plyr video {
      border-radius: inherit;
      height: auto;
      vertical-align: middle;
      width: 100%; }

    .plyr button {
      font: inherit;
      line-height: inherit;
      width: auto; }

    .plyr:focus {
      outline: 0; }

    .plyr--full-ui {
      box-sizing: border-box; }

    .plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
      box-sizing: inherit; }

    .plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
      touch-action: manipulation; }

    .plyr__badge {
      background: #4a5764;
      border-radius: 2px;
      color: #fff;
      font-size: 9px;
      line-height: 1;
      padding: 3px 4px; }

    .plyr--full-ui ::-webkit-media-text-track-container {
      display: none; }

    .plyr__captions {
      -webkit-animation: plyr-fade-in .3s ease;
              animation: plyr-fade-in .3s ease;
      bottom: 0;
      color: #fff;
      display: none;
      font-size: 14px;
      left: 0;
      padding: 10px;
      position: absolute;
      text-align: center;
      transition: transform .4s ease-in-out;
      width: 100%; }

    .plyr__captions .plyr__caption {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 2px;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      line-height: 185%;
      padding: .2em .5em;
      white-space: pre-wrap; }

    .plyr__captions .plyr__caption div {
      display: inline; }

    .plyr__captions span:empty {
      display: none; }

    @media (min-width: 480px) {
      .plyr__captions {
        font-size: 16px;
        padding: 20px; } }

    @media (min-width: 768px) {
      .plyr__captions {
        font-size: 18px; } }

    .plyr--captions-active .plyr__captions {
      display: block; }

    .plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
      transform: translateY(-40px); }

    .plyr__control {
      background: 0 0;
      border: 0;
      border-radius: 3px;
      color: inherit;
      cursor: pointer;
      flex-shrink: 0;
      overflow: visible;
      padding: 7px;
      position: relative;
      transition: all .3s ease; }

    .plyr__control svg {
      display: block;
      fill: currentColor;
      height: 18px;
      pointer-events: none;
      width: 18px; }

    .plyr__control:focus {
      outline: 0; }

    .plyr__control.plyr__tab-focus {
      box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
      outline: 0; }

    a.plyr__control {
      text-decoration: none; }

    a.plyr__control::after, a.plyr__control::before {
      display: none; }

    .plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
      display: none; }

    .plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
      background: #00b3ff;
      color: #fff; }

    .plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
      background: #00b3ff;
      color: #fff; }

    .plyr__control--overlaid {
      background: rgba(0, 179, 255, 0.8);
      border: 0;
      border-radius: 100%;
      color: #fff;
      display: none;
      left: 50%;
      padding: 15px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; }

    .plyr__control--overlaid svg {
      left: 2px;
      position: relative; }

    .plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
      background: #00b3ff; }

    .plyr--playing .plyr__control--overlaid {
      opacity: 0;
      visibility: hidden; }

    .plyr--full-ui.plyr--video .plyr__control--overlaid {
      display: block; }

    .plyr--full-ui ::-webkit-media-controls {
      display: none; }

    .plyr__controls {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      text-align: center; }

    .plyr__controls .plyr__progress__container {
      flex: 1;
      min-width: 0; }

    .plyr__controls .plyr__controls__item {
      margin-left: 2.5px; }

    .plyr__controls .plyr__controls__item:first-child {
      margin-left: 0;
      margin-right: auto; }

    .plyr__controls .plyr__controls__item.plyr__progress__container {
      padding-left: 2.5px; }

    .plyr__controls .plyr__controls__item.plyr__time {
      padding: 0 5px; }

    .plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
      padding-left: 0; }

    .plyr__controls .plyr__controls__item.plyr__volume {
      padding-right: 5px; }

    .plyr__controls .plyr__controls__item.plyr__volume:first-child {
      padding-right: 0; }

    .plyr__controls:empty {
      display: none; }

    .plyr--audio .plyr__controls {
      background: #fff;
      border-radius: inherit;
      color: #4a5764;
      padding: 10px; }

    .plyr--video .plyr__controls {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      bottom: 0;
      color: #fff;
      left: 0;
      padding: 20px 5px 5px;
      position: absolute;
      right: 0;
      transition: opacity .4s ease-in-out,transform .4s ease-in-out;
      z-index: 3; }

    @media (min-width: 480px) {
      .plyr--video .plyr__controls {
        padding: 35px 10px 10px; } }

    .plyr--video.plyr--hide-controls .plyr__controls {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100%); }

    .plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
      display: none; }

    .plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
      display: inline-block; }

    .plyr__menu {
      display: flex;
      position: relative; }

    .plyr__menu .plyr__control svg {
      transition: transform .3s ease; }

    .plyr__menu .plyr__control[aria-expanded=true] svg {
      transform: rotate(90deg); }

    .plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
      display: none; }

    .plyr__menu__container {
      -webkit-animation: plyr-popup .2s ease;
              animation: plyr-popup .2s ease;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      bottom: 100%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      color: #4a5764;
      font-size: 16px;
      margin-bottom: 10px;
      position: absolute;
      right: -3px;
      text-align: left;
      white-space: nowrap;
      z-index: 3; }

    .plyr__menu__container > div {
      overflow: hidden;
      transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

    .plyr__menu__container::after {
      border: 4px solid transparent;
      border-top-color: rgba(255, 255, 255, 0.9);
      content: '';
      height: 0;
      position: absolute;
      right: 15px;
      top: 100%;
      width: 0; }

    .plyr__menu__container [role=menu] {
      padding: 7px; }

    .plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
      margin-top: 2px; }

    .plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
      margin-top: 0; }

    .plyr__menu__container .plyr__control {
      align-items: center;
      color: #4a5764;
      display: flex;
      font-size: 14px;
      padding: 4px 11px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%; }

    .plyr__menu__container .plyr__control > span {
      align-items: inherit;
      display: flex;
      width: 100%; }

    .plyr__menu__container .plyr__control::after {
      border: 4px solid transparent;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }

    .plyr__menu__container .plyr__control--forward {
      padding-right: 28px; }

    .plyr__menu__container .plyr__control--forward::after {
      border-left-color: rgba(74, 87, 100, 0.8);
      right: 5px; }

    .plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
      border-left-color: currentColor; }

    .plyr__menu__container .plyr__control--back {
      font-weight: 500;
      margin: 7px;
      margin-bottom: 3px;
      padding-left: 28px;
      position: relative;
      width: calc(100% - 14px); }

    .plyr__menu__container .plyr__control--back::after {
      border-right-color: rgba(74, 87, 100, 0.8);
      left: 7px; }

    .plyr__menu__container .plyr__control--back::before {
      background: #c1c9d1;
      box-shadow: 0 1px 0 #fff;
      content: '';
      height: 1px;
      left: 0;
      margin-top: 4px;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 100%; }

    .plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
      border-right-color: currentColor; }

    .plyr__menu__container .plyr__control[role=menuitemradio] {
      padding-left: 7px; }

    .plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
      border-radius: 100%; }

    .plyr__menu__container .plyr__control[role=menuitemradio]::before {
      background: rgba(0, 0, 0, 0.1);
      content: '';
      display: block;
      flex-shrink: 0;
      height: 16px;
      margin-right: 10px;
      transition: all .3s ease;
      width: 16px; }

    .plyr__menu__container .plyr__control[role=menuitemradio]::after {
      background: #fff;
      border: 0;
      height: 6px;
      left: 12px;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%) scale(0);
      transition: transform .3s ease,opacity .3s ease;
      width: 6px; }

    .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
      background: #00b3ff; }

    .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
      opacity: 1;
      transform: translateY(-50%) scale(1); }

    .plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
      background: rgba(0, 0, 0, 0.1); }

    .plyr__menu__container .plyr__menu__value {
      align-items: center;
      display: flex;
      margin-left: auto;
      margin-right: -5px;
      overflow: hidden;
      padding-left: 25px;
      pointer-events: none; }

    .plyr--full-ui input[type=range] {
      -webkit-appearance: none;
      background: 0 0;
      border: 0;
      border-radius: 26px;
      color: #00b3ff;
      display: block;
      height: 19px;
      margin: 0;
      padding: 0;
      transition: box-shadow .3s ease;
      width: 100%; }

    .plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
      background: 0 0;
      border: 0;
      border-radius: 2.5px;
      height: 5px;
      transition: box-shadow .3s ease;
      -webkit-user-select: none;
      user-select: none;
      background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

    .plyr--full-ui input[type=range]::-webkit-slider-thumb {
      background: #fff;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
      height: 13px;
      position: relative;
      transition: all .2s ease;
      width: 13px;
      -webkit-appearance: none;
      margin-top: -4px; }

    .plyr--full-ui input[type=range]::-moz-range-track {
      background: 0 0;
      border: 0;
      border-radius: 2.5px;
      height: 5px;
      transition: box-shadow .3s ease;
      -moz-user-select: none;
      user-select: none; }

    .plyr--full-ui input[type=range]::-moz-range-thumb {
      background: #fff;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
      height: 13px;
      position: relative;
      transition: all .2s ease;
      width: 13px; }

    .plyr--full-ui input[type=range]::-moz-range-progress {
      background: currentColor;
      border-radius: 2.5px;
      height: 5px; }

    .plyr--full-ui input[type=range]::-ms-track {
      background: 0 0;
      border: 0;
      border-radius: 2.5px;
      height: 5px;
      transition: box-shadow .3s ease;
      -ms-user-select: none;
      user-select: none;
      color: transparent; }

    .plyr--full-ui input[type=range]::-ms-fill-upper {
      background: 0 0;
      border: 0;
      border-radius: 2.5px;
      height: 5px;
      transition: box-shadow .3s ease;
      -ms-user-select: none;
      user-select: none; }

    .plyr--full-ui input[type=range]::-ms-fill-lower {
      background: 0 0;
      border: 0;
      border-radius: 2.5px;
      height: 5px;
      transition: box-shadow .3s ease;
      -ms-user-select: none;
      user-select: none;
      background: currentColor; }

    .plyr--full-ui input[type=range]::-ms-thumb {
      background: #fff;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
      height: 13px;
      position: relative;
      transition: all .2s ease;
      width: 13px;
      margin-top: 0; }

    .plyr--full-ui input[type=range]::-ms-tooltip {
      display: none; }

    .plyr--full-ui input[type=range]:focus {
      outline: 0; }

    .plyr--full-ui input[type=range]::-moz-focus-outer {
      border: 0; }

    .plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
      box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
      outline: 0; }

    .plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
      box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
      outline: 0; }

    .plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
      box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
      outline: 0; }

    .plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
      background-color: rgba(255, 255, 255, 0.25); }

    .plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
      background-color: rgba(255, 255, 255, 0.25); }

    .plyr--full-ui.plyr--video input[type=range]::-ms-track {
      background-color: rgba(255, 255, 255, 0.25); }

    .plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

    .plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

    .plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

    .plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
      background-color: rgba(193, 201, 209, 0.66); }

    .plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
      background-color: rgba(193, 201, 209, 0.66); }

    .plyr--full-ui.plyr--audio input[type=range]::-ms-track {
      background-color: rgba(193, 201, 209, 0.66); }

    .plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

    .plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

    .plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

    .plyr__poster {
      background-color: #000;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity .2s ease;
      width: 100%;
      z-index: 1; }

    .plyr--stopped.plyr__poster-enabled .plyr__poster {
      opacity: 1; }

    .plyr__time {
      font-size: 14px; }

    .plyr__time + .plyr__time::before {
      content: '\2044';
      margin-right: 10px; }

    @media (max-width: 767px) {
      .plyr__time + .plyr__time {
        display: none; } }

    .plyr--video .plyr__time {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

    .plyr__tooltip {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 3px;
      bottom: 100%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      color: #4a5764;
      font-size: 14px;
      font-weight: 500;
      left: 50%;
      line-height: 1.3;
      margin-bottom: 10px;
      opacity: 0;
      padding: 5px 7.5px;
      pointer-events: none;
      position: absolute;
      transform: translate(-50%, 10px) scale(0.8);
      transform-origin: 50% 100%;
      transition: transform .2s .1s ease,opacity .2s .1s ease;
      white-space: nowrap;
      z-index: 2; }

    .plyr__tooltip::before {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgba(255, 255, 255, 0.9);
      bottom: -4px;
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 0;
      z-index: 2; }

    .plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
      opacity: 1;
      transform: translate(-50%, 0) scale(1); }

    .plyr .plyr__control:hover .plyr__tooltip {
      z-index: 3; }

    .plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
      left: 0;
      transform: translate(0, 10px) scale(0.8);
      transform-origin: 0 100%; }

    .plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
      left: 16px; }

    .plyr__controls > .plyr__control:last-child .plyr__tooltip {
      left: auto;
      right: 0;
      transform: translate(0, 10px) scale(0.8);
      transform-origin: 100% 100%; }

    .plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
      left: auto;
      right: 16px;
      transform: translateX(50%); }

    .plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
      transform: translate(0, 0) scale(1); }

    .plyr--video {
      background: #000;
      overflow: hidden; }

    .plyr--video.plyr--menu-open {
      overflow: visible; }

    .plyr__video-wrapper {
      background: #000;
      border-radius: inherit;
      overflow: hidden;
      position: relative;
      z-index: 0; }

    .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
      height: 0;
      padding-bottom: 56.25%; }

    .plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%; }

    .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
      padding-bottom: 240%;
      position: relative;
      transform: translateY(-38.28125%); }

    .plyr__progress {
      left: 6.5px;
      margin-right: 13px;
      position: relative; }

    .plyr__progress input[type=range], .plyr__progress__buffer {
      margin-left: -6.5px;
      margin-right: -6.5px;
      width: calc(100% + 13px); }

    .plyr__progress input[type=range] {
      position: relative;
      z-index: 2; }

    .plyr__progress .plyr__tooltip {
      font-size: 14px;
      left: 0; }

    .plyr__progress__buffer {
      -webkit-appearance: none;
      background: 0 0;
      border: 0;
      border-radius: 100px;
      height: 5px;
      left: 0;
      margin-top: -2.5px;
      padding: 0;
      position: absolute;
      top: 50%; }

    .plyr__progress__buffer::-webkit-progress-bar {
      background: 0 0; }

    .plyr__progress__buffer::-webkit-progress-value {
      background: currentColor;
      border-radius: 100px;
      min-width: 5px;
      transition: width .2s ease; }

    .plyr__progress__buffer::-moz-progress-bar {
      background: currentColor;
      border-radius: 100px;
      min-width: 5px;
      transition: width .2s ease; }

    .plyr__progress__buffer::-ms-fill {
      border-radius: 100px;
      transition: width .2s ease; }

    .plyr--video .plyr__progress__buffer {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: rgba(255, 255, 255, 0.25); }

    .plyr--audio .plyr__progress__buffer {
      color: rgba(193, 201, 209, 0.66); }

    .plyr--loading .plyr__progress__buffer {
      -webkit-animation: plyr-progress 1s linear infinite;
              animation: plyr-progress 1s linear infinite;
      background-image: linear-gradient(-45deg, rgba(35, 41, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 41, 47, 0.6) 50%, rgba(35, 41, 47, 0.6) 75%, transparent 75%, transparent);
      background-repeat: repeat-x;
      background-size: 25px 25px;
      color: transparent; }

    .plyr--video.plyr--loading .plyr__progress__buffer {
      background-color: rgba(255, 255, 255, 0.25); }

    .plyr--audio.plyr--loading .plyr__progress__buffer {
      background-color: rgba(193, 201, 209, 0.66); }

    .plyr__volume {
      align-items: center;
      display: flex;
      flex: 1;
      position: relative; }

    .plyr__volume input[type=range] {
      margin-left: 5px;
      position: relative;
      z-index: 2; }

    @media (min-width: 480px) {
      .plyr__volume {
        max-width: 90px; } }

    @media (min-width: 768px) {
      .plyr__volume {
        max-width: 110px; } }

    .plyr--is-ios .plyr__volume {
      display: none !important; }

    .plyr--is-ios.plyr--vimeo [data-plyr=mute] {
      display: none !important; }

    .plyr:-webkit-full-screen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:-ms-fullscreen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:-moz-full-screen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:fullscreen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:-webkit-full-screen video {
      height: 100%; }

    .plyr:-ms-fullscreen video {
      height: 100%; }

    .plyr:-moz-full-screen video {
      height: 100%; }

    .plyr:fullscreen video {
      height: 100%; }

    .plyr:-webkit-full-screen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:-ms-fullscreen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:-moz-full-screen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:fullscreen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:fullscreen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:-webkit-full-screen.plyr--hide-controls {
      cursor: none; }

    .plyr:-ms-fullscreen.plyr--hide-controls {
      cursor: none; }

    .plyr:-moz-full-screen.plyr--hide-controls {
      cursor: none; }

    .plyr:fullscreen.plyr--hide-controls {
      cursor: none; }

    @media (min-width: 1024px) {
      .plyr:-webkit-full-screen .plyr__captions {
        font-size: 21px; }
      .plyr:-ms-fullscreen .plyr__captions {
        font-size: 21px; }
      .plyr:-moz-full-screen .plyr__captions {
        font-size: 21px; }
      .plyr:fullscreen .plyr__captions {
        font-size: 21px; } }

    .plyr:-webkit-full-screen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:-webkit-full-screen video {
      height: 100%; }

    .plyr:-webkit-full-screen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:-webkit-full-screen.plyr--hide-controls {
      cursor: none; }

    @media (min-width: 1024px) {
      .plyr:-webkit-full-screen .plyr__captions {
        font-size: 21px; } }

    .plyr:-moz-full-screen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:-moz-full-screen video {
      height: 100%; }

    .plyr:-moz-full-screen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:-moz-full-screen.plyr--hide-controls {
      cursor: none; }

    @media (min-width: 1024px) {
      .plyr:-moz-full-screen .plyr__captions {
        font-size: 21px; } }

    .plyr:-ms-fullscreen {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%; }

    .plyr:-ms-fullscreen video {
      height: 100%; }

    .plyr:-ms-fullscreen .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr:-ms-fullscreen.plyr--hide-controls {
      cursor: none; }

    @media (min-width: 1024px) {
      .plyr:-ms-fullscreen .plyr__captions {
        font-size: 21px; } }

    .plyr--fullscreen-fallback {
      background: #000;
      border-radius: 0 !important;
      height: 100%;
      margin: 0;
      width: 100%;
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 10000000; }

    .plyr--fullscreen-fallback video {
      height: 100%; }

    .plyr--fullscreen-fallback .plyr__video-wrapper {
      height: 100%;
      position: static; }

    .plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

    .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
      display: block; }

    .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
      display: none; }

    .plyr--fullscreen-fallback.plyr--hide-controls {
      cursor: none; }

    @media (min-width: 1024px) {
      .plyr--fullscreen-fallback .plyr__captions {
        font-size: 21px; } }

    .plyr__ads {
      border-radius: inherit;
      bottom: 0;
      cursor: pointer;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1; }

    .plyr__ads > div, .plyr__ads > div iframe {
      height: 100%;
      position: absolute;
      width: 100%; }

    .plyr__ads::after {
      background: rgba(35, 41, 47, 0.8);
      border-radius: 2px;
      bottom: 10px;
      color: #fff;
      content: attr(data-badge-text);
      font-size: 11px;
      padding: 2px 6px;
      pointer-events: none;
      position: absolute;
      right: 10px;
      z-index: 3; }

    .plyr__ads::after:empty {
      display: none; }

    .plyr__cues {
      background: currentColor;
      display: block;
      height: 5px;
      left: 0;
      margin: -2.5px 0 0;
      opacity: .8;
      position: absolute;
      top: 50%;
      width: 3px;
      z-index: 3; }

    .plyr__preview-thumb {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 3px;
      bottom: 100%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      margin-bottom: 10px;
      opacity: 0;
      padding: 3px;
      pointer-events: none;
      position: absolute;
      transform: translate(0, 10px) scale(0.8);
      transform-origin: 50% 100%;
      transition: transform .2s .1s ease,opacity .2s .1s ease;
      z-index: 2; }

    .plyr__preview-thumb--is-shown {
      opacity: 1;
      transform: translate(0, 0) scale(1); }

    .plyr__preview-thumb::before {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgba(255, 255, 255, 0.9);
      bottom: -4px;
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 0;
      z-index: 2; }

    .plyr__preview-thumb__image-container {
      background: #c1c9d1;
      border-radius: 2px;
      overflow: hidden;
      position: relative;
      z-index: 0; }

    .plyr__preview-thumb__image-container img {
      height: 100%;
      left: 0;
      max-height: none;
      max-width: none;
      position: absolute;
      top: 0;
      width: 100%; }

    .plyr__preview-thumb__time-container {
      bottom: 6px;
      left: 0;
      position: absolute;
      right: 0;
      white-space: nowrap;
      z-index: 3; }

    .plyr__preview-thumb__time-container span {
      background-color: rgba(0, 0, 0, 0.55);
      border-radius: 2px;
      color: #fff;
      font-size: 14px;
      padding: 3px 6px; }

    .plyr__preview-scrubbing {
      bottom: 0;
      -webkit-filter: blur(1px);
              filter: blur(1px);
      height: 100%;
      left: 0;
      margin: auto;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity .3s ease;
      width: 100%;
      z-index: 1; }

    .plyr__preview-scrubbing--is-shown {
      opacity: 1; }

    .plyr__preview-scrubbing img {
      height: 100%;
      left: 0;
      max-height: none;
      max-width: none;
      object-fit: contain;
      position: absolute;
      top: 0;
      width: 100%; }

    .plyr--no-transition {
      transition: none !important; }

    .plyr__sr-only {
      clip: rect(1px, 1px, 1px, 1px);
      overflow: hidden;
      border: 0 !important;
      height: 1px !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important; }

    .plyr [hidden] {
      display: none !important; }

    .plyr {
      font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

    .plyr__poster {
      background-size: cover !important; }

    button.plyr__control--overlaid {
      background: #fff;
      width: 6rem;
      height: 6rem;
      padding: 0;
      color: #2C3038; }
      button.plyr__control--overlaid svg {
        transform: scaleX(1);
        margin: 0 auto; }
      button.plyr__control--overlaid:hover {
        background: #00825f; }

    .plyr--video .plyr__control:hover {
      background: #00825f; }

    .plyr--full-ui input[type=range] {
      color: #009b72; }

    .rounded video, .rounded .plyr {
      border-radius: 0.3125rem;
      overflow: hidden; }

    .rounded .plyr__controls {
      border-radius: 0 0 0.3125rem 0.3125rem; }

    .plyr__video-wrapper, .plyr__poster, .plyr--video {
      background: transparent; }

    .video-poster {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center; }
      .video-poster .btn {
        position: absolute; }

    .pricing-table-section .row:not(:first-child) {
      border-top: 1px solid #EAEDF2; }

    .pricing-table-section .row:nth-child(even) {
      background: #F7F9FC; }

    .pricing-table-section + .pricing-table-section {
      margin-top: 4rem; }

    @media (min-width: 768px) {
      .pricing-table-section + .pricing-table-section {
        margin-top: 8rem; } }

    @media (max-width: 991.98px) {
      .pricing-table-section [class*='col-12'] {
        border-bottom: 1px solid #EAEDF2; } }

    /**
     * prism.js default theme for JavaScript, CSS and HTML
     * Based on dabblet (http://dabblet.com)
     * @author Lea Verou
     */
    code[class*="language-"],
    pre[class*="language-"] {
      color: black;
      background: none;
      text-shadow: 0 1px white;
      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      font-size: 1em;
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      word-wrap: normal;
      line-height: 1.5;
      -moz-tab-size: 4;
      tab-size: 4;
      -webkit-hyphens: none;
      -moz-hyphens: none;
      -ms-hyphens: none;
      hyphens: none; }

    pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
    code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
      text-shadow: none;
      background: #b3d4fc; }

    pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
    code[class*="language-"]::selection, code[class*="language-"] ::selection {
      text-shadow: none;
      background: #b3d4fc; }

    @media print {
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: none; } }

    /* Code blocks */
    pre[class*="language-"] {
      padding: 1em;
      margin: .5em 0;
      overflow: auto; }

    :not(pre) > code[class*="language-"],
    pre[class*="language-"] {
      background: #f5f2f0; }

    /* Inline code */
    :not(pre) > code[class*="language-"] {
      padding: .1em;
      border-radius: .3em;
      white-space: normal; }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: slategray; }

    .token.punctuation {
      color: #999; }

    .namespace {
      opacity: .7; }

    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: #905; }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
      color: #690; }

    .token.operator,
    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string {
      color: #9a6e3a;
      background: rgba(255, 255, 255, 0.5); }

    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: #07a; }

    .token.function,
    .token.class-name {
      color: #DD4A68; }

    .token.regex,
    .token.important,
    .token.variable {
      color: #e90; }

    .token.important,
    .token.bold {
      font-weight: bold; }

    .token.italic {
      font-style: italic; }

    .token.entity {
      cursor: help; }

    pre[class*='language-'], code[class*='language-'] {
      background: #EAEDF2;
      color: #555A64;
      border: none;
      font-weight: 400; }

    article pre[class*='language-'] {
      margin: 1rem 0;
      border-radius: 0.3125rem; }

    pre[class*="language-"] {
      padding: 1rem; }

    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: #e83e8c; }

    .side-menu {
      position: fixed;
      pointer-events: none;
      right: 0;
      top: 0;
      z-index: 1031;
      display: flex;
      align-items: center; }
      .side-menu .side-menu-body {
        height: 100vh;
        overflow-y: scroll;
        transform: translateX(100%);
        transition: all 0.2s ease-in-out;
        pointer-events: all; }
      .side-menu .btn {
        transition: all 0.2s ease-in-out;
        pointer-events: all;
        position: relative;
        margin-right: 1.5rem;
        left: calc(100% - 4.5rem); }
        .side-menu .btn .icon {
          margin: 0 !important;
          transition: all 0.2s ease-in-out; }
          .side-menu .btn .icon:last-child {
            position: absolute;
            opacity: 0; }
        .side-menu .btn.active {
          left: 0; }
          .side-menu .btn.active .icon:first-child {
            opacity: 0; }
          .side-menu .btn.active .icon:last-child {
            opacity: 1; }
          .side-menu .btn.active + .side-menu-body {
            transform: translateX(0); }
      .side-menu .nav-link {
        color: #555A64; }
        .side-menu .nav-link:not(:hover), .side-menu .nav-link:not(.active) {
          opacity: .5; }
        .side-menu .nav-link:hover, .side-menu .nav-link.active {
          color: #009b72;
          opacity: 1; }

    .inactive-link, .nav-tabs .nav-link[aria-selected="false"], [data-isotope-filters] .nav-link:not(.active) {
      color: #555A64;
      border-bottom-color: rgba(0, 0, 0, 0); }
      .inactive-link:not(:hover), .nav-tabs .nav-link:not(:hover)[aria-selected="false"], [data-isotope-filters] .nav-link:not(:hover):not(.active) {
        opacity: .5; }

    .nav-tabs {
      border-bottom: 2px solid #EAEDF2; }
      .nav-tabs .nav-link {
        padding-left: 0;
        padding-right: 0;
        margin-right: 1.5rem; }

    @media (min-width: 768px) {
      .nav-tabs .nav-link {
        border-bottom: 2px solid #009b72;
        position: relative;
        top: 2px; }
      .nav-tabs.lead .nav-link {
        margin-right: 2.25rem; } }

    @media (max-width: 767.98px) {
      .nav-tabs {
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: scroll; }
        .nav-tabs .nav-link {
          top: 0;
          border: none; } }

    .btn[data-toggle="tab"][aria-selected="true"] .icon [stroke]:not([stroke="none"]) {
      stroke: #fff; }

    .btn[data-toggle="tab"][aria-selected="true"] .icon [fill]:not([fill="none"]) {
      fill: #fff; }

    .btn[data-toggle="tab"][aria-selected="false"] {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
      color: #555A64; }

    .tooltip-circle {
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      font-size: 0.74375rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center; }

    .user img {
      border-radius: 50%;
      max-height: 2rem; }

    .user [data-scribe="component:author"] {
      display: none; }

    .timePosted > a {
      color: #adb5bd; }

    .tweet {
      word-break: break-word; }

    /*# sourceMappingURL=theme.css.map */

</style>

