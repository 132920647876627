<template>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <form role="form">
                <div class="card">
                    <div class="card-header">
                        <div class="title">Opening Times</div>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-widget="collapse">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div v-for="(time,index) in data.model.openTimes" v-bind:key="index">
                                        <div class="d-flex align-items-center my-2">
                                            <span class="mr-2">Days: </span>
                                            <day-picker :model="time" format="long"/>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <span class="mr-2">Time: </span>
                                            <b-form-select v-model="time.openHours" :options="hoursOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                            <span class="px-1"> : </span>
                                            <b-form-select v-model="time.openMins" :options="minsOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                            <span class="px-1"> to </span>
                                            <b-form-select v-model="time.closeHours" :options="hoursOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                            <span> : </span>
                                            <b-form-select v-model="time.closeMins" :options="minsOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                            <div class="ml-3"><a href="#" @click.prevent.stop="deleteTime(time)"><fa icon="minus" class="fa"></fa></a></div>
                                        </div>
                                    </div>
                                    <field-error :errors="data.errors" field="openTimes"/>
                                    <div class="mt-3"><a href="#" @click.prevent.stop="addTime()"><fa icon="plus" class="fa"></fa>&nbsp;add time</a></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

</template>

<script>

import FieldError from '@/components/FieldError';
import DayPicker from '@/components/DayPicker';


function createMinsOptions() {
    var minsOptions = [];
	for (var i=0;i<60;i++) {
		minsOptions.push({
			value : i,
			label : i<10?'0'+i:''+i
		});
	}
	return minsOptions;
}

function createHoursOptions() {
    var hoursOptions = [];
    for (var i=0;i<24;i++) {
        hoursOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return hoursOptions;
}

export default {
  name: 'LocationOpeningTimes',
  components: {
    FieldError,DayPicker
  },
  props : ['data'],
  data : function() {
    var d = {
         minsOptions : createMinsOptions(),
         hoursOptions : createHoursOptions()
         };

    return d;
  },
  methods : {
        deleteTime : function(time) {
            var index = this.data.model.openTimes.indexOf(time);
            if (index>=0) {
                this.data.model.openTimes.splice(index, 1);
            }
        },
        addTime : function() {
            this.data.model.openTimes.push({
                monday : true,
                tuesday : true,
                wednesday : true,
                thursday : true,
                friday : true,
                saturday : true,
                sunday : true,
                openHours : 12,
                openMins : 0,
                closeHours : 22,
                closeMins : 0
            });
        }
    },
    created : function() {
    }
};
</script>

<style scoped>

</style>