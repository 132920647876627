var menuDisplayFormatOptions = [
		{
		value : 'none',
		label : 'Not On Menu'
		},
		{
		value : 'inlineSinglePrice',
		label : 'Inline With One Price'
		},
		{
		value : 'inlineMultiPrice',
		label : 'Inline With Each Prices'
		},
		{
		value : 'multiLine',
		label : 'Multiple Lines With Each Price'
		},
		{
		value : 'groupNameSinglePrice',
		label : 'Grouping Name Only With One Price'
		}
	];

var alignOptions = [{
				label : '',
				value : null
			},{
				label : 'Inline',
				value : 'inline'
			},{
				label : 'Newline',
				value : 'newline'
			},{
				label : 'Exclude',
				value : 'exclude'
			}];


var LabelService = {
    menuDisplayFormatOptions : menuDisplayFormatOptions,
    alignOptions : alignOptions,
    getPaymentMethod : function(key) {
        if (key==null) {
            return '';
        }
        switch (key) {
            case 'CASH':
                return 'Cash';
            case 'CARD':
                return 'Card';
        }
        return key.substring(0,1).toUpperCase()+key.substring(1).toLowerCase();
    }, getSource : function(key) {
        if (key==null) {
            return '';
        }
       return key.substring(0,1).toUpperCase()+key.substring(1).toLowerCase();
    }, getOrderType : function(key) {
        if (key==null) {
            return '';
        }
       return key.substring(0,1).toUpperCase()+key.substring(1).toLowerCase();
    }

};

export {LabelService as LabelService};