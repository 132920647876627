<template>
    <form name="deliveryForm">
        <ul class="list-group">
            <li class="list-group-item actionable">
                <div>
                    <span class="header">Delivery Orders are: <span
                        v-if="data.model.enabled">Enabled</span><span
                        v-if="!data.model.enabled">Not Enabled</span></span>
                    <p v-if="!data.model.enabled">Customers can't order for
                        delivery.</p>
                    <p v-if="data.model.enabled">Customers can order for
                        delivery.</p>
                    <field-error :errors="data.errors"
                                                field="enabled"></field-error>
                </div>
                <div>
                    <b-form-checkbox switch size="lg" v-model="data.model.enabled"></b-form-checkbox>
                </div>
            </li>
        </ul>

    
        <div v-if="data.model.enabled">
            <div>
            <div class="row">
                <div class="col-sm-12">
                    <div id="details" class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Payment Options</h3>
                        </div>
                        <div class="box-body no-padding">
                            <ul class="list-group">
                                <li class="list-group-item actionable">
                                    <div>
                                        <span class="header">Cash Payment is: <span
                                            v-if="data.model.cashAccepted">Accepted</span><span
                                            v-if="!data.model.cashAccepted">Not Accepted</span></span>
                                        <p v-if="!data.model.cashAccepted">Customers can't
                                            pay by cash when collecting their order.</p>
                                        <p v-if="data.model.cashAccepted">Customers can pay
                                            by cash when collecting their order.</p>
                                    </div>
                                    <div>
                                        <b-form-checkbox switch size="lg" v-model="data.model.cashAccepted"></b-form-checkbox>
                                    </div>
                                </li>
                                <!-- 
                                <li class="list-group-item actionable">
                                    <div>
                                        <span class="header">Card Payment is: <span
                                            v-if="data.model.cardAccepted">Accepted</span><span
                                            v-if="!data.model.cardAccepted">Not Accepted</span></span>
                                        <p v-if="!data.model.cardAccepted">Customers can't
                                            pay by card when collecting their order.</p>
                                        <p v-if="data.model.cardAccepted">Customers can pay
                                            by card when collecting their order.</p>
                                    </div>
                                    <div>
                                        <b-form-checkbox switch size="lg" v-model="data.model.cardAccepted"></b-form-checkbox>
                                    </div>
                                </li>
                                 -->
                                <li class="list-group-item actionable">
                                    <div>
                                        <span class="header">Online Card Payment is: <span
                                            v-if="data.model.onlineCardAccepted">Accepted</span><span
                                            v-if="!data.model.onlineCardAccepted">Not
                                                Accepted</span></span>
                                        <p v-if="!data.model.onlineCardAccepted">Customers
                                            can't pay by card online at time of placing order.</p>
                                        <p v-if="data.model.onlineCardAccepted">Customers can
                                            pay by card online at time of placing order.</p>
                                    </div>
                                    <div>
                                        <b-form-checkbox switch size="lg" v-model="data.model.onlineCardAccepted"></b-form-checkbox>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div id="details" class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Minimum Order</h3>
                        </div>
                        <div class="box-body no-padding">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="actionable">
                                        <div>
                                            <span class="header">Minimum Order Value is: <span
                                                v-if="data.model.minOrderEnabled">Enabled</span><span
                                                v-if="!data.model.minOrderEnabled">Not Enabled</span></span>
                                            <p v-if="!data.model.minOrderEnabled">Customers don't have a minimum order size.</p>
                                            <p v-if="data.model.minOrderEnabled">Customers can't place an order below the specified value.</p>
                                        </div>
                                        <div>
                                            <b-form-checkbox switch size="lg" v-model="data.model.minOrderEnabled"></b-form-checkbox>
                                        </div>
                                    </div>
                                    <div class="container-fluid"
                                        v-if="data.model.minOrderEnabled">
                                        <div class="form-group col-xs-12">
                                            <label for="exampleInputEmail1">Minimum Value</label>
                                            <money
                                                                                 class="form-control"
                                                                                    placeholder="Enter Minimum Value"
                                                                                    v-model="data.model.minOrder"
                                                                                    required/>
                                            <field-error :errors="data.errors"
                                                field="minOrder"></field-error>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <ul class="list-group">
            <li class="list-group-item actionable">
                <div>
                    <span class="header">Take orders during: <span
                        v-if="data.model.customTimesEnabled">Custom Times</span><span
                        v-if="!data.model.customTimesEnabled">Open Times</span></span>
                    <div v-if="data.model.customTimesEnabled">Orders will be taken online during custom times only:
                        <div v-for="(time,index) in data.model.customTimes" v-bind:key="index">
                            <div class="d-flex align-items-center my-2">
                                <span class="mr-2">Days: </span>
                                <day-picker :model="time" format="long"/>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="mr-2">Time: </span>
                                <b-form-select v-model="time.openHours" :options="hoursOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                <span class="px-1"> : </span>
                                <b-form-select v-model="time.openMins" :options="minsOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                <span class="px-1"> to </span>
                                <b-form-select v-model="time.closeHours" :options="hoursOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                <span> : </span>
                                <b-form-select v-model="time.closeMins" :options="minsOptions" class="col-2 col-md-1" value-field="value" text-field="label"></b-form-select>
                                <div class="ml-3"><a href="#" @click.prevent.stop="deleteTime(time)"><fa icon="minus" class="fa"></fa></a></div>
                            </div>
                        </div>
                        <div class="mt-3"><a href="#" @click.prevent.stop="addTime()"><fa icon="plus" class="fa"></fa>&nbsp;add time</a></div>
                        <field-error :errors="data.errors" field="customTimes"/>
                    </div>
                    <p v-if="!data.model.customTimesEnabled">Orders will be taken online during regular opening times.</p>
                    <field-error :errors="data.errors" field="customTimes"></field-error>
                </div>
                <div>
                <b-form-checkbox switch size="lg" v-model="data.model.customTimesEnabled"></b-form-checkbox>
                </div>
            </li>
        </ul>

        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div id="details" class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Delivery Zones</h3>
                        </div>
                        <div class="box-body no-padding">
                            <ul class="list-group" v-if="!data.initialising">
                                <li class="list-group-item" v-if="data.model.deliveryZones.length==0">
                                    <span class="header">No delivery zones defined</span>
                                    <p>Add delivery zones to ensure delivery orders arent placed for outside your designated area.</p>
                                </li>
                                <li class="list-group-item actionable" v-for="(deliveryZone, index) in data.model.deliveryZones" :key="index" :class="{'disabled' : !deliveryZone.enabled, 'exclude' : !deliveryZone.include, 'include' : deliveryZone.include}">
                                    <div>
                                        <div><span class="header">{{deliveryZone.name}} </span></div>
                                        <div><span class="type">Defined by {{deliveryZone.type}} </span></div>
                                        <p v-if="deliveryZone.include">Delivery charge: <span v-if="deliveryZone.deliveryCharge">{{deliveryZone.deliveryCharge}}</span><span v-if="!deliveryZone.deliveryCharge">None</span></p>
                                        <div><a href="#" @click.prevent.stop="editDeliveryZone(deliveryZone)">EDIT</a> | <a href="#" @click.prevent.stop="deleteDeliveryZone(deliveryZone)">DELETE</a></div>
                                    </div>
                                    <div>
                                        <b-form-checkbox switch size="lg" v-model="deliveryZone.enabled"></b-form-checkbox>
                                    </div>
                                </li>
                            </ul>
                            <field-error :errors="data.errors"
                                                        field="deliveryZones"></field-error>
                        </div>
                        <div class="box-tools d-flex justify-content-end mt-2">
                            <button type="button" class="btn btn-success mr-1" @click="addDeliveryZone('radius')"><fa icon="plus"></fa> Radius </button>
                            <button type="button" class="btn btn-success mr-1" @click="addDeliveryZone('custom')"><fa icon="plus"></fa> Custom </button>
                            <button type="button" class="btn btn-success mr-1" @click="addDeliveryZone('postcode')"><fa icon="plus"></fa> Postcodes </button>
                            <button type="button" class="btn btn-danger mr-1" @click="excludeDeliveryZone('custom')"><fa icon="minus"></fa> Custom </button>
                            <button type="button" class="btn btn-danger" @click="excludeDeliveryZone('postcode')"><fa icon="minus"></fa> Postcodes </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <!--
        <ul class="list-group">
            <li class="list-group-item">
                <div class="actionable">
                    <div>
                        <span class="header">Advance Orders Are: <span
                            v-if="data.model.advanceOrderEnabled">Enabled</span><span
                            v-if="!data.model.advanceOrderEnabled">Not Enabled</span></span>
                        <p v-if="data.model.advanceOrderEnabled">Customers can place their orders in advance.</p>
                        <p v-if="!data.model.advanceOrderEnabled">Customers cqn only place their orders for pickup ASAP.</p>
                    </div>
                    <div>
                        <div class="checkbox">
                            <label> <input bootstrap-switch type="checkbox"
                                v-model="data.model.advanceOrderEnabled">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" v-if="data.model.advanceOrderEnabled">
                    <div class="form-group col-xs-12">
                        <label for="exampleInputEmail1">Minimum Minutes in Advance</label>
                        <select class="form-control" v-options="item.value as item.label for item in data.advanceMinMinsOptions" v-model="data.model.minAdvanceMins"></select>
                        <field-error :errors="data.errors" field="minAdvanceMins"></field-error>
                    </div>
                    <div class="form-group col-xs-12">
                        <label for="exampleInputEmail1">Max Days in Advance</label>
                        <select class="form-control" v-options="item.value as item.label for item in data.advanceMaxDaysOptions" v-model="data.model.maxAdvanceDays"></select>
                        <field-error :errors="data.errors"
                            field="maxAdvanceDays"></field-error>
                    </div>
                </div>
            </li>
        </ul>
        -->
        <delivery-zone-modal v-if="showDeliveryZoneModal" :model="deliveryModel" :include="deliveryZoneInclude" :type="deliveryZoneType" @hidden="closeModal" @submitted="submitDeliveryZone"/>
        <delivery-postcode-modal v-if="showDeliveryPostcodeModal" :model="deliveryModel" :include="deliveryZoneInclude" :type="deliveryZoneType" @hidden="closeModal" @submitted="submitDeliveryZone"/>
        </div>

    </form>
</template>

<script>
import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError';
import DayPicker from '@/components/DayPicker';
import DeliveryZoneModal from './DeliveryZoneModal';
import DeliveryPostcodeModal from './DeliveryPostcodeModal';


function createMinsOptions() {
    var minsOptions = [];
    for (var i=0;i<60;i++) {
        minsOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return minsOptions;
}

function createHoursOptions() {
    var hoursOptions = [];
    for (var i=0;i<24;i++) {
        hoursOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return hoursOptions;
}

export default {
  name: 'Delivery',
  components: {
    FieldError, DayPicker, DeliveryZoneModal, DeliveryPostcodeModal
  },
  data : function() {
    var id=this.$route.params.id;
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/olo/config/'+id+'/delivery').saveEndpoint('/wb/api/admin/olo/config/'+id+'/delivery').saveAlert('Saved', 'Delivery details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Delivery',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data,
         minsOptions : createMinsOptions(),
         hoursOptions : createHoursOptions(),
         showDeliveryZoneModal : false,
         showDeliveryPostcodeModal : false,
         deliveryModel : null,
         deliveryZoneInclude : null,
         deliveryZoneType : null
         });

     return d;
  },
  methods : {
    submitDeliveryZone : function(model) {
        if (this.deliveryModel!=null) {
            var index=this.data.model.deliveryZones.indexOf(this.deliveryModel);
            if (index>=0) {
                this.data.model.deliveryZones.splice(index, 1);
            }
        }
        this.data.model.deliveryZones.push(model);
        this.deliveryModel=null;
        this.showDeliveryZoneModal=false;
        this.showDeliveryPostcodeModal=false;
    },
    closeModal : function() {
        this.showDeliveryZoneModal=false;
        this.showDeliveryPostcodeModal=false;
    },
    deleteDeliveryZone : function(model) {
        var index=this.data.model.deliveryZones.indexOf(model);
        if (index>=0) {
            this.data.model.deliveryZones.splice(index, 1);
        }
    },
    editDeliveryZone : function(model) {

       this.deliveryZoneInclude=model.include;
       this.deliveryZoneType=model.type;
       this.deliveryModel=model;
       if (model.type=='postcode') {
        this.showDeliveryPostcodeModal=true;
       } else {
        this.showDeliveryZoneModal=true;
       }
   },
    addDeliveryZone : function(type, model) {
        this.deliveryZoneInclude=true;
        this.deliveryZoneType=type;
        this.deliveryModel=model;
        if (type=='postcode') {
            this.showDeliveryPostcodeModal=true;
           } else {
            this.showDeliveryZoneModal=true;
       }
    },
    excludeDeliveryZone : function(type, model) {
        this.deliveryZoneInclude=false;
        this.deliveryZoneType=type;
        this.deliveryModel=model;
         if (type=='postcode') {
            this.showDeliveryPostcodeModal=true;
           } else {
            this.showDeliveryZoneModal=true;
       }
    },
    deleteTime : function(time) {
                var index = this.data.model.customTimes.indexOf(time);
                if (index>=0) {
                    this.data.model.customTimes.splice(index, 1);
                }
            },
            addTime : function() {
                this.data.model.customTimes.push({
                    monday : true,
                    tuesday : true,
                    wednesday : true,
                    thursday : true,
                    friday : true,
                    saturday : true,
                    sunday : true,
                    openHours : 12,
                    openMins : 0,
                    closeHours : 22,
                    closeMins : 0
                });
            },

            loadData : function() {
                this.data.load();
            }
        },
         beforeRouteLeave : function(to, from, next) {
                 this.data.beforeRouteLeave(to, from, next);
         },
        created : function() {
            this.loadData();
        }
};
</script>

<style scoped>
h3 {
        padding: 10px 0px 10px 15px;
    }

.include {
    border-left: 6px solid green;
}

.exclude {
    border-left: 6px solid red;
}

.disabled {
    background-color: lightgray;
}
</style>