import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {ExtrasParentViewModelFactory} from './ExtrasParentViewModelFactory'
import {ProductImageViewModelFactory} from './ProductImageViewModelFactory'
import {VariationProductViewModelFactory} from './VariationProductViewModelFactory'
import {ProductViewModelFactory} from './ProductViewModelFactory'

var VariationViewModelFactory = {

    create : function(variation, workAreaModifier, parentModifier) {
        if (variation==null) {
            variation={
                    id : null,
                    name : null,
                    type : null,
                    products : [],
                    menuDisplayFormat : 'multiLine'
            };
        }

        var VariationViewModel = {
            errors : ErrorsService.create(),
            isVariation : true,
            type : 'variation'
        };

        function apply(variationProduct) {
            variation.products.push(variationProduct);
            VariationViewModel.model.products.push(variationProduct);
            VariationViewModel.products.push(VariationProductViewModelFactory.create(variationProduct, VariationViewModel, workAreaModifier));
        }

        var variationModifier = {
            viewModel : VariationViewModel,
            applyProduct : apply,
            removeProduct : function() {

            }
        };

        var originalStr=null;

        function resetProducts() {
            VariationViewModel.model.products.forEach(function(product) {
                VariationViewModel.products.push(VariationProductViewModelFactory.create(product, VariationViewModel, workAreaModifier));
            });
        }

        function reset() {
            originalStr=JSON.stringify(variation);
            VariationViewModel.errors = ErrorsService.create();
            VariationViewModel.model=reactive(JSON.parse(JSON.stringify(variation)));
            VariationViewModel.extrasParent=ExtrasParentViewModelFactory.create(VariationViewModel, workAreaModifier);
            VariationViewModel.extrasParent.setParentNode(parentNode);
            VariationViewModel.products=[];
            resetProducts();
            VariationViewModel.created=VariationViewModel.model.id!=null;
            VariationViewModel.images=ProductImageViewModelFactory.create(VariationViewModel.model, workAreaModifier);
        }

        reset();

        VariationViewModel.isDirty=function() {
            return (originalStr!==JSON.stringify(VariationViewModel.model));
        };

        VariationViewModel.reorderProducts=function(fromIndex, toIndex) {
            if (fromIndex!=toIndex) {
                    var fromItem=VariationViewModel.products[fromIndex];
                    var fromModel=VariationViewModel.model.products[fromIndex];
                    VariationViewModel.products.splice(fromIndex, 1);
                    VariationViewModel.model.products.splice(fromIndex, 1);
                    VariationViewModel.products.splice(toIndex, 0, fromItem);
                    VariationViewModel.model.products.splice(toIndex, 0, fromModel);

                    var index=0;
                    VariationViewModel.model.products.forEach(function(p) {
                        p.index=index;
                        index++;
                    });
            }
        };

        VariationViewModel.reset=reset;
        VariationViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
                VariationViewModel.errors.clearServerErrors();
                var url;
                if (VariationViewModel.created) {
                    url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/variation';
                } else {
                    url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/'+parentModifier.viewModel.type+'/'+parentModifier.viewModel.model.id+'/variation';
                }
                RestHelper.put(url, VariationViewModel.model).then(function(result) {
                    handleSaveResult(resolve,reject, result);
                }, PromiseUtils.errorHandler(reject));


                function handleSaveResult(resolve,reject, result) {
                    var created=VariationViewModel.created;
                    if (result.data.success) {
                        if (created) {
                            variation=result.data.result;
                        } else {
                            variation=result.data.result.variation;
                        }
                        reset();
                        workAreaModifier.applyVariation(VariationViewModel);
                        if (!created) {
                            parentModifier.applyVariation(result.data.result.categoryProduct);
                        }
                        resolve({
                            success : true
                        });
                    } else {
                        VariationViewModel.errors.setServerErrors(result.data.errors);
                        resolve({
                            success : false
                        });
                    }
                }
            });
            return promise;
        };

        VariationViewModel.moveToCategory=function(category) {
            var promise = new Promise(function(resolve, reject) {
                RestHelper.post('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/variation/'+VariationViewModel.model.id+"/move", {
                    targetId : category.model.id
                }).then(function(response) {
                    if (response.data.success) {
                        if (parentNode!=null) {
                            parentNode.removeVariation(VariationViewModel);
                        }
                        category.applyVariation(response.data.result);
                        resolve({
                            success : true
                        });
                    } else {
                        resolve({
                            success : false,
                            errors : response.data.errors
                        });
                    }
                }, PromiseUtils.errorHandler(reject));


            });

            return promise;
        };

        var parentNode;

        var parentDeleteListener = null;

        VariationViewModel.newProduct = function() {
            return ProductViewModelFactory.create(null, workAreaModifier, variationModifier);
        }

        VariationViewModel.removeProduct = function(product) {
            alert('removing '+product);
        };

        VariationViewModel.applyProduct = variationModifier.applyProduct;

        VariationViewModel.setParentNode = function(parentNodeP) {
            if (parentNode==parentNodeP) {
                return;
            }
            if (parentDeleteListener!=null) {
                parentDeleteListener();
                parentDeleteListener=null;
            }
            if (parentNodeP!=null) {
                parentDeleteListener = workAreaModifier.addDeletionCallback(parentNodeP.id, function() {
                    parentDeleteListener=null;
                    VariationViewModel.extrasParent.setParentNode(null);
                    parentNode=null;
                });
            }
            parentNode=parentNodeP;
            VariationViewModel.extrasParent.setParentNode(parentNode);

        };

        VariationViewModel=reactive(VariationViewModel);
        workAreaModifier.applyVariation(VariationViewModel);
        return VariationViewModel;
    }

};

export {VariationViewModelFactory as VariationViewModelFactory};