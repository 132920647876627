<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light px-3">
        <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button" @click.prevent="sidemenu.open=!sidemenu.open"><fa icon="bars" class="fa"></fa></a>
          </li>
            <li class="nav-item d-flex align-items-center">
                    {{user.brandName}}
                  </li>
        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item d-flex align-items-center">
                {{user.username}}
              </li>
            <li class="nav-item">
                    <b-dropdown variant="none"  no-caret dropleft>
                        <template #button-content>
                          <fa size="lg" icon="user-circle" class="fa"/>
                        </template>
                      <b-dropdown-item-button @click="settings()">Account</b-dropdown-item-button>
                      <b-dropdown-item-button @click="logout()">Logout</b-dropdown-item-button>
                    </b-dropdown>
              </li>
        </ul>
      </nav>
    <!-- /.navbar -->
</template>

<script>
import {AuthenticationService} from "@/services/AuthenticationService";
import screenfull from 'screenfull';
export default {
  name: "Navbar",
  props : ['sidemenu'],
  components : {},
  data : function() {
    return {
            showModal : false,
               user : AuthenticationService.model.loggedInUser,
               isFullscreen : screenfull.isFullscreen
             };
  },
  methods : {
    toggleMenu : function() {
        this.sidemenu.open=!this.sidemenu.open;
    },
    logout : function() {
        var that=this;
        AuthenticationService.model.logout().then(function() {
            that.$router.push('/login');
        });
    },
    settings : function() {
        this.$router.push('/settings');
    },
     toggleFullscreen : function() {
         if (screenfull.isFullscreen) {
             screenfull.exit();
         } else {
             screenfull.request();
         }
         var that=this;
         setTimeout(function() {
             that.isFullscreen = screenfull.isFullscreen;
         }, 5);
     }
  }
};
</script>