<template>
    <div>
    <div class="card col-12" v-if="!initialising">
      <div class="card-header d-flex">
        <h3 class="card-title flex-grow-1">Banner Message</h3>
      </div>

      <form role="form">
        <div class="card-body">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Display message</label>
                <div class="col-sm-9">
                    <b-form-checkbox switch size="lg" v-model="data.enabled"></b-form-checkbox>

                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Message</label>
                <div class="col-sm-9">
                  <textarea class="form-control" v-model="data.msg" :disabled="!data.enabled">

                  </textarea>
                </div>
                <field-error :errors="errors" field="msg"></field-error>
            </div>
            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-outline-secondary mr-2" @click.stop.prevent="reset()">Reset</button>
              <button type="submit" class="btn btn-primary" @click.stop.prevent="submit()" :disabled="!isDirty">Submit</button>
            </div>
        </div>
        <!-- /.card-body -->


      </form>
    </div>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {AlertService} from "@/services/AlertService";
import {ErrorsService} from '@/services/ErrorsService';
import FieldError from '@/components/FieldError';

export default {
  name: 'BannerMsg',
  components: {
    FieldError
  },
  data : function() {
    var d = {
        originalData : null,
        data : null,
        initialising : true,
        errors : ErrorsService.create()
    };
     return d;
  },
  computed : {
    isDirty : function() {
        return this.originalData!=null && JSON.stringify(this.originalData) != JSON.stringify(this.data);
    }
  },
  methods : {
        reset : function() {
            this.data = JSON.parse(JSON.stringify(this.originalData));
        },
         submit : function() {
             var that=this;
             that.errors.clearServerErrors();
             RestHelper.post('/wb/api/admin/location/primary/bannermsg', that.data).then(function(response) {
                     if (response.data.success) {
                        that.originalData = response.data.result;
                        that.data = JSON.parse(JSON.stringify(that.originalData));
                        AlertService.success('Banner Message updated');
                     } else {
                        that.errors.setServerErrors(response.data.errors);
                     }
                 },PromiseUtils.viewErrorHandler('Error saving banner message data', function() {
                      that.initialising=false;
                  }));
         },
        loadData : function() {
            var that=this;
            that.initialising=true;
            RestHelper.get('/wb/api/admin/location/primary/bannermsg').then(function(response) {
                    that.originalData = response.data;
                    that.data = JSON.parse(JSON.stringify(that.originalData));
                    that.initialising=false;
                },PromiseUtils.viewErrorHandler('Error loading banner message data', function() {
                     that.initialising=false;
                 }));
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>