//import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
//import {RestHelper} from '@/utils/RestHelper'
//import {PromiseUtils} from '@/utils/PromiseUtils'

var CategoryProductViewModelFactory = {

    create : function(category, categoryProduct, workAreaModifier) {
        //var originalCategoryProduct=categoryProduct;
        var copyCategoryProduct=JSON.parse(JSON.stringify(categoryProduct));

        var selectedProduct = null;
        var selectedVariation = null;
        var selectedConfiguredItem = null;


        if (categoryProduct.type=='single') {
            selectedProduct=workAreaModifier.getProductById(copyCategoryProduct.refId);
            selectedProduct.setParentNode(category);
        } else if (categoryProduct.type=='variation') {
            selectedVariation=workAreaModifier.getVariationById(copyCategoryProduct.refId);
            selectedVariation.setParentNode(category);
        } else if (categoryProduct.type=='preconfigured') {
            selectedConfiguredItem=workAreaModifier.getConfiguredItemById(copyCategoryProduct.refId);
            selectedConfiguredItem.setParentNode(category);
        } else if (categoryProduct.type=='linked') {
            selectedProduct=workAreaModifier.getProductByRefId(copyCategoryProduct.refId);
        }


        /*
        var deleteListener = workAreaModifier.addDeletionCallback(copyCategoryProduct.refId, function() {
            deleteListener=null;
            vm.removeSync();
        });
        */

        function getText() {
            if (vm.isSingleProduct) {
                return 'Product ['+selectedProduct.name+']';
            } else if (vm.isVariation) {
                return 'Variation ['+selectedVariation.name+']';
            } else if (vm.isPreconfigured) {
                if (!selectedConfiguredItem) {
                    return '';
                } else {
                    return 'Preconfigured ['+selectedConfiguredItem.name+']';
                }

            } else if (vm.isLinked) {
                return 'Linked Product ['+selectedProduct.name+']';
            } else {
                return '';
            }
        }

        function getName() {
            if (vm.isSingleProduct) {
                return selectedProduct.model.name;
            } else if (vm.isVariation) {
                return selectedVariation.model.name;
            } else if (vm.isPreconfigured) {
                if (!selectedConfiguredItem) {
                    return '';
                } else {
                    return selectedConfiguredItem.model.name;
                }
            } else if (vm.isLinked) {
                return '[*] '+selectedProduct.model.name;
            } else {
                return '';
            }
        }

        function getNodes() {
            if (vm.isSingleProduct) {
                return selectedProduct.nodes;
            } else if (vm.isVariation) {
                return selectedVariation.nodes;
            } else if (vm.isPreconfigured) {
                if (!selectedConfiguredItem) {
                    return [];
                } else {
                return selectedConfiguredItem.nodes;
                }
            } else if (vm.isLinked) {
                return '[*] '+selectedProduct.nodes;
            } else {
                return '';
            }
        }

        var vm = {
            model : copyCategoryProduct,
            type : copyCategoryProduct.type,
            isSingleProduct : copyCategoryProduct.type=='single',
            isVariation : copyCategoryProduct.type=='variation',
            isPreconfigured : copyCategoryProduct.type=='preconfigured',
            isLinked : copyCategoryProduct.type=='linked',
            isCategoryProduct : true,
            selectedProduct : selectedProduct,
            linkedProduct : selectedProduct,
            selectedVariation : selectedVariation,
            selectedConfiguredItem : selectedConfiguredItem
        };

        vm.text=getText();
        vm.name=getName();
        vm.nodes=getNodes();
        vm=reactive(vm);


        /*
            				get availableSingleProducts() {
            					return workAreaModifier.productsForCategory;
            				},
            				get availableItems() {
            					return workAreaModifier.itemsForCategory;
            				},
            				get availableVariationGroupings() {
            					return workAreaModifier.variationsForCategory;
            				},
            				removeSync : function() {
            					for (var i=0;i<original.products.length;i++) {
            						if (original.products[i].id==categoryProduct.id) {
            							original.products.splice(i, 1);
            							break;
            						}
            					}
            					this.remove();
            				},
            				remove : function() {
            					var index = categoryProductViewModels.indexOf(this);
            					if (index>-1) {
            						categoryProductViewModels.splice(index, 1);
            					}
            					for (var i=0;i<copy.products.length;i++) {
            						if (copy.products[i].id==categoryProduct.id) {
            							copy.products.splice(i, 1);
            							break;
            						}
            					}

            					if (selectedProduct!=null) {
            						selectedProduct.setParentNode(null);
            					} else if (selectedGrouping!=null) {
            						selectedGrouping.setParentNode(null);
            					}
            					if (deleteListener!=null) {
            						deleteListener();
            					}
            				},
            				save : function() {
            					var deferred = $q.defer();
            					$http.put('/web/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/category/'+original.id+'/item', copyCategoryProduct).then(function(result) {
            						var newVersion=result.data.version;
            						var saved=result.data.data;
            						originalCategoryProduct=saved;
            						copyCategoryProduct=JSON.parse(JSON.stringify(saved));
            						created=true;
            						deferred.resolve();
            					});
            					return deferred.promise;
            				}
        };
        */

        return vm;
    }

};

export {CategoryProductViewModelFactory as CategoryProductViewModelFactory};