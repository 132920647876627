<template>
    <div>
        <div v-for="(err) in error" v-bind:key="err.message" class="error">{{err.message}}</div>
    </div>
</template>

<script>
export default {
  name: "GeneralErrors",
  props: ['errors'],
  data : function() {
    return {
        error : []
    };
  },
  methods : {
    updateErrors : function() {
        this.error=this.errors.getGeneralErrors();
    }
  },
  created : function() {
    var that=this;
    this.listener = this.errors.addListener(function() {
        that.updateErrors();
    });
    this.updateErrors();
  },
  destroyed : function() {
    this.listener();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .error {
        color : red;
    }
</style>