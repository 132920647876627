<template>
    <div class="basket-container">
        <div class="basket">
            <div v-for="(item, index) in model.details.items" v-bind:key="index">
                <div class="item">
                    <div class="quantity">
                        {{item.quantity}}
                   </div>
                   <div class="details">
                        <div>{{item.productName}}</div>
                        <item-extra v-if="item.extras" :extras="item.extras"/>
                   </div>
                   <div class="price">
                        {{$filters.toCurrency(item.totalPrice)}}
                   </div>
                </div>
            </div>
            <!--
            <div class="discounts" v-if="orderWorkflow.basket.discounts.length>0">
                <div v-for="(discount, index) in orderWorkflow.basket.discounts" v-bind:key="index">
                    <div class="item">
                        <div class="quantity">

                       </div>
                       <div class="details">
                            <div>{{discount.name}}</div>
                       </div>
                       <div class="price">
                            {{$filters.toCurrency(discount.amount)}}
                       </div>
                    </div>
                </div>
            </div>
            <div class="discounts" v-if="orderWorkflow.basket.appliedRewards.length>0">
                <div v-for="(reward, index) in orderWorkflow.basket.appliedRewards" v-bind:key="index">
                    <div class="item">
                        <div class="quantity">

                       </div>
                       <div class="details">
                            <div>{{reward.description}}</div>
                       </div>
                       <div class="price">
                            {{$filters.toCurrency(reward.amount)}}
                       </div>
                    </div>
                </div>
            </div>
            -->
        </div>
        <div class="totals">
                <div class="label">Subtotal</div>
                <div class="value">{{$filters.toCurrency(model.pricing.subtotal)}}</div>
                <div class="label">Discounts</div>
                <div class="value">{{$filters.toCurrency(model.pricing.discount)}}</div>
                <div class="label" v-if="model.pricing.deliveryCharge">Delivery Charge</div>
                <div class="value" v-if="model.pricing.deliveryCharge">{{$filters.toCurrency(model.pricing.deliveryCharge)}}</div>
                <div class="label">Total</div>
                <div class="value">{{$filters.toCurrency(model.pricing.totalCost)}}</div>
        </div>
        <div class="tax-container">
            <div class="tax-row tax-header">
                <div class="rate">Tax Rate</div>
                <div class="net">Net Amount</div>
                <div class="tax">Tax Amount</div>
            </div>
            <div class="tax-row" v-for="(tax, index) in model.pricing.taxes" v-bind:key="index" >
                <div class="rate">{{tax.taxRate}}</div>
                <div class="net">{{tax.nonTaxAmount}}</div>
                <div class="tax">{{tax.taxAmount}}</div>
            </div>
        </div>
    </div>

</template>

<script>

import ItemExtra from './ItemExtra'

var component = {
  name: 'ViewOrderBasket',
  components: {
    ItemExtra
  },
  props : ['model'],
     data : function() {
       var d = {


       };
       return d;
     },
     methods : {

     },
     computed : {

     },
     created : function() {
     }
};

export default component;
</script>

<style lang="scss" scoped>

.basket-container {
    border-right: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
}

.basket {
    display: flex;
    flex-direction: column;
    margin: 25px;
}

.basket .item {
    display: flex;
    padding: 15px 15px 0px 15px;
    font-size: 20x;
    font-weight: 400;
}

.basket .discounts {
    font-style: italic;
    margin-top: 20px;
}

.basket .discount{
    margin-top: 115px;
}

.basket .extra {
    display: flex;
    padding: 2px 15px 2px 25px;
    font-size: 14x;
    font-weight: 300;
}

.basket .item .quantity, .basket .extra .quantity {
    display: flex;
    margin-right: 20px;
}

.basket .item .details, .basket .extra .details {
    flex-grow: 1;
}

.totals {
    border-top: 1px solid lightgray;
    display: grid;
    grid-template-columns: auto 50px;
    margin: 0px 40px 0px 40px;
    padding: 15px 0px 15px 0px;
}

.totals .label {
    justify-self: end;
    font-weight: 500;
    padding-right: 15px;
}

.totals .value {
    justify-self: end;
    padding-left: 15px;
}

.contact-details {
    display: grid;
    grid-template-columns: 30px auto;
    grid-row-gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
}

.dates-panel {
    margin: 15px 0px 15px 0px;
}

.tax-header {
    font-weight: bold;
}

.tax-container {
    border-top: 1px solid lightgray;
    margin: 0px 40px 0px 40px;
    padding: 15px 0px 15px 0px;

    .tax-row {
        display: flex;

        div {
            flex-grow: 1;
            flex-basis: 0;
        }

        .net,.tax {
            text-align: right;
        }
    }

}

</style>