<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item clickable" @click="menus()"><span
                        class="header">Edit Menu</span>
                        <p>Update the contents and pricing of your menu.</p></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import {PageStack} from '@/services/PageStackService'

export default {
  name: 'MenuHomepage',
  components: {

  },
  data : function() {
    var d = PageStack.setPage(this.$route, {
         header : 'Menu',
         buttons : []
         });

     return d;
  },
  methods : {
        menus : function() {
            this.$router.push('/menuworkarea');
        }
    },
    created : function() {

    }
};
</script>

<style scoped>

</style>