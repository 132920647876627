<template>
  <b-modal id="font-selector-modal" modal-class="font-selector-modal" centered hide-footer no-close-on-backdrop title="Select Font" @hide="handleHide()">
        <div class="font-selector-body d-flex flex-column h-100" ref="body">
            <div class="container-fluid">
                <input type="text" class="form-control mb-2" v-model="exampleText" placeholder="Enter example text">
                <div class="list-group">
                <a href="#" class="list-group-item list-group-item-action" v-for="(font, index) in fonts" :key="index" @click.stop.prevent="select(font)">
                    <div class="label">
                        {{font.name}}
                    </div>
                    <div class="example">
                        <span :style="'font-family: '+font.name+', cursive;'">{{exampleText}}</span>
                    </div>
                </a>
                </div>
                <b-pagination
                    class="mt-2"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                    ></b-pagination>
            </div>
        </div>
    </b-modal>
</template>

<script>

import {RestHelper} from '@/utils/RestHelper'

var component = {
  name: "FontSelectorModal",
  props: [],
  components : {},
  data : function() {
      var d = {
           fonts : [],
           allFonts : [],
           pageCount : 0,
           pageNo : 0,
           perPage : 5,
           currentPage : 0,
           exampleText : 'Example Text'
      };
     return d;
    },
    watch : {
        currentPage : function() {
            this.updateCurrentFonts();
        }

    },
    computed : {
        rows() {
            return this.allFonts.length
          }

    },

   methods : {
      handleHide : function() {
          this.$emit('hidden');
      },
      select : function(font) {
        this.$emit('selected', font.name);
      },
      updateCurrentFonts : function() {
        var start=this.currentPage-1;
        var startCount=start*this.perPage;
        var endCount=Math.min((startCount+this.perPage), this.allFonts.length);
        var size = endCount-startCount;
        this.fonts = this.allFonts.splice(startCount,size);
        this.createScript();
      },
      createScript : function() {
        var families = [];
        this.fonts.forEach(function(font) {
            families.push(font.name);
        });
        if (families.length>0) {
            window.WebFont.load({
                google: {
                  families: families
                }
              });
        }
      }
    },
    mounted : function() {
        this.$bvModal.show('font-selector-modal');
        var that=this;

        RestHelper.get('/wb/api/admin/webfonts').then(function(result) {
            that.allFonts=result.data;
            that.currentPage=1;
            that.updateCurrentFonts();
        });

      },
      created : function() {

      }
};
export default component;
</script>


<style>
.example {
    font-size: 25pt;
}

</style>