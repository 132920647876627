//import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
//import {RestHelper} from '@/utils/RestHelper'
//import {PromiseUtils} from '@/utils/PromiseUtils'

var ProductTaxRateViewModelFactory = {

    create : function(product, workAreaModifier) {

        var _selectedTaxRate=null;

        function updateSelectedTaxRate() {
            if (product.overrideTaxRates) {
                if (product.taxRates==null || product.taxRates.length==0) {
                    ProductTaxRateViewModel.selectedTaxRate=null;
                } else {
                    var id=product.taxRates[0].taxRateId;
                    ProductTaxRateViewModel.selectedTaxRate=workAreaModifier.getTaxRateById(id);
                }
            } else {
                ProductTaxRateViewModel.selectedTaxRate=workAreaModifier.defaultTaxRate;
            }
        }



        function resetTaxRate() {
            if (_selectedTaxRate!=null && product.overrideTaxRates) {
                var id=_selectedTaxRate.model.id;
                product.taxRates=[{
                    taxRateId : id
                }];
            } else {
                product.taxRates=[];
            }
        }

        var ProductTaxRateViewModel = {
            id : product.id,
            get overrideTaxRates() {
                return product.overrideTaxRates;
            },
            set overrideTaxRates(value) {
                product.overrideTaxRates=value;
                updateSelectedTaxRate();
            },
            get selectedTaxRate() {
                return _selectedTaxRate;
            },
            set selectedTaxRate(value) {
                _selectedTaxRate=value;
                resetTaxRate();
            },
            getSelectableTaxRates : function() {
                return workAreaModifier.taxRates;
            }
        };

        ProductTaxRateViewModel=reactive(ProductTaxRateViewModel);

        updateSelectedTaxRate();

        return ProductTaxRateViewModel;
    }

};

export {ProductTaxRateViewModelFactory as ProductTaxRateViewModelFactory};