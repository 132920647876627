<template>
  <b-modal id="select-report-modal" scrollable modal-class="select-report-modal" centered hide-footer no-close-on-backdrop title="Select Report" @hide="handleHide()">
        <ul class="list-group">
            <li class="list-group-item clickable" v-for="(report, index) in reports" :key="index" @click="select(report)">
                {{report.name}}
            </li>
        </ul>
    </b-modal>
</template>

<script>

import {RestHelper} from "@/utils/RestHelper";

var component = {
  name: "SelectReportModal",
  props: ['callback'],
  components : {},
  data : function() {
      var d = {
        reports : []
      };
     return d;
    },
   methods : {
      select : function(report) {
        this.callback(report);
      },
      handleHide : function() {
          this.$emit('hidden');
      }
    },
    mounted : function() {
        this.$bvModal.show('select-report-modal');
      },
      created : function() {
        var that=this;
        that.initialising=true;
        RestHelper.get('/wb/api/admin/reports').then(function(response) {
                that.reports=response.data;
                that.initialising=false;
            });
      }
};
export default component;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>