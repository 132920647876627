<template>
    <div class="small-box bg-info report-result-metric">
        <b-dropdown ref="dropdown" size="lg" class="dropdown-btn" variant="link" dropleft toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <fa class="fa" icon="bars"></fa>
            </template>
            <b-dropdown-form>
              <div v-for="(section, index) in menuSections" v-bind:key="index">
                <div class="dropdown-divider" v-if="index>0"></div>
                <h6 class="dropdown-header" v-if="section.header">{{section.header}}</h6>
                <a href="#" class="dropdown-item" v-for="(item, index2) in section.items" v-bind:key="index2" @click.stop.prevent="select(item)">{{item.name}}</a>
              </div>
            </b-dropdown-form>
          </b-dropdown>
          <div class="inner">
            <h3>{{metric.value}}</h3>

            <p>{{metric.title}}</p>
          </div>
    </div>
</template>

<script>
import moment from 'moment';

 function createMetric(data) {
       var valueIndex;
       var valueColumn;
       for (var i=0;i<data.reportData.config.tableColumns.length;i++) {
            var col = data.reportData.config.tableColumns[i];
            if (data.reportData.config.metricConfig.valueColumn===col.id) {
                 valueIndex=i;
                 valueColumn=col;
                 break;
            }
       }
       var value = data.dataset.data[0][valueIndex];
       if (value==null) {
            value = '-';
       } else {
           switch (valueColumn.type) {
           case 'date':
                value=moment(new Date(value)).format('HH:mm');
                break;
           case 'money':
                value=data.$filters.toCurrency(value);
                break;
           }
       }
       var metric = {
                 title : data.reportData.config.metricConfig.title,
                 icon : data.reportData.config.metricConfig.icon,
                 value : value
       };
       data.metric=metric;
       
  }

function createMenuOptions(data) {
        data.menuSections=[];
        if (data.chartTypes.length>1) {
            data.menuSections.push(createChartMenuSection(data));
        }
        data.displayData.menuSections.forEach(function(menuSection) {
            data.menuSections.push(menuSection);
        });
    }

    function createChartMenuSection(data) {
        var menuSection={
            header : 'Select Chart Type',
            items : [

            ]
        };
        data.chartTypes.forEach(function(type) {
            menuSection.items.push({
                name : type,
                select : function() {
                    data.setChartType(type);
                }
            })
        });
        return menuSection;
    }

function processReportData(data, reportData) {
    data.empty=reportData.results[0].data.length===0;
    data.reportData=reportData;
    data.dataset=data.reportData.results[0];
    createMetric(data);
    createMenuOptions(data);
}

function processDisplayData(data, displayData) {
    data.displayData=displayData;
    if (displayData.config==null) {
         data.config={};
     }
     if (displayData.menuSections==null) {
         data.menuSections=[];
     }
}


export default {
  name: 'Metric',
  props : ['report'],
  components: {

  },
  data : function() {
    return {
        menuSections : [],
        config : {},
        chartTypes : [],
        metric : {
          title : null,
          icon : null,
          value : null
        }
    };
  },
  methods : {
    select : function(item) {
        item.select();
        this.$refs.dropdown.hide(true);
    },
    loadData : function() {
        var that=this;
        this.report.getDisplayData().then(function(displayData) {
            processDisplayData(that, displayData);
        }, function(err) {
            alert(err);
        });
        this.report.getReportData().then(function(reportData) {
            processReportData(that, reportData);
        }, function(err) {
                     alert(err);
                 });
}
  },
  created : function() {
    this.loadData();
  }
};
</script>

<style scoped>
    .report-result-metric .dropdown-btn {
        position: absolute;
        right: 0;
        top: 0;

    }

    .report-result-metric .dropdown-btn .fa {
        color : white;
    }

    .report-result-metric .dropdown-menu a {
        color: black !important;
    }
</style>

<style>
    .report-result-metric form {
        padding: 0px;
    }
</style>