import { reactive } from '@vue/composition-api'


var GroupingProductViewModelFactory = {

    create : function(groupingProduct, workAreaModifier, parentViewModel) {

        var deleteListener=null;
        function registerDeleteListener() {
            if (deleteListener!=null) {
                deleteListener();
                deleteListener=null;
            }
            if (GroupingProductViewModel.selectedProduct!=null) {
                deleteListener = workAreaModifier.addDeletionCallback(GroupingProductViewModel.selectedProduct.refId, function() {
                    deleteListener=null;
                    GroupingProductViewModel.remove();
                });
            }
        }

        var GroupingProductViewModel = {
            model : groupingProduct,
            remove : function() {
                 var index=parentViewModel.model.products.indexOf(groupingProduct);
                 if (index>=0) {
                    parentViewModel.model.products.splice(index,1);
                 }
                 index=parentViewModel.products.indexOf(GroupingProductViewModel);
                 if (index>=0) {
                     parentViewModel.products.splice(index,1);
                  }
            }
        };

        if (groupingProduct.productId!=null) {
            GroupingProductViewModel.selectedProduct=workAreaModifier.getProductById(groupingProduct.productId);
            registerDeleteListener();
        }



        GroupingProductViewModel=reactive(GroupingProductViewModel);
        return GroupingProductViewModel;
    }

};

export {GroupingProductViewModelFactory as GroupingProductViewModelFactory};