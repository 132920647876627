<template>
    <div v-if="alertModel.alerts">
    <div class="container-fluid">
          <div v-for="(alert) in alertModel.alerts" v-bind:key="alert.description">
             <b-alert :variant="alert.type" show dismissible fade @dismissed="alert.close()">
                <h4 class="alert-heading">{{alert.title}}</h4>
                <p>{{alert.description}}</p>
             </b-alert>
           </div>
    </div>
    </div>
</template>

<script>
import {AlertService} from "../services/AlertService";

export default {
  name: "Alerts",
  props: [],
  data : function() {
    return {
        alertModel : AlertService.model
    };
  },
  methods : {

  },
  created : function() {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>