<template>
<div>
    <div v-if="!data.initialising">
        <location-contact-details :data="data"/>
    </div>
</div>
</template>

<script>

import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'

import LocationContactDetails from './LocationContactDetails';

export default {
  name: 'LocationContactDetailsPage',
  components: {
    LocationContactDetails
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/location/primary/contactdetails').optional().saveEndpoint('/wb/api/admin/location/primary/contactdetails').saveAlert('Saved', 'Location contact details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Address and Contact Details',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data
         });

    return d;
  },
  beforeRouteLeave (to, from, next) {
     this.data.beforeRouteLeave(to, from, next);
    },
  methods : {
        loadData : function() {
            this.data.load();
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>