<template>
 <div>
  <div class="wrapper">

    <navbar :sidemenu="sidemenu"></navbar>

    <!-- Main Sidebar Container -->

      <sidebar :sidemenu="sidemenu"></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper" v-if="displayContent">
        <alerts></alerts>
            <content-header></content-header>
      <!-- Main content -->
      <section class="content px-3">
        <router-view></router-view>

      </section>
      <!-- /.content -->
    </div>
    <!--
    <app-footer></app-footer>
    -->
  </div>
</div>
</template>

<script>
//import {AuthenticationService} from "../../services/AuthenticationService";
import Alerts from "../../components/Alerts";
import Navbar from './Navbar'
import Sidebar from './Sidebar'
//import AppFooter from './AppFooter'
import ContentHeader from './ContentHeader'
import {ResponsiveService} from '@/services/ResponsiveService';

export default {
  name: "AppTemplate",
  components : {Navbar,ContentHeader,Sidebar,Alerts},
  data : function() {
    return {
        sidemenu : {
            open : ResponsiveService.vm.isDesktop
        },
        displayContent : true
    };
  },
 mounted : function() {
    this.processOpen();
 },
 watch : {
    'sidemenu.open' : function() {
        this.processOpen();
    }
 },
 methods : {
    processOpen : function() {
        var body = document.getElementsByTagName('body')[0];
        if (this.sidemenu.open) {
            body.classList.remove('sidebar-collapse');
            body.classList.add('sidebar-open');
        } else {
            body.classList.add('sidebar-collapse');
            body.classList.remove('sidebar-open');
        }
    }
 },
 created : function() {
    var body = document.getElementsByTagName('body')[0];
    body.removeAttribute('class');
    body.setAttribute('class', 'layout-fixed sidebar-mini sidebar-collapse');
 }
};
</script>
<style scoped>

.content-wrapper {
    display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
}
.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.container section {
    flex-grow: 1;
}

</style>