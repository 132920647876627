<template>
    <div class="card">
        <b-table :fields="fields" :items="devices" empty-text="No devices" show-empty>
            <template v-slot:cell(actions)="data">
              <a href="#" @click.prevent.stop="edit(data.item)"><fa icon="edit" class="fa"></fa></a>
            </template>
        </b-table>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import moment from 'moment';

export default {
  name: 'DeviceList',
  components: {

  },
  data : function() {
    var that=this;
    var fields = [
      { key: 'name', label : 'Name', sortable : true},
      { key: 'location.name', label : 'Location', sortable : true},
      { key: 'ref', label : 'Ref', sortable : true},
      { key: 'menusVersion', label : 'Menu Version', sortable : true},
      { key: 'ordersVersion', label : 'Orders Version', sortable : true},
      { key: 'appVersionName', label : 'App Version', sortable : true},
      { key: 'lastSyncTime', label : 'Last Sync Time', sortable : true, formatter : function(value) {return value==null ? '' : moment(new Date(value)).format("MMM DD YYYY HH:mm");}},
      { key: 'actions', label : '', thStyle:{width:'50px'}}
    ];
    var d = PageStack.setPage(that.$route, {
         fields : fields,
         header : 'Devices',
         buttons : [],
         devices : [],
         initialising : false
     });
     return d;
  },
  methods : {
    edit : function(device) {
        this.$router.push('/device/'+device.id);
    },

    loadData : function() {
        this.initialising=true;
        var that=this;
        RestHelper.get('/wb/api/admin/devices').then(function(result) {
			that.devices=result.data;
			that.initialising=false;
		},PromiseUtils.viewErrorHandler('Error loading devices', function() {
             that.initialising=false;
         }));
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>