<template>
    <div id="setup-steps" class="container-fluid">
        <div class="row" v-if="data && !data.completed">
             <div class="col-12">
                <div class="card card-primary card-outline">
                <div class="card-header">
                    <h3 class="card-title">Completed Setup : {{data.percentage}}%</h3>
                  </div>
                  <div class="card-body p-0">
                    <ul class="list-group">
                      <a href="#" class="list-group-item list-group-item-action" v-for="(step, index) in data.steps" v-bind:key="index" @click="goto(step)">
                          <div class="d-flex">
                            <span class="tick"><fa v-if="step.completed" class="text-success" icon="check"></fa></span>
                            <span class="flex-grow-1" :class="{ 'name-completed': step.completed }">{{step.name}}</span>
                            <a class="skip-link" href="#" v-if="step.skippable && !step.completed" @click.stop.prevent="skip(step)">SKIP</a>
                          </div>
                      </a>
                    </ul>
                  </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";

export default {
  name: 'Setup',
  components: {

  },
  data : function() {
    var d = {
        data : null
        };
    return d;
  },
  methods : {
    goto : function(step) {
        this.$router.push(step.path);
    },
    getIcon : function(step) {
        if (step.completed) {
            return 'check-circle';
        } else {
            return 'circle';
        }
    },
    loadData : function() {
        var that=this;
        RestHelper.get('/wb/api/admin/setup/steps').then(function(response) {
            that.data = response.data;
        },PromiseUtils.viewErrorHandler('Unable to load steps'));
    },
    skip : function(step) {
        var that=this;
                RestHelper.post('/wb/api/admin/setup/steps/skip', {step : step.id}).then(function(response) {
                    that.data = response.data;
                },PromiseUtils.viewErrorHandler('Unable to load steps'));
    }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>
    .step-completed {
      color: green;
    }

    .skip-link {
        color: black;
    }

    .name-completed {
        color : rey;
    }

    .tick {
        width: 40px;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
</style>