<template>
    <div class="card">
        <div class="card-header">
            <div class="title">Orders: {{orders.length}}</div>
        </div>
        <b-table :fields="fields" :items="orders" empty-text="No orders" show-empty>
            <template v-slot:cell(actions)="data">
              <a href="#" @click.prevent.stop="view(data.item)"><fa icon="info-circle" class="fa"></fa></a>
            </template>
        </b-table>
        <view-order-modal v-if="orderId!=null" :callback="reload" :id="orderId" @hidden="orderId=null"/>
    </div>
</template>

<script>

import moment from 'moment';
import ViewOrderModal from './ViewOrderModal'


export default {
  name: 'OrderList',
  components: {
       ViewOrderModal
  },
  props : ['orders'],
  data : function() {
    var that=this;
    var fields = [
        { key: 'placedTime', label : 'Order Time', sortable : true, formatter : function(value) {return value==null ? '' : moment(new Date(value)).format("ddd DD MMM YYYY HH:mm");}},
        { key: 'productCount', label : 'Description', sortable : true, formatter : function(value, key, item) {return value+' items @ '+item.totalAmount.toFixed(2);}},
        { key: 'type', label : 'Type', sortable : true},
        { key: 'source', label : 'Source', sortable : true, formatter : function(value) {return that.sourceLabel(value);}},
        { key: 'customerName', label : 'Customer', sortable : true},
        { key: 'paymentMethod', label : 'Payment Method', sortable : true, formatter : function(value) {return that.titleCase(value);}},
        { key: 'orderStatus', label : 'Status', sortable : true, formatter : function(value) {return that.titleCase(value);}},
        { key: 'actions', label : '', thStyle:{width:'50px'}}
      ];

    var d = {
        fields : fields,
        orderId : null
    };
     return d;
  },
  methods : {
    view : function(item) {
        this.orderId=item.uid;
    },
    reload : function(shouldReload) {
        console.log('reload '+shouldReload);
    },
    sourceLabel : function(val) {
        if (val==null || val.length==0) {
            return '';
        }
        switch (val) {
            case 'inStore':
                return 'Walkin';
            case 'web':
                return 'Online';
            default:
                return this.titleCase(val);
        }
    },
    titleCase : function(val) {
        if (val==null || val.length==0) {
            return '';
        }
        return val.substring(0,1).toUpperCase()+val.substring(1);
    }
  },

    created : function() {

    }
};
</script>

<style scoped>

</style>