<template>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <form role="form" name="locationForm" ng-init="initLocationLookup()">
                <div class="card">
                    <div class="card-header">
                        <div class="title">Contact Details</div>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-widget="collapse">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Name</label> <input
                                type="text" class="form-control" id="name"
                                placeholder="Enter name" v-model="data.model.name"
                                >
                            <field-error :errors="data.errors" field="name"></field-error>
                        </div>
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Phone Number</label> <input
                                type="text" class="form-control" id="name"
                                placeholder="Enter phone" v-model="data.model.phone"
                                >
                            <field-error :errors="data.errors" field="phone"></field-error>
                        </div>

                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Email</label> <input type="text"
                                class="form-control" id="name" placeholder="Enter email"
                                v-model="data.model.email"
                                >
                            <field-error :errors="data.errors" field="email"></field-error>
                        </div>
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Address Lookup</label> <input
                                ref = "searchbar" id="location-address" type="text" class="form-control"
                                placeholder="Please enter your address" />
                        </div>
                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Address</label>
                            <input type="text" class="form-control" id="line1" placeholder="Line 1" v-model="data.model.address.line1">
                            <field-error :errors="data.errors" field="address.line1"></field-error>
                            <input
                                type="text" class="form-control" id="line2" placeholder="Line 2"
                                v-model="data.model.address.line2"
                                >
                                <field-error :errors="data.errors" field="address.line2"></field-error>
                                <input
                                type="text" class="form-control" id="city" placeholder="City"
                                v-model="data.model.address.city"
                                >
                                <field-error :errors="data.errors" field="address.city"></field-error>
                                <input
                                type="text" class="form-control" id="region"
                                placeholder="Region"
                                v-model="data.model.address.region"
                                >
                                <field-error :errors="data.errors" field="address.region"></field-error>
                                <input
                                type="text" class="form-control" id="postcode"
                                placeholder="Postcode"
                                v-model="data.model.address.postcode"
                                >
                                <field-error :errors="data.errors" field="address.postcode"></field-error>
                                <v-select label="label" :options="countryOptions" :reduce="option => option.value" v-model="data.model.address.country"></v-select>
                                <field-error :errors="data.errors" field="address.country"></field-error>

                            <field-error :errors="data.errors" field="address"></field-error>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Google Maps Latitude</label> <input
                                type="text" class="form-control" id="name"
                                placeholder="Enter Latitude"
                                v-model="data.model.mapLatitude"
                                >
                            <field-error :errors="data.errors" field="mapLatitude"></field-error>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Google Maps Longitude</label> <input
                                type="text" class="form-control" id="name"
                                placeholder="Enter Longitude"
                                v-model="data.model.mapLongitude"
                                >
                            <field-error :errors="data.errors" field="mapLongitude"></field-error>
                        </div>

                        <div class="form-group col-12">
                            <label for="exampleInputEmail1">Timezone</label>
                            <v-select label="label" :options="timezoneOptions" :reduce="option => option.value" v-model="data.model.timezone"></v-select>
                            <field-error :errors="data.errors" field="timezone"></field-error>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>

import FieldError from '@/components/FieldError';

function createCountryOptions() {
    var countryOptions = [
        {
            value : "United Kingdom",
            label : "United Kingdom"
        }
    ];
    return countryOptions;
}

function createTimezoneOptions() {
     var timezoneOptions = [
        {
        value : "Europe/London",
        label : "Europe/London"
    }
    ];
    return timezoneOptions;
}

export default {
  name: 'LocationContactDetails',
  components: {
    FieldError
  },
  props : ['data'],
  data : function() {
    var d = {
         timezoneOptions : createTimezoneOptions(),
         countryOptions : createCountryOptions()
         };

    return d;
  },
  methods : {

    },
    created : function() {
    },
    mounted : function() {
        var that=this;

      var attempts=0;
      function setupAutoComplete() {
          var searchbar=that.$refs.searchbar;
          if (searchbar==null) {
              attempts++;
              if (attempts<10) {
                  setTimeout(function() {
                      setupAutoComplete();
                  }, 50);
              }
              return;
          }
          var autocomplete = new window.google.maps.places.Autocomplete(searchbar);
          var geolocation = {
                  lat: 5,
                  lng: 10
                };
          var circle = new window.google.maps.Circle({
              center: geolocation,
              radius: 10000
            });
          autocomplete.setBounds(circle.getBounds());
          autocomplete.setTypes(['address']);

          function getAddressComponent(address_components, type) {
              for (var i=0;i<address_components.length;i++) {
                  var types = address_components[i].types;
                  for (var a=0;a<types.length;a++) {
                      if (types[a]==type) {
                          return address_components[i].long_name;
                      }
                  }
              }
              return null;
          }

          function addIfExists(address, separator, address_components, type) {
              var value = getAddressComponent(address_components, type);
              if (value==null) {
                  return address;
              }
              if (address.length>0) {
                  address+=separator+' ';
              }
              address+=value;
              return address;
          }

          autocomplete.addListener('place_changed', function() {
              var place = autocomplete.getPlace();
              if (!place.geometry) {
                  // User entered the name of a Place that was not suggested and
                  // pressed the Enter key, or the Place Details request failed.
                  window.alert("No details available for input: '" + place.name + "'");
                  return;
                }

                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();

                var line1 = '';
                line1=addIfExists(line1, '', place.address_components, 'street_number');
                line1=addIfExists(line1, '', place.address_components, 'route');


                that.data.model.address.line1=line1;
                that.data.model.address.line2=getAddressComponent(place.address_components, 'locality');
                that.data.model.address.city=getAddressComponent(place.address_components, 'postal_town');
                that.data.model.address.region=getAddressComponent(place.address_components, 'country');
                that.data.model.address.postcode=getAddressComponent(place.address_components, 'postal_code');
                that.data.model.mapLongitude=lng;
                that.data.model.mapLatitude=lat;

          });
      }
      setupAutoComplete();



    }
};
</script>

<style scoped>

</style>