<template>
    <div class="container-fluid">
        <general-errors  :errors="data.errors"/>
        <div class="header d-flex align-items-baseline px-2">
            <div class="step-header" :class="{ 'selected' : step==1 }"  @click.stop.prevent="step=1">Step 1 - Setup Trigger</div>
            <div class="step-header" :class="{ 'selected' : step==2 }" @click.stop.prevent="step=2">Step 2 - Setup Voucher</div>
        </div>
        <div v-if="!data.initialising">
            <discount-form v-if="step==1" :model="data.model.trigger" :errors="data.errors" field-prefix="trigger" name="Trigger" :key="NaN"/>
            <discount-form v-if="step==2" :model="data.model.voucher" :errors="data.errors" field-prefix="voucher" name="Voucher" :key="NaN"/>
        </div>
    </div>
</template>

<script>
//import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'
import GeneralErrors from '@/components/GeneralErrors';
import DiscountForm from './DiscountForm';



export default {
  name: 'RewardEdit',
  components: {
    GeneralErrors,DiscountForm
  },
  data : function() {
    var that=this;
    var isCreate=this.$route.path.indexOf('create')>=0;
    var header;
    var type;
    var id;
    if (isCreate) {
        type=this.$route.params.type;
        header='Create Voucher Incentive';
    } else {
        id = this.$route.params.id;
        header='Edit Voucher Incentive';
    }

    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/discount/voucher/'+id).saveEndpoint('/wb/api/admin/discount/voucher').saveAlert('Saved', 'Changes to the the discount have been changed.').build();
    var buttons=[];
    buttons.push(data.createResetButton());
    buttons.push(data.createSaveButton());
    var d = PageStack.addPage(that.$route, {
        type : type,
         header : header,
         isCreate : isCreate,
         buttons : buttons,
         data : data,
         step : 1
     });
     return d;
  },
  watch : {
    'data.model.timeRestricted' : function(val) {
        if (val) {
            if (this.data.model.times==null) {
                this.data.model.times=[];
            }
            if (this.data.model.times.length==0) {
                this.addTime();
            }
        } else {
            this.data.model.times=[];
        }
    }
  },
  beforeRouteLeave (to, from, next) {
       this.data.beforeRouteLeave(to, from, next);
      },
  methods : {
    deleted : function() {
            PageStack.chopPage(this.$router);
        },
    deleteTime : function(time) {
        var index = this.data.model.times.indexOf(time);
        if (index>=0) {
            this.data.model.times.splice(index, 1);
        }
    },
    addTime : function() {
        if (this.data.model.times==null) {
            this.data.model.times=[];
        }
        this.data.model.times.push({
            monday : true,
            tuesday : true,
            wednesday : true,
            thursday : true,
            friday : true,
            saturday : true,
            sunday : true,
            fromHour : 12,
            fromMin : 0,
            toHour : 22,
            toMin : 0
        });
    }

  },
    created : function() {
        if (this.isCreate) {
            var trigger = {
                type : 'reward',
                enabled : true,
                timeRestricted : false,
                sourceRestricted : false,
                valueRestricted : false
            };
            var voucher = {
                type : 'voucher',
                enabled : true,
                timeRestricted : false,
                sourceRestricted : false,
                valueRestricted : false
            };
            this.data.defaultModel({
                trigger : trigger,
                voucher : voucher
            });
        } else {
            this.data.load();
            var that=this;
            RestHelper.get('/wb/api/admin/discounts').then(function(result) {
                    that.vouchers=[];
                    result.data.forEach(function(promo) {
                        if (promo.type=='voucher') {
                            that.vouchers.push(promo);
                        }
                    });
                });
        }

    }
};
</script>

<style lang="scss" scoped>
.step-header {
    font-size: 30px;
    padding: 12px 20px;
    flex-basis: 0;
    flex-grow: 1;
}

.step-header.selected {
    background-color: white;
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}
</style>