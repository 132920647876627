<template>
    <div class="chart-container">
        <span v-if="empty">There is no data for this report</span>
        <canvas ref="chart">
        </canvas>
    </div>
</template>

<script>

import Chart from 'chart.js';

	var colorList = ['#dc3545', '#28a745', '#ffc107', '#17a2b8', '#007bff', '#6c757d'];


    function createPieChart(d) {
        var cvs = d.$refs.chart;
            if (cvs==null) {
                return false;
            }
        var ctx = cvs.getContext('2d');


        var data = [];
          var labels = [];
          var colors = [];

          d.reportConfig.results.forEach(function(result) {
              var index = 0;
                result.data.forEach(function(row) {
                    data.push(row[1]);
                      labels.push(row[0]);
                      colors.push(colorList[index%colorList.length]);
                      index++;
                });

            });

          var PieData = {
                  datasets: [{
                      data : data,
                      backgroundColor: colors
                  }],
                  labels : labels
          };


          var pieOptions     = {
            legendTemplate       : '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>',
            tooltipTemplate      : '<%=value %> <%=label%>',
            tooltipEvents: [],
            showTooltips: true,
            onAnimationComplete: function() {
                this.showTooltip(this.segments, true);
            }
          };
          new Chart(ctx, {
            type: 'pie',
            data: PieData,
            options: pieOptions,
            maintainAspectRatio : true,
            aspectRatio : 1,
        });

        return true;

    }

export default {
  name: 'PieChart',
  props : ['reportConfig', 'dataset', 'chartConfig'],
  components: {


  },
   data : function() {
      return {
        empty : false

      };
    },
    methods : {

    },
    mounted : function() {
        createPieChart(this);
    }
  };
</script>

<style scoped>

</style>