<template>
<div><div  v-if="!initialising">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Details</div>
                <div class="card-body">
                    <form role="form" name="categoryForm" novalidate>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label> <input type="text"
                                name="name" class="form-control" id="name"
                                placeholder="Enter name" v-model="data.model.name"
                                >
                            <field-error :errors="data.errors" field="name"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Display Name</label> <input type="text"
                                name="name" class="form-control" id="display-name"
                                placeholder="Enter display name" v-model="data.model.displayName"
                                >
                            <field-error :errors="data.errors" field="displayName"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">POS Name</label> <input type="text"
                                name="posName" class="form-control" id="pos-name"
                                placeholder="Enter POS name" v-model="data.model.posName"
                                >
                            <field-error :errors="data.errors" field="posName"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Description</label>
                            <textarea class="form-control" rows="3"
                                placeholder="Enter description"
                                v-model="data.model.description"></textarea>
                            <field-error :errors="data.errors" field="description"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Display on Menu</label>
                            <b-form-select v-model="data.model.menuDisplayFormat" :options="menuDisplayFormatOptions" value-field="value" text-field="label"></b-form-select>
                            <field-error :errors="data.errors"
                                field="menuDisplayFormat"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Disabled for Online
                                Ordering</label> <input type="checkbox"
                                v-model="data.model.onlineDisabled">
                            <field-error :errors="data.errors"
                                field="onlineDisabled"></field-error>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Disabled for Uber</label> <input type="checkbox"
                                v-model="data.model.uberDisabled">
                            <field-error :errors="data.errors"
                                field="uberDisabled"></field-error>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>

    <div v-if="data.created">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">Items</div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="width:50px" class="col-handle">&nbsp;</th>
                                    <th>Name</th>
                                    <th style="width:50px">&nbsp;</th>
                                </tr>
                            </thead>

                            <draggable handle=".row-handle" group="products" tag="tbody" @end="onEnd" id="sortable-category-items">
                                    <tr v-if="data.categoryProducts.length==0">
                                        <td colspan="3">No items setup</td>
                                    </tr>
                                    <tr v-for="(product) in data.categoryProducts" :key="product.model.refId">
                                    <td class="row-handle"><fa class="fa" icon="grip-vertical"></fa></td>
                                    <td><a href="#" @click.stop.prevent="selectCategoryEntry(product)">{{product.name}}</a></td>
                                    <td class="col-btns">
                                        <button type="button" class="btn btn-card-tool pull-right"
                                            @click="removeFromCategory(product)">
                                            <fa class="fa" icon="trash"></fa>
                                        </button>
                                    </td>
                                    </tr>
                            </draggable>
                        </table>
                        <div class="mt-3 btn-group float-right z-index-top">
                            <button class="btn btn-outline-dark" type="button"
                                @click="addNewProduct()">
                                <span><fa class="fa" icon="plus"> </fa> Add Item</span>
                            </button>
                            <button class="btn btn-outline-dark" type="button"
                                @click="addNewVariation()">
                                  <span><fa class="fa" icon="plus"> </fa> Add Variation</span>
                            </button>
                            <b-dropdown variant="outline-dark" offset="-200">
                              <b-dropdown-item-button @click="addConfiguredItem()">Add Preconfigured Item</b-dropdown-item-button>
                              <b-dropdown-item-button @click="moveExisting()">Move Existing Item</b-dropdown-item-button>
                              <b-dropdown-item-button @click="linkProduct()">Link Existing Item</b-dropdown-item-button>
                              <b-dropdown-item-button @click="linkConfiguredItem()">Link Existing Preconfigured Item</b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
    </div>

    <modifiers v-if="data.created" :parent="data.extrasParent"/>
    <select-node-modal v-if="showSelectModifierModal" :types="selectTypes" :callback="selectCallback"  :title="selectTitle" @hidden="showSelectModifierModal=false"/>
</div></div>
</template>

<script>


import {PageStack} from '@/services/PageStackService'
import FieldError from '@/components/FieldError'
import {LabelService} from '@/services/LabelService'
import {AlertService} from '@/services/AlertService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {EditManager} from '@/services/menu/EditManager'
import {WorkAreaService} from '@/services/menu/WorkAreaService'
import Modifiers from './Modifiers'
import SelectNodeModal from './SelectNodeModal'

function moveItems(items, category) {
    items.forEach(function(item) {
        if (item.isProduct) {
            item.moveToCategory(category).then(function(result) {
                if (result.success) {
                    AlertService.success('Product moved');
                }
            });
        } else if (item.isVariation) {
            item.moveToCategory(category).then(function(result) {
                if (result.success) {
                    AlertService.success('Variation moved');
                }
            });
        } else if (item.isPreconfigured) {
            item.moveToCategory(category).then(function(result) {
                if (result.success) {
                    AlertService.success('Preconfigured Item moved');
                }
            });
        }

    });
}

export default {
  name: 'Category',
  components: {
    FieldError, Modifiers, SelectNodeModal
  },
  props : ['category'],
  data : function() {
    var d = PageStack.addPage(this.$route, {
         header : 'Category: ',
         buttons : [],
         initialising : false,
         menuDisplayFormatOptions : LabelService.menuDisplayFormatOptions,
         showSelectModifierModal : false,
         selectTypes : null,
         selectCallback : null,
         selectTitle : null
         });

     return d;
  },
  methods : {
        removeFromCategory : function(categoryProduct) {
            this.data.removeFromCategory(categoryProduct);
        },
        selectCategoryEntry : function(categoryProduct) {
            if (categoryProduct.isSingleProduct) {
                this.$router.push({ name: 'menuworkarea_product', params: {id : categoryProduct.selectedProduct.model.id, product: categoryProduct.selectedProduct }})
            } else if (categoryProduct.isVariation) {
                this.$router.push({ name: 'menuworkarea_variation', params: {id : categoryProduct.selectedVariation.model.id, variation: categoryProduct.selectedVariation }})
            } else if (categoryProduct.isPreconfigured) {
                 this.$router.push({ name: 'menuworkarea_configureditem', params: {id : categoryProduct.selectedConfiguredItem.model.id, configuredItem: categoryProduct.selectedConfiguredItem }})
             } else if (categoryProduct.isLinked) {
                  this.$router.push({ name: 'menuworkarea_product', params: {id : categoryProduct.selectedProduct.model.id, product: categoryProduct.selectedProduct }})
             }
        },
        addNewProduct : function() {
            var params = {
                categoryId : this.data.model.id,
                category : this.data.model
            };
            this.$router.push({ name: 'menuworkarea_createcategoryproduct', params: params});

        },
        addNewVariation : function() {
            var params = {
                categoryId : this.data.model.id,
                category : this.data.model
            };
            this.$router.push({ name: 'menuworkarea_createcategoryvariation', params: params});

        },
        addConfiguredItem : function() {
             var params = {
                 categoryId : this.data.model.id,
                 category : this.data.model
             };
             this.$router.push({ name: 'menuworkarea_createcategoryconfigureditem', params: params});

         },
        moveExistingSelected : function(selected) {
            moveItems(selected, this.data);
        },
        linkProductSelected : function(selected) {
            var that=this;
           selected.forEach(function(product) {
            that.data.linkProduct(product);
           });
       },
        moveExisting : function() {
            this.selectTypes=['Item', 'Variation'];
            this.selectCallback=this.moveExistingSelected;
            this.showSelectModifierModal=true;
            this.selectTitle='Select Item to Move';
        },
        linkProduct : function() {
            this.selectTypes=['Item'];
            this.selectCallback=this.linkProductSelected;
            this.showSelectModifierModal=true;
            this.selectTitle='Select Item to Link';
        },
        onEnd : function(evt) {
            this.data.reorderProducts(evt.oldIndex, evt.newIndex);
        },
        deleted : function() {
            PageStack.chopPage(this.$router);
        },
        initialiseEditManager : function() {
            this.pageHandler=EditManager.builder(this).viewModel(this.data).label('Category').deletionCallback(this.deleted).initialise();
        }
    },
    beforeRouteLeave : function(to, from, next) {
        this.pageHandler.beforeRouteLeave(to, from, next);
    },
    created : function() {
        var that=this;
        if (this.category==null) {
            that.initialising=true;
            WorkAreaService.load().then(function(WorkArea) {
                that.data=WorkArea.getCategory(that.$route.params.id);
                 that.initialiseEditManager();
                 that.initialising=false;
             }, PromiseUtils.viewErrorHandler('Error loading menu'));
        } else {
            this.data=this.category;
            that.initialiseEditManager();
        }

    }
};
</script>

<style scoped>

</style>