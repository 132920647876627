<template>
<div>
<div v-if="!data.initialising">

<location-tax-details :data="data"/>
</div>
</div>
</template>

<script>

import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'

import LocationTaxDetails from './LocationTaxDetails';

export default {
  name: 'LocationCompanyDetailsPage',
  components: {
    LocationTaxDetails
  },
  data : function() {
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/location/primary/companydetails').optional().saveEndpoint('/wb/api/admin/location/primary/companydetails').saveAlert('Saved', 'Location details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Company Details',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data
         });

    return d;
  },
  beforeRouteLeave (to, from, next) {
     this.data.beforeRouteLeave(to, from, next);
    },
  methods : {
        loadData : function() {
            this.data.load();
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>