<template>
<!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid" v-if="isVisible">
          <div class="row mb-2">
          <div class="col-12">
            <ol class="breadcrumb" v-if="pages.length>1">
              <li class="breadcrumb-item" v-for="(page,index) in pages" v-bind:key="page.depth"><a href="#" v-if="index<pages.length-1" @click.stop.prevent="popTo(page)">{{page.header}}</a><span v-if="index==pages.length-1">{{page.header}}</span></li>
            </ol>
          </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="header">
                  <h1 class="mx-0 my-2 text-dark"><span><a class="back-icon" v-if="pages.length>1" @click.stop.prevent="pop()"><fa icon="chevron-left"/></a></span><span v-if="pages.length>0">{{header}}</span></h1>
                  <div class="buttons btn-toolbar">
                      <span v-for="(button, index) in buttons" v-bind:key="index">
                      <button :disabled="button.disabled" v-if="!button.hidden" class="btn btn-lg" :class="button.class==null?'btn-default':button.class" @click.stop.prevent="button.onClick()"><fa ng-if="button.icon" :icon="button.icon"/>&nbsp;<span class="btn-text">{{button.label}}</span></button>
                      </span>
                  </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
</template>

<script>
import {PageStack} from '@/services/PageStackService'

export default {
  name: "ContentHeader",
  data : function() {
   return {
      pages : PageStack.getPages()
   };
 },
 methods : {
    pop : function() {
        var index = this.pages.length-2;
        if (index>=0) {
            PageStack.popPage(this.pages[index], this.$router);
        }
    },
    popTo : function(page) {
        var index = this.pages.indexOf(page);
        if (index==this.pages.length-1 || index<0) {
            return;
        }
        PageStack.popPage(page, this.$router);

    }
 },
    computed: {
        isVisible : function() {
            if (this.pages.length==0) {
                return false;
              } else {
                return !this.pages[this.pages.length-1].hidden;
              }
        },
        header: function () {
          if (this.pages.length==0) {
            return '';
          } else {
            return this.pages[this.pages.length-1].header;
          }
        },
        buttons : function() {
            if (this.pages.length==0) {
                return [];
              } else {
                var btns =  this.pages[this.pages.length-1].buttons;
                if (btns==null) {
                    btns=[];
                }
                return btns;
              }
        }
      }
};
</script>
<style scoped>
.back-icon {

    padding-right: 10px;
}

.header {
    display: flex;
}

.header h1 {
    flex-grow: 1;
}

.content-header {
    padding-bottom: 7px;
}

.btn-toolbar button {
    margin-left: 5px;
}

.btn-icon {
    margin-right: 5px;
}
</style>