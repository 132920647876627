<template>
    <div class="page-container d-flex flex-column flex-grow-1 align-items-center">
        <div class="phone-container d-flex">
            <iframe :src="vm.draft.previewUrl" class="flex-grow-1 h-100"></iframe>
         </div>
     </div>
</template>

<script>


export default {
  name: 'WebsiteMobilePreview',
  components: {

  },
  props : ['vm'],
  data : function() {
    var d = {
        url : null
    };

     return d;
  },
  methods : {

        }
};
</script>

<style scoped>
    .page-container {
        background: black;
    }
    .phone-container {
        background: white;
        width: 350px;
        height: 600px;
        margin-top: 100px;
    }
</style>