<template>
  <b-modal id="delivery-zone-modal" modal-class="delivery-zone-modal" centered hide-footer no-close-on-backdrop :title="title" @hide="handleHide()">
        <div class="delivery-zone-body d-flex flex-column h-100">
            <form class="form-inline mb-2">
                <div class="delivery-zone-form d-flex w-100">
                    <label class="mr-2" for="exampleInputEmail1">Name</label>
                    <input type="text" class="form-control flex-grow-1 mr-2" id="name" placeholder="Enter name" v-model="name">
                    <label class="mr-2" for="exampleInputEmail1" v-if="isRadius">Radius</label>
                    <input type="number" class="form-control flex-grow-1 mr-2" v-if="isRadius" id="name" placeholder="Enter radius in metres" v-model="radius">
                    <label class="mr-2" v-if="include" for="exampleInputEmail1">Delivery Charge</label>
                    <input type="text" v-if="include" class="form-control flex-grow-1 mr-2" id="name" placeholder="No Delivery Charge"  v-model="deliveryCharge">
                    <button type="button" class="btn btn-primary" @click="submit()" :disabled="!valid">Submit</button>
                </div>
            </form>
            <div id="deliveryZoneMap" class="flex-grow-1"></div>
        </div>
    </b-modal>
</template>

<script>

import {RestHelper} from '@/utils/RestHelper'

var component = {
  name: "DeliveryZoneModal",
  props: ['type','include', 'model'],
  components : {},
  data : function() {
      var d = {
           title : '',
           isRadius : this.type=='radius',
           isCustom : this.type=='custom',
           color : this.include?'#98FB98':'#FF0000',
           mapRadius : null,
           radius : null,
           name : null,
           deliveryCharge : null,
           path : []
      };
      if (this.include) {
        if (this.type=='custom') {
            d.title='Custom Delivery Zone';
        } else if (this.type=='radius') {
            d.title='Delivery Zone by Radius';
        }
      } else {
        if (this.type=='custom') {
            d.title='Exclude Delivery Area';
        }
      }
      if (this.model!=null) {
        d.name=this.model.name;
        if (this.include) {
            d.deliveryCharge=this.model.deliveryCharge;
        }
        if (d.isRadius) {
            d.radius=this.model.radius;
        }
        if (d.isCustom) {
            d.path=this.model.path;
        }
      }
     return d;
    },
    watch : {
        'radius' : function() {
            this.createRadiusCircle();
        }

    },
    computed : {
        'valid' : function() {
            if (this.name==null || this.name.trim()=='') {
                return false;
            }
            if (this.include) {
                if (this.deliveryCharge==null || this.deliveryCharge=='') {
                    return false;
                }
            }
            if (this.isRadius) {
                if (this.radius==null) {
                    return false;
                }
                var radius = parseInt(this.radius);
                if (radius<=0) {
                    return false;
                }
            } else {
                if (this.path.length<3) {
                    return false;
                }
            }
            return true;
        }
    },
   methods : {
      handleHide : function() {
          this.$emit('hidden');
      },
      submit : function() {
        var model=this.model;
        if (model==null) {
             model={
                enabled : true,
                paused : false
            };
        }
        model.name=this.name;
        model.type=this.type;
        model.include=this.include;
        if (this.include) {
            model.deliveryCharge=this.deliveryCharge;
        }
        if (this.isCustom) {
            model.path=[];
            this.path.forEach(function(val) {
                model.path.push({
                    lng : val.lng(),
                    lat : val.lat()
                });
            });
        } else {
            model.radius=parseInt(this.radius);
        }
        this.$emit('submitted', model);
      },
      createRadiusCircle : function() {
        if (this.isRadius) {
            if (this.mapRadius!=null) {
                this.mapRadius.setMap(null);
                this.mapRadius=null;
            }
            var value = parseInt(this.radius);
            this.mapRadius = new window.google.maps.Circle({
                strokeColor: this.color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.color,
                fillOpacity: 0.35,
                map: this.map,
                center: this.uluru,
                radius: value
              });
        }
      },
      initForCustom : function() {
        var that=this;
        this.poly = new window.google.maps.Polyline({ map: this.map, path: [], strokeColor: this.color, strokeOpacity: 1.0, strokeWeight: 2 });
        var markers=[];
        function addMarker(latLng, markerIndex, onInit, shouldClose) {
            function getMarkerIndex() {
                return markers.indexOf(marker);
            }

            var isFirstMarker = markerIndex === 0;
            var marker = new window.google.maps.Marker({ map: that.map, position: latLng, draggable: true });
            if (isFirstMarker) {
                window.google.maps.event.addListener(marker, 'click', function () {
                    if (that.isClosed)
                        return;

                    handleClose();
                });

            }
            window.google.maps.event.addListener(marker, "dblclick", function (dblClickEvent) {
                    addMarker(dblClickEvent.latLng, getMarkerIndex()+1);
             });

            function handleClose(inDigest) {
                    var path = that.poly.getPath();
                that.poly.setMap(null);
                that.poly = new window.google.maps.Polygon({ map: that.map, path: path, strokeColor: that.color, strokeOpacity: 0.8, strokeWeight: 2, fillColor: that.color, fillOpacity: 0.35 });
                if (inDigest) {
                        that.isClosed = true;
                } else {
                    that.isClosed = true;
                }
            }

            window.google.maps.event.addListener(marker, "rightclick", function () {
                    var index = getMarkerIndex();
                    that.poly.getPath().removeAt(index);
                    markers.splice(index,1);
                    marker.setMap(null);
                    if (markers.length==0) {
                        that.poly.setMap(null);
                        that.poly = new window.google.maps.Polyline({ map: that.map, path: [], strokeColor: this.color, strokeOpacity: 1.0, strokeWeight: 2 });
                        that.isClosed = false;
                    }

                    that.path=that.poly.getPath().getArray();
             });
            window.google.maps.event.addListener(marker, 'drag', function (dragEvent) {
                that.poly.getPath().setAt(getMarkerIndex(), dragEvent.latLng);
                that.path=that.poly.getPath().getArray();
            });
            that.poly.getPath().insertAt(markerIndex, latLng);
            markers.splice(markerIndex,0,marker);

            if (!onInit) {
                that.path=that.poly.getPath().getArray();
            }

            if (shouldClose) {
                    handleClose(true);
            }
        }

        window.google.maps.event.addListener(that.map, 'click', function (clickEvent) {
                        if (that.isClosed)
                            return;
                        var markerIndex = that.poly.getPath().length;
                    addMarker(clickEvent.latLng, markerIndex);
                });

         for (var i=0;i<that.path.length;i++) {
                         var point=that.path[i];
                         var last=(i==that.path.length-1);
                         addMarker(new window.google.maps.LatLng(point.lat, point.lng), i, true, last);
                 }

      },
      initDeliveryZoneMap : function(location) {
        //var that=this;
        //var iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';
            this.uluru = new window.google.maps.LatLng(location.mapLatitude, location.mapLongitude);
            this.map = new window.google.maps.Map(document.getElementById('deliveryZoneMap'), { center: this.uluru, zoom: 16, mapTypeId: window.google.maps.MapTypeId.HYBRID, scaleControl: true });
            /*
            var markers=[];
            
            var map = null;
            var that.poly = null;
            */
        /*
            function addMarker(latLng, markerIndex, onInit, shouldClose) {
                function getMarkerIndex() {
                    return markers.indexOf(marker);
                }

                var isFirstMarker = markerIndex === 0;
                var marker = new window.google.maps.Marker({ map: map, position: latLng, draggable: true });
                if (isFirstMarker) {
                    window.google.maps.event.addListener(marker, 'click', function () {
                        if (that.isClosed)
                            return;

                        handleClose();
                    });

                }
                window.google.maps.event.addListener(marker, "dblclick", function (dblClickEvent) {
                        addMarker(dblClickEvent.latLng, getMarkerIndex()+1);
                 });

                function handleClose(inDigest) {
                        var path = poly.getPath();
                    poly.setMap(null);
                    poly = new window.google.maps.Polygon({ map: map, path: path, strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#FF0000", fillOpacity: 0.35 });
                    if (inDigest) {
                            that.isClosed = true;
                    } else {
                        that.isClosed = true;
                    }
                }

                window.google.maps.event.addListener(marker, "rightclick", function () {
                        var index = getMarkerIndex();
                        poly.getPath().removeAt(index);
                        markers.splice(index,1);
                        marker.setMap(null);
                        if (markers.length==0) {
                            poly.setMap(null);
                            poly = new window.google.maps.Polyline({ map: map, path: [], strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2 });
                            that.isClosed = false;
                        }

                        that.path=poly.getPath().getArray();
                 });
                window.google.maps.event.addListener(marker, 'drag', function (dragEvent) {
                    poly.getPath().setAt(getMarkerIndex(), dragEvent.latLng);
                    that.path=poly.getPath().getArray();
                });
                poly.getPath().insertAt(markerIndex, latLng);
                markers.splice(markerIndex,0,marker);

                if (!onInit) {
                    that.path=poly.getPath().getArray();
                }

                if (shouldClose) {
                        handleClose(true);
                }
            }

         */
      

        /*
        this.poly = new window.google.maps.Polyline({ map: map, path: [], strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2 });
        window.google.maps.event.addListener(map, 'click', function (clickEvent) {
                if (this.isClosed)
                    return;
                var markerIndex = poly.getPath().length;
            addMarker(clickEvent.latLng, markerIndex);
        });
        */
        /*
        var marker = new window.google.maps.Marker({
            position : uluru,
            map : map,
            icon: iconBase + 'library_maps.png'
        });
        */

        /*
        for (var i=0;i<this.model.path.length;i++) {
                var point=this.model.path[i];
                var last=(i==this.model.path.length-1);
                addMarker(new window.google.maps.LatLng(point.lat, point.lng), i, true, last);
        }

        this.path=poly.getPath().getArray();
        */
    }
    },
    mounted : function() {
        this.$bvModal.show('delivery-zone-modal');
        var that=this;
        RestHelper.get('/wb/api/admin/location/primary').then(function(result) {
            that.$nextTick(function() {
                that.initDeliveryZoneMap(result.data.value);
                if (that.isCustom) {
                    that.initForCustom();
                } else {
                    that.createRadiusCircle();
                }
            });

        });
      },
      created : function() {

      }
};
export default component;
</script>


<style>
.delivery-zone-modal .modal-dialog {
    max-width: 1000px !important;
}

.delivery-zone-modal .modal-content {
     height: 90vh !important;
 }

</style>