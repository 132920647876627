<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item clickable" @click="editMenu()"><span
                        class="header">Edit Menu</span>
                        <p>Update the contents and pricing of your menu.</p></li>
                        <!--
                    <li class="list-group-item clickable" @click="config()"><span
                        class="header">Settings</span>
                        <p>Change configuration about how your menu is displayed.</p></li>
                        -->
                    <li class="list-group-item clickable" @click="editTaxRates()"><span
                        class="header">Tax Rates</span>
                        <p>Update applicable tax rates for your menu.</p></li>
                    <li class="list-group-item clickable"
                        @click="previewOnlineOrdering()"><span class="header">Preview
                            on Online Ordering</span>
                        <p>See how this menu will look configured within online
                            ordering.</p></li>

                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import {PageStack} from '@/services/PageStackService'
import {PromiseUtils} from '@/utils/PromiseUtils'
import {WorkAreaService} from '@/services/menu/WorkAreaService'
import {AlertService} from '@/services/AlertService'

export default {
  name: 'EditHomepage',
  components: {

  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(this.$route, {
         header : 'Edit Menu',
         buttons : [{
                        label : 'Publish',
                        class : 'btn-primary',
                        icon : 'upload',
                        onClick : that.publish,
                        hidden : false,
                        disabled : false
                    },
                    {
                        label : 'Delete Changes',
                        icon : 'trash',
                        onClick : that.deleteChanges,
                        hidden : false,
                        disabled : false
                    }
                ]
         });

     return d;
  },
  methods : {
        previewOnlineOrdering : function() {
            var url = process.env.APP_URL+'/admin3/testorder2?menu='+this.$options.WorkArea.id;
            window.open(url, 'preview');
        },
        editMenu : function() {
            this.$router.push('/menuworkarea/menu/'+this.$options.WorkArea.menu.model.id);
        },
        editTaxRates : function() {
            this.$router.push('/menuworkarea/taxrates');
        },
        publish : function() {
            var that=this;
            this.$options.WorkArea.publish().then(function(result) {
                if (result.success) {
                    AlertService.success('Menu Published', 'Menu has been published successfully');
                    that.$router.push('/menumanagement');
                } else {
                    AlertService.error('Error publishing menu', result.errors);
                }
            })
        },
        doDelete : function() {
            var that=this;
            this.$options.WorkArea.deleteAll().then(function() {
               AlertService.success('Menu Changes Deleted', 'All pending changes to your menu have been deleted');
                that.$router.push('/menumanagement');
            })
        },
        deleteChanges : function() {
            var that=this;
            this.$bvModal.msgBoxConfirm('All changes will be lost.  Are you sure you want to delete?', {
                                  title: 'Delete Pending Menu Changes ',
                                  okTitle: 'Delete',
                                  cancelTitle: 'Cancel',
                                  footerClass: 'p-2',
                                  hideHeaderClose: false,
                                  centered: true
                                })
                                  .then(value => {
                                    if (value) {
                                        that.doDelete();
                                    }
                                  })
                                  .catch(function() {
                                  });

        }
    },
     created : function() {
         var that=this;
         that.initialising=true;
         WorkAreaService.load().then(function(WorkArea) {
            that.$options.WorkArea=WorkArea;
             that.initialising=false;
         }, PromiseUtils.viewErrorHandler('Error loading menu'));
     }
};
</script>

<style scoped>

</style>