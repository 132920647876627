<template>
    <div class="preview-container d-flex flex-column">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent.stop="back">Back <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item">
                      <span class="navbar-brand">Website Editor</span>
                    </li>
                </ul>
                <ul class="navbar-nav  my-2 my-lg-0">
                    <li class="nav-item pr-3" v-if="vm" >
                        <select class="form-control" @change="templateChanged($event.target.value)">
                          <option v-for="(template) in vm.templates" :key="template.id" :selected="template.selected" :value="template.id">{{template.name}}</option>
                        </select>
                    </li>
                    <li class="nav-item">
                      <button class="btn btn-primary mr-3" @click.prevent.stop="save" v-if="isSaveVisible" :disabled="!vm.draft.isDirty">Save</button>
                    </li>
                    <li class="nav-item">
                      <a v-if="isPublishVisible" class="nav-link" href="#" @click.prevent.stop="publish">Publish <span class="sr-only">(current)</span></a>
                    </li>
                  <li class="nav-item">
                      <a class="nav-link" href="#" @click.prevent.stop="edit">Edit <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#" @click.prevent.stop="previewDesktop">Desktop <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#" @click.prevent.stop="previewMobile">Mobile <span class="sr-only">(current)</span></a>
                    </li>
                </ul>
              </div>
        </nav>
        <div class="content flex-grow-1 d-flex flex-column justify-content align-items-stretch h-100">
            <website-desktop-preview v-if="!initialising && view=='desktop'" :vm="vm"></website-desktop-preview>
            <website-mobile-preview v-if="!initialising && view=='mobile'" :vm="vm"></website-mobile-preview>
            <website-config v-if="!initialising && view=='edit'" :vm="vm"></website-config>
        </div>
    </div>
</template>

<script>

import WebsiteDesktopPreview from './WebsiteDesktopPreview'
import WebsiteMobilePreview from './WebsiteMobilePreview'
import WebsiteConfig from './WebsiteConfig'
import {WebsiteService} from '@/services/WebsiteService'

export default {
  name: 'WebsiteEditor',
  components: {
    WebsiteDesktopPreview,
    WebsiteMobilePreview,
    WebsiteConfig
  },
  data : function() {
    var d = {
        view : 'desktop',
        initialising : true,
        vm : null,
        selectedTemplateId : null
    };

     return d;
  },
  computed : {
    isPreview : function() {
        return this.view=='mobile' || this.view=='desktop';
    },
    isPublishVisible : function() {
        return this.isPreview;
    },
     isSaveVisible : function() {
         return this.view=='edit';
     },
      isResetVisible : function() {
          return this.view=='edit';
      }
  },
  methods : {
        templateChanged : function(idStr) {
            var id = parseInt(idStr);
            this.vm.templates.forEach(function(template) {
                if (template.id==id) {
                    template.select();
                }
            });
        },
        back : function() {
            this.$router.push('/website');
        },
        previewDesktop : function() {
            this.view='desktop'
        },
         previewMobile : function() {
             this.view='mobile'
         },
           edit : function() {
               this.view='edit'
           },
           loadData : function() {
               var that=this;
               that.initialising=true;
               WebsiteService.createViewModel().then(function(vm) {
                   that.vm=vm;
                   that.vm.templates.forEach(function(template) {
                    if (template.selected) {
                        that.selectedTemplateId=template.id;
                    }
                   });
                   that.initialising=false;
               }, function() {
                   that.initialising=false;
               });
           },

          save : function() {
                  this.vm.draft.save().then(function(success) {
                      if (success) {
                          alert('Website data saved');
                      } else {
                          alert('Save failed');
                      }
                  });
              },
            publish : function() {
                var that=this;
                this.$bvModal.msgBoxConfirm('Please confirm you want to publish these changes to the live website', {
               title: 'Publishing Website',
               okTitle: 'Publish',
               cancelTitle: 'Cancel',
               footerClass: 'p-2',
               hideHeaderClose: false,
               centered: true
             })
               .then(value => {
                 if (value) {
                    that.vm.draft.publish().then(function(success) {
                                        if (success) {
                                            alert('Website data published');
                                        } else {
                                            alert('Publish failed');
                                        }
                                    }, function() {
                                    alert('Publish failed');
                                    });
                 }
               })
               .catch(function() {
               });

            }
       },
       beforeRouteLeave (to, from, next) {
       if (!this.vm.draft.isDirty) {
                       next();
                   } else {
                       this.$bvModal.msgBoxConfirm('Note: Any unsaved changes will be lost. Are you sure you want to leave this page?', {
                                             title: 'Confirm Navigation',
                                             okTitle: 'Leave this Page',
                                             cancelTitle: 'Stay on this Page',
                                             footerClass: 'p-2',
                                             hideHeaderClose: false,
                                             centered: true
                                           })
                                             .then(value => {
                                               if (value) {
                                                   next();
                                               }
                                             })
                                             .catch(function() {
                                             });
                   }
             },
       created : function() {
            this.loadData();
       }
};
</script>

<style scoped>

.preview-container {
    min-height: 100vh;
}

.content {
    margin-top: 56px;
}
</style>