<template>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{param.name}}</label>
      <div class="input-group mb-3 col-sm-9">
        <input type="text" class="form-control" readonly="true" placeholder="Select Font" v-model="param.value" aria-label="Recipient's username" aria-describedby="basic-addon2">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" @click.stop.prevent="showModal=true">Select</button>
        </div>
      </div>
      <font-selector-modal v-if="showModal" @hidden="showModal=false" @selected="selected"/>
    </div>
</template>

<script>

import FontSelectorModal from './FontSelectorModal';

export default {
  name: 'FontParam',
  components: {
    FontSelectorModal
  },
  props : ['param'],
  data : function() {
    var d = {
        showModal : false
    };
    return d;
  },
  methods : {
    selected : function(value) {
        this.showModal=false;
        this.param.value=value;
    }
  }
};
</script>

<style scoped>
</style>