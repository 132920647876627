<template>
    <div>
        <form>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="form-group row">
                            <div class="col-4">
                                <label>Date Range</label>
                                <date-range-picker ref="datepicker" v-model="filter"></date-range-picker>
                            </div>
                            <div class="col-4">
                                <label>Days of Week</label>
                                <day-picker ref="daypicker" :model="filter" format="short"></day-picker>
                            </div>
                            <div class="col-4">
                                <label>Time</label>
                                <hour-range-picker ref="hourpicker" :model="filter"></hour-range-picker>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <label>Order Source</label>
                                <select multiple size="5" class="form-control" v-model="filter.sources">
                                    <option v-for="option in sourceOptions" :key="option.value" v-bind:value="option.value">
                                        {{ option.label }}
                                      </option>
                                </select>
                            </div>
                        </div>
                      </div>
                      <div class="card-footer">
                          <button type="submit" class="btn btn-primary float-right" @click.stop.prevent="search()">Search</button>
                        </div>
                    </div>
                </div>

            </div>
        </form>
        <delivery-heatmap v-if="data!=null" :data="data"/>
    </div>
</template>

<script>
import {PageStack} from '@/services/PageStackService'
import {RestHelper} from '@/utils/RestHelper'
import DeliveryHeatmap from './DeliveryHeatmap';


export default {
  name: 'DeliveryHeatmapReport',
  components: {
    DeliveryHeatmap
  },
  data : function() {
    var that=this;
    var d = PageStack.setPage(that.$route, {
         header : 'Deliveries',
         buttons : [],
         data : null,
         filter : {
            dateRange : null,
            startDate : null,
            endDate : null,
            sourceOptions : [],
            allDays : true,
            mon : false,
            tue : false,
            wed : false,
            thu : false,
            fri : false,
            sat : false,
            sun : false,
            fromHour : null,
            fromMin : null,
            toHour : null,
            toMin : null,
            allHours : true
         },
         sourceOptions : []
     });
     d.sourceOptions.push({label : 'Walkin', value : 'inStore'});
         d.sourceOptions.push({label : 'Phone', value : 'phone'});
         d.sourceOptions.push({label : 'Online', value : 'web'});
         d.sourceOptions.push({label : 'Uber', value : 'uber'});
         d.sourceOptions.push({label : 'Just Eat', value : 'justeat'});
         d.sourceOptions.push({label : 'Deliveroo', value : 'deliveroo'});
     return d;
  },
  methods : {
    search : function() {
        this.initialising=true;
        var that=this;
        that.data=null;
        RestHelper.post('/wb/api/admin/reports/deliveryheatmap', this.filter).then(function(response) {
            that.data=response.data;
            that.initialising=false;
        });
    }
    },
    created : function() {

    }
};
</script>

<style lang="scss" scoped>


</style>