//import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
//import {RestHelper} from '@/utils/RestHelper'
//import {PromiseUtils} from '@/utils/PromiseUtils'

var ProductImageViewModelFactory = {

    create : function(product) {

        var _imageUrl=null;

        function updateImageUrl() {
            if (product.images==null || product.images.length==0) {
                _imageUrl=null;
            } else {
                _imageUrl=product.images[0].imageUrl;
            }
        }



        function resetImageUrl() {
            if (_imageUrl!=null) {
                product.images=[{
                    imageUrl : _imageUrl
                }];
            } else {
                product.images=[];
            }
        }

        var ProductImageViewModel = {
            get imageUrl() {
                return _imageUrl;
            },
            set imageUrl(value) {
                _imageUrl=value;
                resetImageUrl();
            },
            removeImage : function() {
                ProductImageViewModel.imageUrl=null;
            }
        };

        ProductImageViewModel=reactive(ProductImageViewModel);

        updateImageUrl();

        return ProductImageViewModel;
    }

};

export {ProductImageViewModelFactory as ProductImageViewModelFactory};