
<template>
    <div class="upload-container">
        <div class="inner d-flex flex-column m-5">
            <VueFileAgent ref="vueFileAgent" accept="image/*" maxFiles="1" :multiple="false" v-model="fileRecords"></VueFileAgent>
            <button :disabled="fileRecords==null || fileRecords.length==0" class="mt-5 btn btn-dark" @click="upload()">UPLOAD</button>
        </div>
    </div>
</template>

<script>

import {PromiseUtils} from '@/utils/PromiseUtils'
import {RestHelper} from '@/utils/RestHelper'

export default {
  name: 'MediaUpload',
  components: {

  },
  props : ['callback'],
  data : function() {
    var d = {
        fileRecords : null
         };

     return d;
  },
  watch : {

  },
  methods : {
        upload : function() {
            var that=this;
            if (this.fileRecords!=null) {
                RestHelper.postFile('/wb/api/admin/media/upload/file', this.fileRecords.file).then(function(response) {
                    that.callback(response.data.url);
                }, PromiseUtils.viewErrorHandler('Error uploading file'));
            }
        }
    },
    created : function() {

    }
};
</script>

<style scoped>
.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner {
    max-width: 500px;
}

</style>