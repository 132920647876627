<template>
    <div class="card">
        <b-table :fields="fields" :items="staffList" empty-text="No staff members" show-empty>
            <template v-slot:cell(actions)="data">
              <a href="#" @click.prevent.stop="edit(data.item)"><fa icon="edit" class="fa"></fa></a>
            </template>
        </b-table>
    </div>
</template>

<script>
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'

export default {
  name: 'StaffList',
  components: {

  },
  data : function() {
    var that=this;
    var fields = [
      { key: 'name', label : 'Name', sortable : true},
      { key: 'email', label : 'Email', sortable : true},
      { key: 'active', label : 'Status', sortable : true, formatter : function(value) {
            if (value) {
                return 'Active';
            } else {
                return 'Inactive';
            }
        }},
      { key: 'roleNames', label : 'Roles', sortable : true, formatter : function(value) {
            if (value==null || value.length==0) {
                return '';
            } else {
                var str=value[0];
                for (var i=1;i<value.length;i++) {
                    str+=','+value[i];
                }
                return str;
            }
        }
      },
      { key: 'actions', label : '', thStyle:{width:'50px'}}
    ];
    var d = PageStack.setPage(that.$route, {
         fields : fields,
         header : 'Staff',
         buttons : [{
                    label : 'Create',
                    class : 'btn-primary',
                    icon : 'plus',
                    onClick : function() {
                        that.create();
                    },
                    hidden : false,
                    disabled : false
                }],
         staffList : [],
         initialising : false
     });
     return d;
  },
  methods : {
    edit : function(staff) {
        this.$router.push('/staff/'+staff.id);
    },
    create : function() {
        this.$router.push('/staff');
    },
    loadData : function() {
        this.initialising=true;
        var that=this;
        RestHelper.get('/wb/api/admin/staff').then(function(result) {
			that.staffList=result.data;
			that.initialising=false;
		},PromiseUtils.viewErrorHandler('Error loading staff', function() {
             that.initialising=false;
         }));
        }
    },
    created : function() {
        this.loadData();
    }
};
</script>

<style scoped>

</style>