<template>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{param.name}}</label>
      <div class="col-sm-9">
        <v-swatches v-model="param.value" show-fallback
                                                  fallback-input-type="color"

                                                  popover-x="left"></v-swatches>
      </div>
    </div>
</template>

<script>

import VSwatches from 'vue-swatches'

// Import the styles too, typically in App.vue or main.js
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'ColorParam',
  components: {
    VSwatches
  },
  props : ['param'],
  data : function() {
    var d = {};
    return d;
  },
  methods : {
    }
};
</script>

<style scoped>
</style>