<template>
    <form role="form" name="paymentForm" novalidate>
        <div>
            <div class="row" v-if="!data.model.activated">
                <div class="col-sm-12">
                    <p>Uber Eats integration is not activated.  Please contact support to enable.</p>
                </div>
            </div>
            <div class="row" v-if="data.model.activated">
                <div class="col-sm-12">
                    <div id="details" class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Synchronisation Options</h3>
                        </div>
                        <div class="box-body no-padding">
                            <ul class="list-group">
                                <li class="list-group-item actionable">
                                    <div>
                                        <span class="header">Menus synchronisation is: <span
                                            v-if="data.model.menuSync">Enabled</span><span
                                            v-if="!data.model.menuSync">Not Enabled</span></span>
                                        <p v-if="!data.model.menuSync">Menu changes will not be synchronised to Uber.</p>
                                        <p v-if="data.model.menuSync">Menu changes will be synchronised to Uber.</p>
                                    </div>
                                    <div>
                                        <b-form-checkbox switch size="lg" v-model="data.model.menuSync"></b-form-checkbox>
                                    </div>
                                </li>
                                <li class="list-group-item actionable">
                                    <div>
                                        <span class="header">Order synchronisation is: <span
                                            v-if="data.model.orderSync">Enabled</span><span
                                            v-if="!data.model.orderSync">Not Enabled</span></span>
                                        <p v-if="!data.model.orderSync">Uber orders will automatically appear in your POS once accepted.</p>
                                        <p v-if="data.model.orderSync">You will have to key in Uber orders automatically in your POS.</p>
                                    </div>
                                    <div>
                                        <b-form-checkbox switch size="lg" v-model="data.model.orderSync"></b-form-checkbox>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import {EditManager} from "@/utils/EditManager";
import {PageStack} from '@/services/PageStackService'


export default {
  name: 'UberEats',
  components: {

  },
  data : function() {
    var id=this.$route.params.id;
    var that=this;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/olo/config/'+id+'/ubereats').saveEndpoint('/wb/api/admin/olo/config/'+id+'/ubereats').saveAlert('Saved', 'Payment details have been saved.').build();
    var d = PageStack.setPage(that.$route, {
         header : 'Uber Eats',
         buttons : [data.createResetButton(),data.createSaveButton()],
         data : data
         });

     return d;
  },
  methods : {
            loadData : function() {
                this.data.load();
            }
        },
        created : function() {
            this.loadData();
        }
};
</script>

<style scoped>

</style>