import {ErrorsService} from '@/services/ErrorsService'
import { reactive } from '@vue/composition-api'
import {RestHelper} from '@/utils/RestHelper'
import {PromiseUtils} from '@/utils/PromiseUtils'

var ConfiguredItemViewModelFactory = {

    create : function(item, workAreaModifier) {
        if (item==null) {
            item={
                    id : null,
                    name : null,
                    displayName : null,
                    productId : null,
                    extras : []

            };
        }


        var parentNode=null;
        var parentDeleteListener=null;

        function setParentNode (parentNodeP) {
            if (parentNode==parentNodeP) {
                return;
            }
            if (parentDeleteListener!=null) {
                parentDeleteListener();
                parentDeleteListener=null;
            }
            if (parentNodeP!=null) {
                parentDeleteListener = workAreaModifier.addDeletionCallback(parentNodeP.id, function() {
                    parentDeleteListener=null;
                    parentNode=null;
                });
            }
            parentNode=parentNodeP;
        }

        var ConfiguredItemViewModel = {
            type : 'preconfigured',
            isPreconfigured : true,
            setParentNode : setParentNode,
            errors : ErrorsService.create()
        };


        var originalStr=null;

        function reset() {
            originalStr=JSON.stringify(item);
            ConfiguredItemViewModel.errors = ErrorsService.create();
            ConfiguredItemViewModel.model=reactive(JSON.parse(JSON.stringify(item)));
            ConfiguredItemViewModel.created=ConfiguredItemViewModel.model.id!=null;
        }

        reset();

        ConfiguredItemViewModel.isDirty=function() {
            return (originalStr!==JSON.stringify(ConfiguredItemViewModel.model));
        };

        ConfiguredItemViewModel.reset=reset;

        ConfiguredItemViewModel.delete=function() {
            var promise = new Promise(function(resolve, reject) {
                RestHelper.delete('/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/item/'+ConfiguredItemViewModel.model.id).then(function(result) {
                    handleDeleted(resolve, result);
                }, PromiseUtils.errorHandler(reject));

                function handleDeleted(resolve, result) {
                    if (result.data.success) {
                        workAreaModifier.deleteConfiguredItem(ConfiguredItemViewModel);
                        ConfiguredItemViewModel.created=false;
                        resolve({
                            success : true
                        });
                    } else {
                        resolve({
                            success : false,
                            errors : result.data.errors
                        });
                    }
                }

            });
            return promise;
        };

        ConfiguredItemViewModel.save=function() {
            var promise = new Promise(function(resolve,reject) {
            var url;
            if (ConfiguredItemViewModel.created) {
                url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/item';
            } else {
                url='/wb/api/admin/menubuilder/workarea/'+workAreaModifier.id+'/'+parentNode.type+'/'+parentNode.model.id+'/item';
            }
            ConfiguredItemViewModel.errors.clearServerErrors();
            RestHelper.put(url, ConfiguredItemViewModel.model).then(function(result) {
                handleSaveResult(resolve,reject, result);
            }, PromiseUtils.errorHandler(reject));


            function handleSaveResult(resolve,reject, result) {
                var created=ConfiguredItemViewModel.created;
                if (result.data.success) {
                    if (created) {
                        item=result.data.result;
                    } else {
                        item=result.data.result.configuredItem;
                    }
                    reset();
                    workAreaModifier.applyConfiguredItem(ConfiguredItemViewModel);
                    if (!created) {
                        parentNode.applyConfiguredItem(result.data.result.categoryProduct);
                    }
                    resolve({
                        success : true
                    });
                } else {
                    ConfiguredItemViewModel.errors.setServerErrors(result.data.errors);
                    resolve({
                        success : false
                    });
                }
            }
        });
        return promise;
        };


        return ConfiguredItemViewModel;
    }

};

export {ConfiguredItemViewModelFactory as ConfiguredItemViewModelFactory};