<template>
    
<div class="invoice p-3 mb-3">
    <div class="row" v-if="!data.initialising">

        <div class="col-12">
            <div class="header d-flex align-items-center">
                <div class="d-flex-column px-4 type-container">
                    <div class="type">{{data.model.type}}</div>
                    <div class="source">{{data.model.source}}</div>
                </div>
                <div class="date mx-5 flex-grow-1">@ {{$filters.formatDate(data.model.audit.placedTime, 'ddd DD MMM YYYY HH:mm')}}</div>
                <div class="status">{{data.model.orderStatus}}</div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <view-order-basket :model="data.model"/>
        </div>
        <div class="col-12 col-md-6">
            <view-order-details :model="data.model"/>
        </div>
   </div>
</div>

</template>

<script>
//import {PromiseUtils} from "@/utils/PromiseUtils";
//import {RestHelper} from "@/utils/RestHelper";
import {PageStack} from '@/services/PageStackService'
import {EditManager} from "@/utils/EditManager";
import ViewOrderBasket from './ViewOrderBasket';
import ViewOrderDetails from './ViewOrderDetails';

export default {
  name: 'ViewOrder',
  components: {
    ViewOrderBasket,
    ViewOrderDetails
  },
  data : function() {
    var that=this;
    var id = this.$route.params.id;
    var data = EditManager.builder(this.$bvModal).loadEndpoint('/wb/api/admin/order/'+id).deleteEndpoint('/wb/api/admin/order/'+id, this.deleted).build();
    var d = PageStack.addPage(that.$route, {
         header : 'View Order',
         data : data,
         buttons : [data.createDeleteButton()]
     });
     return d;
  },
  methods : {
    deleted : function() {
            PageStack.chopPage(this.$router);
        },
    goto : function() {
    
    },

    loadData : function() {

        }
    },
    computed : {
        typeIcon : function() {
            if (this.data.model.type=='delivery') {
                return 'car';
            } else {
                return null;
            }
        }
    },
    created : function() {
        this.data.load();
    }
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 20px;
    border-bottom: 1px solid lightgray;
    padding: 10px;

    .type-container {

        .type {
            font-size: 25px;
        }

        text-align: center;
        border-right: 1px solid lightgray;
    }


}
</style>